import 'src/theme/base.css';

export const theme = {
  fontFamily: "'Inter Variable', sans-serif",
  text: {
    fontType: {
      h1: `
      font-size: 4rem;
      line-height: 5rem;
      font-weight: 300;
      `,
      h2: `
      font-size: 3.6rem;
      line-height: 4.2rem;
      font-weight: 300;
      `,
      h3: `
      font-size: 3rem;
      line-height: 3.8rem;
      font-weight: bold;
      `,
      h4: `
      font-size: 2.4rem;
      line-height: 3.2rem;
      font-weight: bold;
      `,
      h5: `
      font-size: 2.4rem;
      line-height: 3.2rem;
      font-weight: normal;
      `,
      h6: `
      font-size: 2rem;
      line-height: 2.8rem;
      font-weight: 500;
      `,
      h7: `
      font-size: 1.6rem;
      line-height: 2.4rem;
      font-weight: 500;
      `,
      h8: `
      font-size: 1.4rem;
      line-height: 2rem;
      font-weight: 500;
      `,
      h9: `
      font-size: 1.2rem;
      line-height: 1.8rem;
      font-weight: 500;
      `,
      body1: `
      font-size: 2rem;
      line-height: 2.4rem;
      font-weight: normal;
      `,
      body2: `
      font-size: 1.6rem;
      line-height: 2.2rem;
      font-weight: normal;
      `,
      body3: `
      font-size: 1.4rem;
      line-height: 2rem;
      font-weight: normal;
      `,
      body4: `
      font-size: 1.2rem;
      line-height: 1.8rem;
      font-weight: normal;
      `,
      caption: `
      font-size: 1.2rem;
      line-height: 1.6rem;
      font-weight: 500;
      `,
      hint: `
      font-size: 1rem;
      line-height: 1.4rem;
      font-weight: normal;
      `,
      link: `
      font-size: 1.2rem;
      line-height: 1.6rem;
      font-weight: normal;
      `,
    },
    weight: {
      bold: 'bold',
      semi: 500,
      normal: 'normal',
      light: 300,
    },
  },
  spacing: {
    px: '0.1rem',
    0.5: '0.2rem',
    1: '0.4rem',
    2: '0.8rem',
    3: '1.2rem',
    4: '1.6rem',
    5: '2rem',
    6: '2.4rem',
    8: '3.2rem',
    10: '4rem',
    12: '4.8rem',
    16: '6.4rem',
    24: '9.6rem',
    28: '11.2rem',
    32: '12.8rem',
    36: '14.4rem',
  },
  radius: {
    none: '0',
    sm: '0.2rem',
    md: '0.4rem',
    big: '0.5rem',
    lg: '0.8rem',
    xl: '1.8rem',
    full: '50%',
  },
  shadows: {
    leftMenu: '0rem 0rem 1.5rem rgba(33, 33, 36, 0.2)',
    button: '0rem 0.5rem 1rem rgba(33, 33, 36, 0.1)',
    card: '0rem 0.5rem 1rem 0 rgba(33, 33, 36, 0.1)',
    cardHover: ' 0rem 0.5rem 1.5rem rgba(33, 33, 36, 0.2)',
    dialog: '0rem 0.5rem 1.5rem rgba(33, 33, 36, 0.1)',
  },
  colors: {
    blue: {
      100: '#227DF3',
      200: '#127DBA',
      300: '#CFD9E1',
      400: '#E1E3EA',
      500: '#F9F9FB',
      600: '#F7FAFC',
      700: '#E7F0F6',
      800: '#E8F9FF',
      900: '#1B62BF',
      1000: '#6496A8',
      translucent: 'rgba(34, 125, 243, 0.4)',
    },
    grey: {
      100: '#606473',
      200: '#7D8295',
      300: '#C0C6DA',
      400: '#BCBCBD',
      500: '#FCFCFC',
      600: '#99999C',
      800: '#F7F7F8',
      900: '#ACB2CC80',
      translucent: 'rgba(33, 33, 36, 0.5)',
    },
    black: {
      100: '#161B29',
      200: '#212124',
      300: '#000',
    },
    white: '#FFFFFF',
    green: {
      100: '#009F5C',
      200: '#00B976',
      300: '#00D28F',
      400: '#CCF6E9',
      500: '#E5FBF4',
    },
    red: {
      100: '#9D0000',
      200: '#B70002',
      300: '#D0021B',
      400: '#F6CCD1',
      500: '#FAE6E8',
    },
    orange: {
      300: '#FF602A',
      400: '#FFDFD4',
      500: '#FFEFEA',
    },
    background: '#DAE2F9',
    loader: {
      white: '#FFFFFF',
      primary: '#227DF3',
      dark: '#212124',
    },
    purple: '#697EC9',
  },
};
