import styled from 'styled-components';
import { MITextInput } from 'src/components/common/MITextInput';
import {
  AdType,
  CallOutAdType,
  CallOutFormErrors,
  CampaignType,
} from 'src/utils/types';
import { FormPreview } from '../FormPreview';
import { fullRow, optionalValues, optionalTextData } from './data';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import {
  createSkagAds,
  updateSkagAds,
  updateOneSkagAd,
} from 'src/redux/skagCompaign/actions';
import {
  createStagAds,
  updateStagAds,
  updateOneStagAd,
} from 'src/redux/stagCampaign/actions';
import { CallOutCardPreview } from './CallOutCardPreview';
import { MIFormAddButton } from 'src/components/common/MIFormAddButton';
import MIFormButtons from 'src/components/common/MIFormButtons';
import {
  invalidParenthesesMessage,
  invalidSpecialCharactersMessage,
} from '../data';
import { checkIsOnlyTemplate, validateParentheses } from 'src/utils/builder';
import { notifyError } from 'src/services/notifications/notificationService';
import { REGEX } from 'src/utils/consts';

type Props = {
  isSkag: boolean;
  initialValues: CallOutAdType;
  closeModal: (toNextStep?: boolean) => void;
  updateCampaignOnServer: (data?: CampaignType) => void;
};

export const CallOutForm = ({
  isSkag,
  initialValues,
  closeModal,
  updateCampaignOnServer,
}: Props) => {
  const isNewData = initialValues?.id === undefined;
  const dispatch = useDispatch();
  const validate = (values) => {
    const errors: CallOutFormErrors = {};
    delete values.keywords; // do not validate keywords and negatives (in case: edit ad from review table)
    delete values.negatives;

    const fields = Object.keys(values);
    let showParenthesesError = false;
    let showCharactersError = false;

    fields.forEach((item) => {
      if (typeof values[item] === 'object') {
        if (Array.isArray(values[item])) {
          values[item].forEach((value, index) => {
            if (!validateParentheses(value)) {
              errors[`${item}[${index}]`] = 'Invalid parentheses';
              showParenthesesError = true;
            }
            if (REGEX.EXCLAMATION_SYMBOL.test(value)) {
              errors[`${item}[${index}]`] = 'There is blocked symbol';
              showCharactersError = true;
            }
          });
        }
        return;
      }
      if (typeof values[item] !== 'boolean') {
        if (!validateParentheses(values[item])) {
          errors[item] = 'Invalid parentheses';
          showParenthesesError = true;
        }
        if (REGEX.EXCLAMATION_SYMBOL.test(values[item])) {
          errors[item] = 'There is blocked symbol';
          showCharactersError = true;
        }
      }
    });

    if (showParenthesesError) {
      notifyError({ msg: invalidParenthesesMessage });
    } else if (showCharactersError) {
      notifyError({ msg: invalidSpecialCharactersMessage });
    }
    if (!values.callOutTextOne && !values.callOutTextTwo && !values.callOutTextThree) {
      errors.callOutTextOne = 'This field is required';
    }

    return errors;
  };

  const formik = useFormik({
    validateOnBlur: false,
    validateOnChange: false,
    initialValues,
    validate,
    onSubmit: (values) => {
      if (!Object.keys(formik.errors).length) {
        closeModal(!!values.edited);

        const onlyTemplate = checkIsOnlyTemplate(AdType.CALLOUT);
        if (isNewData) {
          dispatch(
            isSkag
              ? createSkagAds(AdType.CALLOUT, values, onlyTemplate)
              : createStagAds(AdType.CALLOUT, values, onlyTemplate)
          );
        } else {
          if (isSkag) {
            dispatch(
              !values.edited || onlyTemplate
                ? updateSkagAds(initialValues.id, values, onlyTemplate)
                : updateOneSkagAd(initialValues.id, values)
            );
          } else {
            dispatch(
              !values.edited || onlyTemplate
                ? updateStagAds(initialValues.id, values, onlyTemplate)
                : updateOneStagAd(initialValues.id, values)
            );
          }
        }
        updateCampaignOnServer();
      }
    },
  });

  const inputHandler = (key: string, value: string | string[]) => {
    formik.setFieldValue(key, value);
    if (formik.errors) {
      formik.setErrors({});
    }
  };

  const renderInputs = (values, key?) => {
    const onChangeHandler = (value: string, item, index = null) => {
      inputHandler(index !== null ? item : item.key, value);
    };

    if (key) {
      return values.map((_, index) => (
        <MITextInput
          key={key + index}
          value={formik.values[key][index]}
          errorMessage={
            `${key}[${index}]` in formik.errors
              ? formik.errors[`${key}[${index}]`]
              : null
          }
          onChange={({ value }) =>
            onChangeHandler(value, `${[key]}[${index}]`, index)
          }
          onBlur={({ target }) =>
            onChangeHandler(target.value?.trim(), `${[key]}[${index}]`, index)
          }
          {...(key === optionalValues && optionalTextData)}
        />
      ));
    }
    return values.map((item) => (
      <MITextInput
        key={item.key}
        id={item.key}
        errorMessage={
          item.key in formik.errors ? formik.errors[item.key] : null
        }
        value={formik.values[item.key]}
        onChange={({ value }) => onChangeHandler(value, item)}
        onBlur={({ target }) => onChangeHandler(target.value?.trim(), item)}
        {...item}
      />
    ));
  };

  const optionalValuesHandler = (key) => {
    if (formik.values[key].length < 15) {
      formik.setFieldValue(key, formik.values[key].concat(''));
    }
  };

  return (
    <Wrapper>
      <Container>
        <ItemsContainer>
          <AddButtonContainer>
            <MIFormAddButton
              title='add new callout'
              onClick={() => optionalValuesHandler(optionalValues)}
            />
          </AddButtonContainer>
          {renderInputs(fullRow)}
          {formik.values.optionalValues?.length
            ? renderInputs(formik.values.optionalValues, optionalValues)
            : null}
        </ItemsContainer>
        <FormPreview
          type={AdType.CALLOUT}
          preview={<CallOutCardPreview item={formik.values} />}
        />
      </Container>
      <MIFormButtons closeModal={closeModal} saveHandler={formik.submitForm} />
    </Wrapper>
  );
};

const AddButtonContainer = styled.div`
  margin: ${(props) => props.theme.spacing[3]} 0;
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
`;

const Wrapper = styled.form`
  display: flex;
  flex-direction: column;
  min-width: 100rem;
  max-width: 120rem;
`;

const ItemsContainer = styled.div`
  border-radius: ${(props) => props.theme.radius.xl};
  padding: 4rem;
  background-color: ${(props) => props.theme.colors.white};
`;

const Container = styled.div`
  border: 0.1rem solid ${(props) => props.theme.colors.blue[300]};
  border-radius: ${(props) => props.theme.radius.xl};
  background-color: ${(props) => props.theme.colors.blue[700]};
`;
