import { useState, useCallback } from 'react';
import isEmpty from 'lodash/isEmpty';
import styled from 'styled-components';
import { FieldType } from 'src/utils/types';
import { MIModalMessage } from 'src/components/common/MIModalMessage';
import { GoogleLoginButton } from 'src/components/common/GoogleLoginButton';
import { MITextInput } from 'src/components/common/MITextInput';
import MIPasswordInput from 'src/components/common/MIPasswordInput';
import {
  notifyServerError,
  notifyInfo,
} from 'src/services/notifications/notificationService';
import { REGEX } from 'src/utils/consts';
import AuthenticationService from 'src/services/authenticationService';
import { AuthenticationErrorType } from 'src/infrastructure/restClient/models/AuthenticationErrorType';
import { MIButton } from 'src/components/common/MIButton';
import { WizardOrLine } from 'src/components/layout/WizardElements';
import { MILink } from 'src/components/common/MILink';
import { useDispatch } from 'react-redux';
import CampaignService from 'src/services/campaign/campaignService';
import { getCampaignsFailed, saveCampaigns } from 'src/redux/campaigns/actions';
//import GoogleServise from 'src/services/google/googleService';   //TODO review if something need to be done
const REACT_APP_CLIENT_ID = process.env.REACT_APP_CLIENT_ID;

type Props = {
  id: string;
  dismiss?: (event: React.MouseEvent | null) => void;
  setIsSignedIn: (value: boolean) => void;
  showSendResetPasswordLinkModal: () => void;
  onSignUpClick: () => void;
  onSuccess: () => void;
};

export const SignInModal = ({
  id,
  dismiss,
  setIsSignedIn,
  showSendResetPasswordLinkModal,
  onSignUpClick,
  onSuccess,
}: Props) => {
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);
  const [isGoogleProcessing, setIsGoogleProcessing] = useState<boolean>(false);
  const [validationErrors, setValidationErrors] = useState<Record<string, any>>(
    {}
  );
  const dispatch = useDispatch();

  const loadCampaigns = useCallback(() => {
    CampaignService.getCampaigns()
      .then((data) => {
        dispatch(saveCampaigns(data?.content || []));
      })
      .catch(() => {
        dispatch(getCampaignsFailed());
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onLogin = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    setValidationErrors({});

    if (!REGEX.EMAIL.test(email)) {
      setValidationErrors({
        email: 'Please enter your email, make sure it’s valid',
      });
      setIsLoading(false);
      return;
    } else if (isEmpty(password)) {
      setValidationErrors({
        password: 'Please enter your password',
      });
      setIsLoading(false);
      return;
    }

    const authenticationResult = await AuthenticationService.authenticate(
      email,
      password
    );

    if (authenticationResult.is_error) {
      setIsLoading(false);
      notifyServerError({ msg: 'Server error' });
      return;
    }
    if (
      authenticationResult.content?.authenticationErrorType ===
      AuthenticationErrorType.None
    ) {
      setIsLoading(false);
      setIsSignedIn(true);
      if (id === 'stepsSignInModal') {
        loadCampaigns();
      }
      dismiss && dismiss(event);
      onSuccess && onSuccess();
    }
    if (
      authenticationResult.content?.message &&
      authenticationResult.content?.is_error
    ) {
      setIsLoading(false);
      notifyInfo({ msg: authenticationResult.content?.message });
    }
  };

  const onGoogleLogin = async (event) => {
    setIsGoogleProcessing(true);
    setValidationErrors({});
    const authenticationResult = await AuthenticationService.singInByGoogle(
      event.code
    );
    if (authenticationResult.is_error) {
      setIsGoogleProcessing(false);
      notifyServerError({ msg: 'Server error' });
      return;
    }

    setIsSignedIn(true);
    if (id === 'stepsSignInModal') {
      loadCampaigns();
    }
    dismiss && dismiss(event);
    onSuccess && onSuccess();
    setIsGoogleProcessing(false);
  };

  const onFieldChanged = ({ id, value }: FieldType) => {
    if (id === 'email') {
      setEmail(value);
    }

    if (id === 'password') {
      setPassword(value);
    }
  };

  const onResetPasswordClicked = (event) => {
    showSendResetPasswordLinkModal();
    dismiss && dismiss(event);
  };

  const signUpClickHandler = () => {
    dismiss && dismiss(null);
    onSignUpClick && onSignUpClick();
  };

  return (
    <MIModalMessage
      dismiss={dismiss}
      titleComponent={
        <ModalTitleContainer>
          <ModalTitle>
            Log <Bold>In</Bold>
          </ModalTitle>
          <InputsContainer>
            <MITextInput
              id='email'
              value={email}
              label='Email'
              onChange={onFieldChanged}
              autoFocus
              autocomplete='username email'
              type='email'
              required
              errorMessage={validationErrors.email}
              placeholder='Type here...'
              transparent={false}
            />

            <MIPasswordInput
              id='password'
              value={password}
              label='Password'
              required
              errorMessage={validationErrors.password}
              onChange={onFieldChanged}
              placeholder='Type here...'
              transparent={false}
              additionalTitle={() => (
                <ResetPasswordLink to={onResetPasswordClicked}>
                  Forgot password?
                </ResetPasswordLink>
              )}
            />
          </InputsContainer>
          <MIButton
            label='Log In'
            type='submit'
            onClick={onLogin}
            isProcessing={isLoading}
            fullWidth
          />
          <WizardOrLine />
          <GoogleLoginButton
            onClick={onGoogleLogin}
            clientId={REACT_APP_CLIENT_ID}
            isLoading={isGoogleProcessing}
            disabled={isLoading}
          />
          <Separator />
          <Footer>
            Don't have an account?{' '}
            <LoginButton onClick={signUpClickHandler}>
              {' '}
              Sign up here
            </LoginButton>
          </Footer>
        </ModalTitleContainer>
      }
    />
  );
};

const ModalTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const ModalTitle = styled.span`
  ${(props) => props.theme.text.fontType.h5};
  margin-bottom: ${(props) => props.theme.spacing[2]};
`;

const Bold = styled.span`
  font-weight: ${(props) => props.theme.text.weight.bold};
`;

const InputsContainer = styled.div`
  margin-top: ${(props) => props.theme.spacing[2]};
  width: 100%;
`;

const ResetPasswordLink = styled(MILink)`
  color: ${({ theme }) => theme.colors.blue[100]};
  font-weight: ${(props) => props.theme.text.weight.semi};
`;

const Separator = styled.div`
  position: absolute;
  width: 50rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey[300]};
  bottom: 9rem;
`;

const Footer = styled.div`
  width: 100%;
  text-align: center;
  margin-top: 5rem;
  font-size: 1.6rem;
`;

const LoginButton = styled.span`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.purple};
`;
