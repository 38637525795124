import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { MIButton } from 'src/components/common/MIButton';
import DoneImage from 'src/images/general/done-image.svg';
import { LOCATIONS } from 'src/pages/locations';

export const Congratulation = () => {
  const history = useHistory();

  const handleSubmit = useCallback(() => {
    history.push(LOCATIONS.dashboard);
  }, [history]);

  return (
    <Container>
      <Image src={DoneImage} />
      <Title>
        <Bold>Congratulation!</Bold>
      </Title>
      <Description>
        A confirmation has been sent to your mail. You are now ready to access
        your lifetime plan.
      </Description>
      <MIButton label='Close' type='submit' onClick={handleSubmit} />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const Image = styled.img`
  width: 14rem;
  height: 14rem;
  margin-left: -4rem;
`;

const Title = styled.span`
  ${(props) => props.theme.text.fontType.h5};
  font-weight: ${(props) => props.theme.text.weight.normal};
  margin-bottom: 1.5rem;
`;

const Description = styled.span`
  text-align: center;
  ${(props) => props.theme.text.fontType.body2};
  margin-bottom: 3rem;
  letter-spacing: 0.1px;
`;

const Bold = styled.span`
  font-weight: ${(props) => props.theme.text.weight.bold};
`;
