import { KeywordIdeaType } from 'src/utils/types';
import { GlobalState } from '../types';

export const getPlannerKeywords = (state: GlobalState): string[] =>
  state.planner.keywords;

export const getGoogleKeywords = (state: GlobalState): KeywordIdeaType[] =>
  state.planner.googleKeywords;

export const getSelectedKeywords = (state: GlobalState): KeywordIdeaType[] =>
  state.planner.selectedKeywords;
