import { AdType, ExpTextAdExtType } from 'src/utils/types';

export const firstRow = [
  { label: 'Headline one', key: 'headlineOne', maxlength: 30, required: true },
  { label: 'Headline two', key: 'headlineTwo', maxlength: 30, required: true },
  {
    label: 'Headline three',
    key: 'headlineThree',
    maxlength: 30,
    required: true,
  },
];

export const secondRow = [
  { label: 'Final URL', key: 'finalUrl', required: true },
  { label: 'Path one', key: 'pathOne', maxlength: 25, required: true },
  { label: 'Path two', key: 'pathTwo', maxlength: 25, required: true },
];

export const fullRow = [
  {
    label: 'Description one',
    key: 'descriptionOne',
    maxlength: 90,
    required: true,
  },
  {
    label: 'Description two',
    key: 'descriptionTwo',
    maxlength: 90,
    required: true,
  },
];

export const defaultDataExpText: ExpTextAdExtType = {
  id: undefined,
  headlineOne: '[Keyword]',
  headlineTwo: '70% Off Or 2 Apparels for $24',
  headlineThree: 'New Season',
  descriptionOne:
    'Trendy & Affordable. High Quality. Daily Updates. Petite to Plus Sizes. Low Prices Today.',
  descriptionTwo:
    'Get Up to 80% off on Trendy & Stylish Collection of Clothing & Accessories. Shop Now.',
  finalUrl: 'https://www.google.com',
  pathOne: 'brand',
  pathTwo: 'apparel',
  type: AdType.EXPANDED,
};

export const fieldsToCheckBlockedSymbols = [
  'headlineOne',
  'headlineTwo',
  'headlineThree',
  'finalUrl',
  'pathOne',
  'pathTwo',
];
