import styled from 'styled-components';
import AdIcon from 'src/images/general/blue-plus-icon.svg';

type Props = {
  title: string;
  onClick: () => void;
};

export const MIFormAddButton = ({ title, onClick }: Props) => {
  return (
    <Container onClick={onClick}>
      <PlusIcon src={AdIcon}/>
      <Title>{title}</Title>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const Title = styled.div`
  margin-left: ${(props) => props.theme.spacing[1]};
  color: ${(props) => props.theme.colors.blue[100]};
  ${(props) => props.theme.text.fontType.body4};
`;

const PlusIcon = styled.img``;
