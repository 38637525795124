import React, { SyntheticEvent } from 'react';
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';
import { compose } from 'recompose';
import styled, { css } from 'styled-components';
import { OptionType, SliderSizeType, TextInputSize } from 'src/utils/types';
import { TEXT_INPUT_SIZE } from 'src/utils/consts';
import { withBreak } from 'src/hok/withBreak';
import { MISlider } from 'src/components/common/MISlider';
import { MINotices } from 'src/components/common/MINotices';
import { MIInputLabel } from 'src/components/common/MIInputLabel';
import { withOutsideClickHandler } from 'src/hok/withOutsideClickHandler';

type State = {
  hidden: boolean;
};

export enum INPUT_TYPE {
  TEXT = 'text',
  PASSWORD = 'password',
  NUMBER = 'number',
  TEL = 'tel',
  SEARCH = 'search',
}

export type InputModeType =
  | 'text'
  | 'none'
  | 'tel'
  | 'url'
  | 'email'
  | 'numeric'
  | 'decimal'
  | 'search'
  | undefined;

export type InputType =
  | INPUT_TYPE.TEXT
  | INPUT_TYPE.PASSWORD
  | INPUT_TYPE.NUMBER
  | INPUT_TYPE.TEL
  | INPUT_TYPE.SEARCH;

export type MITextInputBaseProps = {
  id: string;
  value?: string | number | null;
  label: string;
  placeholder?: string;
  type?: InputType;
  step?: number;
  notices?: Array<string>;
  errorMessage?: string | null;
  errorMessageIcon?: React.ReactNode;
  disabled?: boolean;
  required?: boolean;
  readOnlyValue?: boolean;
  size?: TextInputSize;
  autoFocus?: boolean;
  viewOnly?: boolean;
  autocomplete?: string;
  min?: any;
  max?: any;
  onClick: (e: SyntheticEvent<HTMLInputElement>) => void;
  onFocus: () => void;
  onBlur: () => void;
  onKeyPress?: (event: KeyboardEvent) => void;
  device: { isMobile: boolean };
  maxlength?: number;
  pattern?: string;
  inputMode?: InputModeType;
  suffix?: any;
  withDefaultInputArrows?: boolean;
  transparent?: boolean;
  withPin?: boolean;
  isPinned?: boolean;
  pinnedOptions?: OptionType[];
  onPin?: (value: string) => void;
  selectedPinOption?: string;
  additionalTitle?: () => void;
  withSlider?: boolean;
  sliderSize?: SliderSizeType;
  onSliderChange: (value: boolean, id?: string) => any;
};

type Props = MITextInputBaseProps & {
  onChange?: (changeField: { id: string; value: string }) => void;
};

type PropsPassthrough = MITextInputBaseProps & {
  onChange: (
    changeField:
      | { id: string; value: string }
      | SyntheticEvent<HTMLInputElement>
  ) => void;
};

class MITextInputBase<
  T extends MITextInputBaseProps
> extends React.PureComponent<T, State> {
  static defaultProps = {
    disabled: false,
    required: false,
    readOnlyValue: false,
    placeholder: '',
    notices: [],
    type: INPUT_TYPE.TEXT,
    step: undefined,
    size: TEXT_INPUT_SIZE.WIZARD,
    viewOnly: false,
    errorMessage: null,
    errorMessageIcon: null,
    autoFocus: false,
    passthroughOnChange: false,
    min: undefined,
    max: undefined,
    maxlength: undefined,
    pattern: undefined,
    inputMode: undefined,
    suffix: undefined,
    withDefaultInputArrows: false,
    transparent: true,
    withPin: false,
    pinnedOptions: [],
    onPin: () => {},
    selectedPinOption: 'none',
    withSlider: false,
  };

  constructor(props: T) {
    super(props);
    this.state = {
      hidden: true,
    };
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
  handleChange = (e: SyntheticEvent<HTMLInputElement>) => {};
  pinHandler = (value) => {
    if (this.props.onPin) {
      this.props.onPin(value);
      this.setState({ hidden: true });
    }
  };
  render() {
    const {
      id,
      type,
      step,
      placeholder,
      errorMessage,
      size,
      viewOnly,
      disabled,
      required,
      label,
      value,
      notices,
      onClick,
      readOnlyValue,
      onFocus,
      onBlur,
      onKeyPress,
      autocomplete,
      min,
      max,
      device,
      maxlength,
      errorMessageIcon,
      pattern,
      inputMode,
      suffix,
      withDefaultInputArrows,
      transparent,
      withPin,
      pinnedOptions,
      selectedPinOption,
      additionalTitle,
      withSlider,
      sliderSize,
      onSliderChange,
    } = this.props as MITextInputBaseProps;
    const placeholderText = !placeholder ? '' : placeholder;
    const autoFocus = device.isMobile ? false : this.props.autoFocus;
    const shouldShowSlider = withSlider && onSliderChange && !isNil(disabled);
    return (
      <Container
        className='input-container'
        size={size}
        withDefaultInputArrows={withDefaultInputArrows}
        handleClickOutside={() => withPin && this.setState({ hidden: true })}
      >
        {(label || additionalTitle) && (
          <TitlesWrapper withMargin={shouldShowSlider}>
            {shouldShowSlider && (
              <SliderWrapper>
                <MISlider
                  size={sliderSize}
                  value={!disabled}
                  onChange={onSliderChange}
                />
              </SliderWrapper>
            )}
            <MIInputLabel
              inputId={id}
              label={label}
              errorMessage={errorMessage}
              size={
                shouldShowSlider
                  ? TEXT_INPUT_SIZE.INLINE
                  : TEXT_INPUT_SIZE.WIZARD
              }
              required={required}
            />
            {additionalTitle && additionalTitle()}
          </TitlesWrapper>
        )}
        <InputWrapper>
          <React.Fragment>
            <TextInput
              id={id}
              size={size}
              name={id}
              disabled={disabled}
              label={label}
              value={value == null ? '' : value}
              placeholder={placeholderText}
              error={errorMessage}
              type={type}
              step={step}
              readOnly={readOnlyValue}
              viewOnly={viewOnly}
              autoFocus={autoFocus}
              onChange={this.handleChange}
              onClick={onClick}
              onFocus={onFocus}
              onBlur={onBlur}
              onKeyPress={onKeyPress}
              autoComplete={autocomplete}
              min={min}
              max={max}
              pattern={pattern}
              inputMode={inputMode}
              transparent={transparent}
              onWheel={(e) => e.target.blur()}
            />
          </React.Fragment>

          {suffix && (
            <Suffix
              disabled={!withPin}
              onClick={() => this.setState({ hidden: !this.state.hidden })}
            >
              {suffix}
            </Suffix>
          )}
          {withPin ? (
            <DropDownContainer hidden={this.state.hidden}>
              <List>
                {(pinnedOptions || []).map((option) => {
                  const isSelected = selectedPinOption === option.value;
                  return (
                    <DropDownOption
                      key={option.value}
                      onClick={() => this.pinHandler(option.value)}
                      isSelected={isSelected}
                    >
                      {option?.label}
                    </DropDownOption>
                  );
                })}
              </List>
            </DropDownContainer>
          ) : null}
        </InputWrapper>
        {(!isEmpty(notices) || !isEmpty(errorMessage)) && (
          <MINotices
            size={size}
            notices={notices}
            errorMessage={errorMessage}
            errorMessageIcon={errorMessageIcon}
          />
        )}
        {!errorMessage && maxlength ? (
          <MINotices
            size={TEXT_INPUT_SIZE.WIZARD}
            notices={[
              `used ${String(value || '').length} of ${maxlength} chars`,
            ]}
            align='center'
            invalid={String(value || '').length > maxlength}
          />
        ) : null}
      </Container>
    );
  }
}

class PlainMITextInput extends MITextInputBase<Props> {
  handleChange = (e: SyntheticEvent<HTMLInputElement>) => {
    const { disabled, onChange, id, type } = this.props;
    if (!disabled && onChange) {
      onChange(
        Object.assign(
          {},
          {
            id,
            value: e.currentTarget.value,
            valueAsNumber: e.currentTarget.valueAsNumber,
          },
          type ? { type } : {}
        )
      );
    }
  };
}

export const MITextInput = compose(withBreak())(PlainMITextInput);

class MITextInputPassthroughBase extends MITextInputBase<PropsPassthrough> {
  handleChange = (e: SyntheticEvent<HTMLInputElement>) => {
    if (!this.props.disabled) {
      this.props.onChange(e);
    }
  };
}

export const MITextInputPassthrough = compose(withBreak())(
  MITextInputPassthroughBase
);

const Container = withOutsideClickHandler(styled.div<{ size?: TextInputSize }>`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-bottom: ${({ theme, size }) =>
    size === TEXT_INPUT_SIZE.WIZARD ? theme.spacing[5] : '0'};
  ${({ withDefaultInputArrows }) =>
    !withDefaultInputArrows &&
    `
    input[type=number] {
      -moz-appearance:textfield;
    }
    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      margin: 0;
    }
  `}
`);

const placeholderStyle = ({ theme }) => `
  color: ${({ theme }) => theme.colors.grey[200]};
  letter-spacing: -0.028rem;
  ${theme.text.fontType.body3};
`;

const calculateTextInputBackground = (
  theme: any,
  transparent: boolean,
  disabled: boolean
) => {
  if (transparent) {
    return 'transparent';
  }

  if (disabled) {
    return theme.colors.blue[400];
  }

  return theme.colors.white;
};

const TextInput = styled.input.attrs<{
  transparent?: boolean;
  disabled?: boolean;
  error?: string | null;
  viewOnly?: boolean;
  label?: string;
  maxLength?: number;
  size?: TextInputSize;
}>((props) => ({
  maxlength: props?.maxLength,
}))`
  width: ${({ size }) =>
    size === TEXT_INPUT_SIZE.SMALL ? '7rem' : 'calc(100% - 2.5rem)'};
  height: 2rem;
  background: ${({ theme, transparent, disabled }) =>
    calculateTextInputBackground(theme, transparent, disabled)};
  padding: ${({ theme }) => theme.spacing[2]}
    ${({ theme }) => theme.spacing[3]};
  border: 0.1rem solid ${({ theme }) => theme.colors.blue[300]};
  border-color: ${({ theme, error }) =>
    error ? theme.colors.red[300] : theme.colors.blue[300]};
  outline: true;
  ${({ theme }) => theme.text.fontType.body3};
  color: ${({ theme }) => theme.colors.black[100]};
  border-radius: ${({ theme }) => theme.radius.lg};
  
  ${({ viewOnly }) =>
    viewOnly &&
    css`
      pointer-events: none;
      color: ${({ theme }) => theme.colors.blue[400]};
    `};

    ${({ theme }) => theme.text.fontType.body3};

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-transition: "color 9999s ease-out, background-color 9999s ease-out";
    -webkit-transition-delay: 9999s;
  }

  &:-webkit-autofill::first-line {
    ${({ theme }) => theme.text.fontType.body3};
  }

  &::-webkit-input-placeholder {
    ${(props) => placeholderStyle(props)}
  }
  &::-moz-placeholder {
    ${(props) => placeholderStyle(props)}
  }

  &:-ms-input-placeholder {
    ${(props) => placeholderStyle(props)}
  }

  &::placeholder {
    ${(props) => placeholderStyle(props)}
  }

  &:focus {
    border-color: #4D90FE;
    outline: none;
  }

  &:disabled {
    -webkit-text-fill-color: ${({ theme }) => theme.colors.grey[200]};
    color: ${({ theme }) => theme.colors.grey[200]}};
    opacity: 1;
  }
`;

const InputWrapper = styled.div`
  display: flex;
  position: relative;
  white-space: nowrap;
`;

const Suffix = styled.div`
  position: absolute;
  bottom: 1rem;
  z-index: 10;
  overflow: hidden;
  right: 1rem;
  cursor: pointer;
  ${({ theme }) => theme.text.fontType.body3};
`;

const DropDownContainer = styled.div<{ hidden?: boolean }>`
  position: absolute;
  top: 100%;
  border-radius: ${({ theme }) => theme.radius.lg};
  width: 100%;
  z-index: 1;
  box-sizing: border-box;
  box-shadow: ${({ theme }) => theme.shadows.card};
  background-color: ${({ theme }) => theme.colors.white};
`;

const listHeight = 27.6;
const List = styled.div`
  padding: ${({ theme }) => theme.spacing[2]} 0;
  max-height: ${listHeight}rem;
  overflow: auto;
  &:focus {
    outline: none;
  }
  &::-webkit-scrollbar {
    width: 0.6rem;
    height: 10rem;
  }
  &::-webkit-scrollbar-track {
    border-radius: ${({ theme }) => theme.radius.md};
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.blue[100]};
    opacity: 0.4;
    border-radius: ${({ theme }) => theme.radius.md};
  }
`;

const DropDownOption = styled.div<{ isSelected: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  padding: ${({ theme }) => theme.spacing[2]} ${({ theme }) => theme.spacing[3]};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${({ theme, isSelected }) => isSelected && theme.colors.blue[100]};
  ${({ theme }) => theme.text.fontType.body3};
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.colors.blue[100]};
  }
`;

const TitlesWrapper = styled.div<{ withMargin?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${({ theme, withMargin }) =>
    withMargin ? theme.spacing[2] : '0'};
`;

const SliderWrapper = styled.div`
  margin-right: ${({ theme }) => theme.spacing[2]};
`;

export { TextInput, Container, InputWrapper };
