import styled from 'styled-components';
import { RespSearchAdType } from 'src/utils/types';
import ResponsiveIcon from 'src/images/general/responsive-icon.svg';

type Props = {
  item: RespSearchAdType;
};

export const RespResearchPreview = ({ item }: Props) => {
  const showLabel = (position: string, defaultValue: string, isHeadlines?: boolean) => {
    const pinnedField = isHeadlines ? 'pinnedHeadlines' : 'pinnedDescriptions';
    const field = isHeadlines ? 'headlines' : 'descriptions';
    if (item[pinnedField][position].length) {
      return item[field]?.find(headline => headline.id === item[pinnedField][position][0])?.value;
    } else {
      return defaultValue;
    }
  };

  return (
    <>
      <AdPreviewContainer>
        <TitleContainer>
          <AdIcon src={ResponsiveIcon} />
          <Title>
            {`
              ${showLabel('positionOne', item.headlines[0]?.value, true)} | 
              ${showLabel('positionTwo', item.headlines[1]?.value, true)} | 
              ${showLabel('positionThree', item.headlines[2]?.value, true)} 
            `}
          </Title>
        </TitleContainer>
        <Link>{item.finalUrl}</Link>
        <Description>{showLabel('positionOne', item.descriptions[0]?.value)}</Description>
        <Description>{showLabel('positionTwo', item.descriptions[1]?.value)}</Description>
      </AdPreviewContainer>
    </>
  );
};

const AdPreviewContainer = styled.div`
  display: flex;
  width: 60rem;
  height: fit-content;
  flex-direction: column;
  padding: 1.7rem;
  box-sizing: border-box;
  border-radius: ${(props) => props.theme.radius.lg};
  background-color: ${(props) => props.theme.colors.white};
`;

const TitleContainer = styled.span`
  display: flex;
  align-items: center;
`;

const Title = styled.span`
  color: ${(props) => props.theme.colors.blue[200]};
  ${(props) => props.theme.text.fontType.body3};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const Link = styled.span`
  padding: 0.2rem 0;
  color: ${(props) => props.theme.colors.green[200]};
  ${(props) => props.theme.text.fontType.link};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const Description = styled.span`
  color: ${(props) => props.theme.colors.black[100]};
  ${(props) => props.theme.text.fontType.link};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: 2rem;
`;

const AdIcon = styled.img`
  width: 2.2rem;
  height: 2.2rem;
  margin-right: 0.8rem;
`;
