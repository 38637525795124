import styled from 'styled-components';
import { config } from 'src/config/default';
import { CampaignType, AdBuilderType } from 'src/utils/types';
import { BuilderItem } from 'src/pages/main/components/BuilderItem';

import { useDispatch, useSelector } from 'react-redux';
import { setSkagStep } from 'src/redux/skagCreationFlow/actions';
import { setStagStep } from 'src/redux/stagCreationFlow/actions';

import { getSkagCampaign } from 'src/redux/skagCompaign/selectors';
import { getStagCampaign } from 'src/redux/stagCampaign/selectors';
import { useHistory } from 'react-router-dom';
import { LOCATIONS } from 'src/pages/locations';
import { cleanSkagCampaign } from 'src/redux/skagCompaign/actions';
import { cleanStagCampaign } from 'src/redux/stagCampaign/actions';

export const BuilderContainer = () => {
  const skagCampaign: CampaignType = useSelector(getSkagCampaign);
  const stagCampaign: CampaignType = useSelector(getStagCampaign);
  const history = useHistory();

  const dispatch = useDispatch();

  const startSKAGFlow = () => {
    dispatch(setSkagStep(1));
    if (skagCampaign?._id) {
      dispatch(cleanSkagCampaign());
    }
    history.push(LOCATIONS.skagBuilder);
  };

  const startSTAGFlow = () => {
    dispatch(setStagStep(1));
    if (stagCampaign?._id) {
      dispatch(cleanStagCampaign());
    }
    history.push(LOCATIONS.stagBuilder);
  };

  const startADFlow = () => {
    // setSelectedBuilderType(AdBuilderType.AD);
  };

  const renderBuilderStep = () => {
    return (
      <Container>
        <BuilderItem type={AdBuilderType.SKAG} onClick={startSKAGFlow} />
        <BuilderItem type={AdBuilderType.STAG} onClick={startSTAGFlow} />
        {config.featureFlags.ad && (
          <BuilderItem type={AdBuilderType.AD} onClick={startADFlow} />
        )}
      </Container>
    );
  };

  return renderBuilderStep();
};

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  > div:nth-child(2) {
    margin: 0 ${(props) => props.theme.spacing[8]};
  }
`;
