import { useModal } from 'src/helpers/react/useModal';
import styled from 'styled-components';
import { CreationForm } from './components/CreationForm';
import { SaveConversionModal } from './modals/SaveConversionModal';

export const ConversionPage = () => {
  const [SaveConversion, showSaveConversionModal] = useModal(
    SaveConversionModal,
    {
      id: 'saveConversionModal',
    }
  );
  return (
    <>
      {SaveConversion}
      <Container>
        <Title>
          Create new <Bold>Conversion</Bold>
        </Title>
        <CreationForm onSuccessSubmit={showSaveConversionModal} />
      </Container>
    </>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: ${({ theme }) => theme.spacing[16]};
`;

const Title = styled.span`
  margin: ${({ theme }) => theme.spacing[10]} 0;
  ${(props) => props.theme.text.fontType.h2};
`;
const Bold = styled.b``;
