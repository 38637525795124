import ReactTagInput, { ReactTagInputProps } from '@pathofdev/react-tag-input';
import styled from 'styled-components';

import '@pathofdev/react-tag-input/build/index.css';

type TagInputProps = ReactTagInputProps & { icon?: React.ComponentType };

export const TagInput = ({ icon: Icon, ...props }: TagInputProps) => {
  return (
    <Wrapper>
      {Icon && <Icon />}
      <ReactTagInput {...props} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: ${({ theme }) => theme.spacing[2]};
  padding: 0 ${({ theme }) => theme.spacing[4]};
  min-height: 5.5rem;

  border-radius: 1rem;
  border: 0.1rem solid ${({ theme }) => theme.colors.grey[300]};

  background: ${({ theme }) => theme.colors.white};

  &:focus-within {
    border-color: ${({ theme }) => theme.colors.blue[200]};
  }

  .react-tag-input {
    min-height: 5.5rem;
    height: 100%;
    border: none;
    box-sizing: border-box;
  }

  .react-tag-input__input {
    font-size: 1.4rem;
  }

  .react-tag-input__tag {
    min-height: 3.9rem;
    margin: 0.6rem 0.5rem;
    padding: 0 1rem 0 0.5rem;
    color: #fff;
    background-color: #227df3;
    border-radius: 2rem;
    font-size: 1.8rem;
    line-height: 2.2rem;
    font-weight: 300;
  }

  .react-tag-input__tag__remove {
    width: 2rem;
    height: 2rem;
    background-color: #fff;
    border-radius: 50%;
  }

  .react-tag-input__tag__remove:before,
  .react-tag-input__tag__remove:after {
    width: 0.08rem;
    background-color: #227df3;
  }
`;

TagInput.defaultProps = {
  icon: null,
};
