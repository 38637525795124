import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import { MIModalMessage } from 'src/components/common/MIModalMessage';
import DownloadImage from 'src/images/general/download-image.svg';
import GoogleAdwardsLogo from 'src/images/general/google-adwards-logo.svg';
import CloudDownloadImage from 'src/images/general/cloud-download.svg';
import { useDispatch, useSelector } from 'react-redux';
import { getSkagCampaign } from 'src/redux/skagCompaign/selectors';
import CampaignService from 'src/services/campaign/campaignService';
import {
  notifyServerError,
  notifyInfo,
  notifySuccess,
} from 'src/services/notifications/notificationService';
import { DownloadCSV } from './components/DownloadCSV';
import { CampaignType, ImportError } from 'src/utils/types';
import { getStagCampaign } from 'src/redux/stagCampaign/selectors';
import {
  generateNegatives,
  replaceTemplatesDependOnTemplateType,
} from 'src/utils/builder';
import { getUserDetails } from 'src/redux/user/selectors';
import { USER_PLAN } from 'src/utils/consts';
import { loadSkagCampaign } from 'src/redux/skagCompaign/actions';
import { loadStagCampaign } from 'src/redux/stagCampaign/actions';
import GoogleService from 'src/services/google/googleService';
import { ConnectGoogleAds } from './components/ConnectGoogleAds';
import { GoogleAccountList } from 'src/services/google/models/GoogleAccountList';
import { SelectImportAccount } from './components/SelectImportAccount';
import { ModalActionItem } from './components/ModalActionItem';
import { ImportToGoogleAds } from './components/ImportToGoogleAds';
import { Errors } from './components/Errors';
import { ImportToGoogle } from './components/ImportToGoogle';

type Props = {
  isSkag: boolean;
  dismiss?: (event: React.MouseEvent) => void;
  upgradePlan: () => void;
};

type ModalScreens =
  | 'CSV_DOWNLOAD'
  | 'IMPORT_TO_GOOGLE'
  | 'GOOGLE_ADS_AUTH'
  | 'SELECT_ACCOUNTS'
  | 'APPROVE_IMPORT'
  | 'DEFAULT'
  | 'ERRORS';

const isGoogleAdsImportEnabled =
  process.env.REACT_APP_GOOGLE_ADS_IMPORT === 'true';

export const SaveCampaignModal = ({ isSkag, upgradePlan, dismiss }: Props) => {
  const [isDownloadProcessing, setIsDownloadProcessing] =
    useState<boolean>(false);
  const [isGoogleProcessing, setIsGoogleProcessing] = useState<boolean>(false);
  const [isUpdateProcessing, setIsUpdateProcessing] = useState<boolean>(false);
  const [currentScreen, setCurrentScreen] = useState<ModalScreens>('DEFAULT');
  const [importErrors, setImportErrors] = useState<ImportError[]>([]);
  const [accounts, setAccounts] = useState<GoogleAccountList[]>([]);
  const [selectedAccountId, setAccountId] = useState<number | null>(null);
  const dispatch = useDispatch();
  const isDefaultScreen = currentScreen === 'DEFAULT';
  const userDetails = useSelector(getUserDetails);
  const isPaidPlan =
    userDetails?.plan === USER_PLAN.BASIC ||
    userDetails?.plan === USER_PLAN.PRO;
  const campaign: CampaignType = useSelector(
    isSkag ? getSkagCampaign : getStagCampaign
  );
  const negativesList = useMemo(() => {
    return campaign.negativePhrase
      ? generateNegatives(campaign.adGroupList)
      : [];
  }, [campaign.adGroupList, campaign.negativePhrase]);
  const isDownloadDisabled = isGoogleProcessing || isUpdateProcessing;
  const isUpdateDisabled = isGoogleProcessing || isDownloadProcessing;
  const isGoogleDisabled = isUpdateProcessing || isDownloadProcessing;

  const requestHandler = useCallback(
    (response, type) => {
      if (response.is_error) {
        notifyServerError({ msg: 'Server error' });
        return;
      }
      const updatedData = {
        ...campaign,
        draft: false,
        _id: response._id,
        name: response.name,
      };
      dispatch(
        isSkag ? loadSkagCampaign(updatedData) : loadStagCampaign(updatedData)
      );
      notifySuccess({
        msg: `Campaign ${type} successful`,
      });
    },
    [campaign, dispatch, isSkag]
  );

  const onCampaignUpdate = useCallback(async () => {
    if (isUpdateDisabled) {
      return;
    }

    setIsUpdateProcessing(true);
    if (campaign._id) {
      CampaignService.updateCampaign({ ...campaign, draft: false })
        .then((response) => {
          requestHandler(response, 'edit');
          setIsUpdateProcessing(false);
        })
        .catch(() => {
          setIsUpdateProcessing(false);
          notifyServerError({ msg: 'Server error' });
        });
      return;
    }

    delete campaign._id;

    CampaignService.saveCampaign({ ...campaign, draft: false })
      .then((response) => {
        requestHandler(response, 'save');
        setIsUpdateProcessing(false);
      })
      .catch((err) => {
        setIsUpdateProcessing(false);
        notifyServerError({ msg: 'Server error' });
      });
  }, [campaign, requestHandler, isUpdateDisabled]);

  const uploadToGoogle = useCallback(async (shouldConnect = true) => {
    try {
      setIsGoogleProcessing(true);
      const { is_error, content } = await GoogleService.getGoogleAccountList();
      setIsGoogleProcessing(false);

      if (is_error || !content) {
        notifyServerError({ msg: content?.msg || 'Server error' });
        return;
      }

      const { accounts, msg } = content;

      if (!accounts?.length) {
        notifyInfo({ msg });
        setCurrentScreen(shouldConnect ? 'GOOGLE_ADS_AUTH' : 'DEFAULT');
        return;
      }

      setAccounts(accounts);
      setCurrentScreen('SELECT_ACCOUNTS');
    } catch (error) {
      setIsGoogleProcessing(false);
      notifyServerError({ msg: 'Server error' });
      setCurrentScreen('DEFAULT');
    }
  }, []);

  const csvDownload = useCallback(async () => {
    setIsDownloadProcessing(true);
    campaign.adGroupList = campaign.adGroupList.map((group) => {
      const adGroupName = group.adGroup.replaceAll('\r', '');
      const formsInJson = JSON.stringify(group);
      const formWithReplacedKeywords = replaceTemplatesDependOnTemplateType(
        formsInJson,
        adGroupName
      );
      const parsedForm = JSON.parse(formWithReplacedKeywords);
      const negatives = negativesList.filter(
        (negative) => negative.value !== parsedForm.adGroup
      );
      return { ...parsedForm, negatives };
    });

    CampaignService.csvDownload(campaign)
      .then((response) => {
        if (response.is_error) {
          setIsDownloadProcessing(false);
          notifyServerError({ msg: 'Server error' });
          return;
        }
        setIsDownloadProcessing(false);
        notifySuccess({ msg: 'Campaigns download started' });
        return;
      })
      .catch((err) => {
        setIsDownloadProcessing(false);
        notifyServerError({ msg: 'Server error' });
        return;
      });
  }, [campaign, negativesList]);

  const onCsvDownload = useCallback(() => {
    if (isDownloadDisabled) {
      return;
    }

    if (isPaidPlan) {
      csvDownload();
    } else {
      setCurrentScreen('CSV_DOWNLOAD');
    }
  }, [csvDownload, isPaidPlan, isDownloadDisabled]);

  const onConnectGoogleAds = useCallback(
    async () => uploadToGoogle(false),
    [uploadToGoogle]
  );

  const onAccountSelect = useCallback((accountId: number) => {
    setAccountId(accountId);
    setCurrentScreen('APPROVE_IMPORT');
  }, []);

  const onGoogleUpload = useCallback(() => {
    if (isGoogleDisabled) {
      return;
    }

    if (isPaidPlan) {
      uploadToGoogle();
    } else {
      setCurrentScreen('IMPORT_TO_GOOGLE');
    }
  }, [uploadToGoogle, isPaidPlan, isGoogleDisabled]);

  const importCampaign = useCallback(async () => {
    if (!selectedAccountId) {
      setCurrentScreen('SELECT_ACCOUNTS');
      notifyInfo({ msg: 'Please, select Google ads account.' });
      return;
    }

    try {
      const response = await GoogleService.importCampaign(
        selectedAccountId,
        campaign
      );

      if (response.is_error && response?.error_content) {
        setImportErrors(response.error_content.data.importErrors);
        notifyServerError({ msg: response.error_content.message || '' });
        setCurrentScreen('ERRORS');
        return;
      }

      notifySuccess({ msg: 'Campaign has been imported successfully!' });
      setCurrentScreen('DEFAULT');
    } catch (error) {
      notifyServerError({ msg: 'Server Error' });
      setCurrentScreen('DEFAULT');
    }
  }, [campaign, selectedAccountId]);

  const modalContent = useMemo(() => {
    switch (currentScreen) {
      case 'CSV_DOWNLOAD':
        return (
          <DownloadCSV
            csvDownload={csvDownload}
            upgradePlan={upgradePlan}
            onPrev={() => setCurrentScreen('DEFAULT')}
          />
        );
      case 'IMPORT_TO_GOOGLE':
        return (
          <ImportToGoogle
            importToGoogle={uploadToGoogle}
            upgradePlan={upgradePlan}
            onPrev={() => setCurrentScreen('DEFAULT')}
            loading={isGoogleProcessing}
          />
        );
      case 'GOOGLE_ADS_AUTH':
        return <ConnectGoogleAds handleConnect={onConnectGoogleAds} />;
      case 'SELECT_ACCOUNTS':
        return (
          <SelectImportAccount
            accounts={accounts}
            onSelect={onAccountSelect}
            onSwitchAccount={() => setCurrentScreen('GOOGLE_ADS_AUTH')}
          />
        );
      case 'APPROVE_IMPORT':
        return (
          <ImportToGoogleAds
            campaign={campaign}
            onSubmit={importCampaign}
            onPrev={() => setCurrentScreen('SELECT_ACCOUNTS')}
          />
        );
      case 'ERRORS':
        return <Errors errors={importErrors} dismiss={dismiss} />;
      case 'DEFAULT':
      default:
        return (
          <ModalTitleContainer>
            <ModalTitle>
              Save <Bold>Campaign</Bold>
            </ModalTitle>
            <ItemsContainer>
              <ModalActionItem
                onClick={onCsvDownload}
                icon={DownloadImage}
                title='Download'
                subtitle='CSV'
                loading={isDownloadProcessing}
                disabled={isGoogleProcessing || isUpdateProcessing}
              />
              {isGoogleAdsImportEnabled && (
                <ModalActionItem
                  onClick={onGoogleUpload}
                  icon={GoogleAdwardsLogo}
                  title='Import To'
                  subtitle='Google Ads'
                  loading={isGoogleProcessing}
                  disabled={isUpdateProcessing || isDownloadProcessing}
                />
              )}
              <ModalActionItem
                onClick={onCampaignUpdate}
                icon={CloudDownloadImage}
                title='Save'
                subtitle='Campaign'
                loading={isUpdateProcessing}
                disabled={isGoogleProcessing || isDownloadProcessing}
              />
            </ItemsContainer>
          </ModalTitleContainer>
        );
    }
  }, [
    accounts,
    campaign,
    importErrors,
    currentScreen,
    isUpdateProcessing,
    isGoogleProcessing,
    isDownloadProcessing,
    dismiss,
    upgradePlan,
    csvDownload,
    onCsvDownload,
    onGoogleUpload,
    uploadToGoogle,
    importCampaign,
    onAccountSelect,
    onCampaignUpdate,
    onConnectGoogleAds,
  ]);

  const dismissAction = isDefaultScreen
    ? dismiss
    : () => setCurrentScreen('DEFAULT');

  return (
    <MIModalMessage dismiss={dismissAction} contentComponent={modalContent} />
  );
};

const ModalTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const ModalTitle = styled.span`
  ${({ theme }) => theme.text.fontType.h5};
  font-weight: ${({ theme }) => theme.text.weight.normal};
`;

const Bold = styled.span`
  font-weight: ${({ theme }) => theme.text.weight.bold};
`;

const ItemsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  > div:nth-child(2) {
    margin: 5rem 2rem;
  }
`;
