import { TemplatesType } from './types';

export enum LINKS {
  MAIN = 'https://mybuilda.com/',
  PRIVACY_POLICY = 'https://mybuilda.com/privacy-policy',
  TERMS_OF_SERVICE = 'https://mybuilda.com/terms-of-service',
}

export enum NOTIFICATION_VARIANT {
  SUCCESS = 'success',
  INFO = 'info',
  ERROR = 'error',
}

export const TIMEOUTS = {
  CLOSE_MODAL: 2000,
  MELIO_ME_INFO_MODAL: 2800,
  CLOSE_NOTIFICATION: 3000,
};

export const PROGRESS_BAR_STEPS = {
  skag: ['Start', 'Keywords', 'Ad Templates', 'Settings', 'Review', 'Finish'],
  stag: [
    'Start',
    'Ad Groups',
    'Keywords',
    'Ad Templates',
    'Settings',
    'Review',
    'Finish',
  ],
  planner: ['Discover keywords', 'Keyword planner'],
};

export const TABLE_AD_TYPES = {
  expTextAdExt: 'Expanded text ad',
  callOnlyExt: 'Call only ad',
  searchExt: 'Responsive research ad',
  snippetExt: 'Snippet extension',
  callOutExt: 'Callout extension',
  sitelinkExt: 'Sitelink extension',
};

export enum TEXT_INPUT_SIZE {
  INLINE = 'inline',
  WIZARD = 'wizard',
  SMALL = 'small',
}

export enum BUTTON_VARIANT {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  TERTIARY = 'tertiary',
}

export enum BUTTON_SIZE {
  SMALL = 'small',
  TINY = 'tiny',
  NORMAL = 'normal',
  VERY_SMALL = 'very-small',
}

export enum BID_STRATEGY {
  MANUAL_CPC = 'manual-cpc',
  MAXIMIZE = 'maximize',
  TARGET_CPA = 'target-cpa',
  TARGET_ROAS = 'target-roas',
}

export enum SLIDER_SIZE {
  NORMAL = 'normal',
  TINY = 'tiny',
}

export const DEFAULT_PAGE_SIZE = 10;

export const REGEX = {
  // eslint-disable-next-line
  URL: /https:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/,
  // eslint-disable-next-line
  PHONE: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/,
  EMAIL:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  DECIMAL_NUMBER: /^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$/,
  CPA_GOAL: /^(?!0*[.,]0*$|[.,]0*$|0*$)\d+[,.]?\d{0,2}$/,
  KEYWORDS: /[!%^*()=";?,]/,
  EXCLAMATION_SYMBOL: /[!]/,
};

export const defaultTemplatesData: TemplatesType = {
  snippetExt: [],
  searchExt: [],
  callOnlyExt: [],
  callOutExt: [],
  expTextAdExt: [],
  sitelinkExt: [],
};

export enum USER_PLAN {
  BASIC = 'BASIC',
  FREE = 'FREE',
  PRO = 'PRO',
  APPSUMO = 'BASIC-APPSUMO',
}

export const NONE_VALUE = 'none';

export const SIGN_UP_FORM_KEYS = {
  component: 0,
  handler: 1,
};

export const generateAdTypeFilterData = (templates: TemplatesType) => {
  const {
    callOnlyExt,
    callOutExt,
    expTextAdExt,
    searchExt,
    snippetExt,
    sitelinkExt,
  } = templates;

  const filterData = [{ label: 'All types', value: 'all' }];
  expTextAdExt.length &&
    filterData.push({ label: 'Expanded text ad', value: 'expTextAdExt' });
  callOnlyExt.length &&
    filterData.push({ label: 'Call only ad', value: 'callOnlyExt' });
  searchExt.length &&
    filterData.push({ label: 'Responsive research ad', value: 'searchExt' });
  snippetExt.length &&
    filterData.push({ label: 'Snippet extension', value: 'snippetExt' });
  callOutExt.length &&
    filterData.push({ label: 'Callout extension', value: 'callOutExt' });
  sitelinkExt.length &&
    filterData.push({ label: 'Sitelink extension', value: 'sitelinkExt' });
  return filterData;
};

export const GOOGLE_ADS_SCOPE = 'https://www.googleapis.com/auth/adwords';

export const REACT_APP_DASHBOARD = process.env.REACT_APP_DASHBOARD === 'true';

export const generateFilterByCampaignType = () => {
  return [
    { label: 'All', value: NONE_VALUE },
    { label: 'Stag', value: 'Stag' },
    { label: 'Skag', value: 'Skag' },
  ];
};
