import styled from 'styled-components';
import { CallOnlyAdType } from 'src/utils/types';
import CallIcon from 'src/images/general/call-icon.svg';

type Props = {
  item: CallOnlyAdType;
};

export const CallOnlyCardPreview = ({ item }: Props) => {
  return (
    <AdPreviewContainer>
      <TitleContainer>
        <AdIcon src={CallIcon} />
        <Title>
          {`${item.headlineOne} | ${item.headlineTwo} ${item.phoneNumber}`}
        </Title>
      </TitleContainer>
      <Link>{item.finalUrl}</Link>
      <Description>{item.descriptionOne}</Description>
      <Description>{item.descriptionTwo}</Description>
    </AdPreviewContainer>
  );
};

const AdPreviewContainer = styled.div`
  display: flex;
  width: 60rem;
  cursor: pointer;
  height: fit-content;
  flex-direction: column;
  padding: 1.7rem;
  box-sizing: border-box;
  border-radius: ${(props) => props.theme.radius.lg};
  border: 0.1rem solid ${(props) => props.theme.colors.grey[300]};
  background-color: ${(props) => props.theme.colors.white};
`;

const TitleContainer = styled.span`
  display: flex;
  align-items: center;
`;

const Title = styled.span`
  color: ${(props) => props.theme.colors.blue[200]};
  ${(props) => props.theme.text.fontType.body3};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const Link = styled.span`
  padding: 0.2rem 0;
  color: ${(props) => props.theme.colors.green[200]};
  ${(props) => props.theme.text.fontType.link};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const Description = styled.span`
  color: ${(props) => props.theme.colors.black[100]};
  ${(props) => props.theme.text.fontType.link};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: 2rem;
`;

const AdIcon = styled.img`
  width: 2.2rem;
  height: 2.2rem;
  margin-right: 0.8rem;
`;
