import { AuthenticationErrorType } from 'src/infrastructure/restClient/models/AuthenticationErrorType';
import { AuthenticationResponse } from 'src/infrastructure/restClient/models/AuthenticationResponse';
import TokenLocalStore from 'src/infrastructure/stores/TokenLocalStore';
import RestClient from '../../infrastructure/restClient/RestClient';
import { ResetPasswordModel } from './models/resetPasswordModel';
import { ResetPasswordResponse } from './models/resetPasswordResponse';
import { SetNewuserPasswordRequest } from './models/setNewUserPasswordRequest';
import { UserDetailsResponse } from './models/userDetails';
import { UserRegistration } from './models/userRegistration';

export default class UserService {
  public static registerUser(request: UserRegistration) {
    return RestClient.post<AuthenticationResponse>('api/users/register', request)
    .then((response) => {
      if (!response.is_error && response.content) {
        TokenLocalStore.setJwtToken(response.content.jwtToken);
        TokenLocalStore.setRefreshToken(response.content.refreshToken);
      }
      return response;
    }).catch(() => {
      return {
        is_error: true, 
        content:{
          authenticationErrorType : AuthenticationErrorType.IsServerError
        } 
      }
    })
  }
  public static resetPassword(request: ResetPasswordModel) {
    return RestClient.post<ResetPasswordResponse>(`api/users/reset`, request);
  }

  public static setNewUserPassword(request: SetNewuserPasswordRequest) {
    return RestClient.post<ResetPasswordResponse>(
      `api/users/update-password`,
      request
    );
  }

  public static getUserDetails() {
    return RestClient.get<UserDetailsResponse>('api/users/details')
      .then((response) => {
        if (!response.is_error && response?.content) {
          return response.content;
        }
      })
      .catch((e) => e);
  }
}
