import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useModal } from 'src/helpers/react/useModal';
import { WizardContainer } from 'src/components/layout/WizardElements';
import { ResetPasswordModal } from 'src/pages/auth/components/ResetPasswordModal';
import { LOCATIONS } from 'src/pages/locations';

export const ResetPasswordPage = () => {
  const history = useHistory();

  const [ResetPassword, showResetPasswordModal] = useModal(ResetPasswordModal, {
    id: 'ResetPasswordModal',
    onDismiss: () => history.push(LOCATIONS.login),
  });

  useEffect(() => {
    showResetPasswordModal();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return <WizardContainer>{ResetPassword}</WizardContainer>;
};
