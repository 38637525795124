import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useModal } from 'src/helpers/react/useModal';
import { WizardContainer } from 'src/components/layout/WizardElements';
import { SendResetPasswordLinkModal } from 'src/components/header/Modal/SendResetPasswordLinkModal';
import { LOCATIONS } from 'src/pages/locations';
import { getIsLoggedIn } from 'src/redux/user/selectors';
import { useSelector } from 'react-redux';

export const RequestPasswordPage = () => {
  const history = useHistory();
  const isSignedIn = useSelector(getIsLoggedIn);

  useEffect(() => {
    isSignedIn && history.push(LOCATIONS.dashboard);
  }, [isSignedIn, history]);

  const [RequestPasswordModal, showRequestPasswordLinkModal] = useModal(
    SendResetPasswordLinkModal,
    {
      id: 'RequestPasswordModal',
      onDismiss: () => history.push(LOCATIONS.login),
    }
  );

  useEffect(() => {
    showRequestPasswordLinkModal();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return <WizardContainer>{RequestPasswordModal}</WizardContainer>;
};
