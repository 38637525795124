import { useCallback } from 'react';
import styled from 'styled-components';

import { TagInput } from 'src/components/common/TagInput';
import { ReactComponent as SearchIcon } from 'src/images/general/search.svg';
import { ReactComponent as MapPointIcon } from 'src/images/general/map-point.svg';
import { ReactComponent as TranslateIcon } from 'src/images/general/translate.svg';

type DiscoverPageProps = {
  onKeywordsChange: (tags: string[]) => void;
  keywords: string[];
};

export const DiscoverPage = ({ onKeywordsChange, keywords }: DiscoverPageProps) => {
  const handleChange = useCallback((tags) => onKeywordsChange(tags), [onKeywordsChange]);

  return (
    <Container>
      <Header>
        <Title>
          Discover New <Bold>Keywords</Bold>
        </Title>
        <Subtitle>
          Enter products or services closely related to your business
        </Subtitle>
      </Header>
      <TagInput
        icon={SearchIcon}
        placeholder="enter keywords"
        tags={keywords}
        onChange={handleChange}
      />
      <Row>
        <Span>
          <TranslateIcon />
          English
        </Span>
        <Span>
          <MapPointIcon />
          United States
        </Span>
      </Row>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: ${({ theme }) => theme.spacing[5]};
  padding: 0;
`;

const Header = styled.h1`
  margin: 0 0 0.6rem;
  text-align: center;
  font-weight: 300;
`;

const Title = styled.p`
  ${({ theme }) => theme.text.fontType.h1}
  margin: 0;
  text-align: center;
  font-size: 4.8rem;
  line-height: 5.9rem;
`;

const Subtitle = styled.p`
  ${({ theme }) => theme.text.fontType.body1}
  margin: ${({ theme }) => theme.spacing[5]} 0 0;
  text-align: center;
  font-weight: 300;
`;

const Span = styled.p`
  display: flex;
  align-items: center;
  margin: 0;
  column-gap: ${({ theme }) => theme.spacing[1]};
  color: ${({ theme }) => theme.colors.grey[200]};
  ${({ theme }) => theme.text.fontType.body3}
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  column-gap: ${({ theme }) => theme.spacing[3]};
  padding-left: ${({ theme }) => theme.spacing[3]};
`;

const Bold = styled.b``;
