import styled from 'styled-components';
import { MIPagination } from 'src/components/common/MIPagination';

type Props = {
  className?: string;
  onPageChange: (page: number) => void;
  pageCount: number;
  onPageSizeChange: (value: number) => void;
  pageSize: number;
  currentPage: number;
};

export const TableFooter = ({
  onPageChange,
  pageCount,
  className = '',
  onPageSizeChange,
  pageSize,
  currentPage,
}: Props) => {
  return (
    <Container className={className}>
      <PaginationContainer>
        <MIPagination
          onPageChange={onPageChange}
          pageSize={pageSize}
          pageCount={pageCount}
          onPageSizeChange={onPageSizeChange}
          currentPage={currentPage}
        />
      </PaginationContainer>
    </Container>
  );
};

const Container = styled.div`
  height: 5.5rem;
  display: flex;
  padding: 0 ${(props) => props.theme.spacing[6]};
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.colors.blue[100]};
  border-radius: ${({ theme }) =>
    `0 0 ${theme.radius.xl} ${theme.radius.xl}`};
`;
const PaginationContainer = styled.div`
  flex: 1;
  color: ${({ theme }) => theme.colors.white};
  ${({ theme }) => theme.text.fontType.body3}
`;
