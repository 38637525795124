import * as History from 'history';
import {
  TEXT_INPUT_SIZE,
  BUTTON_VARIANT,
  BUTTON_SIZE,
  USER_PLAN,
  BID_STRATEGY,
  SLIDER_SIZE,
} from 'src/utils/consts';

export type Expandable<T> = T & Record<string, any>;

export enum AdBuilderType {
  STAG = 'stag',
  SKAG = 'skag',
  AD = 'ad',
  PLANNER = 'planner',
  CONVERSIONS = 'conversions',
  EMPTY = 'empty',
}

export enum AdType {
  ALL = 'all',
  EXPANDED = 'expTextAdExt',
  CALL = 'callOnlyExt',
  RESPONSIVE = 'searchExt',
  SNIPPET = 'snippetExt',
  CALLOUT = 'callOutExt',
  SITELINK = 'sitelinkExt',
}

export type PlannerType = {
  keywords: string[];
  googleKeywords: KeywordIdeaType[];
  selectedKeywords: KeywordIdeaType[];
};

export type OptionType = {
  label: string;
  value: string;
};

export type BidStrategyType =
  | BID_STRATEGY.MANUAL_CPC
  | BID_STRATEGY.MAXIMIZE
  | BID_STRATEGY.TARGET_CPA
  | BID_STRATEGY.TARGET_ROAS;

export type CampaignType = {
  _id?: string | null;
  name: string;
  draft: boolean;
  budget: number | null;
  exact: boolean;
  phrase: boolean;
  broad: boolean;
  differentGroup: boolean;
  negativePhrase: boolean;
  skag: boolean;
  stag: boolean;
  adGroupList: AdGroupType[];
  keywordsList: string[];
  googleAccountId: string | null | undefined;
  templates: TemplatesType;
  forGoogleAds?: boolean;
  bidTargetGoal?: number | null;
  bidStrategyType: BidStrategyType;
  bidStrategyPortfolioName: string;
};

export type CampaignReviewTableType = {
  entity: string;
  updated?: number;
  added?: number;
  skipped?: number;
};

export type TemplatesType = {
  snippetExt: SnippetExtensionType[];
  searchExt: RespSearchAdType[];
  callOnlyExt: CallOnlyAdType[];
  callOutExt: CallOutAdType[];
  expTextAdExt: ExpTextAdExtType[];
  sitelinkExt: SitelinkAdType[];
};

export type AdsTypes =
  | SnippetExtensionType
  | RespSearchAdType
  | CallOnlyAdType
  | CallOutAdType
  | ExpTextAdExtType
  | SitelinkAdType;

export type AdGroupType = TemplatesType & {
  id: number;
  adGroup: string;
  keywords: string[];
  negatives: string[];
  keywordsValidationError?: boolean;
};

export type ReviewAdType = AdsTypes & {
  negatives?: OptionType[];
  keywords?: OptionType[];
};

export type SnippetExtensionType = {
  id?: number;
  adGroupId?: number;
  language: string;
  adGroupName: string;
  headerType: string;
  snippetValueOne: string;
  snippetValueTwo: string;
  snippetValueThree: string;
  type: AdType.SNIPPET;
  edited?: boolean;
  isEdit?: boolean;
};

export type SnippetExtensionFormErrors = {
  language?: string;
  headerType?: string;
  snippetValueOne?: string;
  snippetValueTwo?: string;
  snippetValueThree?: string;
};

type PinStructure = {
  positionOne: number[];
  positionTwo: number[];
};

export type RespSearchAdType = {
  id?: number;
  adGroupId?: number;
  headlines: { id: number; value: string }[];
  pinnedHeadlines: PinStructure & { positionThree: number[] };
  descriptions: { id: number; value: string }[];
  pinnedDescriptions: PinStructure;
  finalUrl: string;
  pathOne: string;
  pathTwo: string;
  adGroupName: string;
  type: AdType.RESPONSIVE;
  edited?: boolean;
  isEdit?: boolean;
};

export type RespSearchFormErrors = {
  finalUrl?: string;
};

export type SitelinkDataType = {
  id?: number;
  text: string;
  descriptionOne: string;
  descriptionTwo: string;
  finalUrl: string;
};

export type SitelinkFormType = {
  id?: number;
  adGroupId?: number;
  adGroupName: string;
  type: AdType.SITELINK;
  edited?: boolean;
  data: SitelinkDataType[];
};

export type SitelinkAdType = {
  id?: number;
  adGroupId?: number;
  text: string;
  descriptionOne: string;
  descriptionTwo: string;
  finalUrl: string;
  adGroupName: string;
  type: AdType.SITELINK;
  edited?: boolean;
  isEdit?: boolean;
};

export type SitelinkFormErrors = {
  id?: number;
  text?: string;
  descriptionOne?: string;
  descriptionTwo?: string;
  finalUrl?: string;
};

export type CallOnlyAdType = {
  id?: number;
  adGroupId?: number;
  country: string;
  phoneNumber: string;
  headlineOne: string;
  headlineTwo: string;
  descriptionOne: string;
  descriptionTwo: string;
  businessName: string;
  verificationURL: string;
  finalUrl: string;
  type: AdType.CALL;
  edited?: boolean;
  isEdit?: boolean;
};

export type CallOnlyFormErrors = {
  finalUrl?: string;
  phoneNumber?: string;
  country?: string;
  verificationURL?: string;
};

export type CallOutAdType = {
  id?: number;
  adGroupId?: number;
  callOutTextOne: string;
  callOutTextTwo: string;
  callOutTextThree: string;
  optionalValues: string[];
  type: AdType.CALLOUT;
  edited?: boolean;
  isEdit?: boolean;
};

export type CallOutFormErrors = {
  callOutTextOne?: string;
  callOutTextTwo?: string;
  callOutTextThree?: string;
};

export type ExpTextAdExtType = {
  id?: number;
  adGroupId?: number;
  headlineOne: string;
  headlineTwo: string;
  headlineThree: string;
  descriptionOne: string;
  descriptionTwo: string;
  finalUrl: string;
  pathOne: string;
  pathTwo: string;
  type: AdType.EXPANDED;
  edited?: boolean;
  isEdit?: boolean;
};

export type ExpTextAdExtTypeErrors = {
  finalUrl?: string;
};

export type TextInputSize =
  | TEXT_INPUT_SIZE.INLINE
  | TEXT_INPUT_SIZE.WIZARD
  | TEXT_INPUT_SIZE.SMALL;

export type FieldType = { id: string; value: string };

export type ButtonVariantType =
  | BUTTON_VARIANT.PRIMARY
  | BUTTON_VARIANT.SECONDARY
  | BUTTON_VARIANT.TERTIARY;

export type ButtonSizeType =
  | BUTTON_SIZE.NORMAL
  | BUTTON_SIZE.SMALL
  | BUTTON_SIZE.TINY
  | BUTTON_SIZE.VERY_SMALL;

export type SliderSizeType = SLIDER_SIZE.NORMAL | SLIDER_SIZE.TINY;

export type UserPlanType =
  | USER_PLAN.BASIC
  | USER_PLAN.PRO
  | USER_PLAN.FREE
  | USER_PLAN.APPSUMO;

export type InputValue = {
  id: string;
  value: string;
  valueAsNumber: number;
};

export type ToNavigationType =
  | History.LocationDescriptor
  | ((location?: History.Location) => History.LocationDescriptor);

export type UserDetailsType = {
  email: string;
  fullName: string;
  msg: string;
  plan: UserPlanType;
  planInterval: string;
  _id: string;
};

export type PricingPlansType = {
  monthlyBasicPrice: string;
  monthlyProPrice: string;
  yearlyBasicPrice: string;
  yearlyProPrice: string;
};

export type PaginationOptions = {
  offset: number;
  limit: number;
};

export type ImportError = {
  source: string;
  message: string;
  fieldName: string;
};

export type GoogleLoginResponse = {
  code: string;
};

export enum KeywordPlanCompetitionLevelType {
  UNSPECIFIED = 0,
  UNKNOWN = 1,
  LOW = 2,
  MEDIUM = 3,
  HIGH = 4,
}

export type KeywordIdeaType = {
  keyword: string;
  avgMonthSearch: number;
  competition: KeywordPlanCompetitionLevelType;
  competitionIndex: number;
  lowBidRange: number;
  highBidRange: number;
};

type ContingTypes = 'onePerClick' | 'manyPerClick' | null;

export type ConversionType = {
  category: string | null;
  name: string;
  useDefaultValue: number | boolean | null;
  value: string;
  defaultValue: string;
  countingType: ContingTypes;
  clickThroughConversionWindowDays: string | number | null;
  viewThroughConversionWindowDays: string | number | null;
  attributionModel: string | null;
};

export type ConversionFormErrors = {
  category?: string;
  name?: string;
  value?: string;
  useDefaultValue?: string;
  countingType?: string;
  clickThroughConversionWindowDays?: string;
  viewThroughConversionWindowDays?: string;
  attributionModel?: string;
};
