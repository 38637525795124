import { OptionType } from 'src/utils/types';

export const actionsOptions: OptionType[] = [
  { label: 'Headline 1', value: 'headlineOne' },
  { label: 'Headline 2', value: 'headlineTwo' },
  { label: 'Headlines 3', value: 'headlineThree' },
  { label: 'Description 1', value: 'descriptionOne' },
  { label: 'Description 2', value: 'descriptionTwo' },
  { label: 'Final URL', value: 'finalUrl' },
  { label: 'Path 1', value: 'path1' },
  { label: 'Path 2', value: 'path2' },
];

export const replaceTextOptions: OptionType[] = [
  { label: 'Headlines', value: 'headlines' },
  { label: 'Headline 1', value: 'headlineOne' },
  { label: 'Headline 2', value: 'headlineTwo' },
  { label: 'Headlines 3', value: 'headlineThree' },
  { label: 'Descriptions', value: 'descriptions' },
  { label: 'Description 1', value: 'descriptionOne' },
  { label: 'Description 2', value: 'descriptionTwo' },
  { label: 'Headlines and descriptions', value: 'headlinesDescriptions' },
  { label: 'Final URL', value: 'finalUrl' },
  { label: 'Path 1', value: 'path1' },
  { label: 'Path 2', value: 'path2' },
];

const allFields = {
  headlines: ['headlineOne', 'headlineTwo', 'headlineThree'],
  descriptions: ['descriptionOne', 'descriptionTwo'],
  headlinesDescriptions: [
    'headlineOne',
    'headlineTwo',
    'headlineThree',
    'descriptionOne',
    'descriptionTwo',
  ],
};

export const getFieldsByActionOption = (field: string) => {
  return field in allFields ? allFields[field] : [field];
};
