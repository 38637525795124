import styled from 'styled-components';
import { TABLE_AD_TYPES } from 'src/utils/consts';
import { SnippetExtensionType } from 'src/utils/types';
import { CardContextMenu } from '../Table/CardContextMenu';
import { MIEditedAdLabel } from 'src/components/common/MIEditedAdLabel';
import { checkIsOnlyTemplate } from 'src/utils/builder';

type Props = {
  isSkag: boolean;
  item: SnippetExtensionType;
  template?: SnippetExtensionType;
  headerTypeLabel?: string;
  adGroupNames: string[];
  renderAdGroupNames: (adGroupNames: string[]) => void;
  showEditFormModal: (item: any) => void;
  onlyForm?: boolean;
  paused?: boolean;
};

export const SnippetCard = ({
  isSkag,
  item,
  template,
  headerTypeLabel,
  adGroupNames,
  renderAdGroupNames,
  showEditFormModal,
  onlyForm = false,
  paused = false,
}: Props) => {
  return (
    <>
      <AdPreviewContainer paused={paused}>
        <Content>
          <Title>{`${headerTypeLabel}: ${item.snippetValueOne}, ${item.snippetValueTwo}, ${item.snippetValueThree}`}</Title>
        </Content>
        <CardContextMenu
          isSkag={isSkag}
          onlyForm={onlyForm}
          item={template || item}
          showEditFormModal={showEditFormModal}
        />
        {item.edited && <MIEditedAdLabel />}
      </AdPreviewContainer>
      {!onlyForm && (
        <>
          <TypeContainer>{TABLE_AD_TYPES[item.type]}</TypeContainer>
          <AdGroupsContainer>
            {!checkIsOnlyTemplate(item.type)
              ? renderAdGroupNames(adGroupNames)
              : '-'}
          </AdGroupsContainer>
        </>
      )}
    </>
  );
};

const AdPreviewContainer = styled.div`
  position: relative;
  display: flex;
  height: fit-content;
  align-items: center;
  justify-content: space-between;
  padding: 1.7rem;
  box-sizing: border-box;
  border-radius: 0 0 1rem 1rem;
  border: 0.1rem solid ${(props) => props.theme.colors.grey[300]};
  border-top: 0.1rem dashed ${(props) => props.theme.colors.grey[300]};
  width: 56rem;
  opacity: ${({ paused }) => (paused ? 0.6 : 1)};
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 50rem;
`;

const Title = styled.span`
  color: ${(props) => props.theme.colors.grey[100]};
  ${(props) => props.theme.text.fontType.body3};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const TypeContainer = styled.span`
  display: flex;
  align-items: center;
  max-width: 30rem;
  font-weight: ${(props) => props.theme.text.weight.semi};
`;

const AdGroupsContainer = styled.span`
  width: 10%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-weight: ${(props) => props.theme.text.weight.normal};
`;
