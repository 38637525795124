import styled from 'styled-components';
import { AdType } from 'src/utils/types';
import { ExpTextCard } from './ExpTextCard';
import { CallOnlyCard } from './CallOnlyCard';
import { ResponsiveSearchCard } from './ResponsiveSearchCard';
import { SnippetCard } from './SnippetCard';
import { CallOutCard } from './CallOutCard';
import { headerTypes } from 'src/utils/headerTypes';
import { getDataForForm, getFormTitle } from '../form/data';
import { useDispatch, useSelector } from 'react-redux';
import {
  replaceSkagStep,
  setSkagCurrentFormData,
  setSkagStep,
} from 'src/redux/skagCreationFlow/actions';
import {
  replaceStagStep,
  setStagCurrentFormData,
  setStagStep,
} from 'src/redux/stagCreationFlow/actions';
import { getSkagFlowStep } from 'src/redux/skagCreationFlow/selectors';
import { getSkagCampaign } from 'src/redux/skagCompaign/selectors';
import { getStagFlowStep } from 'src/redux/stagCreationFlow/selectors';
import { getStagCampaign } from 'src/redux/stagCampaign/selectors';
import { SitelinkCard } from './SitelinkCard';

type Props = {
  isSkag: boolean;
  item: any;
  template: any;
};

export const AdCreatorTableItem = ({ isSkag, item, template }: Props) => {
  const currentStep = useSelector(isSkag ? getSkagFlowStep : getStagFlowStep);
  const { keywordsList: groupsList } = useSelector(getSkagCampaign);
  const stagCampaign = useSelector(getStagCampaign);

  const groups = isSkag
    ? groupsList
    : stagCampaign.adGroupList.reduce((group, value) => {
        group.push(value.adGroup);
        return group;
      }, [] as string[]);

  const dispatch = useDispatch();
  const showEditFormModal = (data) => {
    if (isSkag) {
      dispatch(
        setSkagCurrentFormData({ ...data }, getDataForForm(data.type, true))
      );
      dispatch(replaceSkagStep(3, getFormTitle(data.type)));
      dispatch(setSkagStep(currentStep + 1));
    } else {
      dispatch(
        setStagCurrentFormData({ ...data }, getDataForForm(data.type, true))
      );
      dispatch(replaceStagStep(4, getFormTitle(data.type)));
      dispatch(setStagStep(currentStep + 1));
    }
  };

  const renderItem = (item) => {
    const { type } = item;

    if (type === AdType.EXPANDED) {
      return (
        <ExpTextCard
          isSkag={isSkag}
          item={item}
          template={template}
          adGroupNames={groups}
          renderAdGroupNames={renderAdGroupNames}
          showEditFormModal={showEditFormModal}
        />
      );
    }

    if (type === AdType.CALL) {
      return (
        <CallOnlyCard
          isSkag={isSkag}
          item={item}
          template={template}
          adGroupNames={groups}
          renderAdGroupNames={renderAdGroupNames}
          showEditFormModal={showEditFormModal}
        />
      );
    }

    if (type === AdType.RESPONSIVE) {
      return (
        <ResponsiveSearchCard
          isSkag={isSkag}
          item={item}
          template={template}
          adGroupNames={groups}
          renderAdGroupNames={renderAdGroupNames}
          showEditFormModal={showEditFormModal}
        />
      );
    }

    if (type === AdType.SNIPPET) {
      return (
        <SnippetCard
          isSkag={isSkag}
          item={item}
          template={template}
          headerTypeLabel={
            headerTypes.find((el) => el.value === item.headerType)?.label
          }
          adGroupNames={groups}
          renderAdGroupNames={renderAdGroupNames}
          showEditFormModal={showEditFormModal}
        />
      );
    }

    if (type === AdType.CALLOUT) {
      return (
        <CallOutCard
          isSkag={isSkag}
          item={item}
          template={template}
          adGroupNames={groups}
          renderAdGroupNames={renderAdGroupNames}
          showEditFormModal={showEditFormModal}
        />
      );
    }

    if (type === AdType.SITELINK) {
      return (
        <SitelinkCard
          isSkag={isSkag}
          item={item}
          template={template}
          adGroupNames={groups}
          renderAdGroupNames={renderAdGroupNames}
          showEditFormModal={showEditFormModal}
        />
      );
    }
  };

  const renderAdGroupNames = (adGroupNames) => {
    const visibleAdGroupNames = adGroupNames.slice(0, 3);
    const unVisibleAdGroupNamesCount = adGroupNames.length - 3;
    const showUnVisibleAdGroupNamesCount = unVisibleAdGroupNamesCount > 0;
    return (
      <>
        {visibleAdGroupNames.map((name, index) => {
          return <AdGroupName key={index}>{name}</AdGroupName>;
        })}
        {showUnVisibleAdGroupNamesCount && (
          <AdGroupNamesCount>{` + ${unVisibleAdGroupNamesCount}`}</AdGroupNamesCount>
        )}
      </>
    );
  };

  return <Container>{renderItem(item)}</Container>;
};

const Container = styled.div`
  width: inherit;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: ${(props) => props.theme.spacing[5]};
`;

const AdGroupName = styled.span`
  width: 12rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const AdGroupNamesCount = styled.span`
  background-color: rgba(96, 100, 115, 0.05);
  border-radius: ${(props) => props.theme.radius.md};
  width: fit-content;
  margin-top: ${(props) => props.theme.spacing[2]};
  padding: ${(props) => props.theme.spacing[1]}
    ${(props) => props.theme.spacing[2]};
  ${(props) => props.theme.text.fontType.hint};
`;
