import RestClient from '../../infrastructure/restClient/RestClient';
import { SendCodeResponse } from './models/RedemptionCodeModel';

export default class RedemptionService {
  public static sendCode = (code: string) => {
    return RestClient.post<SendCodeResponse>('api/redemptions/activate', {
      code,
    });
  };
}
