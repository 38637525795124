import { useState, useMemo, useCallback, useRef } from 'react';
import styled, { css } from 'styled-components';
import isEmpty from 'lodash/isEmpty';
import throttle from 'lodash/throttle';
import { Expandable } from 'src/utils/types';
import { withOutsideClickHandler } from 'src/hok/withOutsideClickHandler';
import DropDownArrow from 'src/images/general/dropdown-arrow.svg';
import DropDownFilter from 'src/images/general/dropdown-filter.svg';
import DropDownClear from 'src/images/general/close-icon.svg';
import { MITextInput } from './MITextInput';
import { MINotices } from './MINotices';
import { MICheckbox } from './MICheckbox';

export type OptionType = {
  label: string;
  value: any;
  isDisabled?: boolean;
};

export type MIDropDownProps = {
  options: OptionType[];
  label?: string;
  value?: any;
  onChange: (change: Expandable<{ value: string }>) => void;
  onClear?: () => void;
  onSearch?: (value: string) => void;
  onSelectAll?: () => void;
  onSearchStart?: () => void;
  errorMessage?: string;
  transparent?: boolean;
  responsiveWidth?: boolean;
  multiselect?: boolean;
  isAllSelected?: boolean;
  className?: string;
};

export const MIDropDown = ({
  className,
  options,
  label,
  value,
  onChange,
  onClear,
  onSearch,
  onSelectAll,
  onSearchStart,
  errorMessage,
  transparent = true,
  responsiveWidth,
  multiselect,
  isAllSelected,
}: MIDropDownProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');

  const optionsCount = useRef<number>(options.length);

  const handleOnClear = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();
    setSearchValue('');
    onClear && onClear();
  };

  const handleSearch = useCallback(
    (value) => {
      if (onSearchStart && value.length > 1) {
        onSearchStart();
      }
    },
    [onSearchStart]
  );

  const filteredOptions = options.filter(({ value }) =>
    value.toLowerCase().includes(searchValue.toLowerCase())
  );

  const throttledSearch = useMemo(
    () => throttle(handleSearch, 200, { leading: false }),
    [handleSearch]
  );

  const onSelected = (option) => () => {
    !multiselect && setOpen(false);
    if (value !== option.value) {
      onChange({ value: option.value });
    }
  };

  const handleKeyPress = (event: KeyboardEvent) => {
    if (event.key !== 'Enter' || !onSearch) {
      return;
    }

    onSearch(searchValue);
  };

  const handleSetSearchValue = useCallback(
    ({ value }) => {
      setSearchValue(value);
      throttledSearch(value);
    },
    [throttledSearch]
  );

  const isSearchShown = useMemo(
    () => !!optionsCount.current && typeof onSearch === 'function',
    [onSearch]
  );

  const getIsSelected = (option: OptionType) => {
    if (multiselect) {
      return value.includes(option.value);
    }

    return (
      (typeof value === 'string' &&
        typeof option.value === 'string' &&
        option.value.toLowerCase() === value?.toLowerCase()) ||
      option.value === value ||
      (!value && option.label === 'ALL')
    );
  };

  return (
    <SingleSelectContainer
      className={className}
      handleClickOutside={() => setOpen(false)}
    >
      <SingleSelect
        transparent={transparent}
        error={errorMessage}
        responsiveWidth={responsiveWidth}
        onClick={() => setOpen(!open)}
      >
        <Flex>
          {onClear && <DropDownFilterIcon src={DropDownFilter} />}
          <SingleSelectLabel>
            {isEmpty(searchValue) ? label : `contains "${searchValue}"`}
          </SingleSelectLabel>
        </Flex>
        <Flex>
          {onClear && (
            <DropDownClearIcon src={DropDownClear} onClick={handleOnClear} />
          )}
          <DropDownArrowIcon src={DropDownArrow} isOpen={open} />
        </Flex>
      </SingleSelect>
      <MINotices errorMessage={errorMessage} />
      <DropDownContainer hidden={!open}>
        <List noPadding={!!onSearch}>
          {isSearchShown && (
            <MITextInput
              size='inline'
              placeholder='Search'
              value={searchValue}
              onChange={handleSetSearchValue}
              onKeyPress={handleKeyPress}
            />
          )}
          {multiselect && onSelectAll && (
            <DropDownOption onClick={onSelectAll}>
              <MICheckbox
                value={isAllSelected ? isAllSelected : false}
                positiveLabel={'select all'}
                negativeLabel={'select all'}
              />
            </DropDownOption>
          )}
          {filteredOptions.map((option, index) => {
            const isSelected = getIsSelected(option);
            return multiselect ? (
              <DropDownOption key={index} onClick={onSelected(option)}>
                <MICheckbox
                  value={isSelected}
                  positiveLabel={option.label}
                  negativeLabel={option.label}
                />
              </DropDownOption>
            ) : (
              <DropDownOption
                key={index}
                onClick={onSelected(option)}
                isSelected={isSelected}
              >
                {option.label}
              </DropDownOption>
            );
          })}
        </List>
      </DropDownContainer>
    </SingleSelectContainer>
  );
};

const SingleSelectContainer = withOutsideClickHandler(styled.div`
  position: relative;
  box-sizing: border-box;
  cursor: pointer;
  width: 100%;
`);

const SingleSelect = styled.div<{ transparent: boolean }>`
  display: flex;
  height: 2rem;
  align-items: center;
  justify-content: space-between;
  padding: ${(props) => props.theme.spacing[2]}
    ${(props) => props.theme.spacing[3]};
  background: ${(props) =>
    props.transparent ? 'transparent' : props.theme.colors.grey[800]};
  border-radius: ${(props) => props.theme.radius.lg};
  border: 0.1rem solid ${(props) => props.theme.colors.blue[300]};
  ${(props) => props.theme.text.fontType.body3};
  ${(props) =>
    !props.responsiveWidth &&
    css`
      min-width: 20rem;
    `}
`;

const SingleSelectLabel = styled.span`
  display: flex;
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
`;

const DropDownFilterIcon = styled.img`
  width: 1.5rem;
  height: 1.6rem;
  padding-right: ${(props) => props.theme.spacing[2]};
`;

const DropDownClearIcon = styled.img`
  width: 0.8rem;
  height: 0.8rem;
  margin-right: ${(props) => props.theme.spacing[4]};
`;

const DropDownArrowIcon = styled.img<{ isOpen: boolean }>`
  width: 0.9rem;
  height: 0.5rem;
  transform: ${(props) => (props.isOpen ? 'rotateZ(-180deg)' : 'none')};
`;

const DropDownContainer = styled.div<{ hidden?: boolean }>`
  top: 100%;
  border-radius: ${(props) => props.theme.radius.lg};
  position: absolute;
  width: 100%;
  z-index: 1;
  box-sizing: border-box;
  box-shadow: ${(props) => props.theme.shadows.card};
  background-color: ${(props) => props.theme.colors.white};
`;

const listHeight = 27.6;
const List = styled.div<{ noPadding: boolean }>`
  padding: ${(props) => props.theme.spacing[2]} 0;
  padding-top: ${(props) => props.noPadding && '0'};
  max-height: ${listHeight}rem;
  overflow: auto;
  &:focus {
    outline: none;
  }
  &::-webkit-scrollbar {
    width: 0.6rem;
    height: 10rem;
  }
  &::-webkit-scrollbar-track {
    border-radius: ${(props) => props.theme.radius.md};
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.theme.colors.blue[100]};
    opacity: 0.4;
    border-radius: ${(props) => props.theme.radius.md};
  }
`;

const DropDownOption = styled.div<{ isSelected: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  padding: ${(props) => props.theme.spacing[2]}
    ${(props) => props.theme.spacing[3]};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${(props) => props.isSelected && props.theme.colors.blue[100]};
  ${(props) => props.theme.text.fontType.body3};
`;
