import { useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { withOutsideClickHandler } from 'src/hok/withOutsideClickHandler';
import {
  deleteSkagAd,
  deleteAllSkagAdsByType,
  duplicateSkagAdTemplate,
  duplicateSkagAdGroup,
} from 'src/redux/skagCompaign/actions';
import MenuIcon from 'src/images/general/three-dots.svg';
import EditIcon from 'src/images/general/edit-icon.svg';
import DuplicateIcon from 'src/images/general/duplicate-icon.svg';
import DeleteIcon from 'src/images/general/delete-icon.svg';
import {
  deleteAllStagAdsByType,
  deleteStagAd,
  duplicateStagAdGroup,
  duplicateStagAdTemplate,
} from 'src/redux/stagCampaign/actions';
import { checkIsOnlyTemplate } from 'src/utils/builder';

type Props = {
  isSkag: boolean;
  item: any;
  showEditFormModal: (item: any) => void;
  onlyForm: any;
};

export const CardContextMenu = ({
  isSkag,
  item,
  showEditFormModal,
  onlyForm,
}: Props) => {
  const dispatch = useDispatch();
  const [showMenu, setShowMenu] = useState<boolean>(false);

  const onMenuClick = () => {
    setShowMenu(!showMenu);
  };

  const onEditClick = () => {
    showEditFormModal(item);
  };

  const onDeleteClick = () => {
    setShowMenu(false);
    const isTemplate = checkIsOnlyTemplate(item.type);
    if (onlyForm) {
      if (isSkag) {
        dispatch(
          deleteSkagAd({
            id: item.id,
            adGroupId: item.adGroupId,
            type: item.type,
            isTemplate,
          })
        );
      }
      if (!isSkag) {
        dispatch(
          deleteStagAd({
            id: item.id,
            adGroupId: item.adGroupId,
            type: item.type,
            isTemplate,
          })
        );
      }
      return;
    }
    if (isSkag) {
      dispatch(deleteAllSkagAdsByType(item.id, item.type, isTemplate));
    }
    if (!isSkag) {
      dispatch(deleteAllStagAdsByType(item.id, item.type, isTemplate));
    }
  };

  const onDuplicateClick = () => {
    setShowMenu(false);
    const onlyTemplate = checkIsOnlyTemplate(item.type);

    if (onlyForm && onlyTemplate) {
      if (isSkag) {
        dispatch(duplicateSkagAdGroup(item));
      }
      if (!isSkag) {
        dispatch(duplicateStagAdGroup(item));
      }
      return;
    }
    if (isSkag) {
      dispatch(duplicateSkagAdTemplate(item, onlyTemplate));
    }
    if (!isSkag) {
      dispatch(duplicateStagAdTemplate(item, onlyTemplate));
    }
  };

  return (
    <Container handleClickOutside={() => setShowMenu(false)}>
      <Menu src={MenuIcon} onClick={onMenuClick} />
      {showMenu && (
        <MenuContainer>
          <MenuItem onClick={onEditClick}>
            <MenuItemIcon src={EditIcon} /> Edit
          </MenuItem>
          <MenuItem onClick={onDuplicateClick}>
            <MenuItemIcon src={DuplicateIcon} />
            Duplicate
          </MenuItem>
          <MenuItem onClick={onDeleteClick}>
            <MenuItemIcon src={DeleteIcon} />
            Delete
          </MenuItem>
        </MenuContainer>
      )}
    </Container>
  );
};

const MenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: calc(100% - 9.5rem);
  left: 5rem;
  background: white;
  border-radius: ${(props) => props.theme.radius.xl};
  box-shadow: ${(props) => props.theme.shadows.card};
  color: ${(props) => props.theme.colors.black[100]};
  ${(props) => props.theme.text.fontType.body3};

  > div:nth-child(2) {
    border-top: 0.1rem solid ${(props) => props.theme.colors.grey[300]};
    border-bottom: 0.1rem solid ${(props) => props.theme.colors.grey[300]};
  }
`;

const MenuItem = styled.div`
  display: flex;
  cursor: pointer;
  padding: ${(props) => props.theme.spacing[4]}
    ${(props) => props.theme.spacing[16]} ${(props) => props.theme.spacing[4]}
    ${(props) => props.theme.spacing[5]};
`;

const Container = withOutsideClickHandler(styled.div`
  position: relative;
`);

const Menu = styled.img`
  width: 0.3rem;
  height: 1.5rem;
  cursor: pointer;
  padding: ${(props) => props.theme.spacing[1]}
    ${(props) => props.theme.spacing[2]};
`;

const MenuItemIcon = styled.img`
  padding-right: ${(props) => props.theme.spacing[3]};
`;
