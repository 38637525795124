import { useState } from 'react';
import styled from 'styled-components';
import CloseIcon from 'src/images/general/close-icon.svg';
import { MITextInput } from 'src/components/common/MITextInput';
import { MIDropDown } from 'src/components/common/MIDropDown';
import { TEXT_INPUT_SIZE } from 'src/utils/consts';
import { MIInputLabel } from 'src/components/common/MIInputLabel';
import { actionsOptions } from '../../data';
import { withOutsideClickHandler } from 'src/hok/withOutsideClickHandler';
import { FormActions } from './FormActions';
import { getDropDownLabelByValue } from 'src/utils/builder';
import { MICheckbox } from 'src/components/common/MICheckbox';

type Props = {
  onClose: () => void;
  itemNumber: number;
  onSubmit: (data: any) => void;
};

export const SetTextForm = ({ onClose, itemNumber, onSubmit }: Props) => {
  const [stringToAdd, setStringToAdd] = useState<string>('');
  const [selectedField, selectField] = useState<string>('');
  const [keepOriginalAds, setKeepOriginalAd] = useState<boolean>(false);

  return (
    <Container handleClickOutside={onClose} itemNumber={itemNumber}>
      <CloseIconContainer>
        <CloseBlock src={CloseIcon} width='10rem' onClick={onClose} />
      </CloseIconContainer>
      <InputsContainer>
        <ItemContainer>
          <MIInputLabel label='In' required={true} />
          <MIDropDown
            value={selectedField}
            label={
              getDropDownLabelByValue(actionsOptions, selectedField) || 'Field'
            }
            options={actionsOptions}
            onChange={(data) => selectField(data.value)}
            responsiveWidth={true}
          />
        </ItemContainer>
        <Input>
          <MITextInput
            size={TEXT_INPUT_SIZE.WIZARD}
            outlined='true'
            label='Change text to'
            placeholder='Type here...'
            value={stringToAdd}
            onChange={({ value }) => setStringToAdd(value)}
            onBlur={({ target }) => setStringToAdd(target.value?.trim())}
            required={true}
          />
        </Input>
      </InputsContainer>
      <AdditionalOptions>
        <MICheckbox
          value={keepOriginalAds}
          onChange={() => setKeepOriginalAd(!keepOriginalAds)}
          positiveLabel='Keep original ads and create duplicate ads with new text'
          negativeLabel='Keep original ads and create duplicate ads with new text'
        />
      </AdditionalOptions>
      <FormActions
        onClose={onClose}
        onSubmit={() =>
          onSubmit({ field: selectedField, stringToAdd, keepOriginalAds })
        }
        submitDisabled={!stringToAdd.length || !selectedField.length}
      />
    </Container>
  );
};

const Container = withOutsideClickHandler(styled.div`
  width: 52.8rem;
  height: 18.9rem;
  color: ${(props) => props.theme.colors.black[200]};
  position: absolute;
  border-radius: ${(props) => props.theme.radius.xl};
  background-color: white;
  top: ${(props) => props.theme.spacing[24]};
  padding: ${(props) => props.theme.spacing[3]}
    ${(props) => props.theme.spacing[3]} ${(props) => props.theme.spacing[8]}
    ${(props) => props.theme.spacing[3]};
  box-shadow: 0rem 2rem 4rem 0rem ${(props) => props.theme.colors.grey[900]};
  left: ${({ itemNumber }) => itemNumber * 15 + 10}rem;
  border: 0.1rem solid ${(props) => props.theme.colors.blue[300]};
  z-index: 1;
`);

const CloseIconContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const CloseBlock = styled.img`
  cursor: pointer;
`;

const InputsContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0 ${(props) => props.theme.spacing[5]};

  >div: nth-child(1) {
    margin-right: ${(props) => props.theme.spacing[1]};
    margin-bottom: ${(props) => props.theme.spacing[2]};
  }
  >div: nth-child(2) {
    margin-left: ${(props) => props.theme.spacing[1]};
    margin-bottom: ${(props) => props.theme.spacing[2]};
  }
`;

const ItemContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-right: ${(props) => props.theme.spacing[10]};
  color: ${({ theme }) => theme.colors.grey[200]};
`;

const Input = styled.div`
  display: flex;
  flex: 1;
`;

const AdditionalOptions = styled.div`
  padding: 0 ${(props) => props.theme.spacing[5]};
  margin-bottom: ${(props) => props.theme.spacing[6]};
`;
