import styled from 'styled-components';

export const MIEditedAdLabel = () => {
  return (
    <Container>
     <Label>Edited</Label>
    </Container>
  );
};

const Container = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  background-color: #DBE0F3;
  width: 5.8rem;
  height: 2.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0 0.7rem 0 1rem;
`;

const Label = styled.div`
  color: ${props => props.theme.colors.grey[100]};
  font-size: 1.2rem;
`;
