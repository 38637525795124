import { PlannerType } from 'src/utils/types';

import {
  SET_PLANNER_KEYWORDS,
  SET_GOOGLE_KEYWORDS,
  DELETE_GOOGLE_KEYWORD,
  SET_SELECTED_KEYWORDS,
} from './actionTypes';

const initialState: PlannerType = {
  keywords: [],
  googleKeywords: [],
  selectedKeywords: [],
};

const planner = (state = initialState, action) => {
  switch (action.type) {
    case SET_PLANNER_KEYWORDS: {
      return {
        ...state,
        keywords: [...action.payload.keywords],
      };
    }
    case SET_GOOGLE_KEYWORDS: {
      return {
        ...state,
        googleKeywords: [...action.payload.keywords],
      };
    }
    case DELETE_GOOGLE_KEYWORD: {
      return {
        ...state,
        googleKeywords: state.googleKeywords.filter(
          (idea) => idea.keyword !== action.payload.keyword
        ),
      };
    }
    case SET_SELECTED_KEYWORDS: {
      return {
        ...state,
        selectedKeywords: [...action.payload.keywords],
      };
    }
    default:
      return state;
  }
};

export default planner;
