import styled, { ThemeProvider } from 'styled-components';
import includes from 'lodash/includes';
import { useLocation } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, Bounce } from 'react-toastify';
import { useBreak } from 'src/hooks/useBreak';
import { theme } from 'src/theme/defaultTheme';
import { AppHeader } from 'src/components/header/AppHeader';
import { AppFooter } from 'src/components/footer/AppFooter';
import { UnsupportedResolutionPage } from 'src/pages/unsupported/UnsupportedResolutionPage';
import { LOCATIONS } from 'src/pages/locations';
import { RegisterLayout } from './RegisterLayout';

type Props = {
  children: React.ReactNode;
};

export const AppLayout = ({ children }: Props) => {
  const location = useLocation();
  const mobileFriendlyLocations = [
    LOCATIONS.login,
    LOCATIONS.register,
    LOCATIONS.requestPassword,
    LOCATIONS.redemption,
  ];
  const isMobileFriendlyLocation = includes(
    mobileFriendlyLocations,
    location.pathname
  );
  const { isDesktop, isMobile } = useBreak();

  if (isMobile && isMobileFriendlyLocation) {
    return (
      <ThemeProvider theme={theme}>
        <RegisterLayout>{children}</RegisterLayout>
      </ThemeProvider>
    );
  }

  if (isDesktop) {
    return (
      <ThemeProvider theme={theme}>
        <Container>
          <AppHeader />
          <ToastContainer
            transition={Bounce}
            closeButton={false}
            className='MIToastContainer'
            toastClassName='MIToastInner'
          />
          <Main>{children}</Main>
          <AppFooter />
        </Container>
      </ThemeProvider>
    );
  } else {
    return <UnsupportedResolutionPage />;
  }
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: -webkit-fill-available;
`;

const Main = styled.div`
  position: relative;
  flex: 1;
`;
