import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { AdType, OptionType } from 'src/utils/types';
import { ExpTextCard } from 'src/components/common/AdCreator/Table/ExpTextCard';
import { CallOnlyCard } from 'src/components/common/AdCreator/Table/CallOnlyCard';
import { ResponsiveSearchCard } from 'src/components/common/AdCreator/Table/ResponsiveSearchCard';
import { SnippetCard } from 'src/components/common/AdCreator/Table/SnippetCard';
import { CallOutCard } from 'src/components/common/AdCreator/Table/CallOutCard';
import { getDataForForm, getFormTitle } from '../../AdCreator/form/data';
import {
  replaceSkagStep,
  setSkagCurrentFormData,
  setSkagStep,
} from 'src/redux/skagCreationFlow/actions';
import {
  replaceStagStep,
  setStagCurrentFormData,
  setStagStep,
} from 'src/redux/stagCreationFlow/actions';
import { getSkagFlowStep } from 'src/redux/skagCreationFlow/selectors';
import { MICheckbox } from 'src/components/common/MICheckbox';
import { getStagFlowStep } from 'src/redux/stagCreationFlow/selectors';
import { getSkagCampaign } from 'src/redux/skagCompaign/selectors';
import { getStagCampaign } from 'src/redux/stagCampaign/selectors';
import { headerTypes } from 'src/utils/headerTypes';
import { SitelinkCard } from '../../AdCreator/Table/SitelinkCard';

type Props = {
  isSkag: boolean;
  item: any;
  selectedAds: { id: number; adGroupId: number; type: string }[];
  selectAd: (id: number, adGroupId: number, type: string) => void;
  pausedAds: { id: number; adGroupId: number; type: string }[];
  withWholeData: boolean;
  negatives: OptionType[];
  keywords: OptionType[];
};

export const ReviewTableItem = ({
  isSkag,
  item,
  selectedAds,
  selectAd,
  pausedAds,
  withWholeData,
  negatives,
  keywords,
}: Props) => {
  const currentStep = useSelector(isSkag ? getSkagFlowStep : getStagFlowStep);
  const { adGroupList, negativePhrase } = useSelector(
    isSkag ? getSkagCampaign : getStagCampaign
  );
  const dispatch = useDispatch();

  const showEditFormModal = (data) => {
    if (isSkag) {
      dispatch(
        setSkagCurrentFormData(
          { ...data, edited: true },
          getDataForForm(data.type, true)
        )
      );
      dispatch(replaceSkagStep(3, getFormTitle(data.type)));
      dispatch(setSkagStep(currentStep - 1));
      return;
    }
    if (!isSkag) {
      dispatch(
        setStagCurrentFormData(
          { ...data, edited: true },
          getDataForForm(data.type, true)
        )
      );
      dispatch(replaceStagStep(4, getFormTitle(data.type)));
      dispatch(setStagStep(currentStep - 1));
      return;
    }
  };

  const formByAdType = useCallback(() => {
    const formProps = {
      isSkag,
      item,
      adGroupNames: [],
      renderAdGroupNames: () => {},
      showEditFormModal,
      onlyForm: true,
      paused: !!pausedAds.find(
        (pausedAd) =>
          pausedAd.adGroupId === item.adGroupId &&
          pausedAd.type === item.type &&
          pausedAd.id === item.id
      ),
    };
    switch (item.type) {
      case AdType.EXPANDED:
        return <ExpTextCard {...formProps} />;
      case AdType.CALL:
        return <CallOnlyCard {...formProps} />;
      case AdType.RESPONSIVE:
        return <ResponsiveSearchCard {...formProps} />;
      case AdType.SNIPPET:
        return (
          <SnippetCard
            headerTypeLabel={
              headerTypes.find((el) => el.value === item.headerType)?.label
            }
            {...formProps}
          />
        );
      case AdType.CALLOUT:
        return <CallOutCard {...formProps} />;
      case AdType.SITELINK:
        return <SitelinkCard {...formProps} />;
      default:
        return null;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSkag, item, pausedAds]);

  const renderItems = (items: OptionType[]) => {
    const visibleItems = items.slice(0, 3);
    const unVisibleItemsCount = items.length - 3;
    const showUnVisibleItemsCount = unVisibleItemsCount > 0;

    return (
      <>
        {visibleItems.map((item) => (
          <Label key={item.value}>{item.label}</Label>
        ))}
        {showUnVisibleItemsCount && (
          <TotalCount>{` + ${unVisibleItemsCount}`}</TotalCount>
        )}
      </>
    );
  };

  const renderItem = () => {
    return (
      <FormContainer>
        <MICheckbox
          id='table'
          value={
            !!selectedAds.find(
              (selectedAd) =>
                selectedAd.adGroupId === item.adGroupId &&
                selectedAd.type === item.type &&
                selectedAd.id === item.id
            )
          }
          onChange={() => selectAd(item.id, item.adGroupId, item.type)}
          color='blue'
        />
        {formByAdType()}
      </FormContainer>
    );
  };

  return (
    <Container>
      <ContentContainer>{renderItem()}</ContentContainer>
      {withWholeData && (
        <>
          <GroupName>
            {adGroupList.find((group) => item.adGroupId === group.id)?.adGroup}
          </GroupName>
          <Keywords>{renderItems(keywords)}</Keywords>
          {negativePhrase && <Negatives>{renderItems(negatives)}</Negatives>}
        </>
      )}
    </Container>
  );
};

const Container = styled.div`
  width: inherit;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

const FormContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;

  > div:nth-child(1) {
    margin-right: 2rem;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Negatives = styled.div`
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  font-weight: ${(props) => props.theme.text.weight.normal};
  color: ${(props) => props.theme.colors.black[100]};
  flex-wrap: wrap;
  margin-right: ${(props) => props.theme.spacing[2]};
  overflow: hidden;
  width: 10%;
`;

const Keywords = styled(Negatives)`
  color: ${(props) => props.theme.colors.grey[200]};
  width: 10%;
`;

const GroupName = styled.div`
  justify-content: flex-start;
  color: ${(props) => props.theme.colors.black[100]};
  width: 10%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const Label = styled.div`
  width: 12rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const TotalCount = styled.span`
  background-color: rgba(96, 100, 115, 0.05);
  border-radius: ${(props) => props.theme.radius.md};
  width: fit-content;
  margin-top: ${(props) => props.theme.spacing[2]};
  padding: ${(props) => props.theme.spacing[1]}
    ${(props) => props.theme.spacing[2]};
  ${(props) => props.theme.text.fontType.hint};
`;
