import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { BuilderWrapper } from './components/BuilderWrapper';
import { BuilderContainer } from 'src/pages/main/components/BuilderContainer';
import { WizardContainer } from 'src/components/layout/WizardElements';
import { CampaignsDetails } from 'src/components/campaignDetails/CampaignDetails';
import { getIsLoggedIn } from 'src/redux/user/selectors';
import { REACT_APP_DASHBOARD } from 'src/utils/consts';

import { LOCATIONS } from '../locations';
import { useEffect } from 'react';
import { getStagSteps } from 'src/redux/stagCreationFlow/selectors';
import { getSkagSteps } from 'src/redux/skagCreationFlow/selectors';
import { replaceSkagStep } from 'src/redux/skagCreationFlow/actions';
import { replaceStagStep } from 'src/redux/stagCreationFlow/actions';

export const MainPage = () => {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(getIsLoggedIn);
  const skagFlow = useSelector(getSkagSteps);
  const stagFlow = useSelector(getStagSteps);
  const route = useRouteMatch();

  const isMainPage = route.path === LOCATIONS.dashboard;

  const showCampaignsDetails = isLoggedIn && isMainPage && REACT_APP_DASHBOARD;

  useEffect(() => {
    // if user closes browser on ad editing
    if (skagFlow[3] !== 'Settings') {
      dispatch(replaceSkagStep(3, 'Settings'));
    }
    if (stagFlow[4] !== 'Settings') {
      dispatch(replaceStagStep(4, 'Settings'));
    }
  }, [dispatch, skagFlow, stagFlow]);

  return (
    <BuilderWrapper bigTopArea={showCampaignsDetails}>
      <WizardContainer toTopPosition={showCampaignsDetails}>
        <BuilderContainer />
      </WizardContainer>
      {showCampaignsDetails && <CampaignsDetails />}
    </BuilderWrapper>
  );
};
