import { MILoader } from 'src/components/common/MILoader';
import styled from 'styled-components';

type Props = {
  title: string;
  subtitle: string;
  icon: string;
  disabled?: boolean;
  loading?: boolean;
  onClick: () => void;
};

export const ModalActionItem = ({
  title,
  subtitle,
  icon,
  disabled,
  loading,
  onClick,
}: Props) => {
  return (
    <ItemContainer onClick={onClick} disabled={disabled}>
      {loading ? (
        <MILoader color='primary' context='button' />
      ) : (
        <>
          <ItemIcon src={icon} />
          <ItemDescription>
            <Description>{title}</Description>
            <Bold>{subtitle}</Bold>
          </ItemDescription>
        </>
      )}
    </ItemContainer>
  );
};

const ItemContainer = styled.div<{ disabled?: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${({ disabled }) => (disabled ? 'auto' : 'pointer')};
  box-sizing: border-box;
  width: 31rem;
  height: 12.8rem;
  border: 0.1rem solid ${({ theme }) => theme.colors.blue[400]};
  border-radius: ${({ theme }) => theme.radius.xl};
  background: ${({ theme, disabled }) =>
    disabled ? theme.colors.blue[600] : theme.colors.white};

  &:hover {
    box-shadow: ${({ theme, disabled }) =>
      disabled ? 'none' : theme.shadows.card};
  }
`;

const ItemDescription = styled.div`
  display: flex;
  flex-direction: column;
  ${({ theme }) => theme.text.fontType.body2};
`;

const Description = styled.span``;

const Bold = styled.span`
  font-weight: ${({ theme }) => theme.text.weight.bold};
`;

const ItemIcon = styled.img`
  margin-right: ${({ theme }) => theme.spacing[6]};
`;
