import styled from 'styled-components';
import { ExpTextAdExtType } from 'src/utils/types';

type Props = {
  item: ExpTextAdExtType;
};

export const ExpTextCardPreview = ({ item }: Props) => {
  return (
    <AdPreviewContainer>
      <Title>
        {`${item.headlineOne} | ${item.headlineTwo} | ${item.headlineThree}`}
      </Title>
      <Link>{item.finalUrl}</Link>
      <Description>{item.descriptionOne}</Description>
      <Description>{item.descriptionTwo}</Description>
    </AdPreviewContainer>
  );
};

const AdPreviewContainer = styled.div`
  display: flex;
  width: 60rem;
  height: fit-content;
  flex-direction: column;
  padding: 1.7rem;
  box-sizing: border-box;
  border-radius: ${(props) => props.theme.radius.lg};
  background-color: ${(props) => props.theme.colors.white};
`;

const Title = styled.span`
  color: ${(props) => props.theme.colors.blue[200]};
  ${(props) => props.theme.text.fontType.body3};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const Link = styled.span`
  padding: 0.2rem 0;
  color: ${(props) => props.theme.colors.green[200]};
  ${(props) => props.theme.text.fontType.link};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const Description = styled.span`
  color: ${(props) => props.theme.colors.black[100]};
  ${(props) => props.theme.text.fontType.link};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: 2rem;
`;
