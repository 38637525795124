import styled from 'styled-components';
import { MIModalMessage } from 'src/components/common/MIModalMessage';
import { PricingPlansPage } from 'src/pages/pricing/PricingPlansPage';

type Props = {
  dismiss?: (event: React.MouseEvent) => void;
};

export const ChoosePaymentPlanModal = ({ dismiss }: Props) => {
  return (
    <MIModalMessage
      dismiss={dismiss}
      contentComponent={
        <Container>
          <PricingPlansPage />
        </Container>
      }
    />
  );
};

const Container = styled.div`
  width: 100rem;
`;
