import { AdGroupType, TemplatesType } from 'src/utils/types';
import { GlobalState } from '../types';

export const getStagCampaign = (state: GlobalState) => state.stagCampaign;

export const getGroupsCount = (state: GlobalState): number => state.stagCampaign.adGroupList.length;

export const getStagAdGroupList = ({ stagCampaign: { adGroupList } }: GlobalState): AdGroupType[] => adGroupList;

export const getStagTemplates = ({ stagCampaign: { templates } }: GlobalState): TemplatesType => templates;
