import styled from 'styled-components';
import { MITextInput } from 'src/components/common/MITextInput';
import {
  AdType,
  CampaignType,
  SitelinkFormErrors,
  SitelinkAdType,
} from 'src/utils/types';
import { FormPreview } from '../../FormPreview';
import { firstRow, secondRow, thirdRow } from '../data';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import {
  createSkagAds,
  updateSkagAds,
  updateOneSkagAd,
} from 'src/redux/skagCompaign/actions';
import {
  createStagAds,
  updateStagAds,
  updateOneStagAd,
} from 'src/redux/stagCampaign/actions';
import MIFormButtons from 'src/components/common/MIFormButtons';
import {
  invalidParenthesesMessage,
  invalidSpecialCharactersMessage,
} from '../../data';
import { checkIsOnlyTemplate, validateParentheses } from 'src/utils/builder';
import { notifyError } from 'src/services/notifications/notificationService';
import { REGEX } from 'src/utils/consts';
import { SitelinkEditCardPreview } from './SitelinkEditCardPreview';
import { isEmpty } from 'lodash';

type Props = {
  isSkag: boolean;
  initialValues: SitelinkAdType;
  closeModal: (toNextStep?: boolean) => void;
  updateCampaignOnServer: (data?: CampaignType) => void;
};

export const SitelinkEditForm = ({
  isSkag,
  initialValues,
  closeModal,
  updateCampaignOnServer,
}: Props) => {
  const isNewData = initialValues?.id === undefined;
  const dispatch = useDispatch();
  const validate = (values) => {
    const errors: SitelinkFormErrors = {};
    delete values.keywords; // do not validate keywords and negatives (in case: edit ad from review table)
    delete values.negatives;

    const fields = Object.keys(values);
    let showParenthesesError = false;
    let showCharactersError = false;

    fields.forEach((item) => {
      if (
        typeof values[item] === 'string' &&
        !validateParentheses(values[item])
      ) {
        errors[item] = 'Invalid parentheses';
        showParenthesesError = true;
      }
      if (REGEX.EXCLAMATION_SYMBOL.test(values[item])) {
        errors[item] = 'There is blocked symbol';
        showCharactersError = true;
      }
    });

    if (showParenthesesError) {
      notifyError({ msg: invalidParenthesesMessage });
    } else if (showCharactersError) {
      notifyError({ msg: invalidSpecialCharactersMessage });
    }

    if (isEmpty(values.text)) {
      errors.text = 'This field is required';
    } else if (isEmpty(values.descriptionOne)) {
      errors.descriptionOne = 'This field is required';
    } else if (isEmpty(values.descriptionTwo)) {
      errors.descriptionTwo = 'This field is required';
    } else if (isEmpty(values.finalUrl)) {
      errors.finalUrl = 'This field is required';
    } else if (!REGEX.URL.test(values.finalUrl)) {
      errors.finalUrl = 'Wrong format (should start with https://)';
    }
    return errors;
  };

  const formik = useFormik({
    validateOnBlur: false,
    validateOnChange: false,
    initialValues,
    validate,
    onSubmit: (values) => {
      if (!Object.keys(formik.errors).length) {
        closeModal(!!values.edited);

        const onlyTemplate = checkIsOnlyTemplate(AdType.SITELINK);
        if (isNewData) {
          dispatch(
            isSkag
              ? createSkagAds(AdType.SITELINK, values, onlyTemplate)
              : createStagAds(AdType.SITELINK, values, onlyTemplate)
          );
        } else {
          if (isSkag) {
            dispatch(
              !values.edited || onlyTemplate
                ? updateSkagAds(initialValues.id, values, onlyTemplate)
                : updateOneSkagAd(initialValues.id, values)
            );
          } else {
            dispatch(
              !values.edited || onlyTemplate
                ? updateStagAds(initialValues.id, values, onlyTemplate)
                : updateOneStagAd(initialValues.id, values)
            );
          }
        }
        updateCampaignOnServer();
      }
    },
  });

  const inputHandler = (key: string, value: string) => {
    formik.setFieldValue(key, value);
    if (formik.errors) {
      formik.setErrors({});
    }
  };

  const renderInputs = (values) => {
    const onChangeHandler = (value: string, item) => {
      inputHandler(item.key, value);
    };

    return values.map((item) => (
      <MITextInput
        key={item.key}
        id={item.key}
        errorMessage={
          item.key in formik.errors ? formik.errors[item.key] : null
        }
        value={formik.values[item.key]}
        onChange={({ value }) => onChangeHandler(value, item)}
        onBlur={({ target }) => onChangeHandler(target.value?.trim(), item)}
        {...item}
      />
    ));
  };

  return (
    <Wrapper>
      <Container>
        <ItemsContainer>
          <Item>{renderInputs(firstRow)}</Item>
          <Item>{renderInputs(secondRow)}</Item>
          <Item>{renderInputs(thirdRow)}</Item>
        </ItemsContainer>
        <FormPreview
          type={AdType.SITELINK}
          preview={<SitelinkEditCardPreview item={formik.values} />}
          withoutFooter={true}
        />
      </Container>
      <MIFormButtons closeModal={closeModal} saveHandler={formik.submitForm} />
    </Wrapper>
  );
};

const Wrapper = styled.form`
  display: flex;
  flex-direction: column;
  max-width: 120rem;
`;

const ItemsContainer = styled.div`
  border-radius: ${({ theme }) => theme.radius.xl};
  padding: 4rem;
  background-color: ${({ theme }) => theme.colors.white};
`;

const Item = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-width: 100rem;
  > div:nth-child(2) {
    margin: 0 0 0 ${({ theme }) => theme.spacing[10]};
  }
`;

const Container = styled.div`
  border: 0.1rem solid ${({ theme }) => theme.colors.blue[300]};
  border-radius: ${({ theme }) => theme.radius.xl};
  background-color: ${({ theme }) => theme.colors.blue[700]};
`;
