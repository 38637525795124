import { ReactNode } from 'react';
import styled from 'styled-components';

type Props = {
  content: ReactNode;
  visible: boolean;
  children?: ReactNode;
};

export const Tooltip = ({ content, children, visible }: Props) => {
  return (
    <Container>
      <TooltipBox visible={visible}>
        {content}
        <TooltipArrow />
      </TooltipBox>
      <ChildrenContainer>{children}</ChildrenContainer>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
`;

const TooltipBox = styled.div`
  position: absolute;
  width: 100%;
  background: white;
  top: calc(100% - 16rem);
  border-radius: 2rem;
  box-shadow: ${(props) => props.theme.shadows.card};
  visibility: ${(props) => (props.visible ? 'visible' : 'hidden')};
`;

const TooltipArrow = styled.span`
  position: absolute;
  bottom: -2rem;
  left: 2.5rem;
  border-width: 1rem;
  border-style: solid;
  border-color: white transparent transparent transparent;
`;

const ChildrenContainer = styled.div``;
