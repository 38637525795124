import { useEffect } from 'react';
import { AdBuilderType } from 'src/utils/types';
import { BuilderFlow } from './components/BuilderFlow';
import { useBuilderSelection } from 'src/hooks/useBuilderSelection';
import { useDispatch, useSelector } from 'react-redux';
import { getSkagFlowStep } from 'src/redux/skagCreationFlow/selectors';
import { setSkagStep } from 'src/redux/skagCreationFlow/actions';

export const SkagCampaignBuilderPage = () => {
  const dispatch = useDispatch();
  const { setSelectedBuilderType, selectedBuilderType } = useBuilderSelection();
  const skagCurrentStep = useSelector(getSkagFlowStep);
  const isZeroStep = skagCurrentStep === 0;

  useEffect(() => {
    if (isZeroStep) {
      dispatch(setSkagStep(1));
    }
  }, [isZeroStep, dispatch]);

  useEffect(() => {
    if (selectedBuilderType !== AdBuilderType.SKAG) {
      setSelectedBuilderType(AdBuilderType.SKAG);
    }
  }, [selectedBuilderType, setSelectedBuilderType]);

  return <BuilderFlow isSkag={true} selectedBuilderType={AdBuilderType.SKAG} />;
};
