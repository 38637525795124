import { ReactNode } from 'react';
import { checkIsOnlyTemplate } from 'src/utils/builder';
import { AdType } from 'src/utils/types';
import styled from 'styled-components';

type Props = {
  preview: ReactNode;
  type: AdType;
  withoutFooter?: boolean;
};

export const FormPreview = ({ preview, type, withoutFooter }: Props) => {
  const shouldShowPreview =
    type === AdType.SITELINK ? true : !checkIsOnlyTemplate(type);

  const getLeftDescription = (type: AdType) => {
    return 'Use the [KeyWord] tag to generate unique ads.';
  };

  const getRightDescription = (type: AdType) => {
    return [
      '[KeyWord] First letter in each word uppercase',
      '[KEYWORD] No change on the keyword case',
      '[keyword] Lowercase',
      '[Keyword] First letter uppercase',
    ];
  };

  const rightDescriptionItems = getRightDescription(type);
  return shouldShowPreview ? (
    <PreviewContainer>
      <PreviewFormat>Desktop</PreviewFormat>
      <PreviewTitle>Preview</PreviewTitle>
      {preview}
      {!withoutFooter && (
        <>
          <Separator />
          <PreviewFooter>
            <LeftDescription>{getLeftDescription(type)}</LeftDescription>
            <RightDescription>
              <Row>
                <Item>{rightDescriptionItems[0]}</Item>
                <Item>{rightDescriptionItems[1]}</Item>
              </Row>
              <Row>
                <Item>{rightDescriptionItems[2]}</Item>
                <Item>{rightDescriptionItems[3]}</Item>
              </Row>
            </RightDescription>
          </PreviewFooter>
        </>
      )}
    </PreviewContainer>
  ) : null;
};

const PreviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  align-items: center;
  padding: ${(props) => props.theme.spacing[6]} 0
    ${(props) => props.theme.spacing[12]} 0;
`;

const PreviewFormat = styled.div`
  position: absolute;
  left: 5rem;
  top: 3rem;
  color: ${(props) => props.theme.colors.grey[100]};
  ${(props) => props.theme.text.fontType.body2};
  font-weight: 300;
`;

const PreviewTitle = styled.div`
  margin-bottom: ${(props) => props.theme.spacing[6]};
  color: ${(props) => props.theme.colors.grey[100]};
  ${(props) => props.theme.text.fontType.h4};
`;

const PreviewFooter = styled.div`
  width: 90%;
  display: flex;
  justify-content: space-between;
`;

const LeftDescription = styled.div`
  color: ${(props) => props.theme.colors.grey[100]};
  ${(props) => props.theme.text.fontType.body3};
`;

const RightDescription = styled.div`
  display: flex;
  color: ${(props) => props.theme.colors.grey[100]};
  ${(props) => props.theme.text.fontType.body4};
  > div:nth-child(1) {
    margin: 0 ${(props) => props.theme.spacing[5]};
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: column;
  color: ${(props) => props.theme.colors.grey[200]};
  > div:nth-child(1) {
    margin: 0 0 ${(props) => props.theme.spacing[2]} 0;
  }
`;

const Item = styled.div``;

const Separator = styled.div`
  width: 93%;
  height: 0.1rem;
  background-color: ${(props) => props.theme.colors.grey[300]};
  margin: ${(props) => props.theme.spacing[10]};
`;
