import { languages } from 'src/utils/languages';
import { headerTypes } from 'src/utils/headerTypes';
import { AdType, SnippetExtensionType } from 'src/utils/types';

export const firstRow = [
  {
    label: 'Header',
    key: 'language',
    required: true,
    options: languages,
  },
  {
    label: 'Header type',
    key: 'headerType',
    required: true,
    options: headerTypes,
  },
];

export const fullRow = [
  {
    label: 'Values one',
    key: 'snippetValueOne',
    required: true,
    maxlength: 90,
  },
  {
    label: 'Values two',
    key: 'snippetValueTwo',
    required: true,
    maxlength: 90,
  },
  {
    label: 'Values three',
    key: 'snippetValueThree',
    required: true,
    maxlength: 90,
  },
];

export const defaultDataSnippetExt: SnippetExtensionType = {
  id: undefined,
  language: 'en',
  adGroupName: '',
  headerType: 'se',
  snippetValueOne: '',
  snippetValueTwo: '',
  snippetValueThree: '',
  type: AdType.SNIPPET,
};

export const fieldsToCheckBlockedSymbols = [
  'adGroupName',
  'snippetValueOne',
  'snippetValueTwo',
  'snippetValueThree',
];
