import { MIButton } from 'src/components/common/MIButton';
import { BUTTON_VARIANT } from 'src/utils/consts';
import styled from 'styled-components';

type Props = {
  onClose: () => void;
  onSubmit: () => void;
  submitDisabled: boolean;
};

export const FormActions = ({ onClose, onSubmit, submitDisabled }: Props) => {
  return (
    <Container>
      <ButtonsContainer>
        <CancelButton>
          <MIButton
            label='CANCEL'
            variant={BUTTON_VARIANT.SECONDARY}
            onClick={onClose}
          />
        </CancelButton>
        <ApplyButton>
          <MIButton label='APPLY' onClick={onSubmit} disabled={submitDisabled} />
        </ApplyButton>
      </ButtonsContainer>
    </Container>
  );
};

const Container = styled.div``;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const CancelButton = styled.div`
  margin-right: 2rem;
`;

const ApplyButton = styled.div`
  margin-left: 2rem;
`;
