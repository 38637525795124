import uniqBy from 'lodash/uniqBy';
import { v4 as uuid } from 'uuid';

import { BID_STRATEGY, defaultTemplatesData } from 'src/utils/consts';
import { CampaignType } from 'src/utils/types';
import {
  appendTextReducer,
  replaceTextReducer,
  setTextReducer,
} from '../utils';

import {
  SET_STAG_AD_GROUPS,
  SET_KEYWORDS_FOR_GROUP,
  CREATE_STAG_ADS,
  UPDATE_STAG_ADS,
  UPDATE_ONE_STAG_AD,
  DELETE_STAG_AD,
  DELETE_ALL_STAG_ADS_BY_TYPE,
  SET_STAG_SETTINGS,
  REPLACE_STAG_TEXT,
  APPEND_STAG_TEXT,
  SET_STAG_TEXT,
  UPDATE_NEGATIVES_IN_STAG_GROUP,
  DUPLICATE_STAG_AD_TEMPLATE,
  DUPLICATE_STAG_AD_GROUP,
  LOAD_STAG_CAMPAIGN,
  CLEAN_STAG_CAMPAIGN,
  SET_KEYWORDS_VALIDATION_ERROR,
} from './actionTypes';
import { StagActionType } from './actions';

const initialState: CampaignType = {
  _id: null,
  name: '',
  draft: true,
  budget: null,
  exact: false,
  phrase: false,
  broad: true,
  differentGroup: false,
  negativePhrase: false,
  skag: false,
  stag: true,
  adGroupList: [],
  keywordsList: [],
  templates: defaultTemplatesData,
  googleAccountId: undefined,
  forGoogleAds: false,
  bidTargetGoal: null,
  bidStrategyType: BID_STRATEGY.MANUAL_CPC,
  bidStrategyPortfolioName: '',
};

const stagCampaign = (state = initialState, action: StagActionType) => {
  switch (action.type) {
    case LOAD_STAG_CAMPAIGN: {
      return {
        ...state,
        ...action.payload.data,
      };
    }
    case SET_STAG_AD_GROUPS: {
      return {
        ...state,
        adGroupList: uniqBy(
          action.payload.adGroupList,
          ({ adGroup }) => adGroup
        ),
      };
    }
    case SET_KEYWORDS_FOR_GROUP: {
      return {
        ...state,
        adGroupList: state.adGroupList.map((group) => {
          if (group.id === action.payload.id) {
            return { ...group, keywords: action.payload.keywords };
          }
          return group;
        }),
      };
    }
    case SET_STAG_SETTINGS: {
      return {
        ...state,
        name: action.payload.campaign.name,
        budget: action.payload.campaign.budget,
        exact: action.payload.campaign.exact,
        phrase: action.payload.campaign.phrase,
        broad: action.payload.campaign.broad,
        differentGroup: action.payload.campaign.differentGroup,
        negativePhrase: action.payload.campaign.negativePhrase,
        bidTargetGoal: action.payload.campaign.bidTargetGoal,
        bidStrategyType: action.payload.campaign.bidStrategyType,
        bidStrategyPortfolioName:
          action.payload.campaign.bidStrategyPortfolioName,
      };
    }
    case CREATE_STAG_ADS: {
      const idid = uuid();
      return {
        ...state,
        adGroupList: state.adGroupList.map((group) => {
          if (!action.payload.onlyTemplate) {
            const id = idid;
            group[action.payload.type].push({
              ...action.payload.data,
              type: action.payload.type,
              id: id,
              adGroupId: group.id,
            });
          }
          return group;
        }),
        templates: {
          ...state.templates,
          [action.payload.type]: [
            ...state.templates[action.payload.type],
            {
              ...action.payload.data,
              type: action.payload.type,
              id: idid,
            },
          ],
        },
      };
    }
    case UPDATE_STAG_ADS: {
      return {
        ...state,
        adGroupList: state.adGroupList.map((group) => {
          if (!action.payload.onlyTemplate) {
            const valueIndex = group[action.payload.data.type].findIndex(
              (item) => item.id === action.payload.id && !item.edited
            );
            if (valueIndex !== -1) {
              group[action.payload.data.type][valueIndex] = {
                ...group[action.payload.data.type][valueIndex],
                ...action.payload.data,
              };
            }
          }
          return group;
        }),
        templates: {
          ...state.templates,
          [action.payload.data.type]: state.templates[
            action.payload.data.type
          ].map((ad) => {
            if (ad.id === action.payload.id) {
              return { ...ad, ...action.payload.data };
            }
            return ad;
          }),
        },
      };
    }
    case UPDATE_ONE_STAG_AD: {
      return {
        ...state,
        adGroupList: state.adGroupList.map((group) => {
          if (group.id === action.payload.data.adGroupId) {
            const valueIndex = group[action.payload.data.type].findIndex(
              (item) => item.id === action.payload.id
            );
            if (valueIndex !== -1) {
              group[action.payload.data.type][valueIndex] = action.payload.data;
            }
          }
          return group;
        }),
      };
    }
    case DELETE_STAG_AD: {
      return {
        ...state,
        adGroupList: state.adGroupList.map((group) => {
          if (
            !action.payload.isTemplate &&
            group.id === action.payload.adGroupId
          ) {
            return {
              ...group,
              [action.payload.type]: group[action.payload.type].filter(
                (ad) => ad.id !== action.payload.id
              ),
            };
          }
          return group;
        }),
        templates: {
          ...state.templates,
          [action.payload.type]: action.payload.isTemplate
            ? state.templates[action.payload.type].filter(
                (item) => item.id !== action.payload.id
              )
            : state.templates[action.payload.type],
        },
      };
    }
    case APPEND_STAG_TEXT: {
      return appendTextReducer(state, action);
    }
    case SET_STAG_TEXT: {
      return setTextReducer(state, action);
    }
    case REPLACE_STAG_TEXT: {
      return replaceTextReducer(state, action);
    }
    case DUPLICATE_STAG_AD_TEMPLATE: {
      const duplicate = {
        ...action.payload.data,
        id: uuid(),
      };
      return {
        ...state,
        templates: {
          ...state.templates,
          [action.payload.data.type]: [
            ...state.templates[action.payload.data.type],
            duplicate,
          ],
        },
        adGroupList: state.adGroupList.map((group) => {
          if (!action.payload.onlyTemplate) {
            group[action.payload.data.type].push({
              ...duplicate,
              adGroupId: group.id,
            });
          }
          return group;
        }),
      };
    }
    case DUPLICATE_STAG_AD_GROUP: {
      return {
        ...state,
        adGroupList: state.adGroupList.map((group) => {
          if (group.id === action.payload.data.adGroupId) {
            const ad = group[action.payload.data.type].find(
              (ad) => ad.id === action.payload.data.id
            );
            if (ad) {
              const duplicate = {
                ...ad,
                id: uuid(),
              };
              group[action.payload.data.type].push(duplicate);
            }
          }
          return group;
        }),
      };
    }
    case UPDATE_NEGATIVES_IN_STAG_GROUP: {
      return {
        ...state,
        adGroupList: state.adGroupList.map((group) => {
          if (action.payload.id === group.id) {
            return { ...group, negatives: action.payload.data };
          }
          return group;
        }),
      };
    }
    case DELETE_ALL_STAG_ADS_BY_TYPE: {
      return {
        ...state,
        adGroupList: state.adGroupList.map((group) => {
          if (!action.payload.isTemplate) {
            return {
              ...group,
              [action.payload.type]: group[action.payload.type].filter(
                (ad) => ad.edited || ad.id !== action.payload.id
              ),
            };
          }
          return group;
        }),
        templates: {
          ...state.templates,
          [action.payload.type]: state.templates[action.payload.type].filter(
            (template) => template.id !== action.payload.id
          ),
        },
      };
    }
    case CLEAN_STAG_CAMPAIGN: {
      return initialState;
    }
    case SET_KEYWORDS_VALIDATION_ERROR: {
      return {
        ...state,
        adGroupList: state.adGroupList.map((group) => {
          if (group.id === action.payload.id) {
            return {
              ...group,
              keywordsValidationError: action.payload.keywordsValidationError,
            };
          }
          return group;
        }),
      };
    }
    default:
      return state;
  }
};

export default stagCampaign;
