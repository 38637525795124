import { useState } from 'react';
import styled, { css } from 'styled-components';
import CloseIcon from 'src/images/general/close-icon.svg';
import { MITextInput } from 'src/components/common/MITextInput';
import { MICheckbox } from 'src/components/common/MICheckbox';
import { TEXT_INPUT_SIZE } from 'src/utils/consts';
import { MIInputLabel } from 'src/components/common/MIInputLabel';
import { MIDropDown } from 'src/components/common/MIDropDown';
import { replaceTextOptions } from '../../data';
import { withOutsideClickHandler } from 'src/hok/withOutsideClickHandler';
import { FormActions } from './FormActions';
import { getDropDownLabelByValue } from 'src/utils/builder';

type Props = {
  onClose: () => void;
  itemNumber: number;
  onSubmit: (data: any) => void;
};

export const ReplaceTextForm = ({ onClose, itemNumber, onSubmit }: Props) => {
  const [formData, setFormData] = useState({
    matchCase: false,
    matchWholeWords: false,
    preserveCapitalizations: false,
    keepOriginalAds: false,
    selectedField: '',
    stringForReplace: '',
    newString: '',
  });

  return (
    <Container handleClickOutside={onClose} itemNumber={itemNumber}>
      <CloseIconContainer>
        <CloseBlock src={CloseIcon} width='10rem' onClick={onClose} />
      </CloseIconContainer>
      <InputsContainer>
        <MITextInput
          size={TEXT_INPUT_SIZE.WIZARD}
          outlined='true'
          placeholder='Type here...'
          label='Find text'
          required={true}
          value={formData.stringForReplace}
          onChange={({ value }) =>
            setFormData({ ...formData, stringForReplace: value })
          }
        />
        <ItemContainer>
          <MIInputLabel label='In' required={true} />
          <MIDropDown
            value={formData.selectedField}
            label={
              getDropDownLabelByValue(replaceTextOptions, formData.selectedField) ||
              'Field'
            }
            options={replaceTextOptions}
            onChange={(data) =>
              setFormData({ ...formData, selectedField: data.value })
            }
            responsiveWidth={true}
          />
        </ItemContainer>
      </InputsContainer>
      <MatchesContainer>
        <MICheckbox
          value={formData.matchCase}
          onChange={() =>
            setFormData({ ...formData, matchCase: !formData.matchCase })
          }
          positiveLabel='Match case'
          negativeLabel='Match case'
        />
        <MICheckbox
          value={formData.matchWholeWords}
          onChange={() =>
            setFormData({
              ...formData,
              matchWholeWords: !formData.matchWholeWords,
            })
          }
          positiveLabel='Match whole words only'
          negativeLabel='Match whole words only'
        />
      </MatchesContainer>
      <ReplaceContainer>
        <MITextInput
          size={TEXT_INPUT_SIZE.INLINE}
          outlined='true'
          placeholder='Type here...'
          label='Replace with'
          required={true}
          value={formData.newString}
          onChange={({ value }) =>
            setFormData({ ...formData, newString: value })
          }
          onBlur={({ target }) => setFormData({ ...formData, newString: target.value?.trim() }) }
        />
      </ReplaceContainer>
      <OptionalParamContainer isLast>
        <MICheckbox
          value={formData.keepOriginalAds}
          onChange={() =>
            setFormData({
              ...formData,
              keepOriginalAds: !formData.keepOriginalAds,
            })
          }
          positiveLabel='Keep original ads and create duplicate ads with new text'
          negativeLabel='Keep original ads and create duplicate ads with new text'
        />
      </OptionalParamContainer>
      <FormActions
        onClose={onClose}
        onSubmit={() => onSubmit(formData)}
        submitDisabled={
          !formData.stringForReplace.length ||
          !formData.newString.length ||
          !formData.selectedField.length
        }
      />
    </Container>
  );
};

const Container = withOutsideClickHandler(styled.div`
  width: 52.8rem;
  height: 32.8rem;
  color: ${(props) => props.theme.colors.black[200]};
  position: absolute;
  border-radius: ${(props) => props.theme.radius.xl};
  background-color: white;
  top: ${(props) => props.theme.spacing[24]};
  padding: ${(props) => props.theme.spacing[3]}
    ${(props) => props.theme.spacing[3]} ${(props) => props.theme.spacing[8]}
    ${(props) => props.theme.spacing[3]};
  box-shadow: 0rem 2rem 4rem 0rem ${(props) => props.theme.colors.grey[900]};
  left: ${({ itemNumber }) => itemNumber * 15 + 10}rem;
  border: 0.1rem solid ${(props) => props.theme.colors.blue[300]};
  z-index: 1;
`);

const CloseIconContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const CloseBlock = styled.img`
  cursor: pointer;
`;

const InputsContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0 ${(props) => props.theme.spacing[5]};

  >div: nth-child(1) {
    margin-right: ${(props) => props.theme.spacing[1]};
  }

  >div: nth-child(2) {
    margin-left: ${(props) => props.theme.spacing[1]};
  }
`;

const ReplaceContainer = styled.div`
  width: calc(50% - 3rem);
  padding: 0 ${(props) => props.theme.spacing[5]};
  >div: nth-child(1) {
    margin-bottom: ${(props) => props.theme.spacing[2]};
  }
`;

const MatchesContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 ${(props) => props.theme.spacing[5]};
  margin-bottom: ${(props) => props.theme.spacing[5]};

  >div: nth-child(1) {
    margin-right: ${(props) => props.theme.spacing[5]};
  }
`;

const OptionalParamContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 ${(props) => props.theme.spacing[5]};
  margin-top: ${(props) => props.theme.spacing[3]};
  >div: nth-child(2) {
    margin-left: ${(props) => props.theme.spacing[2]};
  }
  ${({ isLast }) =>
    isLast &&
    css`
      margin-bottom: ${(props) => props.theme.spacing[6]};
    `}
`;

const ItemContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  color: ${({ theme }) => theme.colors.grey[200]};
`;
