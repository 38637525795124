import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { getSkagCampaign } from 'src/redux/skagCompaign/selectors';
import { getStagCampaign } from 'src/redux/stagCampaign/selectors';
import { AdBuilderType } from 'src/utils/types';
import { StagGroupsBuilder } from 'src/components/stag/StagGroupsBuilder';
import { StagKeywordsBuilder } from 'src/components/stag/StagKeywordsBuilder';
import { SkagBuilder } from 'src/components/skag/SkagBuilder';
import { AdCreator } from 'src/components/common/AdCreator/AdCreator';
import { CampaignSettings } from 'src/components/common/CampaignSettings/CampaignSettings';
import { ReviewEditor } from 'src/components/common/ReviewTable/ReviewEditor';
import {
  replaceSkagStep,
  setSkagStep,
} from 'src/redux/skagCreationFlow/actions';
import {
  replaceStagStep,
  setStagStep,
} from 'src/redux/stagCreationFlow/actions';
import {
  getCurrentSkagItem,
  getSkagSteps,
  getCurrentAdTypeDetails,
  getSkagFlowStep,
} from 'src/redux/skagCreationFlow/selectors';
import Form from 'src/components/common/AdCreator/form/Form';
import { FormModal } from 'src/components/common/AdCreator/Modal/FormModal';
import {
  getCurrentStagAdTypeDetails,
  getCurrentStagItem,
  getStagFlowStep,
  getStagSteps,
} from 'src/redux/stagCreationFlow/selectors';
import { useCallback } from 'react';
import { BuilderStepLayout } from 'src/components/layout/BuilderStepLayout/BuilderStepLayout';
import { getIsLoggedIn } from 'src/redux/user/selectors';
import {
  notifyServerError,
  notifySuccess,
} from 'src/services/notifications/notificationService';
import CampaignService from 'src/services/campaign/campaignService';
import { store } from 'src/redux/store';

type Props = {
  selectedBuilderType: AdBuilderType;
  isSkag: boolean;
};

export const BuilderFlow = ({ selectedBuilderType, isSkag }: Props) => {
  const dispatch = useDispatch();

  const skagCampaign = useSelector(getSkagCampaign);
  const stagCampaign = useSelector(getStagCampaign);
  const skagCurrentStep = useSelector(getSkagFlowStep);
  const stagCurrentStep = useSelector(getStagFlowStep);
  const isLoggedIn = useSelector(getIsLoggedIn);
  const skagFlow = useSelector(getSkagSteps);
  const stagFlow = useSelector(getStagSteps);

  const currentItem = useSelector(
    isSkag ? getCurrentSkagItem : getCurrentStagItem
  );

  const getCurrentStep = useCallback(() => {
    switch (selectedBuilderType) {
      case AdBuilderType.SKAG:
        return skagCurrentStep;
      case AdBuilderType.STAG:
        return stagCurrentStep;
      default:
        return 1;
    }
  }, [selectedBuilderType, skagCurrentStep, stagCurrentStep]);

  const currentStep = getCurrentStep();

  const currentAdTypeDetails = useSelector(
    isSkag ? getCurrentAdTypeDetails : getCurrentStagAdTypeDetails
  );

  const closeHandler = (toNextStep: boolean = false) => {
    if (isSkag) {
      dispatch(replaceSkagStep(3, 'Settings'));
      dispatch(setSkagStep(toNextStep ? currentStep + 1 : currentStep - 1));
    } else {
      dispatch(replaceStagStep(4, 'Settings'));
      dispatch(setStagStep(toNextStep ? currentStep + 1 : currentStep - 1));
    }
  };

  const updateCampaignOnServer = (data) => {
    if (isLoggedIn) {
      const campaignKey =
        selectedBuilderType === AdBuilderType.SKAG
          ? 'skagCompaign'
          : 'stagCampaign';
      const campaign = data || store.getState()[campaignKey];
      if (campaign) {
        CampaignService.updateCampaign(campaign)
          .then(() => {
            notifySuccess({ msg: 'Campaign successfully updated' });
          })
          .catch(() => {
            notifyServerError({ msg: 'Server error' });
          });
      }
    }
  };

  const SKAGFlowPages = {
    1: <SkagBuilder campaign={skagCampaign} />,
    2: <AdCreator isSkag campaign={skagCampaign} />,
    3:
      skagFlow[3] === 'Settings' ? (
        <CampaignSettings isSkag campaign={skagCampaign} />
      ) : (
        <Form
          titleComponent={
            <ModalTitleContainer>
              <ModalTitle>
                {currentAdTypeDetails?.title1}{' '}
                <Bold>{currentAdTypeDetails?.boldTitle}</Bold>
              </ModalTitle>
            </ModalTitleContainer>
          }
          contentComponent={null}
          footerComponent={
            <FormModal
              isSkag
              isEdit={currentAdTypeDetails?.isEdit}
              currentAdType={currentAdTypeDetails?.type}
              defaultData={currentAdTypeDetails?.defaultData}
              values={currentItem}
              closeModal={closeHandler}
              updateCampaignOnServer={updateCampaignOnServer}
            />
          }
        />
      ),
    4: <ReviewEditor isSkag campaign={skagCampaign} />,
  };

  const STAGFlowPages = {
    1: <StagGroupsBuilder />,
    2: <StagKeywordsBuilder />,
    3: <AdCreator campaign={stagCampaign} />,
    4:
      stagFlow[4] === 'Settings' ? (
        <CampaignSettings campaign={stagCampaign} />
      ) : (
        <Form
          titleComponent={
            <ModalTitleContainer>
              <ModalTitle>
                {currentAdTypeDetails?.title1}{' '}
                <Bold>{currentAdTypeDetails?.boldTitle}</Bold>
              </ModalTitle>
            </ModalTitleContainer>
          }
          contentComponent={null}
          footerComponent={
            <FormModal
              isEdit={currentAdTypeDetails?.isEdit}
              currentAdType={currentAdTypeDetails?.type}
              defaultData={currentAdTypeDetails?.defaultData}
              values={currentItem}
              closeModal={closeHandler}
              updateCampaignOnServer={updateCampaignOnServer}
            />
          }
        />
      ),
    5: <ReviewEditor campaign={stagCampaign} />,
  };

  const renderFlowContent = () => {
    if (isSkag) {
      return SKAGFlowPages[currentStep] || <div>Not Found</div>;
    }
    return STAGFlowPages[currentStep] || <div>Not Found</div>;
  };

  return (
    <BuilderStepLayout
      isSkag={isSkag}
      currentStep={currentStep}
      progressBarSteps={isSkag ? skagFlow : stagFlow}
      updateCampaign={updateCampaignOnServer}
    >
      {renderFlowContent()}
    </BuilderStepLayout>
  );
};

const ModalTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: ${(props) => props.theme.spacing[8]};
`;

const ModalTitle = styled.span`
  ${(props) => props.theme.text.fontType.h2};
`;

const Bold = styled.span`
  font-weight: ${(props) => props.theme.text.weight.bold};
`;
