import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { MIButton } from 'src/components/common/MIButton';
import { WizardOrLine } from 'src/components/layout/WizardElements';
import { BUTTON_VARIANT } from 'src/utils/consts';
import styled from 'styled-components';
import { LOCATIONS } from 'src/pages/locations';
import { devices } from 'src/theme/appDevices';

export const EnterToSystem = () => {
  const history = useHistory();

  const moveToRegister = useCallback(() => {
    history.push(LOCATIONS.register, { page: LOCATIONS.redemption });
  }, [history]);

  const moveToLogin = useCallback(() => {
    history.push(LOCATIONS.login, { page: LOCATIONS.redemption });
  }, [history]);

  return (
    <Container>
      <Title>
        Thank you for your <Bold>Purchase!</Bold>
      </Title>
      <Subtitle>Follow these simple steps to gain access to your plan</Subtitle>
      <Separator />
      <Steps>
        Step 1. Create an account or login to an existing account <br /> <br />
        Step 2. Enter your redemption code to unlock your plan <br /> <br />
        Step 3. Use a desktop computer to start using Builda
      </Steps>
      <Buttons>
        <MIButton
          label='Create Account'
          onClick={moveToRegister}
          isProcessing={false}
          fullWidth={true}
        />
        <WizardOrLine />
        <MIButton
          label='Sign In'
          variant={BUTTON_VARIANT.SECONDARY}
          onClick={moveToLogin}
          isProcessing={false}
          fullWidth={true}
        />
      </Buttons>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 5%;
`;

const Title = styled.span`
  font-size: 4rem;
  @media ${devices.mobile} {
    font-size: 3rem;
  }
`;

const Bold = styled.span`
  font-weight: ${(props) => props.theme.text.weight.bold};
`;

const Subtitle = styled.span`
  font-size: 2.4rem;
  margin-top: 2rem;
  font-weight: ${(props) => props.theme.text.weight.light};
  @media ${devices.mobile} {
    font-size: 2rem;
    width: 90%;
  }
`;

const Separator = styled.div`
  margin-top: 2rem;
  margin-bottom: 4rem;
  width: 60rem;
  height: 0.1rem;
  background-color: lightGrey;
  @media ${devices.mobile} {
    width: 90%;
  }
`;

const Steps = styled.div`
  text-align: left;
  font-size: 2.4rem;
  font-weight: ${(props) => props.theme.text.weight.light};
  @media ${devices.mobile} {
    font-size: 2rem;
    width: 90%;
  }
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 4rem;
  margin-bottom: 2rem;
  width: 50rem;
  @media ${devices.mobile} {
    width: 90%;
  }
`;
