import { useEffect, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { MICheckbox } from 'src/components/common/MICheckbox';
import { MITableEmptyState } from 'src/components/common/MITableEmtyState';
import { Tooltip } from 'src/components/common/ReviewTable/Table/Tooltip';
import { usePagination } from 'src/hooks/usePagination';
import { deleteSkagAd } from 'src/redux/skagCompaign/actions';
import {
  AdGroupType,
  AdType,
  CampaignType,
  OptionType,
  ReviewAdType,
} from 'src/utils/types';
import { TableFooter } from 'src/components/common/AdCreator/Table/TableFooter';
import { ReviewTableItem } from './ReviewTableItem';
import { TooltipContent } from './TooltipContent';
import { deleteStagAd } from 'src/redux/stagCampaign/actions';
import {
  checkIsOnlyTemplate,
  generateSkagKeywords,
  generateStagKeywords,
  getTemplatesToDisplayInReview,
  removeExtensions,
  replaceTemplatesDependOnTemplateType,
  shouldShowAd,
} from 'src/utils/builder';
import { splitItemsByPages } from '../../AdCreator/Table/utils';

type Props = {
  isSkag: boolean;
  filterByAdType: AdType;
  filterByGroups: string[];
  adsCount: number;
  campaign: CampaignType;
  negativesList: OptionType[];
  filterByNegatives: string[];
  filterByKeywords: string[];
  isAllAdGroupsSelected: boolean;
  isAllKeywordsSelected: boolean;
  isAllNegativesSelected: boolean;
};

export const ReviewTable = ({
  isSkag,
  filterByAdType,
  filterByGroups,
  adsCount,
  campaign,
  negativesList,
  filterByNegatives,
  filterByKeywords,
  isAllAdGroupsSelected,
  isAllKeywordsSelected,
  isAllNegativesSelected,
}: Props) => {
  const [selectedAds, setSelectedAds] = useState<
    { id: number; adGroupId: number; type: AdType }[]
  >([]);
  const [isAllAdsSelected, setIsAllAdsSelected] = useState<boolean>(false);
  const [totalAdsCount, setTotalAdsCount] = useState<number>(0);
  const [pausedAds, setPausedAds] = useState<
    { id: number; adGroupId: number; type: string }[]
  >([]);
  const [pageSize, setPageSize] = useState(10);
  const [isTooltipOpened, setTooltipOpen] = useState<boolean>(false);
  const [allAds, setAllAds] = useState<ReviewAdType[]>([]);
  const { exact, broad, phrase, negativePhrase, adGroupList, templates } =
    campaign;

  const { goToPage, pageCount, pageIndex, setPaginationState } = usePagination({
    totalItems: allAds.length,
    limit: pageSize,
  });

  useEffect(() => {
    setPaginationState(pageIndex);
  }, [pageIndex, setPaginationState]);

  const dispatch = useDispatch();

  const isEmptyState = adsCount === 0;

  useEffect(() => {
    if (selectedAds.length) {
      setSelectedAds([]);
    }
    if (isAllAdsSelected) {
      setIsAllAdsSelected(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterByGroups, filterByAdType]);

  useEffect(() => {
    let totalCount = 0;
    const ads = Object.values(AdType).slice(1);
    const adsWithoutExtensions = removeExtensions(ads);

    adGroupList.forEach((item) => {
      adsWithoutExtensions.forEach((ad) => {
        totalCount += item[ad].length;
      });
    });
    setTotalAdsCount(totalCount);
  }, [adGroupList]);

  const selectAll = () => {
    setIsAllAdsSelected(true);
    setTooltipOpen(true);
    selectAllAds();
  };

  const unselectAll = () => {
    setIsAllAdsSelected(false);
    setTooltipOpen(true);
    setSelectedAds([]);
  };

  const selectAllAds = () => {
    for (const ad of allAds) {
      selectAdByTypeAndGroup(ad.id, ad.adGroupId, ad.type, true);
    }
  };

  const selectAdByTypeAndGroup = (id, adGroupId, type, forceAdd = false) => {
    if (
      selectedAds.find(
        (item) =>
          item.adGroupId === adGroupId && item.type === type && item.id === id
      )
    ) {
      if (!forceAdd) {
        setSelectedAds((prevItems) =>
          prevItems.filter((item) => {
            if (item.type !== type) {
              return true;
            }
            if (item.adGroupId === adGroupId && item.id === id) {
              return false;
            }
            return true;
          })
        );
      }
    } else {
      setSelectedAds((prevValue) => [...prevValue, { id, adGroupId, type }]);
      if (!isTooltipOpened) {
        setTooltipOpen(true);
      }
    }
  };

  const setPausedItemsHandler = () => {
    setPausedAds(selectedAds);
  };

  const removePausedItemsHandler = () => {
    setPausedAds((prevItems) =>
      prevItems.filter((item) =>
        selectedAds.every(
          (ad) =>
            !(
              item.adGroupId === ad.adGroupId &&
              item.id === ad.id &&
              item.type === ad.type
            )
        )
      )
    );
  };

  const deleteHandler = () => {
    if (isSkag) {
      selectedAds.forEach((ad) => {
        const isTemplate = checkIsOnlyTemplate(ad.type);
        dispatch(deleteSkagAd({ ...ad, isTemplate }));
      });
    }
    if (!isSkag) {
      selectedAds.forEach((ad) => {
        const isTemplate = checkIsOnlyTemplate(ad.type);
        dispatch(deleteStagAd({ ...ad, isTemplate }));
      });
    }
    setSelectedAds([]);
  };

  useEffect(() => {
    const templatesToDisplay = getTemplatesToDisplayInReview(templates);

    const filteredGroupList = adGroupList.filter(({ adGroup }) =>
      filterByGroups.includes(adGroup)
    );

    const adsList = filteredGroupList.reduce(
      (currentValue: ReviewAdType[], group: AdGroupType) => {
        const adGroupName = group.adGroup.replace('\r', '');
        const formInJson = JSON.stringify(group);
        const formWithReplacedKeywords = replaceTemplatesDependOnTemplateType(
          formInJson,
          adGroupName
        );
        const parsedForm: AdGroupType = JSON.parse(formWithReplacedKeywords);
        const negatives = negativePhrase
          ? negativesList.filter(
              (negative) => negative.value !== parsedForm.adGroup
            )
          : [];

        const keywords: OptionType[] = isSkag
          ? generateSkagKeywords(parsedForm, exact, broad, phrase)
          : generateStagKeywords(parsedForm, exact, broad, phrase);
        const ads = Object.values(AdType).slice(1);
        const adsWithoutExtensions = removeExtensions(ads);

        adsWithoutExtensions.forEach((type) => {
          parsedForm[type].forEach((ad) => {
            if (
              shouldShowAd(
                keywords,
                negatives,
                filterByKeywords,
                filterByNegatives,
                filterByAdType,
                ad.type
              )
            ) {
              currentValue.push({
                ...ad,
                adGroupName: parsedForm.adGroup,
                keywords,
                negatives,
              });
            }
          });
        });
        return currentValue;
      },
      []
    );

    // filterTemplates
    const filteredTemplatesToDisplay = templatesToDisplay.filter(
      (item) =>
        (filterByAdType === AdType.ALL || filterByAdType === item.type) &&
        isAllAdGroupsSelected &&
        isAllKeywordsSelected &&
        isAllNegativesSelected
    );

    setAllAds([...adsList.flat(), ...filteredTemplatesToDisplay]);
  }, [
    adGroupList,
    filterByGroups,
    filterByAdType,
    exact,
    broad,
    phrase,
    negativePhrase,
    negativesList,
    isSkag,
    filterByKeywords,
    filterByNegatives,
    templates,
    isAllAdGroupsSelected,
    isAllKeywordsSelected,
    isAllNegativesSelected,
  ]);

  const renderItems = useCallback(() => {
    const itemsOnPage = splitItemsByPages(
      allAds,
      allAds.length,
      pageIndex,
      pageSize
    );

    return itemsOnPage.length ? (
      itemsOnPage.map((ad, index) => {
        const isTemplate = !!!ad?.adGroupId;

        const keywords = isTemplate ? [] : ad?.keywords;
        const negatives = isTemplate ? [] : ad?.negatives;

        const groupId = ad?.adGroupId;
        const key = `${ad.id}${isTemplate ? '' : groupId}`;
        const shouldShowWholeData = isTemplate
          ? false
          : index === 0 || groupId !== itemsOnPage[index - 1].adGroupId;

        return (
          <ReviewTableItem
            key={key}
            withWholeData={
              shouldShowWholeData //show ad group, keywords and negatives only for the first ad from group
            }
            isSkag={isSkag}
            item={ad}
            selectedAds={selectedAds}
            selectAd={selectAdByTypeAndGroup}
            pausedAds={pausedAds}
            negatives={negatives}
            keywords={keywords}
          />
        );
      })
    ) : (
      <NoAdsDescription>There is no ads or extensions.</NoAdsDescription>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allAds, pageIndex, selectedAds, pageSize]);

  return (
    <Container>
      <BlockContainer>
        <Tooltip
          content={
            <TooltipContent
              isSkag={isSkag}
              onClose={() => setTooltipOpen(false)}
              selectedAds={selectedAds}
              totalAdsCount={totalAdsCount}
              selectAllAds={selectAll}
              setPausedItems={setPausedItemsHandler}
              removePausedItems={removePausedItemsHandler}
              deleteItems={deleteHandler}
            />
          }
          visible={isTooltipOpened}
        >
          <Header>
            <LeftBlock>
              <MICheckbox
                id='whole'
                value={isAllAdsSelected}
                onChange={() =>
                  isAllAdsSelected ? unselectAll() : selectAll()
                }
                positiveLabel=''
                negativeLabel=''
                color='white'
              />
              <Title>Ad and extension</Title>
            </LeftBlock>
            <RightBlockItem>Ad group</RightBlockItem>
            <RightBlockItem>Keywords</RightBlockItem>
            {negativePhrase && <RightBlockItem>Negatives</RightBlockItem>}
          </Header>
        </Tooltip>
        {isEmptyState ? (
          <MITableEmptyState />
        ) : (
          <ItemsContainer>{renderItems()}</ItemsContainer>
        )}
        <Footer
          onPageChange={({ selected }) => goToPage(selected + 1)}
          currentPage={pageIndex - 1}
          pageCount={pageCount}
          onPageSizeChange={setPageSize}
          pageSize={pageSize}
        />
      </BlockContainer>
    </Container>
  );
};

const Container = styled.div`
  width: inherit;
  display: flex;
  flex-direction: column;
  margin-bottom: ${(props) => props.theme.spacing[5]};
  margin-top: ${(props) => props.theme.spacing[10]};

  > div:nth-child(1) {
    margin-bottom: ${(props) => props.theme.spacing[2]};
  }
`;

const BlockContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: ${(props) => props.theme.radius.xl};
  border: 0.1rem solid ${(props) => props.theme.colors.blue[300]};
`;

const Header = styled.div`
  height: 5.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 ${(props) => props.theme.spacing[6]};
  border-radius: ${(props) => props.theme.radius.xl}
    ${(props) => props.theme.radius.xl} 0 0;
  color: ${(props) => props.theme.colors.white};
  background-color: ${(props) => props.theme.colors.blue[100]};
  ${(props) => props.theme.text.fontType.body3};
  border-bottom: 0.1rem solid ${(props) => props.theme.colors.blue[300]};
`;

const Footer = styled(TableFooter)`
  border-top: 0.1rem solid ${(props) => props.theme.colors.blue[300]};
`;

const LeftBlock = styled.div`
  display: flex;
  width: 55%;
  align-items: center;
`;

const RightBlockItem = styled.div`
  display: flex;
  margin-right: 5rem;
`;

const Title = styled.div`
  margin-left: ${(props) => props.theme.spacing[3]};
`;

const ItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${(props) => props.theme.spacing[8]}
    ${(props) => props.theme.spacing[6]};
  ${(props) => props.theme.text.fontType.body2};
`;

const NoAdsDescription = styled.div`
  margin: 0 auto;
  font-size: 2rem;
`;
