import { useState, useEffect, useMemo } from 'react';
import { v4 as uuid } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import styled, { css } from 'styled-components';

import KeywordsIcon from 'src/images/general/keywords-icon.svg';
import { MIFormCounter } from 'src/components/common/MIFormCounter';
import { AdGroupType } from 'src/utils/types';
import {
  setKeywordsForGroup,
  setStagAdGroups,
} from 'src/redux/stagCampaign/actions';
import { GroupsSelector } from './GroupsSelector/GroupsSelector';
import { useModal } from 'src/helpers/react/useModal';
import { MIPromptMessage } from '../common/MIPromptMessage';
import { MITextInput } from '../common/MITextInput';
import { getStagAdGroupList } from 'src/redux/stagCampaign/selectors';

export const StagKeywordsBuilder = () => {
  const dispatch = useDispatch();
  const adGroupList: AdGroupType[] = useSelector(getStagAdGroupList);

  const [value, setValue] = useState('');
  const [newGroupName, setGroupName] = useState('');
  const [selectedGroupId, selectGroupId] = useState<null | number>(() => {
    const [adGroup] = adGroupList;

    if (!adGroup) {
      return null;
    }

    return adGroup.id;
  });

  const selectedGroup = useMemo(() => {
    return adGroupList.find((group) => group.id === selectedGroupId) || null;
  }, [adGroupList, selectedGroupId]);

  useEffect(() => {
    if (selectedGroup) {
      setValue(selectedGroup.keywords.map((keyword) => keyword).join('\r\n'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedGroup?.id]);

  const handleChange = (event) => {
    if (selectedGroup) {
      const keywords = event.target.value
        .split('\n')
        .filter((el) => !isEmpty(el));
      setValue(event.target.value);
      dispatch(setKeywordsForGroup(selectedGroup.id, keywords));
    }
  };

  const updateAutomaticallyKeywords = () => {
    const updatedGroups = adGroupList.map((group) => {
      if (!group.keywords.find((keyword) => keyword === group.adGroup)) {
        return {
          ...group,
          keywords: [...group.keywords, group.adGroup],
        };
      }
      return group;
    });
    dispatch(setStagAdGroups(updatedGroups));

    const selectedGroup = updatedGroups.find(
      (group) => group.id === selectedGroupId
    );

    if (selectedGroup) {
      setValue(selectedGroup.keywords.map((keyword) => keyword).join('\r\n'));
    }
  };

  const groupCreationHandler = () => {
    const newGroup = {
      id: uuid(),
      adGroup: newGroupName,
      keywords: [],
      negatives: [],
      snippetExt: [],
      searchExt: [],
      callOnlyExt: [],
      callOutExt: [],
      expTextAdExt: [],
      sitelinkExt: [],
    };
    dispatch(setStagAdGroups([...adGroupList, newGroup]));
    setGroupName('');
    selectGroupId(newGroup.id);
  };

  const [CreateGroup, showCreationModal] = useModal(MIPromptMessage, {
    id: 'groupCreationModal',
    title: 'Enter group name',
    onSubmitClick: groupCreationHandler,
    onCancelClick: () => setGroupName(''),
    content: (
      <MITextInput
        onChange={({ value }) => setGroupName(value)}
        value={newGroupName}
      />
    ),
  });

  return (
    <Container>
      {CreateGroup}
      <Title>
        Enter <Bold>Keywords</Bold>
      </Title>
      <KeywordsContainer>
        <InputContainer>
          <TitleRow>
            <Label>Ad Groups</Label>
            <SmallLabel onClick={updateAutomaticallyKeywords} description>
              Copy ad group names to keywords
            </SmallLabel>
            <SmallLabel onClick={showCreationModal}>Add ad group +</SmallLabel>
          </TitleRow>
          <GroupsSelector
            selectedGroupId={selectedGroupId}
            adGroupList={adGroupList}
            handleClick={selectGroupId}
          />
        </InputContainer>
        <InputContainer>
          Keywords
          <KeywordsInput
            disabled={!selectedGroup}
            value={value}
            onChange={handleChange}
          />
        </InputContainer>
        {selectedGroup && (
          <CounterContainer>
            <MIFormCounter
              icon={KeywordsIcon}
              count={selectedGroup?.keywords?.length || 0}
              label='keywords'
            />
          </CounterContainer>
        )}
      </KeywordsContainer>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const Title = styled.div`
  margin-bottom: ${(props) => props.theme.spacing[6]};
  ${(props) => props.theme.text.fontType.h1};
`;

const Bold = styled.span`
  font-weight: ${(props) => props.theme.text.weight.bold};
`;

const KeywordsContainer = styled.div`
  display: flex;
  position: relative;

  ${(props) => props.theme.text.fontType.h6};
`;

const KeywordsInput = styled.textarea`
  width: 46.5rem;
  height: 30rem;
  margin: ${(props) => props.theme.spacing[5]} 0;
  padding: ${(props) => props.theme.spacing[2]}
    ${(props) => props.theme.spacing[5]};
  resize: none;
  border-radius: ${(props) => props.theme.radius.xl};
  background-color: ${(props) => props.theme.colors.white};
  color: ${(props) => props.theme.colors.black[100]};
  border: 0.1rem solid ${(props) => props.theme.colors.blue[400]};
  ${(props) => props.theme.text.fontType.body2};
  background-attachment: local;
  disabled: ${(props) => props.theme.disabled};

  &:focus {
    outline: none;
  }

  &::-webkit-scrollbar {
    width: 0.6rem;
    height: 10rem;
  }

  &::-webkit-scrollbar-track {
    border-radius: ${(props) => props.theme.radius.md};
    margin-top: ${(props) => props.theme.spacing[3]};
    margin-bottom: ${(props) => props.theme.spacing[3]};
    background-color: ${(props) => props.theme.colors.white};
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.theme.colors.blue.translucent};
    border-radius: ${(props) => props.theme.radius.md};
  }
`;

const CounterContainer = styled.div`
  position: absolute;
  right: 2rem;
  bottom: 3.5rem;
`;

const TitleRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const Label = styled.span``;

const SmallLabel = styled.span`
  font-size: 1.5rem;
  color: ${({ theme }) => theme.colors.grey[100]};
  opacity: 0.9;
  margin-right: 1rem;
  cursor: pointer;

  ${({ description }) =>
    description &&
    css`
      margin-right: auto;
      margin-left: 2rem;
    `}
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 1rem 0 1rem;
  font-weight: 300;
`;
