export function convertCurrencyToNumber(amount) {
  return amount ? amount.toString().replace(/[$,]/g, '') : null;
}

export const convertNumberToCurrency = (
  amount: number,
  options: Intl.NumberFormatOptions = {}
): string =>
  amount?.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    ...options,
  }) || '';

export const compareNumericString = (rowA, rowB, id, desc) => {
  let a = Number.parseFloat(rowA.values[id]);
  let b = Number.parseFloat(rowB.values[id]);
  if (Number.isNaN(a)) {
    a = desc ? Number.NEGATIVE_INFINITY : Number.POSITIVE_INFINITY;
  }
  if (Number.isNaN(b)) {
    b = desc ? Number.NEGATIVE_INFINITY : Number.POSITIVE_INFINITY;
  }
  if (a > b) return 1;
  if (a < b) return -1;
  return 0;
};
