import styled, { css } from 'styled-components';
import PrevIcon from 'src/images/general/prev-icon.svg';
import NextIcon from 'src/images/general/next-icon.svg';

type Props = {
  closeModal: () => void;
  saveHandler: () => void;
};

const MIFormButtons = ({ closeModal, saveHandler }: Props) => {
  const save = (event) => {
    event.preventDefault();
    saveHandler && saveHandler();
  };

  const close = (event) => {
    event.preventDefault();
    closeModal && closeModal();
  };

  return (
    <Buttons>
      <BackButton onClick={close} type="button">
        BACK
        <BackIconContainer>
          <BackIcon src={PrevIcon} />
        </BackIconContainer>
      </BackButton>
      <SaveButton onClick={save} type="submit">
        SAVE
        <SaveIconContainer>
          <SaveIcon src={NextIcon} />
        </SaveIconContainer>
      </SaveButton>
    </Buttons>
  );
};

const Buttons = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin: ${(props) => props.theme.spacing[5]} 0;
`;

const StepButtonStyles = css`
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: ${(props) => props.theme.radius.xl};
  padding: ${(props) => props.theme.spacing[2]}
    ${(props) => props.theme.spacing[2]} ${(props) => props.theme.spacing[2]}
    ${(props) => props.theme.spacing[10]};
  ${(props) => props.theme.text.fontType.h6};

  &:hover {
    box-shadow: ${(props) => props.theme.shadows.card};
  }
`;

const BackButton = styled.button`
  margin-right: 22rem;
  color: ${(props) => props.theme.colors.blue[100]};
  background-color: ${(props) => props.theme.colors.white};
  border: 0.1rem solid ${(props) => props.theme.colors.blue[400]};
  ${StepButtonStyles}
`;

const BackIconContainer = styled.div`
  display: flex;
  padding: ${(props) => props.theme.spacing[3]}
    ${(props) => props.theme.spacing[8]};
  margin-left: ${(props) => props.theme.spacing[6]};
  box-sizing: border-box;
  border-radius: ${(props) => props.theme.radius.xl};
  border: 0.2rem solid ${(props) => props.theme.colors.blue[400]};
`;

const BackIcon = styled.img`
  width: 1rem;
  height: 2rem;
`;

const SaveButton = styled.button`
  color: ${(props) => props.theme.colors.white};
  background-color: ${(props) =>
    props.isDisabled
      ? props.theme.colors.blue[400]
      : props.theme.colors.blue[100]};
  border: none;
  ${StepButtonStyles}
  cursor: ${(props) => props.isDisabled && 'default'};

  &:hover {
    box-shadow: ${(props) => props.isDisabled && 'none'};
  }
`;

const SaveIconContainer = styled.div`
  display: flex;
  padding: ${(props) => props.theme.spacing[3]}
    ${(props) => props.theme.spacing[6]};
  margin-left: ${(props) => props.theme.spacing[6]};
  box-sizing: border-box;
  border-radius: ${(props) => props.theme.radius.xl};
  border: 0.2rem solid ${(props) => props.theme.colors.white};
`;

const SaveIcon = styled.img`
  width: 2.2rem;
  height: 2rem;
`;

export default MIFormButtons;
