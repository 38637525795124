import isEmpty from 'lodash/isEmpty';
import { useCallback, useMemo } from 'react';
import { useModal } from 'src/helpers/react/useModal';
import { useDispatch, useSelector } from 'react-redux';
import { CommonStepLayout } from 'src/components/layout/CommonStepLayout/CommonStepLayout';
import { useBuilderSelection } from 'src/hooks/useBuilderSelection';
import { AdBuilderType } from 'src/utils/types';
import {
  getPlannerKeywords,
  getSelectedKeywords,
} from 'src/redux/planner/selectors';
import { useHistory } from 'react-router-dom';
import { DiscoverPage } from './components/DiscoverPage';
import { KeywordPlannerPage } from './components/KeywordPlannerPage';
import { setPlannerKeywords } from 'src/redux/planner/actions';
import { OrganizeKeywordsModal } from 'src/components/common/AdCreator/Modal/OrganizeKeywordsModal/OrganizeKeywordsModal';
import { LOCATIONS } from 'src/pages/locations';

export const PlannerPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const keywords = useSelector(getPlannerKeywords);
  const selectedKeywords = useSelector(getSelectedKeywords);

  const { currentStep, setCurrentStep, progressBarSteps } = useBuilderSelection(
    { initialStep: 0, builderType: AdBuilderType.PLANNER }
  );

  const handleKeywordsChange = useCallback(
    (data: string[]) => {
      dispatch(setPlannerKeywords(data));
    },
    [dispatch]
  );

  const [OrganizeModal, showOrganizeModal] = useModal(OrganizeKeywordsModal, {
    id: 'organizeModal',
    selectedKeywords,
  });

  const isSecondStep = currentStep === 1;
  const isEmptyKeywords = isEmpty(keywords);
  const isEmptySelectedKeywords = isEmpty(selectedKeywords);
  const isNextDisabled = useMemo(() => {
    switch (currentStep) {
      case 0: {
        return isEmptyKeywords;
      }
      case 1: {
        return isEmptySelectedKeywords;
      }
      default:
        return true;
    }
  }, [currentStep, isEmptyKeywords, isEmptySelectedKeywords]);

  const onNext = isSecondStep ? showOrganizeModal : undefined;

  const onCancel = () => {
    history.push(LOCATIONS.dashboard);
  };

  return (
    <>
      {OrganizeModal}
      <CommonStepLayout
        onNext={onNext}
        onCancel={onCancel}
        onChangePage={setCurrentStep}
        currentStep={currentStep}
        isNextDisabled={isNextDisabled}
        progressBarSteps={progressBarSteps}
      >
        <CommonStepLayout.Child>
          <DiscoverPage
            onKeywordsChange={handleKeywordsChange}
            keywords={keywords}
          />
        </CommonStepLayout.Child>
        <CommonStepLayout.Child>
          <KeywordPlannerPage keywords={keywords} />
        </CommonStepLayout.Child>
      </CommonStepLayout>
    </>
  );
};
