import { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useModal } from 'src/helpers/react/useModal';
import { WizardContainer } from 'src/components/layout/WizardElements';
import { SignInModal } from 'src/components/header/Modal/SignInModal';
import { LOCATIONS } from 'src/pages/locations';
import { useDispatch, useSelector } from 'react-redux';
import { saveUser, saveUserDetails } from 'src/redux/user/actions';
import UserService from 'src/services/users/userService';
import { getIsLoggedIn } from 'src/redux/user/selectors';
import { useLocation } from 'react-router-dom';

export const LoginPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isSignedIn = useSelector(getIsLoggedIn);
  const location: any = useLocation();

  useEffect(() => {
    if (!location?.state?.page) {
      isSignedIn && history.push(LOCATIONS.dashboard);
    }
  }, [isSignedIn, history, location]);

  const setIsSignedIn = useCallback(async () => {
    dispatch(saveUser());
    const userDetails = await UserService.getUserDetails();
    dispatch(saveUserDetails(userDetails));
  }, [dispatch]);

  const params = {
    ...location?.state
  }

  const [SignIn, showSignIn] = useModal(SignInModal, {
    id: 'signInModal',
    hideDismiss: true,
    setIsSignedIn: setIsSignedIn,
    showSendResetPasswordLinkModal: () =>
      history.push(LOCATIONS.requestPassword),
    onSignUpClick: () => history.push(LOCATIONS.register, params),
    onSuccess: () =>
      history.push(
        location?.state?.page ? location?.state?.page : LOCATIONS.dashboard
      ),
  });

  useEffect(() => {
    showSignIn();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return <WizardContainer>{SignIn}</WizardContainer>;
};
