import styled from 'styled-components';
import EmptyIcon from 'src/images/general/empty-state-icon.svg';

export const CampaignCardEmptyState = () => {
  return (
    <Container>
      <EmptyStateIcon src={EmptyIcon} />
      <Title>Looks like you don’t have any campaign yet...</Title>
      <Subtitle>
        Fortunately, it’s easy to create new one by choosing the option on the
        very top of the page.
      </Subtitle>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const EmptyStateIcon = styled.img`
  width: 18rem;
  height: 15rem;
`;

const Title = styled.span`
  display: flex;
  margin: ${(props) => props.theme.spacing[5]} 0;
  color: ${(props) => props.theme.colors.blue[100]};
  ${(props) => props.theme.text.fontType.h5};
`;

const Subtitle = styled.span`
  width: 40rem;
  display: flex;
  color: ${(props) => props.theme.colors.black[100]};
  ${(props) => props.theme.text.fontType.body2};
`;
