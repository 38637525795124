import styled from 'styled-components';
import { MIModalMessage } from 'src/components/common/MIModalMessage';
import { MILink } from 'src/components/common/MILink';
import { LOCATIONS } from 'src/pages/locations';

type Props = {
  dismiss?: (event: React.MouseEvent) => void;
};

export const CannotCreateCampaignModal = ({ dismiss }: Props) => {
  return (
    <MIModalMessage
      dismiss={dismiss}
      titleComponent={
        <Message>
          Upgrade your
          <Link to={LOCATIONS.pricing}> subscription plan </Link>
          to create additional campaigns.
        </Message>
      }
    />
  );
};

const Message = styled.span`
  font-size: 2rem;
`;

const Link = styled(MILink)`
  color: ${({ theme }) => theme.colors.blue[100]};
`;
