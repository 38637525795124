import { countries } from 'src/utils/countries';
import { AdType, CallOnlyAdType } from 'src/utils/types';

export const firstRow = [
  {
    label: 'Country',
    key: 'country',
    required: true,
    options: countries,
  },
  { label: 'Phone number', key: 'phoneNumber', required: true },
];

export const secondRow = [
  { label: 'Headline one', key: 'headlineOne', maxlength: 30 },
  { label: 'Headline two', key: 'headlineTwo', maxlength: 30 },
];

export const fullRow = [
  {
    label: 'Description one',
    key: 'descriptionOne',
    maxlength: 90,
  },
  {
    label: 'Description two',
    key: 'descriptionTwo',
    maxlength: 90,
  },
];

export const thirdRow = [
  { label: 'Display URL', key: 'finalUrl', required: true },
  {
    label: 'Bussines name',
    key: 'businessName',
    maxlength: 25,
  },
];

export const secondFullRow = [
  {
    label: 'Verification URL',
    key: 'verificationURL',
    required: true,
  },
];

export const defaultDataCallOnly: CallOnlyAdType = {
  id: undefined,
  country: 'US',
  phoneNumber: '800 555 3535',
  headlineOne: '[Keyword]',
  headlineTwo: '2 to 6 week',
  descriptionOne:
    'CNA Programs Available. Apply 100% Online. Learn Entry-Level and Advance Nursing Skills.',
  descriptionTwo:
    'Study 100% Online Or Near By With Courses Designed For Working Adults. Apply Today!',
  businessName: 'sna-course',
  verificationURL: 'https://www.google.com/',
  finalUrl: 'https://www.google.com/',
  type: AdType.CALL,
};

export const fieldsToCheckBlockedSymbols = [
  'phoneNumber',
  'headlineOne',
  'headlineTwo',
  'businessName',
  'verificationURL',
  'finalUrl',
];
