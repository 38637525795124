import { AdGroupType, CampaignType } from 'src/utils/types';
import {
  SET_SKAG_KEYWORDS,
  SET_SKAG_SETTINGS,
  CREATE_SKAG_ADS,
  UPDATE_SKAG_ADS,
  DELETE_SKAG_AD,
  UPDATE_NEGATIVES_IN_SKAG_GROUP,
  REPLACE_SKAG_TEXT,
  APPEND_SKAG_TEXT,
  SET_SKAG_TEXT,
  DELETE_ALL_SKAG_ADS_BY_TYPE,
  UPDATE_ONE_SKAG_AD,
  DUPLICATE_SKAG_AD_TEMPLATE,
  DUPLICATE_SKAG_AD_GROUP,
  LOAD_SKAG_CAMPAIGN,
  CLEAN_SKAG_CAMPAIGN,
} from './actionTypes';

export const loadSkagCampaign = (data: CampaignType) => {
  return {
    type: LOAD_SKAG_CAMPAIGN,
    payload: {
      data,
    },
  };
};

export const setSkagKeywords = (
  keywords: string[],
  adGroupList: AdGroupType[]
) => {
  return {
    type: SET_SKAG_KEYWORDS,
    payload: {
      keywords,
      adGroupList,
    },
  };
};

export const setSkagSettings = (campaign: CampaignType) => {
  return {
    type: SET_SKAG_SETTINGS,
    payload: {
      campaign,
    },
  };
};

export const createSkagAds = (type, data, onlyTemplate?) => {
  return {
    type: CREATE_SKAG_ADS,
    payload: {
      type,
      data,
      onlyTemplate,
    },
  };
};

export const updateSkagAds = (id, data, onlyTemplate) => {
  return {
    type: UPDATE_SKAG_ADS,
    payload: {
      id,
      data,
      onlyTemplate,
    },
  };
};

export const updateOneSkagAd = (id, data) => {
  return {
    type: UPDATE_ONE_SKAG_AD,
    payload: {
      id,
      data,
    },
  };
};

export const deleteSkagAd = (data) => {
  return {
    type: DELETE_SKAG_AD,
    payload: {
      ...data,
    },
  };
};

export const deleteAllSkagAdsByType = (id, type, isTemplate) => {
  return {
    type: DELETE_ALL_SKAG_ADS_BY_TYPE,
    payload: {
      id,
      type,
      isTemplate,
    },
  };
};

export const updateSkagNegatives = (id, data) => {
  return {
    type: UPDATE_NEGATIVES_IN_SKAG_GROUP,
    payload: {
      id,
      data,
    },
  };
};

export const replaceSkagText = (data) => {
  return {
    type: REPLACE_SKAG_TEXT,
    payload: {
      groupId: data.groupId,
      adType: data.adType,
      adId: data.adId,
      newString: data.newString,
      stringForReplace: data.stringForReplace,
      selectedField: data.selectedField,
      fields: data.fields,
      matchCase: data.matchCase,
      matchWholeWords: data.matchWholeWords,
      keepOriginalAds: data.keepOriginalAds,
      preserveCapitalizations: data.preserveCapitalizations,
    },
  };
};

export const appendSkagText = (data) => {
  return {
    type: APPEND_SKAG_TEXT,
    payload: {
      groupId: data.groupId,
      adType: data.adType,
      adId: data.adId,
      field: data.field,
      before: data.before,
      value: data.value,
      keepOriginalAds: data.keepOriginalAds,
    },
  };
};

export const setSkagText = (data) => {
  return {
    type: SET_SKAG_TEXT,
    payload: {
      groupId: data.groupId,
      adType: data.adType,
      adId: data.adId,
      field: data.field,
      value: data.value,
      keepOriginalAds: data.keepOriginalAds,
    },
  };
};

export const duplicateSkagAdTemplate = (data, onlyTemplate) => {
  return {
    type: DUPLICATE_SKAG_AD_TEMPLATE,
    payload: {
      data,
      onlyTemplate,
    },
  };
};

export const duplicateSkagAdGroup = (data) => {
  return {
    type: DUPLICATE_SKAG_AD_GROUP,
    payload: {
      data,
    },
  };
};

export const cleanSkagCampaign = () => {
  return {
    type: CLEAN_SKAG_CAMPAIGN,
    payload: {
      data: [],
    },
  };
};

export type SkagActionType =
  | ReturnType<typeof loadSkagCampaign>
  | ReturnType<typeof setSkagKeywords>
  | ReturnType<typeof setSkagSettings>
  | ReturnType<typeof createSkagAds>
  | ReturnType<typeof updateSkagAds>
  | ReturnType<typeof updateOneSkagAd>
  | ReturnType<typeof deleteSkagAd>
  | ReturnType<typeof deleteAllSkagAdsByType>
  | ReturnType<typeof updateSkagNegatives>
  | ReturnType<typeof replaceSkagText>
  | ReturnType<typeof appendSkagText>
  | ReturnType<typeof setSkagText>
  | ReturnType<typeof duplicateSkagAdTemplate>
  | ReturnType<typeof duplicateSkagAdGroup>
  | ReturnType<typeof cleanSkagCampaign>;
