import { ReactNode } from 'react';
import styled from 'styled-components';
import { CampaignDetailsType } from 'src/redux/campaigns/types';
import { useDispatch } from 'react-redux';
import { AdBuilderType } from 'src/utils/types';
import { CampaignCardEmptyState } from './CampaignCardEmptyState';
import { CampaignCard } from './CampaignCard';
import { setSkagStep } from 'src/redux/skagCreationFlow/actions';
import { setStagStep } from 'src/redux/stagCreationFlow/actions';
import { useHistory } from 'react-router-dom';
import { LOCATIONS } from 'src/pages/locations';

type Props = {
  label: string;
  campaigns: CampaignDetailsType[];
  additionalButton?: ReactNode;
};

export const CampaignCardsContainer = ({
  campaigns,
  additionalButton,
  label,
}: Props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const startCampaignEditing = (type: AdBuilderType) => {
    if (type === AdBuilderType.SKAG) {
      dispatch(setSkagStep(1));
      history.push(LOCATIONS.skagBuilder);
    } else {
      dispatch(setStagStep(1));
      history.push(LOCATIONS.stagBuilder);
    }
  };
  const visibleCampaigns = campaigns.slice(0, 4);

  return (
    <>
      <HeaderContainer>
        <Title>{label}</Title>
        {additionalButton}
      </HeaderContainer>
      <Cards isEmpty={!visibleCampaigns.length}>
        {visibleCampaigns.length ? (
          visibleCampaigns.map((campaign) => (
            <CampaignCard
              campaign={campaign}
              key={campaign._id}
              startCampaignEditing={startCampaignEditing}
            />
          ))
        ) : (
          <CampaignCardEmptyState />
        )}
      </Cards>
    </>
  );
};

const Cards = styled.div`
  margin-left: ${({ isEmpty }) => (isEmpty ? 0 : 15)}%;
  margin-right: 10%;
  padding: 0 0 3% 0;
  display: flex;
`;

const Title = styled.h1`
  margin-bottom: 4rem;
  margin-left: 15%;

  ${(props) => props.theme.text.fontType.h3};
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
