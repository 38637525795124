import { useMemo } from 'react';
import styled from 'styled-components';

import { MIButton } from 'src/components/common/MIButton';
import { MITable } from 'src/components/common/table/MITable';

import { BUTTON_VARIANT } from 'src/utils/consts';
import { useViewport } from 'src/hooks/useViewport';
import { conversionTableResults } from 'src/utils/builder';
import { useHistory } from 'react-router-dom';
import { LOCATIONS } from 'src/pages/locations';

type Props = {
  data: any;
  dismiss?: (event: React.MouseEvent) => void;
};

export const ConversionResults = ({ data, dismiss }: Props) => {
  const { height } = useViewport();
  const history = useHistory();
  const columns = useMemo(conversionTableResults, []);

  const handleCloseModal = (event) => {
    dismiss && dismiss(event);
    history.push(LOCATIONS.dashboard);
  };

  return (
    <Container height={height || 0}>
      <Header>
        <Title>Created Conversion</Title>
      </Header>
      <TableContainer>
        <Table columns={columns} data={data} showPagination={false} />
      </TableContainer>
      <ButtonContainer>
        <MIButton
          label='Close'
          onClick={handleCloseModal}
          variant={BUTTON_VARIANT.SECONDARY}
        />
      </ButtonContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  min-width: 1152px;
  max-height: ${({ height }) => height / 1.3}px;
`;

const Header = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  width: -webkit-fill-available;
  margin-bottom: ${(props) => props.theme.spacing[10]};
`;

const Title = styled.span`
  margin: 0 auto;
  ${(props) => props.theme.text.fontType.h2};
  font-weight: ${(props) => props.theme.text.weight.bold};
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: ${(props) => props.theme.spacing[5]};
  width: 100%;
  margin-top: ${(props) => props.theme.spacing[10]};

  & > button {
    width: 200px;
  }
`;

const Table = styled(MITable)`
  & td {
    font-weight: 300;
  }
`;

const TableContainer = styled.div`
  width: 100%;
  overflow-y: auto;
`;
