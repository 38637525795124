import { MIButton } from 'src/components/common/MIButton';
import { notifyServerError } from 'src/services/notifications/notificationService';
import { BUTTON_VARIANT } from 'src/utils/consts';
import GoogleIcon from 'src/images/general/google-icon.svg';
import { useCallback } from 'react';
import GoogleAuthorize from 'src/components/common/libs/GoogleLoginButton/GoogleAuthorize';
import { GoogleLoginResponse } from 'src/utils/types';

type GoogleButtonProps = {
  onClick: () => void;
};

type Props = {
  onClick?: (event: GoogleLoginResponse) => void | Promise<void>;
  clientId?: string;
  scope?: string;
  isLoading: boolean;
  disabled?: boolean;
};

export const GoogleLoginButton = ({
  onClick,
  clientId,
  scope,
  isLoading,
  disabled,
}: Props) => {
  const handleGoogleSingIn = async (event: GoogleLoginResponse) => {
    if (!event.code) {
      notifyServerError({ msg: 'Unable to get tokenId from Google' });
      return;
    }

    return onClick && onClick(event as GoogleLoginResponse);
  };

  const renderButton = useCallback(
    (renderProps: GoogleButtonProps) => {
      return (
        <MIButton
          label='Sign in with Google'
          onClick={renderProps.onClick}
          disabled={disabled}
          variant={BUTTON_VARIANT.TERTIARY}
          fullWidth
          icon={GoogleIcon}
          isProcessing={isLoading}
        />
      );
    },
    [disabled, isLoading]
  );

  return (
    <GoogleAuthorize
      scope={scope}
      clientId={clientId || ''}
      responseType='code'
      prompt='select_account consent'
      render={renderButton}
      onSuccess={handleGoogleSingIn}
      onFailure={handleGoogleSingIn}
    />
  );
};

GoogleLoginButton.defaultProps = {
  scope: 'profile email',
};
