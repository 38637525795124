import { AdGroupType, CampaignType } from 'src/utils/types';
import {
  SET_STAG_AD_GROUPS,
  SET_KEYWORDS_FOR_GROUP,
  CREATE_STAG_ADS,
  UPDATE_STAG_ADS,
  UPDATE_ONE_STAG_AD,
  DELETE_STAG_AD,
  DELETE_ALL_STAG_ADS_BY_TYPE,
  SET_STAG_SETTINGS,
  REPLACE_STAG_TEXT,
  APPEND_STAG_TEXT,
  SET_STAG_TEXT,
  UPDATE_NEGATIVES_IN_STAG_GROUP,
  DUPLICATE_STAG_AD_TEMPLATE,
  DUPLICATE_STAG_AD_GROUP,
  LOAD_STAG_CAMPAIGN,
  CLEAN_STAG_CAMPAIGN,
  SET_KEYWORDS_VALIDATION_ERROR,
} from './actionTypes';

export const loadStagCampaign = (data: CampaignType) => {
  return {
    type: LOAD_STAG_CAMPAIGN,
    payload: {
      data,
    },
  };
};

export const setStagAdGroups = (adGroupList: AdGroupType[]) => {
  return {
    type: SET_STAG_AD_GROUPS,
    payload: {
      adGroupList,
    },
  };
};

export const setKeywordsForGroup = (id: number, keywords: string[]) => {
  return {
    type: SET_KEYWORDS_FOR_GROUP,
    payload: {
      id,
      keywords,
    },
  };
};

export const setStagSettings = (campaign: CampaignType) => {
  return {
    type: SET_STAG_SETTINGS,
    payload: {
      campaign,
    },
  };
};

export const createStagAds = (type, data, onlyTemplate?) => {
  return {
    type: CREATE_STAG_ADS,
    payload: {
      type,
      data,
      onlyTemplate,
    },
  };
};

export const updateStagAds = (id, data, onlyTemplate) => {
  return {
    type: UPDATE_STAG_ADS,
    payload: {
      id,
      data,
      onlyTemplate,
    },
  };
};

export const updateOneStagAd = (id, data) => {
  return {
    type: UPDATE_ONE_STAG_AD,
    payload: {
      id,
      data,
    },
  };
};

export const deleteStagAd = (data) => {
  return {
    type: DELETE_STAG_AD,
    payload: {
      ...data,
    },
  };
};

export const deleteAllStagAdsByType = (id, type, isTemplate) => {
  return {
    type: DELETE_ALL_STAG_ADS_BY_TYPE,
    payload: {
      id,
      type,
      isTemplate,
    },
  };
};

export const updateStagNegatives = (id, data) => {
  return {
    type: UPDATE_NEGATIVES_IN_STAG_GROUP,
    payload: {
      id,
      data,
    },
  };
};

export const replaceStagText = (data) => {
  return {
    type: REPLACE_STAG_TEXT,
    payload: {
      groupId: data.groupId,
      adType: data.adType,
      adId: data.adId,
      newString: data.newString,
      stringForReplace: data.stringForReplace,
      selectedField: data.selectedField,
      fields: data.fields,
      matchCase: data.matchCase,
      matchWholeWords: data.matchWholeWords,
      keepOriginalAds: data.keepOriginalAds,
      preserveCapitalizations: data.preserveCapitalizations,
    },
  };
};

export const appendStagText = (data) => {
  return {
    type: APPEND_STAG_TEXT,
    payload: {
      groupId: data.groupId,
      adType: data.adType,
      adId: data.adId,
      field: data.field,
      before: data.before,
      value: data.value,
      keepOriginalAds: data.keepOriginalAds,
    },
  };
};

export const setStagText = (data) => {
  return {
    type: SET_STAG_TEXT,
    payload: {
      groupId: data.groupId,
      adType: data.adType,
      adId: data.adId,
      field: data.field,
      value: data.value,
      keepOriginalAds: data.keepOriginalAds,
    },
  };
};

export const duplicateStagAdTemplate = (data, onlyTemplate) => {
  return {
    type: DUPLICATE_STAG_AD_TEMPLATE,
    payload: {
      data,
      onlyTemplate,
    },
  };
};

export const duplicateStagAdGroup = (data) => {
  return {
    type: DUPLICATE_STAG_AD_GROUP,
    payload: {
      data,
    },
  };
};

export const cleanStagCampaign = () => {
  return {
    type: CLEAN_STAG_CAMPAIGN,
  };
};

export const setKeywordsValidationError = (
  id: number,
  keywordsValidationError: boolean
) => {
  return {
    type: SET_KEYWORDS_VALIDATION_ERROR,
    payload: {
      id,
      keywordsValidationError,
    },
  };
};

export type StagActionType =
  | ReturnType<typeof setStagAdGroups>
  | ReturnType<typeof setKeywordsForGroup>
  | ReturnType<typeof setStagSettings>
  | ReturnType<typeof createStagAds>
  | ReturnType<typeof updateStagAds>
  | ReturnType<typeof updateOneStagAd>
  | ReturnType<typeof deleteStagAd>
  | ReturnType<typeof deleteAllStagAdsByType>
  | ReturnType<typeof updateStagNegatives>
  | ReturnType<typeof replaceStagText>
  | ReturnType<typeof appendStagText>
  | ReturnType<typeof setStagText>
  | ReturnType<typeof duplicateStagAdTemplate>
  | ReturnType<typeof duplicateStagAdGroup>
  | ReturnType<typeof loadStagCampaign>
  | ReturnType<typeof cleanStagCampaign>
  | ReturnType<typeof setKeywordsValidationError>;
