import { IRestResponse } from 'src/infrastructure/restClient/models/IRestResponse';
import RestClient from 'src/infrastructure/restClient/RestClient';
import { KeyWordPlannerRequest } from './models/keyWordPlannerRequest';
import { KeyWordPlannerResponse } from './models/keyWordPlannerResponse';

export default class KeyWordPlannerService {
  public static getIdeasList({
    keywords,
  }: KeyWordPlannerRequest): Promise<IRestResponse<KeyWordPlannerResponse>> {
    const seed = keywords.join(',');
    return RestClient.get<KeyWordPlannerResponse>(
      `api/keywords/ideas?seed=${seed}`
    ).then((response) => {
      return response;
    });
  }
  public static csvDownload(request) {
    return RestClient.post('api/keywords/export/csv', request, 'keywords');
  }
}
