import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import user from './user/reducer';
import skagCompaign from './skagCompaign/reducer';
import stagCampaign from './stagCampaign/reducer';
import skagCreationFlow from './skagCreationFlow/reducer';
import stagCreationFlow from './stagCreationFlow/reducer';
import planner from './planner/reducer';
import campaigns from './campaigns/reducer';

const userConfig = {
  key: 'user',
  storage,
};

const skagCompaignConfig = {
  key: 'skagCompaign',
  storage,
};

const stagCampaignConfig = {
  key: 'stagCampaign',
  storage,
};

const skagCreationFlowConfig = {
  key: 'skagCreationFlow',
  storage,
};

const stagCreationFlowConfig = {
  key: 'stagCreationFlow',
  storage,
};

const rootReducer = combineReducers({
  user: persistReducer(userConfig, user),
  skagCompaign: persistReducer(skagCompaignConfig, skagCompaign),
  stagCampaign: persistReducer(stagCampaignConfig, stagCampaign),
  skagCreationFlow: persistReducer(skagCreationFlowConfig, skagCreationFlow),
  stagCreationFlow: persistReducer(stagCreationFlowConfig, stagCreationFlow),
  planner,
  campaigns,
});

export default rootReducer;
