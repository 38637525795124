import styled from 'styled-components';
import { MIDropDown, OptionType } from 'src/components/common/MIDropDown';
import { Expandable } from 'src/utils/types';

type Props = {
  title: string;
  placeholder: string;
  value: any;
  options: OptionType[];
  multiselect: boolean;
  isAllSelected?: boolean;
  totalCount?: number;
  onChange: (change: Expandable<{ value: string }>) => void;
  onClear?: () => void;
  onSearch?: (value: string) => void;
  onSelectAll?: () => void;
  onSearchStart?: () => void;
};

export const DropDownFilter = ({
  title,
  placeholder,
  value,
  options,
  multiselect,
  isAllSelected,
  totalCount,
  onChange,
  onClear,
  onSearch,
  onSelectAll,
  onSearchStart,
}: Props) => {
  return (
    <ItemContainer>
      <ItemHeader>
        <Text>{title}</Text>
        <Text>
          Total <Bold>{totalCount || options.length}</Bold>
        </Text>
      </ItemHeader>
      <MIDropDown
        label={placeholder}
        value={value}
        options={options}
        onChange={onChange}
        onClear={onClear}
        onSearch={onSearch}
        onSelectAll={onSelectAll}
        onSearchStart={onSearchStart}
        responsiveWidth={true}
        multiselect={multiselect}
        isAllSelected={isAllSelected}
        transparent={false}
      />
    </ItemContainer>
  );
};

const ItemContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const ItemHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.9rem;
`;

const Text = styled.div``;

const Bold = styled.span`
  font-weight: bold;
`;
