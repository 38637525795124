import { UserState } from './types';
import { LOG_IN_USER, LOG_OUT_USER, SAVE_USER_DETAILS } from './actionTypes';

const initialState: UserState = {
  isLoggedIn: false,
  details: null,
};

const user = (state = initialState, action) => {
  switch (action.type) {
    case LOG_IN_USER: {
      return {
        ...state,
        isLoggedIn: true,
      };
    }
    case LOG_OUT_USER: {
      return {
        ...state,
        isLoggedIn: false,
        details: null,
      };
    }
    case SAVE_USER_DETAILS: {
      return {
        ...state,
        details: action.data,
      };
    }
    default:
      return state;
  }
};

export default user;
