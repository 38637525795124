import { UserDetailsType } from 'src/utils/types';
import { LOG_IN_USER, LOG_OUT_USER, SAVE_USER_DETAILS } from './actionTypes';

export const saveUser = () => {
  return {
    type: LOG_IN_USER,
  };
};

export const removeUser = () => {
  return {
    type: LOG_OUT_USER,
  };
};

export const saveUserDetails = (data: UserDetailsType) => {
  return {
    type: SAVE_USER_DETAILS,
    data,
  };
};
