import {
  DELETE_CAMPAIGNS,
  SAVE_CAMPAIGNS,
  GET_CAMPAIGNS_FAILED,
  DELETE_CAMPAIGN,
  UPDATE_CAMPAIGN_NAME,
} from './actionTypes';
import { CampaignsType } from './types';

const initialState: CampaignsType = {
  isError: undefined,
  campaigns: [],
};

const campaigns = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_CAMPAIGNS: {
      return {
        ...state,
        isError: false,
        campaigns: action.payload,
      };
    }
    case DELETE_CAMPAIGNS: {
      return {
        ...state,
        isError: false,
        campaigns: [],
      };
    }
    case GET_CAMPAIGNS_FAILED: {
      return {
        ...state,
        isError: true,
        campaigns: [],
      };
    }
    case DELETE_CAMPAIGN: {
      return {
        ...state,
        campaigns: state.campaigns.filter(
          (campaign) => campaign._id !== action.payload.id
        ),
      };
    }
    case UPDATE_CAMPAIGN_NAME: {
      return {
        ...state,
        campaigns: state.campaigns.map((campaign) => {
          if (campaign._id === action.payload.id) {
            return { ...campaign, name: action.payload.data };
          }
          return campaign;
        }),
      };
    }
  }
  return state;
};

export default campaigns;
