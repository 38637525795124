import React from "react";
import ReactDOM from "react-dom";
import { App } from "./App";
import TagManager from "react-gtm-module";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

const REACT_APP_GTM = process.env.REACT_APP_GTM;
if (REACT_APP_GTM) {
  TagManager.initialize({
    gtmId: REACT_APP_GTM,
  });
}

const REACT_APP_SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;
if (REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: REACT_APP_SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

ReactDOM.render(<App />, document.getElementById("root"));
