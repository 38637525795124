import { useState, useMemo } from 'react';
import styled from 'styled-components';
import { CampaignType, AdType } from 'src/utils/types';
import { AdCreatorTableControllers } from './Table/AdCreatorTableControllers';
import { AdCreatorTable } from './Table/AdCreatorTable';
import { generateAdTypeFilterData, NONE_VALUE } from 'src/utils/consts';

type Props = {
  isSkag?: boolean;
  campaign: CampaignType;
};

export const AdCreator = ({ isSkag = false, campaign }: Props) => {
  const [selectedAdType, setSelectedAdType] = useState<AdType>(AdType.ALL);
  const { adGroupList, templates } = campaign;
  const [selectedAdGroup, setSelectedAdGroup] = useState<string>(NONE_VALUE);
  const adsCount = useMemo(() => {
    const {
      callOnlyExt,
      callOutExt,
      expTextAdExt,
      searchExt,
      snippetExt,
      sitelinkExt,
    } = templates;
    return (
      callOnlyExt.length +
      callOutExt.length +
      expTextAdExt.length +
      searchExt.length +
      snippetExt.length +
      sitelinkExt.length
    );
  }, [templates]);

  const onSelectAdType = (result) => {
    const { value } = result;
    setSelectedAdType(value);
  };

  const onSelectAdGroup = (result) => {
    const { value } = result;
    setSelectedAdGroup(value);
  };

  const adTypeFilterOptions = useMemo(() => {
    return generateAdTypeFilterData(templates);
  }, [templates]);

  return (
    <Container>
      <Title>
        <Bold>Ad</Bold> Templates
      </Title>
      <AdCreatorTableControllers
        isSkag={isSkag}
        adsCount={adsCount}
        adGroupList={adGroupList}
        selectedAdType={selectedAdType}
        selectedAdGroup={selectedAdGroup}
        onSelectAdType={onSelectAdType}
        onSelectAdGroup={onSelectAdGroup}
        adTypeFilterOptions={adTypeFilterOptions}
      />
      <AdCreatorTable
        isSkag={isSkag}
        adsCount={adsCount}
        templates={templates}
        selectedAdType={selectedAdType}
        selectedAdGroup={selectedAdGroup}
      />
    </Container>
  );
};

const Container = styled.div`
  width: -webkit-fill-available;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 120rem;
`;

const Title = styled.div`
  margin-bottom: ${(props) => props.theme.spacing[6]};
  ${(props) => props.theme.text.fontType.h2};
`;

const Bold = styled.span`
  font-weight: ${(props) => props.theme.text.weight.bold};
`;
