import { useCallback } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { MIButton } from 'src/components/common/MIButton';
import { LOCATIONS } from '../locations';
import CompleteImage from 'src/images/general/complete.svg';

export const CongratulationPage = () => {
  const history = useHistory();
  const goHome = useCallback(
    () => history.push(LOCATIONS.dashboard),
    [history]
  );

  return (
    <Container>
      <Complete src={CompleteImage} />
      <ContentContainer>
        <Title>Congratulation!</Title>
        <Subtitle>
          A confirmation has been sent to your email. You are now ready to
          access your full campaigns.
        </Subtitle>
        <MIButton label='Continue' className='button' onClick={goHome} />
      </ContentContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Complete = styled.img`
  margin-bottom: 20rem;
`;

const ContentContainer = styled.div`
  position: absolute;
  top: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 41rem;
  text-align: center;

  .button {
    margin-top: 3rem;
    width: 22rem;
    height: 6rem;
    font-size: 2rem;
  }
`;

const Title = styled.h1`
  margin-top: 0rem;
  font-size: 2.4rem;
`;

const Subtitle = styled.div`
  font-size: 1.8rem;
  font-weight: 300;
  line-height: 2.8rem;
`;
