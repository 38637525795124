import { useCallback } from 'react';
import ReactPaginate from 'react-paginate';
import styled from 'styled-components';

type Props = {
  pageCount: number;
  pageSize: number;
  currentPage: number;
  onPageSizeChange: (value: number) => void;
  onPageChange: (value) => void;
  perPageValues?: number[];
};

export const MIPagination = ({
  pageCount,
  pageSize,
  currentPage,
  onPageSizeChange,
  onPageChange,
  perPageValues = [10, 20, 30, 40, 50],
}: Props) => {
  const handlePageSizeChange = useCallback(({ target: { value } }) => {
    onPageSizeChange(Number(value));
  }, [onPageSizeChange]);

  return (
    <Wrapper>
      <StyledPagination
        breakLabel="..."
        nextLabel={null}
        nextClassName={'navButton'}
        previousLabel={null}
        forcePage={currentPage}
        previousClassName={'navButton'}
        onPageChange={onPageChange}
        pageRangeDisplayed={1}
        pageCount={pageCount}
        renderOnZeroPageCount={() => null}
      />
      <SelectWrapper>
        <Description>Per page &nbsp;</Description>
        <Select
          value={pageSize}
          onChange={handlePageSizeChange}
        >
          {perPageValues!.map((pageSize) => (
            <SelectOption key={pageSize} value={pageSize}>
              {pageSize}
            </SelectOption>
          ))}
        </Select>
      </SelectWrapper>
    </Wrapper>
  );
};

const StyledPagination = styled(ReactPaginate)`
  display: flex;
  column-gap: 1rem;
  align-items: center;
  padding: 0;
  margin: 0;
  margin-right: 10%;
  list-style: none;

  li {
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${({ theme }) => theme.spacing[6]};
    height: ${({ theme }) => theme.spacing[6]};
    border-radius: ${({ theme }) => theme.radius.big};
    cursor: pointer;
  }

  li.selected {
    color: ${({ theme }) => theme.colors.blue[100]};
    background-color: ${({ theme }) => theme.colors.white};
  }

  .navButton {
    width: 0px;
    height: 0px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10%;
`;

const SelectWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Select = styled.select`
  padding: ${({ theme }) => theme.radius.big};
  border-radius: ${({ theme }) => theme.radius.big};
  background-color: ${({ theme }) => theme.colors.white};
  font-size: 1.4rem;
`;

const SelectOption = styled.option``;

const Description = styled.div`
  font-weight: 300;
`;
