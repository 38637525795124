import * as React from 'react';
import styled, { css } from 'styled-components';

type Props = {
  id?: string;
  positiveLabel?: string | null;
  negativeLabel?: string | null;
  value: boolean;
  onChange?: (value: boolean) => void;
  color?: 'green' | 'blue' | 'red' | 'white';
  disabled?: boolean;
  dashed?: boolean;
  transparent?: boolean;
};

export const MICheckbox = ({
  id,
  positiveLabel,
  negativeLabel,
  value,
  onChange,
  color = 'blue',
  disabled,
  dashed,
  transparent = true,
}: Props) => {
  const label = value ? positiveLabel : negativeLabel;
  const idCheckbox = `${label || ''}_checkbox`;

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();
    onChange && onChange(event.target.checked);
  };

  const handleCheckClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();
  };

  return (
    <Container>
      <BooleanCheckboxContainer>
        <Checkbox
          id={id || idCheckbox}
          checked={value}
          onChange={handleOnChange}
          color={color}
          disabled={disabled}
        />
        {dashed ? (
          <CheckDash
            disabled={disabled}
            onClick={handleCheckClick}
            transparent={transparent}
          />
        ) : (
          <Checkmark
            disabled={disabled}
            onClick={handleCheckClick}
            transparent={transparent}
            color={color}
          />
        )}
      </BooleanCheckboxContainer>
      {label && (
        <Label htmlFor={id || idCheckbox} checked={value}>
          {label}
        </Label>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const Label = styled.label<{ checked?: boolean }>`
  padding-left: 1rem;
  color: ${(props) => props.theme.colors.black[100]};
  ${(props) => props.theme.text.fontType.body3};
  letter-spacing: 0.02rem;
  cursor: pointer;
`;

const Checkmark = styled.i<{ disabled: boolean; transparent: boolean }>`
  box-sizing: border-box;
  position: absolute;
  top: 0.2rem;
  left: 0.2rem;
  height: 2rem;
  width: 2rem;
  border: 0.1rem solid ${(props) => props.theme.colors.blue[300]};
  background: ${(props) =>
    props.transparent ? 'transparent' : props.theme.colors.white};
  ${(props) =>
    props.disabled &&
    css`
      border-color: ${(props) => props.theme.colors.grey[100]};
    `}
  border-radius: ${(props) => props.theme.radius.md};
  cursor: pointer;

  &:after {
    content: '';
    position: absolute;
    left: 0.7rem;
    top: 0.2rem;
    width: 0.5rem;
    height: 1rem;
    border: solid
      ${(props) =>
        props.color === 'white' ? props.theme.colors.blue[100] : 'white'};
    border-width: 0 0.2rem 0.2rem 0;
    transform: rotate(45deg);
    display: none;
  }
`;

const CheckDash = styled.i<{ disabled: boolean; transparent: boolean }>`
  box-sizing: border-box;
  position: absolute;
  top: 0.2rem;
  left: 0.2rem;
  height: 2rem;
  width: 2rem;
  border: 0.2rem solid ${(props) => props.theme.colors.blue[400]};
  background: ${(props) =>
    props.transparent ? 'transparent' : props.theme.colors.white};
  ${(props) =>
    props.disabled &&
    css`
      border-color: ${(props) => props.theme.colors.grey[100]};
    `}
  border-radius: ${(props) => props.theme.radius.md};
  cursor: pointer;

  &:after {
    content: '';
    position: absolute;
    left: 0.9rem;
    top: 0.5rem;
    width: 0rem;
    height: 0.8rem;
    border: solid white;
    border-width: 0 0.2rem 0.2rem 0;
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
    display: none;
    border-radius: ${(props) => props.theme.radius.md};
  }
`;

const checkboxColorMap = (props) => ({
  green: props.theme.colors.green[100],
  blue: props.theme.colors.blue[100],
  red: props.theme.colors.red[100],
  white: props.theme.colors.white,
});

const Checkbox = styled.input.attrs({ type: 'checkbox' })<{ color: string }>`
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;

  :checked + ${Checkmark} {
    border: none;
    background-color: ${(props) => checkboxColorMap(props)[props.color]};

    ${(props) =>
      props.disabled &&
      css`
        background-color: ${props.theme.colors.grey[400]};
      `}

    &:after {
      display: block;
    }
  }

  :checked + ${CheckDash} {
    border: none;
    background-color: ${(props) => checkboxColorMap(props)[props.color]};

    ${(props) =>
      props.disabled &&
      css`
        background-color: ${props.theme.colors.grey[400]};
      `}

    &:after {
      display: block;
    }
  }
`;

const BooleanCheckboxContainer = styled.label`
  position: relative;
  display: inline-block;
  width: 2.4rem;
  height: 2.4rem;
  flex-shrink: 0;

  ${Checkbox} {
    display: none;
  }
`;
