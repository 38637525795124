import styled from 'styled-components';
import { GoogleAccountList } from 'src/services/google/models/GoogleAccountList';
import { ReactComponent as Chevron } from 'src/images/general/chevron.svg';

type SelectImportAccountProps = {
  accounts: GoogleAccountList[];
  onSelect: (id: number) => void;
  onSwitchAccount: () => void;
};

export const SelectImportAccount = ({
  accounts,
  onSelect,
  onSwitchAccount,
}: SelectImportAccountProps) => {
  return (
    <Wrapper>
      <Title>
        Use <Bold>Google Ads</Bold> as...
      </Title>
      <AccountList>
        {accounts.map((item) => (
          <AccountItem key={item.id} onClick={() => onSelect(item.id)}>
            <AccountItemButton type="button">
              <AccountName>{item.name}</AccountName>
              <AccountId>{item.id}</AccountId>
              <ChevronIcon />
            </AccountItemButton>
          </AccountItem>
        ))}
      </AccountList>
      <ButtonsSection>
        <ActionButton onClick={onSwitchAccount} type="button">
          Switch Google Ads Account
        </ActionButton>
      </ButtonsSection>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: grid;
  row-gap: ${({ theme }) => theme.spacing[5]};
`;

const Title = styled.h2`
  margin: 0;
  text-align: left;
  ${(props) => props.theme.text.fontType.h5};
  font-weight: ${(props) => props.theme.text.weight.normal};
`;

const AccountList = styled.ul`
  display: grid;
  row-gap: ${({ theme }) => theme.spacing[3]};
  margin: 0;
  padding: 0;
  list-style: none;
  max-height: 30rem;
  overflow-y: auto;
`;

const AccountItem = styled.li``;

const AccountName = styled.span``;

const ChevronIcon = styled(Chevron)`
  fill: ${({ theme }) => theme.colors.blue[100]};
  margin-left: auto;
`;

const AccountId = styled.span`
  padding: 0 0.5rem;
  color: ${({ theme }) => theme.colors.grey[200]};
`;

const AccountItemButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  padding: ${({ theme }) => theme.spacing[3]};
  text-align: left;
  appearance: none;
  border: 0.1rem solid ${({ theme }) => theme.colors.blue[300]};
  border-radius: 0.5rem;
  background: transparent;
  ${({ theme }) => theme.text.fontType.body3}
  cursor: pointer;
  transition: all 0.3s ease-out;

  &:hover {
    background: ${({ theme }) => theme.colors.blue[400]};
  }
`;

const ButtonsSection = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Bold = styled.b`
  font-weight: bold;
`;

const ActionButton = styled.button`
  appearance: none;
  border: none;
  border-radius: 0.5rem;
  background: transparent;
  cursor: pointer;
  transition: all 0.3s ease-out;

  &:hover {
    color: ${({ theme }) => theme.colors.grey[200]};
  }
`;
