import { useCallback, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useModal } from 'src/helpers/react/useModal';
import { WizardContainer } from 'src/components/layout/WizardElements';
import { SignUpModal } from 'src/components/header/Modal/SignUpModal';
import { LOCATIONS } from 'src/pages/locations';
import { useDispatch, useSelector } from 'react-redux';
import { saveUser, saveUserDetails } from 'src/redux/user/actions';
import UserService from 'src/services/users/userService';
import { getIsLoggedIn } from 'src/redux/user/selectors';
import { useBreak } from 'src/hooks/useBreak';
import { RegisterSuccessMobileModal } from './components/RegisterSuccessMobileModal';

export const RegisterPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location: any = useLocation();

  const { isDesktop } = useBreak();
  const isSignedIn = useSelector(getIsLoggedIn);

  const setIsSignedIn = useCallback(async () => {
    dispatch(saveUser());
    const userDetails = await UserService.getUserDetails();
    dispatch(saveUserDetails(userDetails));
  }, [dispatch]);

  const [SuccessMobile, showSuccessMobile] = useModal(
    RegisterSuccessMobileModal,
    {
      id: 'successMobileModal',
      hideDismiss: true,
    }
  );

  const onSuccess = () => {
    if (location?.state?.page) {
      history.push(location?.state?.page);
    } else if (!isDesktop) {
      showSuccessMobile();
    } else {
      history.push(LOCATIONS.dashboard);
    }
  };

  const params = {
    ...location?.state
  }

  const [SignUp, showSignUp] = useModal(SignUpModal, {
    id: 'signUpModal',
    hideDismiss: true,
    setIsSignedIn: setIsSignedIn,
    onSignInClick: () => history.push(LOCATIONS.login, params),
    onSuccess: onSuccess,
  });

  useEffect(() => {
    if (!location?.state?.page) {
      isSignedIn && isDesktop && history.push(LOCATIONS.dashboard);
    }
  }, [isSignedIn, isDesktop, history, location]);

  useEffect(() => {
    showSignUp();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <WizardContainer>
      <>
        {SignUp}
        {SuccessMobile}
      </>
    </WizardContainer>
  );
};
