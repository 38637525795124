import { useCallback } from 'react';
import styled from 'styled-components';
import { MIButton } from 'src/components/common/MIButton';
import {
  calculateBuilderItemDescription,
  calculateBuilderItemBackground,
} from 'src/utils/builder';
import { AdBuilderType } from 'src/utils/types';
import { useSelector } from 'react-redux';
import { getUserDetails } from 'src/redux/user/selectors';
import { getCampaigns } from 'src/redux/campaigns/selectors';
import { useModal } from 'src/helpers/react/useModal';
import { CannotCreateCampaignModal } from './CannotCreateCampaignModal';

type Props = {
  type: AdBuilderType;
  onClick: () => void;
};

export const BuilderItem = ({ type, onClick }: Props) => {
  const user = useSelector(getUserDetails);
  const { campaigns } = useSelector(getCampaigns);

  const details = calculateBuilderItemDescription(type, user, campaigns);
  const background = calculateBuilderItemBackground(type);

  const [CannotCreateCampaign, showCannotCreateCampaign] = useModal(
    CannotCreateCampaignModal,
    {
      id: 'cannotCreateCampaignModal',
    }
  );

  const buttonHandler = useCallback(
    (canCreateNewCampaign: boolean) => {
      if (canCreateNewCampaign) {
        onClick();
        return;
      }
      showCannotCreateCampaign();
    },
    [onClick, showCannotCreateCampaign]
  );

  return (
    <>
      {CannotCreateCampaign}
      <Container background={background}>
        <HeaderContainer>
          <Icon src={details?.icon} />
          <Title>{details?.title}</Title>
        </HeaderContainer>
        <Subtitle>{details?.subtitle}</Subtitle>
        <MIButton
          label={details?.cta}
          onClick={() => buttonHandler(details?.canCreateNewCampaign)}
        />
      </Container>
    </>
  );
};

const Container = styled.div<{ background: string }>`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 25rem;
  border-radius: ${(props) => props.theme.radius.xl};
  padding: ${(props) => props.theme.spacing[8]};
  background-color: ${(props) => props.background};
  border: 0.5rem solid transparent;
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: ${(props) => props.theme.spacing[5]};
`;

const Icon = styled.img`
  width: 4rem;
  height: 4rem;
  padding-right: ${(props) => props.theme.spacing[4]};
`;

const Title = styled.div`
  ${(props) => props.theme.text.fontType.h3};
`;

const Subtitle = styled.div`
  height: 9rem;
  margin-bottom: ${(props) => props.theme.spacing[6]};
  ${(props) => props.theme.text.fontType.body2};
  font-weight: 200;
`;
