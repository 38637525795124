// link to solution https://github.com/mayple/react-google-authorize/blob/master/src/GoogleAuthorize.js

import { useEffect, useState } from 'react';

type ParamsType = {
  client_id?: string;
  cookie_policy?: string;
  login_hint?: string;
  hosted_domain?: string;
  fetch_basic_profile?: string;
  ux_mode?: boolean;
  redirect_uri?: string;
  include_granted_scopes?: boolean;
  discoveryDocs?: string;
  prompt?: string;
  scope?: string;
  access_type?: string;
  response_type?: string;
};

const initialStyle = {
  display: 'inline-block',
  background: '#d14836',
  color: '#fff',
  width: 190,
  paddingTop: 10,
  paddingBottom: 10,
  borderRadius: 2,
  border: '1px solid transparent',
  fontSize: 16,
  fontWeight: 'bold',
  fontFamily: 'Roboto',
  cursor: 'pointer',
};

const addScript = (doc, script, id, callback) => {
  if (doc.getElementById(id)) {
    callback();
    return;
  }

  const element = doc.getElementsByTagName(script)[0];
  const fjs = element;
  let js = element;
  js = doc.createElement(script);
  js.id = id;
  js.src = '//apis.google.com/js/client:platform.js';
  fjs.parentNode.insertBefore(js, fjs);
  js.onload = () => {
    callback();
  };
};

const GoogleAuthorize = (props) => {
  const [disabled, setDisable] = useState(true);

  const {
    tag: Tag,
    type,
    style,
    className,
    disabledStyle,
    buttonText,
    children,
    render,
  } = props;

  useEffect(() => {
    addScript(document, 'script', 'react-google-authorize-script', () => {
      setDisable(false);
    });
  }, []);

  const handleClick = (e) => {
    if (e) {
      e.preventDefault(); // to prevent submit if used within form
    }

    if (disabled) {
      return;
    }

    const {
      clientId,
      cookiePolicy,
      loginHint,
      hostedDomain,
      fetchBasicProfile,
      redirectUri,
      discoveryDocs,
      onFailure,
      uxMode,
      scope,
      responseType,
      onSuccess,
      onRequest,
      prompt,
    } = props;

    const params: ParamsType = {
      client_id: clientId,
      cookie_policy: cookiePolicy,
      login_hint: loginHint,
      hosted_domain: hostedDomain,
      fetch_basic_profile: fetchBasicProfile,
      ux_mode: uxMode,
      redirect_uri: redirectUri,
      include_granted_scopes: true,
      discoveryDocs,
      prompt,
      scope,
    };

    if (responseType === 'code') {
      params.access_type = 'offline';
      params.response_type = 'code';
    }

    onRequest();

    (window as any).gapi.auth2.authorize(params, (response) => {
      if (response.error) {
        // An error happened!
        onFailure(new Error(response.error));

        return;
      }

      onSuccess(response);
    });
  };

  const isDisabled: boolean = disabled || props.disabled;
  if (render) {
    return render({ onClick: handleClick });
  }

  const styleProp = (() => {
    if (style) {
      return style;
    } else if (className && !style) {
      return {};
    }
    return initialStyle;
  })();

  const defaultStyle = (() => {
    if (isDisabled) {
      return { ...styleProp, ...disabledStyle };
    }
    return styleProp;
  })();

  return (
    <Tag
      style={defaultStyle}
      onClick={handleClick}
      type={type}
      isDisabled={isDisabled}
      className={className}
    >
      {children || buttonText}
    </Tag>
  );
};

GoogleAuthorize.defaultProps = {
  type: 'button',
  tag: 'button',
  buttonText: 'Login with Google',
  scope: 'profile email',
  prompt: '',
  cookiePolicy: 'single_host_origin',
  fetchBasicProfile: true,
  uxMode: 'popup',
  disabledStyle: {
    opacity: 0.6,
    background: '#9c9c9c',
    cursor: 'not-allowed',
  },
  onRequest: () => {},
};

export default GoogleAuthorize;
