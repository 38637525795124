import { useEffect } from 'react';
import { AdBuilderType } from 'src/utils/types';
import { BuilderFlow } from './components/BuilderFlow';
import { useBuilderSelection } from 'src/hooks/useBuilderSelection';
import { useDispatch, useSelector } from 'react-redux';
import { getStagFlowStep } from 'src/redux/stagCreationFlow/selectors';
import { setStagStep } from 'src/redux/stagCreationFlow/actions';

export const StagCampaignBuilderPage = () => {
  const dispatch = useDispatch();
  const { setSelectedBuilderType, selectedBuilderType } = useBuilderSelection();
  const stagCurrentStep = useSelector(getStagFlowStep);
  const isZeroStep = stagCurrentStep === 0;

  useEffect(() => {
    if (isZeroStep) {
      dispatch(setStagStep(1));
    }
  }, [isZeroStep, dispatch]);

  useEffect(() => {
    if (selectedBuilderType !== AdBuilderType.STAG) {
      setSelectedBuilderType(AdBuilderType.STAG);
    }
  }, [selectedBuilderType, setSelectedBuilderType]);

  return (
    <BuilderFlow isSkag={false} selectedBuilderType={AdBuilderType.STAG} />
  );
};
