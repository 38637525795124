import { CampaignType, ConversionType } from 'src/utils/types';
import RestClient from '../../infrastructure/restClient/RestClient';
import { GoogleAccountListResponse } from '../google/models/GoogleAccountListResponse';
import { IImportResponse } from './models/ImportResponse';

export default class GoogleService {
  public static getGoogleAccountList() {
    return RestClient.get<GoogleAccountListResponse>('api/import/accounts');
  }

  public static connectGoogleAdsAccount(code: string) {
    return RestClient.post<string>('api/import/connect', { Code: code });
  }

  public static importCampaign(accoundId: number, campaign: CampaignType) {
    return RestClient.post<IImportResponse>(`api/import/campaign/${accoundId}`, campaign);
  }

  public static createConversion(accoundId: number, conversion: ConversionType) {
    return RestClient.post<IImportResponse>(`api/conversions/${accoundId}`, conversion);
  }
}
