import { useState, useEffect, useCallback } from 'react';
import { MILoader } from 'src/components/common/MILoader';
import { USER_PLAN } from 'src/utils/consts';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import {
  deleteCampaigns,
  getCampaignsFailed,
  saveCampaigns,
} from 'src/redux/campaigns/actions';
import { useHistory } from 'react-router-dom';
import Arrow from 'src/images/general/right-arrow-small.svg';
import { getCampaigns } from 'src/redux/campaigns/selectors';
import { getIsLoggedIn, getUserDetails } from 'src/redux/user/selectors';
import CampaignService from 'src/services/campaign/campaignService';
import { LOCATIONS } from 'src/pages/locations';
import { CampaignCardsContainer } from './components/CampaignCardsContainer';

export const CampaignsDetails = () => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const isLoggedIn = useSelector(getIsLoggedIn);
  const user = useSelector(getUserDetails);
  const { campaigns, isError } = useSelector(getCampaigns);
  const draftCampaigns = campaigns.filter((campaign) => campaign.draft);
  const savedCampaigns = campaigns.filter((campaign) => !campaign.draft);
  const isPaidPlan = user?.plan !== USER_PLAN.FREE;

  useEffect(() => {
    if (isLoggedIn) {
      setIsLoading(true);
      CampaignService.getCampaigns()
        .then((data) => {
          dispatch(saveCampaigns(data?.content || []));
          setIsLoading(false);
        })
        .catch(() => {
          dispatch(getCampaignsFailed());
          setIsLoading(false);
        });
    } else {
      dispatch(deleteCampaigns());
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);

  const goToList = useCallback(() => {
    history.push(LOCATIONS.campaignsList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);

  const renderDraftCampaigns = () => {
    return (
      draftCampaigns.length && (
        <CampaignCardsContainer
          label='Draft Campaigns'
          campaigns={draftCampaigns}
        />
      )
    );
  };

  if (isLoading || isError) {
    return (
      <LoaderContainer>
        <MILoader color='primary' context='page' />;
      </LoaderContainer>
    );
  }

  return (
    <Container>
      {!savedCampaigns.length && renderDraftCampaigns()}
      <CampaignCardsContainer
        label='Campaigns'
        campaigns={savedCampaigns}
        additionalButton={
          isPaidPlan && (
            <Button onClick={goToList}>
              <Title>SEE ALL</Title>
              <ArrowIcon src={Arrow} />
            </Button>
          )
        }
      />
      {savedCampaigns.length && renderDraftCampaigns()}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 5%;
`;

const LoaderContainer = styled.div`
  margin-top: 20%;
`;

const Button = styled.div`
  width: 14.7rem;
  height: 5rem;
  border-radius: 1.2rem;
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-right: 10%;
  border: 0.1rem solid ${({ theme }) => theme.colors.blue[800]};
  box-shadow: ${(props) => props.theme.shadows.button};

  &:hover {
    box-shadow: none;
  }
`;

const Title = styled.span`
  color: ${({ theme }) => theme.colors.blue[200]};
  font-weight: ${(props) => props.theme.text.weight.bold};
  font-size: 1.4rem;
  margin-right: 1rem;
`;

const ArrowIcon = styled.img``;
