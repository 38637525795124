import {
  AdType,
  AdGroupType,
  TemplatesType,
  ReviewAdType,
  AdsTypes,
} from 'src/utils/types';

export const countSelectedAdAds = (
  Ads: AdGroupType[] | TemplatesType[],
  selectedAdType: AdType
) => {
  let count: number = 0;
  Ads.forEach((ad) => {
    if (selectedAdType === AdType.ALL) {
      count =
        ad.callOnlyExt.length +
        ad.callOutExt.length +
        ad.expTextAdExt.length +
        ad.searchExt.length +
        ad.snippetExt.length +
        ad.sitelinkExt.length;
    }

    if (selectedAdType === AdType.CALL) {
      count = ad.callOnlyExt.length;
    }

    if (selectedAdType === AdType.CALLOUT) {
      count = ad.callOutExt.length;
    }

    if (selectedAdType === AdType.EXPANDED) {
      count = ad.expTextAdExt.length;
    }

    if (selectedAdType === AdType.RESPONSIVE) {
      count = ad.searchExt.length;
    }

    if (selectedAdType === AdType.SNIPPET) {
      count = ad.snippetExt.length;
    }

    if (selectedAdType === AdType.SITELINK) {
      count = ad.sitelinkExt.length;
    }
  });

  return count;
};

export const getGroupedItems = (
  templates: TemplatesType,
  selectedAdType: AdType
) => {
  const items: any = [];

  if (selectedAdType === AdType.ALL) {
    items.push(templates?.expTextAdExt);
    items.push(templates?.callOnlyExt);
    items.push(templates?.searchExt);
    items.push(templates?.snippetExt);
    items.push(templates?.callOutExt);
    items.push(templates?.sitelinkExt);
  }

  if (selectedAdType === AdType.CALL) {
    items.push(templates?.callOnlyExt);
  }

  if (selectedAdType === AdType.CALLOUT) {
    items.push(templates?.callOutExt);
  }

  if (selectedAdType === AdType.EXPANDED) {
    items.push(templates?.expTextAdExt);
  }

  if (selectedAdType === AdType.RESPONSIVE) {
    items.push(templates?.searchExt);
  }

  if (selectedAdType === AdType.SNIPPET) {
    items.push(templates?.snippetExt);
  }

  if (selectedAdType === AdType.SITELINK) {
    items.push(templates?.sitelinkExt);
  }

  return items.flat();
};

export const splitItemsByPages = (
  items: ReviewAdType[] | AdsTypes[],
  adsCount: number,
  pageIndex: number,
  pageSize: number
) => {
  const groupedItems: ReviewAdType[] | AdsTypes[] =
    adsCount > pageSize
      ? items.slice((pageIndex - 1) * pageSize, pageIndex * pageSize)
      : items;

  return groupedItems as ReviewAdType[] | AdsTypes[];
};
