import { useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { MIModalMessage } from 'src/components/common/MIModalMessage';
import { MIButton } from 'src/components/common/MIButton';
import { BUTTON_VARIANT } from 'src/utils/consts';

type Props = {
  dismiss: () => void;
  message?: string;
  title: string;
  onSubmitClick: () => void;
  content?: React.ReactNode;
  onCancelClick: () => void;
  isConfirmationModal?: boolean;
};

export const MIPromptMessage = ({
  dismiss,
  title = '',
  message,
  onSubmitClick,
  content = null,
  onCancelClick,
  isConfirmationModal = false,
}: Props) => {
  const handleSubmit = useCallback(
    async (event) => {
      event.preventDefault();
      onSubmitClick();
      dismiss();
    },
    [dismiss, onSubmitClick]
  );

  const closeModal = useCallback(() => {
    onCancelClick();
    dismiss();
  }, [dismiss, onCancelClick]);

  const keysHandler = useCallback(
    (event) => {
      if (event.key === 'Enter') {
        handleSubmit(event);
      } else if (event.key === 'Escape') {
        closeModal();
      }
    },
    [closeModal, handleSubmit]
  );

  useEffect(() => {
    window.addEventListener('keydown', keysHandler);
    return () => {
      window.removeEventListener('keydown', keysHandler);
    };
  }, [keysHandler]);

  return (
    <MIModalMessage
      dismiss={closeModal}
      titleComponent={
        <ModalTitleContainer>
          <ModalTitle>{title}</ModalTitle>
          <ModalDescription>
            {message}
            {content}
          </ModalDescription>
        </ModalTitleContainer>
      }
      footerComponent={
        <ButtonsContainer>
          <MIButton
            label={isConfirmationModal ? 'NO' : 'CANCEL'}
            onClick={closeModal}
            variant={BUTTON_VARIANT.SECONDARY}
          />
          <MIButton
            label={isConfirmationModal ? 'YES' : 'OK'}
            type='submit'
            onClick={handleSubmit}
          />
        </ButtonsContainer>
      }
    />
  );
};

const ModalTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const ModalTitle = styled.span`
  ${(props) => props.theme.text.fontType.h5};
  font-weight: ${(props) => props.theme.text.weight.semi};
  margin-bottom: 1.5rem;
`;

const ModalDescription = styled.span`
  text-align: center;
  width: 60%;
  ${(props) => props.theme.text.fontType.body2};
  margin-bottom: 1rem;
  letter-spacing: 0.1px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  > button:nth-child(1) {
    margin-right: 2rem;
    font-weight: bold;
  }

  > button:nth-child(2) {
    margin-left: 2rem;
    font-weight: bold;
  }
`;
