import styled from 'styled-components';

const Form = ({ titleComponent, contentComponent, footerComponent }) => {
  return (
    <Container>
      {titleComponent && <TitleContainer>{titleComponent}</TitleContainer>}
      {contentComponent && (
        <ContentContainer>{contentComponent}</ContentContainer>
      )}
      {footerComponent && <FooterContainer>{footerComponent}</FooterContainer>}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

const TitleContainer = styled.div``;

const ContentContainer = styled.div``;

const FooterContainer = styled.div``;

export default Form;
