import { Switch, Route, Redirect } from 'react-router-dom';
import { LOCATIONS } from 'src/pages/locations';
import { USER_PLAN } from 'src/utils/consts';
import { MainPage } from 'src/pages/main/MainPage';
import { PlannerPage } from 'src/pages/planner/PlannerPage';
import { LoginPage } from 'src/pages/auth/LoginPage';
import { RegisterPage } from 'src/pages/auth/RegisterPage';
import { ResetPasswordPage } from 'src/pages/auth/ResetPasswordPage';
import { RequestPasswordPage } from 'src/pages/auth/RequestPasswordPage';
import { CongratulationPage } from 'src/pages/congratulation/CongratulationPage';
import { CampaignsListPage } from 'src/pages/campaignsList/CampaignsListPage';
import { REACT_APP_DASHBOARD } from 'src/utils/consts';
import { PricingPlansPage } from 'src/pages/pricing/PricingPlansPage';
import { useSelector } from 'react-redux';
import { getIsLoggedIn, getUserDetails } from 'src/redux/user/selectors';
import { SkagCampaignBuilderPage } from 'src/pages/builder/SkagCampaignBuilderPage';
import { StagCampaignBuilderPage } from 'src/pages/builder/StagCampaignBuilderPage';
import { ConversionPage } from 'src/pages/conversions/ConversionPage';
import { RedemptionPage } from 'src/pages/redemption/RedemptionPage';

export const Routes = () => {
  const isLoggedIn = useSelector(getIsLoggedIn);
  const user = useSelector(getUserDetails);
  const isFreePlan = user?.plan === USER_PLAN.FREE;

  return (
    <Switch>
      <Route path={LOCATIONS.login} component={LoginPage} />
      <Route path={LOCATIONS.redemption} component={RedemptionPage} />
      <Route path={LOCATIONS.register} component={RegisterPage} />
      <Route path={LOCATIONS.resetPassword} component={ResetPasswordPage} />
      <Route path={LOCATIONS.requestPassword} component={RequestPasswordPage} />
      <Route path={LOCATIONS.dashboard} component={MainPage} />
      <Route path={LOCATIONS.pricing} component={PricingPlansPage} />
      <Route path={LOCATIONS.planner} component={PlannerPage} />
      <Route path={LOCATIONS.purchaseComplete} component={CongratulationPage} />
      <Route path={LOCATIONS.skagBuilder} component={SkagCampaignBuilderPage} />
      <Route path={LOCATIONS.stagBuilder} component={StagCampaignBuilderPage} />
      {isLoggedIn && (
        <Route path={LOCATIONS.conversions} component={ConversionPage} />
      )}
      {isLoggedIn && REACT_APP_DASHBOARD && !isFreePlan && (
        <Route path={LOCATIONS.campaignsList} component={CampaignsListPage} />
      )}
      <Redirect from='/' to={LOCATIONS.dashboard} />
    </Switch>
  );
};
