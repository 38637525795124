import styled from 'styled-components';
import { devices } from 'src/theme/appDevices';
import {
  WizardInner,
  WizardContainer,
  WizardStepActionsContainer,
} from 'src/components/layout/WizardElements';

type Props = {
  children: React.ReactNode;
};

export const RegisterLayout = ({ children }: Props) => {
  return (
    <Container>
      <StyledWizardInner>
        <WizardStepActionsContainer>{children}</WizardStepActionsContainer>
      </StyledWizardInner>
    </Container>
  );
};

const StyledWizardInner = styled(WizardInner)`
  height: 715px;
  flex-grow: 0;
  justify-content: start;
  @media ${devices.mobile}, ${devices.phablet} {
    height: auto;
    flex-grow: 1;
    justify-content: center;
  }
`;

const Container = styled(WizardContainer)`
  scroll-behavior: auto;
  overflow: auto;
`;
