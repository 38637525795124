import {
  AdType,
  CallOnlyAdType,
  ExpTextAdExtType,
  SnippetExtensionType,
  RespSearchAdType,
  CallOutAdType,
  CampaignType,
  SitelinkAdType,
  SitelinkFormType,
} from 'src/utils/types';
import { CallOnlyForm } from '../form/CallOnlyForm';
import { ExpandedTextForm } from '../form/ExpandedTextForm';
import { SnippetExtForm } from '../form/SnippetExtForm';
import { RespResearchForm } from '../form/ResponsiveResearchForm';
import { CallOutForm } from '../form/CallOutForm';
import { SitelinkForm } from '../form/SitelinkForm';
import { SitelinkEditForm } from '../form/SitelinkForm/components/SitelinkEditForm';

type Props = {
  isSkag?: boolean;
  isEdit?: boolean;
  values?:
    | CallOnlyAdType
    | ExpTextAdExtType
    | SnippetExtensionType
    | RespSearchAdType
    | CallOutAdType
    | SitelinkAdType
    | SitelinkFormType;
  closeModal: (toNextStep?: boolean) => void;
  currentAdType: AdType;
  defaultData: any;
  updateCampaignOnServer: (data?: CampaignType) => void;
};

export const FormModal = ({
  isSkag = false,
  isEdit = false,
  values,
  currentAdType,
  defaultData,
  closeModal,
  updateCampaignOnServer,
}: Props) => {
  if (currentAdType === AdType.EXPANDED) {
    return (
      <ExpandedTextForm
        isSkag={isSkag}
        initialValues={values || defaultData}
        closeModal={closeModal}
        updateCampaignOnServer={updateCampaignOnServer}
      />
    );
  } else if (currentAdType === AdType.CALL) {
    return (
      <CallOnlyForm
        isSkag={isSkag}
        initialValues={values || defaultData}
        closeModal={closeModal}
        updateCampaignOnServer={updateCampaignOnServer}
      />
    );
  } else if (currentAdType === AdType.SNIPPET) {
    return (
      <SnippetExtForm
        isSkag={isSkag}
        initialValues={values || defaultData}
        closeModal={closeModal}
        updateCampaignOnServer={updateCampaignOnServer}
      />
    );
  } else if (currentAdType === AdType.RESPONSIVE) {
    return (
      <RespResearchForm
        isSkag={isSkag}
        initialValues={values || defaultData}
        closeModal={closeModal}
        updateCampaignOnServer={updateCampaignOnServer}
      />
    );
  } else if (currentAdType === AdType.CALLOUT) {
    return (
      <CallOutForm
        isSkag={isSkag}
        initialValues={values || defaultData}
        closeModal={closeModal}
        updateCampaignOnServer={updateCampaignOnServer}
      />
    );
  } else if (currentAdType === AdType.SITELINK && isEdit) {
    return (
      <SitelinkEditForm
        isSkag={isSkag}
        initialValues={values || defaultData}
        closeModal={closeModal}
        updateCampaignOnServer={updateCampaignOnServer}
      />
    );
  } else if (currentAdType === AdType.SITELINK) {
    return (
      <SitelinkForm
        isSkag={isSkag}
        initialValues={values || defaultData}
        closeModal={closeModal}
        updateCampaignOnServer={updateCampaignOnServer}
      />
    );
  }

  return (
    <ExpandedTextForm
      isSkag={isSkag}
      initialValues={values || defaultData}
      closeModal={closeModal}
      updateCampaignOnServer={updateCampaignOnServer}
    />
  );
};
