import { useCallback, useState } from 'react';
import styled from 'styled-components';
import moment from 'moment';

import TicketsIcon from 'src/images/general/tickets.svg';
import MouthPieceIcon from 'src/images/general/mouthpiece.svg';
import Ticket from 'src/images/general/ticket.svg';
import MenuIcon from 'src/images/general/three-dots.svg';
import { CampaignDetailsType } from 'src/redux/campaigns/types';
import CampaignService from 'src/services/campaign/campaignService';
import { useDispatch } from 'react-redux';
import { loadSkagCampaign } from 'src/redux/skagCompaign/actions';
import { loadStagCampaign } from 'src/redux/stagCampaign/actions';
import { AdBuilderType, CampaignType } from 'src/utils/types';
import {
  notifyServerError,
  notifySuccess,
} from 'src/services/notifications/notificationService';
import { MIContextMenu } from 'src/components/common/MIContextMenu';
import {
  deleteCampaign,
  updateCampaignName,
} from 'src/redux/campaigns/actions';
import { useModal } from 'src/helpers/react/useModal';
import { MIPromptMessage } from 'src/components/common/MIPromptMessage';
import { MITextInput } from 'src/components/common/MITextInput';
import EditIcon from 'src/images/general/edit-icon.svg';
import RenameIcon from 'src/images/general/append-icon.svg';
import DeleteIcon from 'src/images/general/delete-icon.svg';

type Props = {
  campaign: CampaignDetailsType;
  startCampaignEditing: (type: AdBuilderType) => void;
};

export const CampaignCard = ({ campaign, startCampaignEditing }: Props) => {
  const dispatch = useDispatch();
  const [newCampaignName, setCampaignName] = useState<string>('');

  const getCampaignType = useCallback(() => {
    return campaign.skag ? 'SKAG' : 'STAG';
  }, [campaign.skag]);

  const toCampaignDetails = async () => {
    try {
      const data: CampaignType = await CampaignService.getCampaignDetails(
        campaign._id
      );
      if (data?.skag) {
        dispatch(loadSkagCampaign(data));
        startCampaignEditing(AdBuilderType.SKAG);
        notifySuccess({ msg: 'Campaign successfully loaded' });
        return;
      } else if (data?.stag) {
        dispatch(loadStagCampaign(data));
        startCampaignEditing(AdBuilderType.STAG);
        notifySuccess({ msg: 'Campaign successfully loaded' });
        return;
      }
      throw new Error();
    } catch (e) {
      notifyServerError({ msg: 'Server error' });
    }
  };

  const deleteCampaignHandler = async () => {
    const deleteResult = await CampaignService.deleteCampaign(campaign._id);
    if (deleteResult === 'OK') {
      dispatch(deleteCampaign(campaign._id));
      notifySuccess({ msg: 'Campaign successfully deleted' });
    }
  };

  const newCampaignNameHandler = async () => {
    const result = await CampaignService.updateCampaignName(
      campaign._id,
      newCampaignName
    );

    if (result) {
      dispatch(updateCampaignName(campaign._id, newCampaignName));
      notifySuccess({ msg: 'Campaign name successfully updated' });
    }
  };

  const [ConfirmationModal, showConfirmationModal] = useModal(MIPromptMessage, {
    id: 'confirmationModal',
    title: 'Are you sure you want to delete the campaign?',
    onSubmitClick: deleteCampaignHandler,
    onCancelClick: () => {},
    isConfirmationModal: true,
  });

  const changeNameHandler = () => {
    setCampaignName(campaign.name);
    showCampaignNameModal();
  };

  const [EnterCampaignName, showCampaignNameModal] = useModal(MIPromptMessage, {
    id: 'campaignNameModal',
    title: 'Enter a new campaign name',
    onSubmitClick: newCampaignNameHandler,
    onCancelClick: () => setCampaignName(''),
    content: (
      <MITextInput
        onChange={({ value }) => setCampaignName(value)}
        value={newCampaignName}
      />
    ),
  });

  const contextMenuOptions = [
    { label: 'Edit', icon: EditIcon, onClick: toCampaignDetails },
    { label: 'Delete', icon: DeleteIcon, onClick: showConfirmationModal },
    { label: 'Rename', icon: RenameIcon, onClick: changeNameHandler },
  ];

  return (
    <>
      {ConfirmationModal}
      {EnterCampaignName}
      <CardContainer onClick={toCampaignDetails}>
        <FirstRow>
          <Type>
            <Tickets src={TicketsIcon} />
            {getCampaignType()}
          </Type>
          {campaign.draft && <DraftLabel>DRAFT</DraftLabel>}
          <MenuContainer onClick={(e) => e.stopPropagation()}>
            <Icon src={MenuIcon} />
            <MIContextMenu id='menu' options={contextMenuOptions} />
          </MenuContainer>
        </FirstRow>
        <CampaignName>{campaign.name}</CampaignName>
        <DetailsContainer>
          <DetailsItem>
            <Icon src={Ticket} />
            <Bold>{campaign.keywordsCnt}</Bold>
            <Description>keywords</Description>
          </DetailsItem>
          <DetailsItem>
            <Icon src={MouthPieceIcon} />
            <Bold>{campaign.adsAndExtCnt}</Bold>
            <Description>ads and ext.</Description>
          </DetailsItem>
          <Time>Last updates {moment(campaign.updatedAt).fromNow()}</Time>
        </DetailsContainer>
      </CardContainer>
    </>
  );
};

const CardContainer = styled.div`
  position: relative;
  min-width: 36rem;
  background-color: ${({ theme }) => theme.colors.white};
  height: 30rem;
  border: 0.2rem solid ${({ theme }) => theme.colors.blue[800]};
  border-radius: 3rem;
  margin-right: 2rem;
  display: flex;
  flex-direction: column;
  cursor: pointer;
`;

const FirstRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 1.9rem 1.9rem 3rem 1.9rem;
`;

const Type = styled.div`
  width: 10rem;
  height: 3rem;
  border-radius: 0.8rem;
  background-color: ${({ theme }) => theme.colors.blue[800]};
  color: ${({ theme }) => theme.colors.blue[200]};
  font-size: 1.7rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
`;

const Tickets = styled.img`
  margin-right: 0.9rem;
`;

const Icon = styled.img``;

const DetailsContainer = styled.div`
  position: absolute;
  width: calc(100% - 2rem);
  height: 14rem;
  background-color: ${({ theme }) => theme.colors.blue[800]};
  border-radius: 2rem;
  bottom: 0rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 2rem 0 0 2rem;
`;

const CampaignName = styled.h1`
  font-weight: 400;
  margin: 0 2rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const DetailsItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${({ theme }) => theme.colors.blue[900]};
  font-size: 1.6rem;
`;

const Bold = styled.div`
  margin-left: 2rem;
  margin-right: 1rem;
  font-size: 3.6rem;
`;

const Description = styled.div`
  margin-top: 1.1rem;
`;

const Time = styled.div`
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.blue[1000]};
  margin-top: 2.5rem;
  font-weight: 300;
`;

const MenuContainer = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  height: 15px;
  width: 15px;
  border-radius: 20px;
  padding: 5px;
  &:hover {
    background-color: ${({ theme }) => theme.colors.grey[500]};
    #menu {
      display: block;
    }
  }
`;

const DraftLabel = styled.span`
  color: ${({ theme }) => theme.colors.blue[1000]};
  font-size: 1.3rem;
  margin: 0 auto 0 1rem;
`;
