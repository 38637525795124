import { useState, useMemo } from 'react';
import isEmpty from 'lodash/isEmpty';
import styled from 'styled-components';
import { ReviewTable } from './Table/ReviewTable';
import { AdType, OptionType } from 'src/utils/types';
import { generateAdTypeFilterData } from 'src/utils/consts';
import { CampaignType } from 'src/utils/types';
import {
  generateNegatives,
  getTemplatesToDisplayInReview,
} from 'src/utils/builder';
import { DropDownFilter } from './components/DropDownFilter';

type Props = {
  isSkag?: boolean;
  campaign: CampaignType;
};

export const ReviewEditor = ({ isSkag = false, campaign }: Props) => {
  const {
    adGroupList,
    keywordsList,
    broad,
    exact,
    phrase,
    negativePhrase,
    templates,
  } = campaign;

  const keywords = useMemo(() => {
    if (isSkag) {
      return keywordsList;
    }

    return adGroupList.reduce<string[]>(
      (group, value) => [...group, ...value.keywords],
      []
    );
  }, [isSkag, keywordsList, adGroupList]);

  const adGroupOptions: OptionType[] = adGroupList
    .map((el) => el.adGroup)
    .map((el) => ({ label: el, value: el }));

  const generateAllKeywords = (keywords: string[]): OptionType[] => {
    return keywords.reduce<OptionType[]>(
      (currentValue: OptionType[], item: string) => {
        if (exact) {
          currentValue.push({ label: `[${item}]`, value: `[${item}]` });
        }

        if (broad) {
          currentValue.push({ label: `${item}`, value: `${item}` });
        }

        if (phrase) {
          currentValue.push({ label: `"${item}"`, value: `"${item}"` });
        }

        return currentValue;
      },
      []
    );
  };

  const keywordsOptions: OptionType[] = generateAllKeywords(keywords);
  const negativesOptions = negativePhrase ? generateNegatives(adGroupList) : [];
  const adTypeFilterOptions = useMemo(() => {
    return generateAdTypeFilterData(templates);
  }, [templates]);
  const [selectedAdGroups, setSelectedAdGroups] = useState<string[]>(
    adGroupList.map((el) => el.adGroup)
  );
  const [selectedAdType, setSelectedAdType] = useState<AdType>(AdType.ALL);
  const [selectedKeywords, setSelectedKeywords] = useState<string[]>(
    keywordsOptions.map((keyword) => keyword.value)
  );
  const [selectedNegatives, setSelectedNegatives] = useState<string[]>(
    negativesOptions.map((negative) => negative.value)
  );

  const onSelectAdGroup = ({ value }) => {
    if (selectedAdGroups.includes(value)) {
      setSelectedAdGroups((prevValue) =>
        prevValue.filter((item) => item !== value)
      );
    } else {
      setSelectedAdGroups((prevValue) => [...prevValue, value]);
    }
  };

  const clearAdGroupSelection = () => {
    setSelectedAdGroups(adGroupList.map((el) => el.adGroup));
  };

  const onSelectAllAdGroups = () => {
    isAllAdGroupsSelected
      ? setSelectedAdGroups([])
      : setSelectedAdGroups(adGroupList.map((el) => el.adGroup));
  };

  const onSelectAdType = ({ value }) => {
    setSelectedAdType(value);
  };

  const clearAdTypeSelection = () => {
    setSelectedAdType(AdType.ALL);
  };

  const onSelectKeyword = ({ value }) => {
    if (selectedKeywords.includes(value)) {
      setSelectedKeywords((prevValue) =>
        prevValue.filter((item) => item !== value)
      );
    } else {
      setSelectedKeywords((prevValue) => [...prevValue, value]);
    }
  };

  const clearKeywordSelection = () => {
    setSelectedKeywords(keywordsOptions.map((keyword) => keyword.value));
  };

  const onSelectAllKeywords = () => {
    isAllKeywordsSelected
      ? setSelectedKeywords([])
      : setSelectedKeywords(keywordsOptions.map((keyword) => keyword.value));
  };

  const onSelectNegatives = ({ value }) => {
    if (selectedNegatives.includes(value)) {
      setSelectedNegatives((prevValue) =>
        prevValue.filter((item) => item !== value)
      );
    } else {
      setSelectedNegatives((prevValue) => [...prevValue, value]);
    }
  };

  const clearNegativesSelection = () => {
    setSelectedNegatives(negativesOptions.map((negative) => negative.value));
  };

  const onSelectAllNegatives = () => {
    isAllNegativesSelected
      ? setSelectedNegatives([])
      : setSelectedNegatives(
          negativesOptions.map((negative) => negative.value)
        );
  };

  const getAdsCount = useMemo(() => {
    const adsCount = adGroupList.reduce((acc, el) => {
      const { callOnlyExt, expTextAdExt, searchExt } = el;
      return (
        acc +
        callOnlyExt.length +
        expTextAdExt.length +
        searchExt.length
      );
    }, 0);
    const templatesToDisplay = getTemplatesToDisplayInReview(templates);
    return templatesToDisplay.length + adsCount;
  }, [adGroupList, templates]);

  const isAllAdGroupsSelected =
    selectedAdGroups.length === adGroupOptions.length;
  const isAllKeywordsSelected =
    selectedKeywords.length === keywordsOptions.length;
  const isAllNegativesSelected =
    selectedNegatives.length === negativesOptions.length;

  return (
    <Container>
      <Title>
        <Bold>Campaign</Bold> Review
      </Title>
      <Filters>
        <DropDownFilter
          title='Ad groups'
          placeholder={
            isAllAdGroupsSelected
              ? 'Choose the group'
              : `${selectedAdGroups.length} ad groups selected`
          }
          value={selectedAdGroups}
          options={adGroupOptions}
          multiselect={true}
          isAllSelected={isAllAdGroupsSelected}
          onClear={isAllAdGroupsSelected ? undefined : clearAdGroupSelection}
          onChange={onSelectAdGroup}
          onSearch={(value) => {
            setSelectedAdGroups(
              adGroupList
                .map(({ adGroup }) => adGroup)
                .filter((item) =>
                  item.toLowerCase().includes(value.toLowerCase())
                )
            );
          }}
          onSelectAll={onSelectAllAdGroups}
        />

        <DropDownFilter
          title='Ad types'
          placeholder={
            adTypeFilterOptions.find(
              (option) => option.value === selectedAdType
            )?.label || 'Choose the type'
          }
          value={selectedAdType}
          options={adTypeFilterOptions}
          multiselect={false}
          totalCount={adTypeFilterOptions.length - 1}
          onChange={onSelectAdType}
          onClear={
            selectedAdType === AdType.ALL ? undefined : clearAdTypeSelection
          }
        />

        <DropDownFilter
          title='Keywords'
          placeholder={
            isAllKeywordsSelected
              ? 'Choose keywords'
              : `${selectedKeywords.length} keywords selected`
          }
          value={selectedKeywords}
          options={keywordsOptions}
          multiselect={true}
          isAllSelected={isAllKeywordsSelected}
          onClear={isAllKeywordsSelected ? undefined : clearKeywordSelection}
          onChange={onSelectKeyword}
          onSearch={(value) => {
            setSelectedKeywords(
              keywordsOptions
                .map(({ value }) => value)
                .filter((item) =>
                  item.toLowerCase().includes(value.toLowerCase())
                )
            );
          }}
          onSelectAll={onSelectAllKeywords}
        />

        {!isEmpty(negativesOptions) && (
          <DropDownFilter
            title='Negatives'
            placeholder={
              isAllNegativesSelected
                ? 'Choose keywords'
                : `${selectedNegatives.length} negatives selected`
            }
            value={selectedNegatives}
            options={negativesOptions}
            multiselect={true}
            isAllSelected={isAllNegativesSelected}
            onClear={
              isAllNegativesSelected ? undefined : clearNegativesSelection
            }
            onChange={onSelectNegatives}
            onSearch={(value) => {
              setSelectedNegatives(
                negativesOptions
                  .map(({ value }) => value)
                  .filter((item) =>
                    item.toLowerCase().includes(value.toLowerCase())
                  )
              );
            }}
            onSelectAll={onSelectAllNegatives}
          />
        )}
      </Filters>
      <ReviewTable
        isSkag={isSkag}
        campaign={campaign}
        adsCount={getAdsCount}
        filterByGroups={selectedAdGroups}
        filterByAdType={selectedAdType}
        filterByNegatives={selectedNegatives}
        filterByKeywords={selectedKeywords}
        negativesList={negativesOptions}
        isAllAdGroupsSelected={isAllAdGroupsSelected}
        isAllKeywordsSelected={isAllKeywordsSelected}
        isAllNegativesSelected={isAllNegativesSelected}
      />
    </Container>
  );
};

const Container = styled.div`
  padding: 0 ${({ theme }) => theme.spacing[32]};
  display: flex;
  width: 100%;
  max-width: 120rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.grey[200]};
  ${({ theme }) => theme.text.fontType.body3};
`;

const Bold = styled.span`
  font-weight: ${({ theme }) => theme.text.weight.bold};
`;

const Filters = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  > div:nth-child(2) {
    margin: 0 1rem 0 2rem;
  }
  > div:nth-child(3) {
    margin: 0 2rem 0 1rem;
  }
`;

const Title = styled.div`
  margin-bottom: 2.4rem;
  color: ${({ theme }) => theme.colors.black[200]};
  ${({ theme }) => theme.text.fontType.h2};
`;
