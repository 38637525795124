import { AdType, SitelinkDataType, SitelinkFormType } from 'src/utils/types';

export const firstRow = [
  { label: 'Sitelink text', key: 'text', required: true },
  { label: 'Final URL', key: 'finalUrl', required: true, maxlength: 35 },
];

export const secondRow = [
  {
    label: 'Description text one',
    key: 'descriptionOne',
    maxlength: 25,
    required: true,
  },
];

export const thirdRow = [
  {
    label: 'Description text two',
    key: 'descriptionTwo',
    maxlength: 35,
    required: true,
  },
];

export const defaultSitelinkData: SitelinkFormType = {
  id: undefined,
  adGroupName: 'adGroupName',
  type: AdType.SITELINK,
  data: [
    {
      id: 1,
      text: '',
      descriptionOne: '',
      descriptionTwo: '',
      finalUrl: '',
    },
  ],
};

export const defaultSitelinkEditData: SitelinkDataType = {
  id: 1,
  text: '2 to 6 week program',
  descriptionOne: 'Classes start soon',
  descriptionTwo: 'Classes start soon',
  finalUrl: `https://google.com`,
};

export const descriptions = 'descriptions';

export const fieldsToCheckBlockedSymbols = ['text', 'finalUrl', 'adGroupName'];
