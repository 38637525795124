import { useMemo, useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { MITable } from 'src/components/common/table/MITable';
import { getCampaigns } from 'src/redux/campaigns/selectors';
import CampaignService from 'src/services/campaign/campaignService';
import { campaignsTableColumns, campaignsTableData } from 'src/utils/builder';
import { Controllers } from './components/Controllers';
import {
  deleteCampaign,
  getCampaignsFailed,
  saveCampaigns,
  updateCampaignName,
} from 'src/redux/campaigns/actions';
import { generateFilterByCampaignType, NONE_VALUE } from 'src/utils/consts';
import { CampaignDetailsType } from 'src/redux/campaigns/types';
import { ReactComponent as SettingsIcon } from 'src/images/general/settings.svg';
import { MIContextMenu } from 'src/components/common/MIContextMenu';
import EditIcon from 'src/images/general/edit-icon.svg';
import RenameIcon from 'src/images/general/append-icon.svg';
import DeleteIcon from 'src/images/general/delete-icon.svg';
import { CampaignType } from 'src/utils/types';
import { loadSkagCampaign } from 'src/redux/skagCompaign/actions';
import { setSkagStep } from 'src/redux/skagCreationFlow/actions';
import {
  notifyServerError,
  notifySuccess,
} from 'src/services/notifications/notificationService';
import { setStagStep } from 'src/redux/stagCreationFlow/actions';
import { loadStagCampaign } from 'src/redux/stagCampaign/actions';
import { LOCATIONS } from '../locations';
import { MITextInput } from 'src/components/common/MITextInput';
import { useModal } from 'src/helpers/react/useModal';
import { MIPromptMessage } from 'src/components/common/MIPromptMessage';

export const CampaignsListPage = () => {
  const { campaigns } = useSelector(getCampaigns);
  const dispatch = useDispatch();
  const history = useHistory();
  const [searchValue, searchName] = useState<string>('');
  const [selectedCampaignType, setCampaignType] = useState<string>(NONE_VALUE);
  const [newCampaignName, setCampaignName] = useState<string>('');
  const [selectedCampaignId, selectCampaignId] = useState<string | null>(null);

  const loadCampaigns = useCallback(() => {
    CampaignService.getCampaigns()
      .then((data) => {
        dispatch(saveCampaigns(data?.content || []));
      })
      .catch(() => {
        dispatch(getCampaignsFailed());
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    loadCampaigns();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const data: CampaignDetailsType[] = useMemo(
    () => campaignsTableData(campaigns),
    [campaigns]
  );

  const filterData = useMemo(() => {
    const filteredData: CampaignDetailsType[] = [];

    if (selectedCampaignType === NONE_VALUE) {
      filteredData.push(...data);
    } else {
      const filteredDataByType = data.filter(
        (campaign) => campaign.type === selectedCampaignType
      );
      filteredData.push(...filteredDataByType);
    }
    return filteredData.filter((campaign) =>
      campaign.name.toLowerCase().includes(searchValue.toLowerCase())
    );
  }, [selectedCampaignType, searchValue, data]);

  const toCampaignDetails = useCallback(async (id: string) => {
    try {
      const data: CampaignType = await CampaignService.getCampaignDetails(id);
      if (data?.skag) {
        dispatch(loadSkagCampaign(data));
        dispatch(setSkagStep(1));
        history.push(LOCATIONS.skagBuilder);
        notifySuccess({ msg: 'Campaign successfully loaded' });
        return;
      } else if (data?.stag) {
        dispatch(loadStagCampaign(data));
        dispatch(setStagStep(1));
        history.push(LOCATIONS.stagBuilder);
        notifySuccess({ msg: 'Campaign successfully loaded' });
        return;
      }
      throw new Error();
    } catch (e) {
      notifyServerError({ msg: 'Server error' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const deleteCampaignHandler = useCallback(async () => {
    if (selectedCampaignId) {
      const deleteResult = await CampaignService.deleteCampaign(
        selectedCampaignId
      );
      if (deleteResult === 'OK') {
        dispatch(deleteCampaign(selectedCampaignId));
        notifySuccess({ msg: 'Campaign successfully deleted' });
      }
      selectCampaignId(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCampaignId]);

  const newCampaignNameHandler = useCallback(async () => {
    if (selectedCampaignId) {
      const result = await CampaignService.updateCampaignName(
        selectedCampaignId,
        newCampaignName
      );

      if (result) {
        dispatch(updateCampaignName(selectedCampaignId, newCampaignName));
        notifySuccess({ msg: 'Campaign name successfully updated' });
      }
      selectCampaignId(null);
      loadCampaigns();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCampaignId, newCampaignName]);

  const changeNameHandler = useCallback((id: string, name: string) => {
    selectCampaignId(id);
    setCampaignName(name);
    showCampaignNameModal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const deleteHandler = (id: string) => {
    selectCampaignId(id);
    showConfirmationModal();
  };

  const [ConfirmationModal, showConfirmationModal] = useModal(MIPromptMessage, {
    id: 'confirmationModal',
    title: 'Are you sure you want to delete the campaign?',
    onSubmitClick: deleteCampaignHandler,
    onCancelClick: () => {},
    isConfirmationModal: true,
  });

  const [EnterCampaignName, showCampaignNameModal] = useModal(MIPromptMessage, {
    id: 'campaignNameModal',
    title: 'Enter a new campaign name',
    onSubmitClick: newCampaignNameHandler,
    onCancelClick: () => selectCampaignId(null),
    content: (
      <MITextInput
        onChange={({ value }) => setCampaignName(value)}
        value={newCampaignName}
      />
    ),
  });

  const columns = useMemo(
    () => [
      ...campaignsTableColumns,
      {
        accessor: '_id',
        minWidth: 10,
        width: 10,
        maxWidth: 10,
        Cell: ({ value, row }) => {
          const contextMenuOptions = [
            {
              label: 'Edit',
              icon: EditIcon,
              onClick: () => toCampaignDetails(value),
            },
            {
              label: 'Delete',
              icon: DeleteIcon,
              onClick: () => deleteHandler(value),
            },
            {
              label: 'Rename',
              icon: RenameIcon,
              onClick: () => changeNameHandler(value, row.values.name),
            },
          ];
          return (
            <SettingsContainer>
              <Settings id='settingsIcon' />
              <MIContextMenu
                id='menu'
                options={contextMenuOptions}
                position={{ right: 15, top: 0 }}
              />
            </SettingsContainer>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const onSelectCampaignType = (result) => {
    const { value } = result;
    setCampaignType(value);
  };

  const campaignTypes = useMemo(generateFilterByCampaignType, []);

  return (
    <>
      {ConfirmationModal}
      {EnterCampaignName}
      <Container>
        <Title>
          All <Bold>Campaigns</Bold>
        </Title>
        <Controllers
          searchValue={searchValue}
          searchName={searchName}
          selectedCampaignType={selectedCampaignType}
          typesList={campaignTypes}
          filterByType={onSelectCampaignType}
        />
        <Table
          columns={columns}
          data={filterData}
          showPagination={true}
          onRowClick={(id) => toCampaignDetails(id)}
        />
      </Container>
    </>
  );
};

const Container = styled.div`
  display: flex;
  padding: 0 10%;
  align-items: center;
  flex-direction: column;
`;

const Title = styled.span`
  margin-top: 5rem;
  ${(props) => props.theme.text.fontType.h1};
`;

const Bold = styled.span`
  font-weight: ${(props) => props.theme.text.weight.bold};
`;

const Table = styled(MITable)`
  margin-top: 5rem;
  min-height: 70rem;
`;

const SettingsContainer = styled.div`
  cursor: pointer;
  position: absolute;

  &:hover {
    #menu {
      display: block;
    }
    #settingsIcon {
      color: ${({ theme }) => theme.colors.black[100]};
    }
  }
`;

const Settings = styled(SettingsIcon)`
  color: ${({ theme }) => theme.colors.grey[300]};
`;
