import { ReactNode } from 'react';
import styled from 'styled-components';
import isFunction from 'lodash/isFunction';
import { SmartLink } from 'src/modules/navigation/components/SmartLink';
import { ToNavigationType } from 'src/utils/types';

type Props = {
  to: ToNavigationType | (() => void);
  children: string | ReactNode;
  disabled?: boolean;
  plain?: boolean;
  className?: string;
};

export const MILink = ({ to, children, disabled, plain, className }: Props) => {
  if (!isFunction(to)) {
    return (
      <StyledLink
        className={className}
        to={to}
        disabled={disabled}
        plain={plain}
      >
        {children}
      </StyledLink>
    );
  }

  const goTo = () => {
    if (typeof to !== 'function') {
      return;
    }

    return to();
  };

  return (
    <StyledButton
      className={className}
      onClick={goTo}
      disabled={disabled}
      plain={plain}
    >
      {children}
    </StyledButton>
  );
};

const shareStyle = (props) => `
  background-color: transparent;
  line-height: 2rem;
  outline: none;
  text-decoration: none;
  border-radius: ${(props) => props.theme.radius.none};
  border: none;
  border-bottom: solid 0.2rem rgba(0, 0, 0, 0);
  color: ${props.theme.colors.black[100]};
  cursor: ${props.disabled ? 'default' : 'pointer'};
  padding: 0;

  &:hover {
    color: ${
      props.disabled
        ? props.theme.colors.grey[400]
        : props.theme.colors.blue[100]
    };
  }

  &:active {
    color: ${
      props.disabled
        ? props.theme.colors.grey[400]
        : props.theme.colors.blue[200]
    };
  }

  &:disabled {
    cursor: default;
  }
`;

const plainLink = (props) => `
  background-color: transparent;
  cursor: ${props.disabled ? 'auto' : 'pointer'};
  color: ${props.theme.colors.blue[100]};
  outline: none;
  text-decoration: none;
  border-radius: ${(props) => props.theme.radius.none};
  border: none;
  padding: 0;

  &:hover {
    color: ${props.theme.colors.black[100]};
  }

  &:active {
    color: ${props.theme.colors.black[100]};
  }
`;

const StyledLink = styled(({ plain, to, ...rest }: Props) => (
  <SmartLink to={to as ToNavigationType} {...rest} />
))`
  ${(props) => (props.plain ? plainLink(props) : shareStyle(props))}
`;

const StyledButton = styled.button<{ plain?: boolean }>`
  ${(props) => (props.plain ? plainLink(props) : shareStyle(props))}
`;
