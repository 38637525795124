export const LOCATIONS = {
  login: '/login',
  register: '/register',
  dashboard: '/dashboard',
  skagBuilder: '/skag',
  stagBuilder: '/stag',
  planner: '/planner',
  resetPassword: '/reset-password/:id',
  requestPassword: '/request-reset-password',
  pricing: '/pricing',
  purchaseComplete: '/purchase-complete',
  campaignsList: '/campaigns',
  conversions: '/conversions',
  redemption: '/redemption',
};
