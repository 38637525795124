import { useCallback } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import { LINKS } from 'src/utils/consts';
import { LOCATIONS } from 'src/pages/locations';
import { useSelector } from 'react-redux';
import { getIsLoggedIn } from 'src/redux/user/selectors';

export const AppFooter = () => {
  const history = useHistory();
  const isLoggedIn = useSelector(getIsLoggedIn);
  const toConversion = useCallback(
    (e) => {
      e.preventDefault();
      history.push(LOCATIONS.conversions);
    },
    [history]
  );

  return (
    <AppFooterContainer>
      <Copyright>Copyright © 2021</Copyright>
      <Menu>
        {isLoggedIn && (
          <MenuItem>
            <Link onClick={toConversion}>Conversions</Link>
          </MenuItem>
        )}
        <MenuItem>
          <Link href={LINKS.TERMS_OF_SERVICE}>Terms of Service</Link>
        </MenuItem>
        <MenuItem>
          <Link href={LINKS.PRIVACY_POLICY}>Privacy Policy</Link>
        </MenuItem>
        <MenuItem>Contact</MenuItem>
      </Menu>
    </AppFooterContainer>
  );
};

const AppFooterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${(props) => props.theme.spacing[4]}
    ${(props) => props.theme.spacing[28]};
  color: ${(props) => props.theme.colors.black[100]};
  box-shadow: ${(props) => props.theme.shadows.card};
  background-color: ${(props) => props.theme.colors.white};
  ${(props) => props.theme.text.fontType.body2};
`;

const Copyright = styled.div``;

const Menu = styled.div`
  display: flex;
  flex-direction: row;

  > div:nth-child(2) {
    margin: 0 ${(props) => props.theme.spacing[2]};
  }
`;

const MenuItem = styled.div`
  cursor: pointer;
  padding: ${(props) => props.theme.spacing[2]};
`;

const Link = styled.a`
  text-decoration: none;
  color: ${(props) => props.theme.colors.black[100]};
`;
