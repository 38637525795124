export const SET_SKAG_KEYWORDS: 'SET_SKAG_KEYWORDS' = 'SET_SKAG_KEYWORDS';
export const SET_SKAG_SETTINGS: 'SET_SKAG_SETTINGS' = 'SET_SKAG_SETTINGS';
export const CREATE_SKAG_ADS: 'CREATE_SKAG_ADS' = 'CREATE_SKAG_ADS';
export const UPDATE_SKAG_ADS: 'UPDATE_SKAG_ADS' = 'UPDATE_SKAG_ADS';
export const DELETE_SKAG_AD: 'DELETE_SKAG_AD' = 'DELETE_SKAG_AD';
export const UPDATE_NEGATIVES_IN_SKAG_GROUP: 'UPDATE_NEGATIVES_IN_SKAG_GROUP' = 'UPDATE_NEGATIVES_IN_SKAG_GROUP';
export const REPLACE_SKAG_TEXT: 'REPLACE_SKAG_TEXT' = 'REPLACE_SKAG_TEXT';
export const APPEND_SKAG_TEXT: 'APPEND_SKAG_TEXT' = 'APPEND_SKAG_TEXT';
export const SET_SKAG_TEXT: 'SET_SKAG_TEXT' = 'SET_SKAG_TEXT';
export const DELETE_ALL_SKAG_ADS_BY_TYPE: 'DELETE_ALL_SKAG_ADS_BY_TYPE' = 'DELETE_ALL_SKAG_ADS_BY_TYPE';
export const UPDATE_ONE_SKAG_AD: 'UPDATE_ONE_SKAG_AD' = 'UPDATE_ONE_SKAG_AD';
export const DUPLICATE_SKAG_AD_TEMPLATE: 'DUPLICATE_SKAG_AD_TEMPLATE' = 'DUPLICATE_SKAG_AD_TEMPLATE';
export const DUPLICATE_SKAG_AD_GROUP: 'DUPLICATE_SKAG_AD_GROUP' = 'DUPLICATE_SKAG_AD_GROUP';
export const LOAD_SKAG_CAMPAIGN: 'LOAD_SKAG_CAMPAIGN' = 'LOAD_SKAG_CAMPAIGN';
export const CLEAN_SKAG_CAMPAIGN: 'CLEAN_SKAG_CAMPAIGN' = 'CLEAN_SKAG_CAMPAIGN';
