import styled from 'styled-components';
import EmptyIcon from 'src/images/general/empty-state-icon.svg';

type Props = {
  canCreateAd?: boolean;
}

export const MITableEmptyState = ({ canCreateAd }: Props) => {
  return (
    <Container>
      {
        canCreateAd ? (
          <>
           <EmptyStateIcon src={EmptyIcon} />
            <Title>Looks like you didn’t create any Ads or Extensions yet...</Title>
            <Subtitle>
              To begin, create them by clicking “Add ads or extensions +”
            </Subtitle>
          </>
        ) : (
          <>
            <Title>Looks like you didn’t create any Ads or Extensions yet...</Title>
            <Subtitle>
              Please come back to the "Ad Templates" step to create them
            </Subtitle>
          </>
        )
      }
     
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0 ${(props) => props.theme.spacing[10]}
    ${(props) => props.theme.spacing[10]} ${(props) => props.theme.spacing[10]};
`;

const EmptyStateIcon = styled.img`
  width: 18rem;
  height: 15rem;
`;

const Title = styled.span`
  display: flex;
  margin: ${(props) => props.theme.spacing[5]} 0;
  color: ${(props) => props.theme.colors.blue[100]};
  ${(props) => props.theme.text.fontType.h5};
`;

const Subtitle = styled.span`
  display: flex;
  color: ${(props) => props.theme.colors.black[100]};
  ${(props) => props.theme.text.fontType.body2};
`;
