import { MIDropDown, OptionType } from 'src/components/common/MIDropDown';
import { MITextInput } from 'src/components/common/MITextInput';
import { NONE_VALUE, TEXT_INPUT_SIZE } from 'src/utils/consts';
import { Expandable } from 'src/utils/types';
import styled from 'styled-components';

type Props = {
  searchValue: string;
  searchName: (value: string) => void;
  selectedCampaignType: string;
  typesList: OptionType[];
  filterByType: (change: Expandable<{ value: string }>) => void;
};

export const Controllers = ({
  searchValue,
  searchName,
  selectedCampaignType,
  typesList,
  filterByType,
}: Props) => {
  return (
    <Container>
      <ItemContainer>
        <MITextInput
          id='searchCampaign'
          size={TEXT_INPUT_SIZE.WIZARD}
          value={searchValue}
          onChange={({ value }) => searchName(value)}
          transparent={false}
          label='Campaign'
          placeholder='Search campaign name'
          required
        />
      </ItemContainer>
      <ItemContainer>
        <ItemHeader>
          <Text>Campaign types</Text>
          <Text>
            Total <Bold>{typesList.length - 1}</Bold>
          </Text>
        </ItemHeader>
        <MIDropDown
          label={
            selectedCampaignType === NONE_VALUE
              ? 'Choose the campaign'
              : selectedCampaignType
          }
          value={selectedCampaignType}
          options={typesList}
          onChange={filterByType}
          transparent={false}
        />
      </ItemContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: auto;
  margin-top: 7rem;
  color: ${(props) => props.theme.colors.grey[200]};
  ${(props) => props.theme.text.fontType.body3};
`;

const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: ${(props) => props.theme.spacing[10]};
`;

const ItemHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${(props) => props.theme.spacing[2]};
`;

const Text = styled.div`
  font-size: 1.2rem;
  font-weight: ${(props) => props.theme.text.weight.semi};
  letter-spacing: 0.02rem;
  line-height: 1.8rem;
  color: ${(props) => props.theme.colors.grey[600]};
`;

const Bold = styled.span`
  font-weight: ${(props) => props.theme.text.weight.semi};
`;
