import {
  DELETE_CAMPAIGNS,
  SAVE_CAMPAIGNS,
  GET_CAMPAIGNS_FAILED,
  DELETE_CAMPAIGN,
  UPDATE_CAMPAIGN_NAME,
} from './actionTypes';

export const saveCampaigns = (data) => {
  return {
    type: SAVE_CAMPAIGNS,
    payload: data,
  };
};

export const deleteCampaigns = () => {
  return {
    type: DELETE_CAMPAIGNS,
  };
};

export const getCampaignsFailed = () => {
  return {
    type: GET_CAMPAIGNS_FAILED,
  };
};

export const deleteCampaign = (id: string) => {
  return {
    type: DELETE_CAMPAIGN,
    payload: {
      id,
    },
  };
};

export const updateCampaignName = (id: string, data: string) => {
  return {
    type: UPDATE_CAMPAIGN_NAME,
    payload: {
      id,
      data,
    },
  };
};
