import { useFormik } from 'formik';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { MIDropDown } from 'src/components/common/MIDropDown';
import MIFormButtons from 'src/components/common/MIFormButtons';
import { MIInputLabel } from 'src/components/common/MIInputLabel';
import { MIRadioButton } from 'src/components/common/MIRadioButton';
import { INPUT_TYPE, MITextInput } from 'src/components/common/MITextInput';
import styled from 'styled-components';
import {
  attributionModels,
  categories,
  countings,
  defaultData,
  viewPeriods,
  clickPeriods,
} from '../data';
import { validate } from '../utils';

type Props = {
  onSuccessSubmit: (data: any) => void;
};

export const CreationForm = ({ onSuccessSubmit }: Props) => {
  const history = useHistory();

  const formik = useFormik({
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: defaultData,
    validate,
    onSubmit: (values) => {
      if (!Object.keys(formik.errors).length) {
        const data = {
          category: values.category,
          name: values.name,
          useDefaultValue:
            values.useDefaultValue === 0 || values.useDefaultValue === 2,
          defaultValue: Number(values.useDefaultValue !== 2 ? values.value : 0),
          countingType: values.countingType,
          clickThroughConversionWindowDays: Number(
            values.clickThroughConversionWindowDays
          ),
          viewThroughConversionWindowDays: Number(
            values.viewThroughConversionWindowDays
          ),
          attributionModel: values.attributionModel,
        };
        onSuccessSubmit({ data });
      }
    },
  });

  useEffect(() => {
    if (formik.errors) {
      formik.setErrors({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values]);

  const renderDropDown = (props) => (
    <DrowpDownContainer withMargin={props.withMargin}>
      <ItemContainer>
        <MIInputLabel label={props.inputLabel} required={true} />
        <MIDropDown {...props} />
      </ItemContainer>
    </DrowpDownContainer>
  );

  const renderRadioButton = (props) => (
    <RadioButtonsContainer
      fullWidth={props.fullWidth}
      withMargins={props.withMargins}
    >
      <MIRadioButton
        transparent={false}
        value={props.value}
        onClick={props.onChange}
      />
      <Description>{props.title}</Description>
      {props.smallTitle && (
        <SmallDescription>{props.smallTitle}</SmallDescription>
      )}
    </RadioButtonsContainer>
  );

  const keyPressCheck = (event: React.KeyboardEvent) => {
    return (event.key === 'e' || event.key === '-') && event.preventDefault();
  };

  return (
    <>
      <Container>
        <Row>
          {renderDropDown({
            inputLabel: 'Goal and action optimization',
            options: categories,
            value: formik.values.category,
            transparent: false,
            errorMessage:
              'category' in formik.errors ? formik.errors.category : null,
            onChange: ({ value }) => formik.setFieldValue('category', value),
            label:
              categories.find(
                (category) => category.value === formik.values.category
              )?.label || 'Select',
          })}
          <NameContainer>
            <MITextInput
              label='Conversion'
              placeholder='Type here...'
              required={true}
              value={formik.values.name}
              errorMessage={'name' in formik.errors ? formik.errors.name : null}
              transparent={false}
              onChange={({ value }) => formik.setFieldValue('name', value)}
              onBlur={({ target }) =>
                formik.setFieldValue('name', target.value?.trim())
              }
            />
          </NameContainer>
        </Row>
        <Row>
          <SmallTitle
            error={
              'useDefaultValue' in formik.errors
                ? formik.errors.useDefaultValue
                : false
            }
          >
            Value
          </SmallTitle>
        </Row>
        <Row>
          {renderRadioButton({
            value: formik.values.useDefaultValue === 0,
            withMargins: true,
            title: 'Use the same value for each conversion',
            onChange: () => formik.setFieldValue('useDefaultValue', 0),
          })}
          <ValueContainer>
            <MITextInput
              required={true}
              min={0}
              disabled={formik.values.useDefaultValue !== 0}
              onKeyPress={keyPressCheck}
              type={INPUT_TYPE.NUMBER}
              errorMessage={
                'value' in formik.errors && formik.values.useDefaultValue === 0
                  ? formik.errors.value
                  : null
              }
              placeholder='Enter value here...'
              value={formik.values.value}
              transparent={false}
              onChange={({ value }) => formik.setFieldValue('value', value)}
              onBlur={({ target }) =>
                formik.setFieldValue('value', target.value?.trim())
              }
            />
          </ValueContainer>
        </Row>
        <Row>
          {renderRadioButton({
            value: formik.values.useDefaultValue === 1,
            title: 'Use different values for each conversion',
            withMargins: true,
            onChange: () => formik.setFieldValue('useDefaultValue', 1),
          })}
          <ValueContainer>
            <MITextInput
              required={true}
              disabled={formik.values.useDefaultValue !== 1}
              type={INPUT_TYPE.NUMBER}
              min={0}
              onKeyPress={keyPressCheck}
              placeholder='Default value here...'
              errorMessage={
                'value' in formik.errors && formik.values.useDefaultValue === 1
                  ? formik.errors.value
                  : null
              }
              value={formik.values.value}
              transparent={false}
              onChange={({ value }) => formik.setFieldValue('value', value)}
              onBlur={({ target }) =>
                formik.setFieldValue('value', target.value?.trim())
              }
            />
          </ValueContainer>
        </Row>
        <Row>
          {renderRadioButton({
            value: formik.values.useDefaultValue === 2,
            title:
              "Don't use a value for this conversion action (not recommended)",
            onChange: () => formik.setFieldValue('useDefaultValue', 2),
            fullWidth: true,
          })}
        </Row>
        <CountTitle
          error={
            'countingType' in formik.errors ? formik.errors.countingType : false
          }
        >
          Count
        </CountTitle>
        <Row>
          {renderRadioButton({
            value: formik.values.countingType === countings.MANY_PER_CLICK,
            title: 'Every',
            smallTitle:
              'Recommended for purchases because every purchase is valuable.',
            withMargins: true,
            fullWidth: true,
            onChange: () =>
              formik.setFieldValue('countingType', countings.MANY_PER_CLICK),
          })}
        </Row>
        <Row>
          {renderRadioButton({
            value: formik.values.countingType === countings.ONE_PER_CLICK,
            title: 'One',
            smallTitle:
              'Recommended for leads, sign-ups, and other conversions because only the first interaction is valuable.',
            withMargins: true,
            fullWidth: true,
            onChange: () =>
              formik.setFieldValue('countingType', countings.ONE_PER_CLICK),
          })}
        </Row>
        {renderDropDown({
          inputLabel: 'Click-through conversion window',
          options: clickPeriods,
          transparent: false,
          withMargin: true,
          errorMessage:
            'clickThroughConversionWindowDays' in formik.errors
              ? formik.errors.clickThroughConversionWindowDays
              : null,
          value: formik.values.clickThroughConversionWindowDays,
          onChange: ({ value }) =>
            formik.setFieldValue('clickThroughConversionWindowDays', value),
          label:
            clickPeriods.find(
              (period) =>
                period.value === formik.values.clickThroughConversionWindowDays
            )?.label || 'Select',
        })}
        {renderDropDown({
          inputLabel: 'View-through conversion window',
          options: viewPeriods,
          transparent: false,
          errorMessage:
            'viewThroughConversionWindowDays' in formik.errors
              ? formik.errors.viewThroughConversionWindowDays
              : null,
          value: formik.values.viewThroughConversionWindowDays,
          onChange: ({ value }) =>
            formik.setFieldValue('viewThroughConversionWindowDays', value),
          label:
            viewPeriods.find(
              (period) =>
                period.value === formik.values.viewThroughConversionWindowDays
            )?.label || 'Select',
        })}
        {renderDropDown({
          inputLabel: 'Attribution model',
          options: attributionModels,
          transparent: false,
          errorMessage:
            'attributionModel' in formik.errors
              ? formik.errors.attributionModel
              : null,
          value: formik.values.attributionModel,
          onChange: ({ value }) =>
            formik.setFieldValue('attributionModel', value),
          label:
            attributionModels.find(
              (model) => model.value === formik.values.attributionModel
            )?.label || 'Select',
        })}
      </Container>
      <MIFormButtons
        closeModal={history.goBack}
        saveHandler={formik.submitForm}
      />
    </>
  );
};

const Container = styled.form`
  background-color: ${({ theme }) => theme.colors.blue[700]};
  border-radius: 1.5rem;
  width: 70rem;
  display: flex;
  flex-direction: column;
  padding: 5rem 12rem;
`;

const Row = styled.div`
  display: flex;
  width: 100%;
`;

const ItemContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const SmallTitle = styled.span`
  color: ${({ theme, error }) =>
    error ? theme.colors.red[300] : theme.colors.grey[200]};
  font-size: 1.4rem;
  margin: ${({ theme }) => theme.spacing[4]} 0
    ${({ theme }) => theme.spacing[6]} 0;
  text-decoration: ${({ error }) => (error ? 'underline' : 'none')};
`;

const CountTitle = styled.span`
  color: ${({ theme, error }) =>
    error ? theme.colors.red[300] : theme.colors.grey[200]};
  font-size: 1.4rem;
  margin: ${({ theme }) => theme.spacing[10]} 0
    ${({ theme }) => theme.spacing[6]} 0;
  text-decoration: ${({ error }) => (error ? 'underline' : 'none')};
`;

const RadioButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: ${({ fullWidth }) => (fullWidth ? '100%' : '60%')};
  margin-bottom: ${({ withMargins, theme }) =>
    withMargins ? theme.spacing[8] : 0};
`;

const Description = styled.span`
  margin-left: 1rem;
  font-size: 1.6rem;
  font-weight: 300;
  line-height: 2.8rem;
`;

const SmallDescription = styled.span`
  position: absolute;
  top: 3rem;
  left: 3rem;
  color: ${({ theme }) => theme.colors.grey[200]};
`;

const DrowpDownContainer = styled.div`
  margin-top: ${({ withMargin, theme }) => (withMargin ? theme.spacing[6] : 0)};
  margin-bottom: ${({ theme }) => theme.spacing[6]};
  width: 40%;
`;

const ValueContainer = styled.div`
  width: 30%;
`;

const NameContainer = styled.div`
  margin-left: 9.5rem;
  width: 50%;
`;
