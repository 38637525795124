import { useState } from 'react';
import { v4 as uuid } from 'uuid';
import styled from 'styled-components';
import isEmpty from 'lodash/isEmpty';
import KeywordsIcon from 'src/images/general/keywords-icon.svg';
import { MIFormCounter } from 'src/components/common/MIFormCounter';
import { AdGroupType, TemplatesType } from 'src/utils/types';
import { useDispatch, useSelector } from 'react-redux';
import { setStagAdGroups } from 'src/redux/stagCampaign/actions';
import { fillGroupWithData } from 'src/utils/builder';
import {
  getGroupsCount,
  getStagAdGroupList,
  getStagTemplates,
} from 'src/redux/stagCampaign/selectors';

export const StagGroupsBuilder = () => {
  const dispatch = useDispatch();

  const adGroupList: AdGroupType[] = useSelector(getStagAdGroupList);
  const templates: TemplatesType = useSelector(getStagTemplates);

  const [value, setValue] = useState(() => {
    if (isEmpty(adGroupList)) {
      return '';
    }

    return adGroupList.map((adGroup) => adGroup.adGroup).join('\r\n');
  });
  const groupsCount = useSelector(getGroupsCount);

  const handleChange = (event) => {
    const groups = event.target.value
      .split('\n')
      .filter((el) => !isEmpty(el))
      .map((el) => el.trim());
    const updatedGroupList: AdGroupType[] = groups.map((group) => {
      const groupDetails = adGroupList.find(
        (eachGroup) => eachGroup.adGroup === group
      );

      if (groupDetails) {
        return groupDetails;
      } else {
        const adGroupId = uuid();

        const data = fillGroupWithData(templates, adGroupId);
        return {
          ...data,
          id: adGroupId,
          adGroup: group,
          keywords: [],
          negatives: [],
        };
      }
    });

    dispatch(setStagAdGroups(updatedGroupList));
    setValue(event.target.value);
  };

  return (
    <Container>
      <Title>
        Enter <Bold>Ad Groups</Bold>
      </Title>
      <KeywordsContainer>
        Ad Groups
        <KeywordsInput value={value} onChange={handleChange} />
        <CounterContainer>
          <MIFormCounter
            icon={KeywordsIcon}
            count={groupsCount}
            label='ad groups'
          />
        </CounterContainer>
      </KeywordsContainer>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const Title = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing[6]};
  ${({ theme }) => theme.text.fontType.h1};
`;

const Bold = styled.span`
  font-weight: ${({ theme }) => theme.text.weight.bold};
`;

const KeywordsContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  ${({ theme }) => theme.text.fontType.h6};
  font-weight: ${({ theme }) => theme.text.weight.light};
`;

const KeywordsInput = styled.textarea`
  width: 70rem;
  height: 30rem;
  margin: ${({ theme }) => theme.spacing[5]} 0;
  padding: ${({ theme }) => theme.spacing[2]} ${({ theme }) => theme.spacing[5]};
  resize: none;
  border-radius: ${({ theme }) => theme.radius.xl};
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.black[100]};
  border: 0.1rem solid ${({ theme }) => theme.colors.blue[400]};
  ${({ theme }) => theme.text.fontType.body2};
  background-attachment: local;

  &:focus {
    outline: none;
  }

  &::-webkit-scrollbar {
    width: 0.6rem;
    height: 10rem;
  }

  &::-webkit-scrollbar-track {
    border-radius: ${({ theme }) => theme.radius.md};
    margin-top: ${({ theme }) => theme.spacing[3]};
    margin-bottom: ${({ theme }) => theme.spacing[3]};
    background-color: ${({ theme }) => theme.colors.white};
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.blue.translucent};
    border-radius: ${({ theme }) => theme.radius.md};
  }
`;

const CounterContainer = styled.div`
  position: absolute;
  right: 2rem;
  bottom: 3.5rem;
`;
