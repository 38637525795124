import { useCallback, useMemo, useState } from 'react';
import { ConnectGoogleAds } from 'src/components/common/AdCreator/Modal/SaveCampaignModal/components/ConnectGoogleAds';
import { Errors } from 'src/components/common/AdCreator/Modal/SaveCampaignModal/components/Errors';
import { ImportToGoogleAds } from 'src/components/common/AdCreator/Modal/SaveCampaignModal/components/ImportToGoogleAds';
import { SelectImportAccount } from 'src/components/common/AdCreator/Modal/SaveCampaignModal/components/SelectImportAccount';
import { MIModalMessage } from 'src/components/common/MIModalMessage';
import GoogleService from 'src/services/google/googleService';
import { GoogleAccountList } from 'src/services/google/models/GoogleAccountList';
import {
  notifyInfo,
  notifyServerError,
  notifySuccess,
} from 'src/services/notifications/notificationService';
import { ConversionType, ImportError } from 'src/utils/types';
import { ConversionResults } from './ConversionResults';

type Props = {
  dismiss?: (event: React.MouseEvent) => void;
  data: ConversionType;
};

export const SaveConversionModal = ({ data, dismiss }: Props) => {
  const [currentScreen, setCurrentScreen] = useState('GOOGLE_ADS_AUTH');
  const [accounts, setAccounts] = useState<GoogleAccountList[]>([]);
  const [selectedAccountId, setAccountId] = useState<number | null>(null);
  const [importErrors, setImportErrors] = useState<ImportError[]>([]);
  const [conversionResults, setConversionResults] = useState([]);

  const uploadToGoogle = useCallback(async () => {
    try {
      const { is_error, content } = await GoogleService.getGoogleAccountList();

      if (is_error || !content) {
        notifyServerError({ msg: content?.msg || 'Server error' });
        return;
      }

      const { accounts, msg } = content;

      if (!accounts?.length) {
        notifyInfo({ msg });
        setCurrentScreen('GOOGLE_ADS_AUTH');
        return;
      }

      setAccounts(accounts);
      setCurrentScreen('SELECT_ACCOUNTS');
    } catch (error) {
      notifyServerError({ msg: 'Server error' });
      setCurrentScreen('GOOGLE_ADS_AUTH');
    }
  }, []);

  const onAccountSelect = useCallback((accountId: number) => {
    setAccountId(accountId);
    setCurrentScreen('APPROVE_IMPORT');
  }, []);

  const createConversion = useCallback(async () => {
    if (!selectedAccountId) {
      setCurrentScreen('SELECT_ACCOUNTS');
      notifyInfo({ msg: 'Please, select Google ads account.' });
      return;
    }

    try {
      const response = await GoogleService.createConversion(
        selectedAccountId,
        data
      );

      if (response.is_error) {
        const errorData = response?.error_content?.data?.errors[0];
        const errorToDisplay = [
          {
            source: 'Error',
            message: errorData?.message,
            fieldName: errorData?.location?.field_path_elements[2].field_name,
          },
        ];
        setImportErrors(errorToDisplay);
        notifyServerError({ msg: errorData?.message || '' });
        setCurrentScreen('ERRORS');
        return;
      }
      setConversionResults(
        response?.content?.importResult[0]?.conversion_action?.tag_snippets
      );
      notifySuccess({ msg: 'Conversion has been created successfully!' });
      setCurrentScreen('CONVERSION_RESULTS');
    } catch (error) {
      notifyServerError({ msg: 'Server Error' });
      setCurrentScreen('GOOGLE_ADS_AUTH');
    }
  }, [data, selectedAccountId]);

  const modalContent = useMemo(() => {
    switch (currentScreen) {
      case 'SELECT_ACCOUNTS':
        return (
          <SelectImportAccount
            accounts={accounts}
            onSelect={onAccountSelect}
            onSwitchAccount={() => setCurrentScreen('GOOGLE_ADS_AUTH')}
          />
        );
      case 'APPROVE_IMPORT':
        return (
          <ImportToGoogleAds
            isConversion={true}
            onSubmit={createConversion}
            onPrev={() => setCurrentScreen('SELECT_ACCOUNTS')}
          />
        );
      case 'CONVERSION_RESULTS':
        return <ConversionResults data={conversionResults} dismiss={dismiss} />;
      case 'ERRORS':
        return <Errors errors={importErrors} dismiss={dismiss} />;
      case 'GOOGLE_ADS_AUTH':
      default:
        return <ConnectGoogleAds handleConnect={uploadToGoogle} />;
    }
  }, [
    accounts,
    conversionResults,
    createConversion,
    currentScreen,
    dismiss,
    importErrors,
    onAccountSelect,
    uploadToGoogle,
  ]);

  return <MIModalMessage dismiss={dismiss} contentComponent={modalContent} />;
};
