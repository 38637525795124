import { Fragment } from 'react';
import styled from 'styled-components';
import FolderIcon from 'src/images/general/folder-icon.svg';
import SelectedArrow from 'src/images/general/selected-arrow.svg';
import UnselectedArrow from 'src/images/general/unselected-arrow.svg';
import { AdGroupType } from 'src/utils/types';
import { MIFormCounter } from 'src/components/common/MIFormCounter';
import KeywordsIcon from 'src/images/general/keywords-icon.svg';

type Props = {
  adGroupList: AdGroupType[];
  selectedGroupId: null | number;
  handleClick: (groupId: number) => void;
};

export const GroupsSelector = ({
  adGroupList,
  handleClick,
  selectedGroupId,
}: Props) => {
  return (
    <GroupsContainer>
      <GroupsArea>
        {adGroupList.map((group, index) => {
          const isGroupSelected = selectedGroupId === group.id;
          const isKeywordValidationError = group?.keywordsValidationError;
          return (
            <Fragment key={group.id}>
              <Group
                isGroupSelected={isGroupSelected}
                onClick={() => handleClick(group.id)}
              >
                <GroupNumber isGroupSelected={isGroupSelected}>
                  {index + 1}
                </GroupNumber>
                <Folder src={FolderIcon} />
                <GroupName>{group.adGroup}</GroupName>
                <KeywordsCounter>{group.keywords.length}</KeywordsCounter>
                <GroupSelector
                  src={isGroupSelected ? SelectedArrow : UnselectedArrow}
                />
              </Group>
              <GroupSeparator isError={isKeywordValidationError} />
            </Fragment>
          );
        })}
      </GroupsArea>
      <CounterContainer>
        <MIFormCounter
          icon={KeywordsIcon}
          count={adGroupList.length}
          label='groups'
        />
      </CounterContainer>
    </GroupsContainer>
  );
};

const GroupsContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const Group = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding 0.5rem 1.5rem 0.3rem 1.5rem;
  cursor: pointer;
  height: 4rem;
  border-radius: 0.4rem;
  background-color: ${({ isGroupSelected }) =>
    isGroupSelected ? 'rgba(34, 125, 243, 0.1)' : 'transparent'}
`;

const Folder = styled.img`
  display: flex;
  flex: 1;
  height: 3rem;
`;

const GroupNumber = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  font-size: 1.4rem;
  color: ${({ theme, isGroupSelected }) =>
    theme.colors.grey[isGroupSelected ? 200 : 300]};
`;

const GroupName = styled.div`
  width: 35.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  margin-left: 1rem;
`;

const KeywordsCounter = styled.div`
  display: flex;
  flex: 1;
  font-size: 1.4rem;
  color: ${({ theme }) => theme.colors.grey[300]};
  margin-left: 0.4rem;
`;

const GroupSelector = styled.img`
  width: 5%;
`;

const GroupsArea = styled.div`
  width: 50rem;
  height: 27.3rem;
  margin: ${({ theme }) => theme.spacing[5]} 0;
  display: flex;
  padding: 0.5rem 0 4rem 0.5rem;
  flex-direction: column;
  align-items: stratch;
  overflow: hidden;
  overflow-y: scroll;
  resize: none;
  border-radius: ${({ theme }) => theme.radius.xl};
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.black[100]};
  border: 0.1rem solid ${({ theme }) => theme.colors.blue[400]};
  ${({ theme }) => theme.text.fontType.body2};
  background-attachment: local;

  &:focus {
    outline: none;
  }

  &::-webkit-scrollbar {
    width: 0.6rem;
    height: 10rem;
  }

  &::-webkit-scrollbar-track {
    border-radius: ${({ theme }) => theme.radius.md};
    margin-top: ${({ theme }) => theme.spacing[3]};
    margin-bottom: ${({ theme }) => theme.spacing[3]};
    background-color: ${({ theme }) => theme.colors.white};
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.blue.translucent};
    border-radius: ${({ theme }) => theme.radius.md};
  }
`;

const CounterContainer = styled.div`
  position: absolute;
  left: 41.5rem;
  bottom: 3.5rem;
`;

const GroupSeparator = styled.div<{ isError?: boolean }>`
  width: 100%;
  height: 0.1rem;
  background-color: ${({ theme, isError }) =>
    isError ? theme.colors.red[300] : theme.colors.grey[300]};
`;
