import { KeywordIdeaType } from 'src/utils/types';
import {
  SET_PLANNER_KEYWORDS,
  SET_GOOGLE_KEYWORDS,
  DELETE_GOOGLE_KEYWORD,
  SET_SELECTED_KEYWORDS,
} from './actionTypes';

export const setPlannerKeywords = (keywords: string[]) => {
  return {
    type: SET_PLANNER_KEYWORDS,
    payload: {
      keywords,
    },
  };
};

export const setGoogleKeywords = (keywords: KeywordIdeaType[]) => {
  return {
    type: SET_GOOGLE_KEYWORDS,
    payload: {
      keywords,
    },
  };
};

export const deleteGoogleKeyword = (keyword: string) => {
  return {
    type: DELETE_GOOGLE_KEYWORD,
    payload: {
      keyword,
    },
  };
};

export const setSelectedKeywords = (keywords: KeywordIdeaType[]) => {
  return {
    type: SET_SELECTED_KEYWORDS,
    payload: {
      keywords,
    },
  };
};
