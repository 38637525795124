import { useMemo, useState } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { CampaignType, InputValue } from 'src/utils/types';
import { setSkagSettings } from 'src/redux/skagCompaign/actions';
import { setStagSettings } from 'src/redux/stagCampaign/actions';
import { INPUT_TYPE, MITextInput } from 'src/components/common/MITextInput';
import { MISlider } from 'src/components/common/MISlider';
import { MIDropDown } from 'src/components/common/MIDropDown';
import { MICheckbox } from 'src/components/common/MICheckbox';
import { MIInputLabel } from 'src/components/common/MIInputLabel';
import { ReactComponent as TranslateIcon } from 'src/images/general/translate.svg';
import { ReactComponent as MapPointIcon } from 'src/images/general/map-point.svg';
import { getDropDownLabelByValue } from 'src/utils/builder';
import { bidStrategyOptions } from './data';
import { BID_STRATEGY, SLIDER_SIZE, TEXT_INPUT_SIZE } from 'src/utils/consts';
import { isEmpty } from 'lodash';

type Props = {
  isSkag?: boolean;
  campaign: CampaignType;
};

export const CampaignSettings = ({ isSkag = false, campaign }: Props) => {
  const dispatch = useDispatch();
  const [settings, setSettings] = useState<CampaignType>(campaign);
  const [isAdvancedOpen, setIsAdvancedOpen] = useState<boolean>(false);
  const [isPortfolioInputEnabled, setIsPortfolioInputEnabled] =
    useState<boolean>(!isEmpty(campaign.bidStrategyPortfolioName));
  const eligibleStrategies = [
    BID_STRATEGY.TARGET_CPA,
    BID_STRATEGY.TARGET_ROAS,
  ];
  const shouldShowTargetGoalInput = eligibleStrategies.includes(
    campaign.bidStrategyType
  );

  const onChange = ({ id, value }: InputValue) => {
    let updatedSettings = settings;
    updatedSettings[id] = value;
    setSettings(updatedSettings);
    dispatch(isSkag ? setSkagSettings(settings) : setStagSettings(settings));
  };

  const onCheckboxChange = (id: string) => {
    let updatedSettings = settings;
    updatedSettings[id] = !updatedSettings[id];
    setSettings(updatedSettings);
    dispatch(isSkag ? setSkagSettings(settings) : setStagSettings(settings));
  };

  const onDropDownChange = (data: any) => {
    let updatedSettings = settings;
    updatedSettings.bidStrategyType = data.value;
    setSettings(updatedSettings);
    dispatch(isSkag ? setSkagSettings(settings) : setStagSettings(settings));
  };

  const onPortfolioSliderChange = (value: boolean) => {
    let updatedSettings = settings;
    if (!value) {
      updatedSettings.bidStrategyPortfolioName = '';
    } else {
      if (updatedSettings.bidStrategyType === BID_STRATEGY.MANUAL_CPC) {
        updatedSettings.bidStrategyType = BID_STRATEGY.MAXIMIZE;
      }
    }
    setSettings(updatedSettings);
    dispatch(isSkag ? setSkagSettings(settings) : setStagSettings(settings));
    setIsPortfolioInputEnabled(!isPortfolioInputEnabled);
  };

  const keyPressCheck = (event: React.KeyboardEvent) => {
    return (event.key === 'e' || event.key === '-') && event.preventDefault();
  };

  useMemo(() => {
    if (!shouldShowTargetGoalInput && !isEmpty(settings.bidTargetGoal)) {
      let updatedSettings = settings;
      updatedSettings.bidTargetGoal = null;
      setSettings(updatedSettings);
      dispatch(isSkag ? setSkagSettings(settings) : setStagSettings(settings));
    }
  }, [isSkag, dispatch, settings, shouldShowTargetGoalInput]);

  return (
    <PageContanier>
      <PageTitle>Settings</PageTitle>
      <SettingsContainer>
        <InputsContainer>
          <MITextInput
            label='Campaign name'
            placeholder='Type here...'
            id='name'
            value={settings.name}
            onChange={onChange}
            required
            transparent={false}
          />
          <MITextInput
            label='Campaign budget'
            placeholder='Type here...'
            id='budget'
            min={0}
            onKeyPress={keyPressCheck}
            type={INPUT_TYPE.NUMBER}
            value={settings.budget}
            onChange={onChange}
            required
            transparent={false}
          />
        </InputsContainer>
        <Title>Match types</Title>
        <LineContainer>
          <CheckboxContainer>
            <MICheckbox
              positiveLabel='Exact'
              negativeLabel='Exact'
              value={settings.exact}
              onChange={() => onCheckboxChange('exact')}
              transparent={false}
            />
          </CheckboxContainer>
          <CheckboxContainer>
            <MICheckbox
              positiveLabel='Phrase'
              negativeLabel='Phrase'
              value={settings.phrase}
              onChange={() => onCheckboxChange('phrase')}
              transparent={false}
            />
          </CheckboxContainer>
          <CheckboxContainer>
            <MICheckbox
              positiveLabel='Broad'
              negativeLabel='Broad'
              value={settings.broad}
              onChange={() => onCheckboxChange('broad')}
              transparent={false}
            />
          </CheckboxContainer>
        </LineContainer>
        <LineContainer>
          <Language>
            <TranslateIcon />
            English
          </Language>
          <Country>
            <MapPointIcon />
            United States
          </Country>
        </LineContainer>
        {!isAdvancedOpen && (
          <SettingsLine>
            <MISlider
              value={isAdvancedOpen}
              onChange={() => setIsAdvancedOpen(!isAdvancedOpen)}
            />
            <AdvancedText isOpen={false}>Advanced Settings</AdvancedText>
          </SettingsLine>
        )}
        {isAdvancedOpen && (
          <>
            <InputsContainer>
              <div>
                <MIInputLabel
                  label={'Bid Strategy'}
                  required={true}
                  errorMessage={null}
                />
                <MIDropDown
                  value={settings.bidStrategyType}
                  label={getDropDownLabelByValue(
                    bidStrategyOptions,
                    settings.bidStrategyType
                  )}
                  options={
                    isPortfolioInputEnabled
                      ? bidStrategyOptions.slice(1)
                      : bidStrategyOptions
                  }
                  onChange={onDropDownChange}
                  responsiveWidth={false}
                  transparent={false}
                />
              </div>
              {shouldShowTargetGoalInput && (
                <MITextInput
                  label='Goal value'
                  min={0}
                  max={1000}
                  type={INPUT_TYPE.NUMBER}
                  size={TEXT_INPUT_SIZE.INLINE}
                  placeholder='Type here...'
                  id='bidTargetGoal'
                  value={settings.bidTargetGoal}
                  onChange={onChange}
                  required
                  transparent={false}
                />
              )}
            </InputsContainer>
            <LineContainer>
              <ShortLine>
                <MITextInput
                  label='Portfolio Bid Strategy'
                  placeholder='Type name here...'
                  id='bidStrategyPortfolioName'
                  value={settings.bidStrategyPortfolioName}
                  onChange={onChange}
                  transparent={false}
                  withSlider
                  sliderSize={SLIDER_SIZE.TINY}
                  disabled={!isPortfolioInputEnabled}
                  onSliderChange={onPortfolioSliderChange}
                />
              </ShortLine>
            </LineContainer>
            <LineContainer>
              <MICheckbox
                positiveLabel='Create different ad groups for each match type'
                negativeLabel='Create different ad groups for each match type'
                value={settings.differentGroup}
                onChange={() => onCheckboxChange('differentGroup')}
                transparent={false}
              />
            </LineContainer>
            <Notice>
              We don't usually recommend to have different ad groups for each
              match type
            </Notice>
            <LineContainer>
              <MICheckbox
                positiveLabel='Generate negative keywords'
                negativeLabel='Generate negative keywords'
                value={settings.negativePhrase}
                onChange={() => onCheckboxChange('negativePhrase')}
                transparent={false}
              />
            </LineContainer>
            <Notice>Avoid that your ad groups compete with each other</Notice>
            <SettingsLine>
              <MISlider
                value={isAdvancedOpen}
                onChange={() => setIsAdvancedOpen(!isAdvancedOpen)}
              />
              <AdvancedText isOpen>Advanced Settings</AdvancedText>
            </SettingsLine>
          </>
        )}
      </SettingsContainer>
    </PageContanier>
  );
};

const SettingsContainer = styled.div`
  display: flex;
  width: 48rem;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacing[10]}
    ${({ theme }) => theme.spacing[16]};
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: ${({ theme }) => theme.radius.xl};
  border: 0.1rem solid ${({ theme }) => theme.colors.blue[400]};
  background-color: ${({ theme }) => theme.colors.blue[700]};

  > div:nth-child(4) {
    margin: ${({ theme }) => theme.spacing[5]} 0
      ${({ theme }) => theme.spacing[5]} 0;
  }

  > div:nth-child(7) {
    margin: 0 0 ${({ theme }) => theme.spacing[5]} 0;
  }

  > div:nth-child(9) {
    margin: 0 0 ${({ theme }) => theme.spacing[5]} 0;
  }
`;

const PageContanier = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const PageTitle = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing[5]};
  ${({ theme }) => theme.text.fontType.h2};
`;

const Title = styled.div`
  width: 100%;
  margin-bottom: 0.8rem;
  font-size: 1.2rem;
  font-weight: ${({ theme }) => theme.text.weight.semi};
  letter-spacing: 0.02rem;
  line-height: 1.8rem;
  color: ${({ theme }) => theme.colors.grey[200]};
`;

const Notice = styled(Title)`
  margin-bottom: 0;
`;

const CheckboxContainer = styled.div`
  margin-right: ${({ theme }) => theme.spacing[6]};
`;

const LineContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

const ShortLine = styled.div`
  width: 50%;
  display: flex;
  margin-top: ${({ theme }) => theme.spacing[5]};
`;

const SettingsLine = styled(LineContainer)`
  justify-content: end;

  > div:nth-child(1) {
    margin-right: ${({ theme }) => theme.spacing[2]};
  }
`;

const AdvancedText = styled.div<{ isOpen?: boolean }>`
  color: ${({ theme, isOpen }) =>
    isOpen ? theme.colors.blue[100] : theme.colors.grey[100]};
  ${({ theme }) => theme.text.fontType.body3}
`;

const InputsContainer = styled.div`
  width: 100%;
  display: flex;

  > div:nth-child(1) {
    margin: 0 ${({ theme }) => theme.spacing[5]} 0 0;
  }
`;

const Language = styled.p`
  display: flex;
  align-items: center;
  margin: 0;
  column-gap: ${({ theme }) => theme.spacing[1]};
  color: ${({ theme }) => theme.colors.grey[200]};
  ${({ theme }) => theme.text.fontType.body4}
`;

const Country = styled(Language)`
  padding-left: ${({ theme }) => theme.spacing[8]};
`;
