import { useState, useEffect, useCallback } from 'react';
import styled, { css } from 'styled-components';
import CurrentIcon from 'src/images/general/current-step-icon.svg';

type Props = {
  label: string;
  currentStep: number;
  isZeroStep: boolean;
  isLastStep: boolean;
  step: number;
  changeStep: (page: number) => void;
};

export const CommonStepLayoutItem = ({
  label,
  currentStep,
  isZeroStep,
  isLastStep,
  step,
  changeStep,
}: Props) => {
  const [isPrevStep, setIsPrevStep] = useState<boolean>(false);
  const isCurrent: boolean = currentStep === step;

  useEffect(() => {
    setIsPrevStep(isCurrent || step < currentStep);
  }, [currentStep, step, isCurrent]);

  const handleClick = useCallback(() => {
    changeStep(step);
  }, [changeStep, step]);

  if (isZeroStep) {
    return <Step>{label}</Step>;
  }

  if (isLastStep) {
    return (
      <LastStepContainer>
        <LastStepSeparator />
        <LastStep>{label}</LastStep>
      </LastStepContainer>
    );
  }

  if (isCurrent && !isLastStep) {
    return (
      <CurrentStep>
        <CurrentStepIcon src={CurrentIcon} />
        {label}
      </CurrentStep>
    );
  }

  if (isPrevStep && !isCurrent) {
    return (
      <Step onClick={handleClick}>
        <CurrentStepIcon src={CurrentIcon} />
        {label}
      </Step>
    );
  }

  return (
    <EmptyStep>
      <CurrentStepIcon src={CurrentIcon} isHidden />
      {label}
    </EmptyStep>
  );
};

CommonStepLayoutItem.defaultProps = {
  currentStep: -1,
  isZeroStep: false,
  isLastStep: false,
  step: -1,
  changeStep: () => {},
};

const StepStyles = css`
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  border-radius: ${(props) => props.theme.radius.lg};
  height: 4.2rem;
  padding: 0 ${(props) => props.theme.spacing[4]};
  margin-right: ${(props) => props.theme.spacing[1]};
  ${(props) => props.theme.text.fontType.body3};
`;

const Step = styled.div`
  color: ${(props) => props.theme.colors.grey[100]};
  background-color: ${(props) => props.theme.colors.blue[400]};
  &:hover {
    cursor: pointer;
  }
  ${StepStyles};
`;

const CurrentStep = styled.div`
  color: ${(props) => props.theme.colors.blue[100]};
  background-color: ${(props) => props.theme.colors.blue[600]};
  ${StepStyles};
`;

const CurrentStepIcon = styled.img<{ isHidden?: boolean }>`
  margin-right: ${(props) => props.theme.spacing[2]};
  visibility: ${(props) => (props.isHidden ? 'hidden' : 'visible')};
`;

const LastStep = styled.div`
  height: 100%;
  color: ${(props) => props.theme.colors.blue[400]};
  border: 0.2rem dashed ${(props) => props.theme.colors.blue[400]};
  ${StepStyles};
  margin-right: 0;
  height: 3.8rem;
`;

const LastStepContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

const LastStepSeparator = styled.div`
  width: 100%;
  height: 0.1rem;
  margin: 0 ${(props) => props.theme.spacing[5]};
  background-color: ${(props) => props.theme.colors.blue[400]};
`;

const EmptyStep = styled.div`
  color: ${(props) => props.theme.colors.white};
  border: 0.2rem dashed ${(props) => props.theme.colors.blue[400]};
  ${StepStyles};
  height: 3.8rem;
`;
