import { useState } from 'react';
import { v4 as uuid } from 'uuid';
import styled from 'styled-components';
import uniqWith from 'lodash/uniqWith';
import isEmpty from 'lodash/isEmpty';
import KeywordsIcon from 'src/images/general/keywords-icon.svg';
import { MIFormCounter } from 'src/components/common/MIFormCounter';
import { AdGroupType, CampaignType } from 'src/utils/types';
import { useDispatch } from 'react-redux';
import { setSkagKeywords } from 'src/redux/skagCompaign/actions';
import { fillGroupWithData } from 'src/utils/builder';

type Props = {
  campaign: CampaignType;
};

export const SkagBuilder = ({ campaign }: Props) => {
  const dispatch = useDispatch();
  const defaultKeywords = isEmpty(campaign.keywordsList)
    ? ''
    : campaign.keywordsList.join('\r\n');
  const [keywords, setKeywords] = useState(defaultKeywords);
  const [keywordsCount, setKeywordsCount] = useState<number>(
    campaign.keywordsList.length
  );

  const onChange = (event) => {
    const { adGroupList, templates } = campaign;
    const uniqKeywords: string[] = uniqWith(
      event.target.value
        .split('\n')
        .filter((el) => !isEmpty(el))
        .map((el) => el.trim()),
      (a, b) => a.toLowerCase() === b.toLowerCase()
    );
    const updatedGroups: AdGroupType[] = [];

    uniqKeywords.forEach((keyword) => {
      const groupDetails = adGroupList.find(
        (group) => group.adGroup === keyword
      );
      if (groupDetails) {
        updatedGroups.push(groupDetails);
      } else {
        const adGroupId = uuid();
        const data = fillGroupWithData(templates, adGroupId);

        updatedGroups.push({
          ...data,
          id: adGroupId,
          adGroup: keyword,
          keywords: [keyword],
          negatives: [],
        });
      }
    });
    dispatch(setSkagKeywords(uniqKeywords, updatedGroups));
    setKeywords(event.target.value);
    setKeywordsCount(uniqKeywords.length);
  };

  return (
    <Container>
      <Title>
        Enter <Bold>Keywords</Bold>
      </Title>
      <KeywordsContainer>
        Keywords
        <KeywordsInput value={keywords} onChange={onChange} />
        <CounterContainer>
          <MIFormCounter
            icon={KeywordsIcon}
            count={keywordsCount}
            label='keywords'
          />
        </CounterContainer>
      </KeywordsContainer>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const Title = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing[6]};
  ${({ theme }) => theme.text.fontType.h1};
`;

const Bold = styled.span`
  font-weight: ${({ theme }) => theme.text.weight.bold};
`;

const KeywordsContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  ${({ theme }) => theme.text.fontType.h6};
  font-weight: ${({ theme }) => theme.text.weight.light};
`;

const KeywordsInput = styled.textarea`
  width: 70rem;
  height: 30rem;
  margin: ${({ theme }) => theme.spacing[5]} 0;
  padding: ${({ theme }) => theme.spacing[2]} ${({ theme }) => theme.spacing[5]};
  resize: none;
  border-radius: ${({ theme }) => theme.radius.xl};
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.black[100]};
  border: 0.1rem solid ${({ theme }) => theme.colors.blue[400]};
  ${({ theme }) => theme.text.fontType.body2};
  background-attachment: local;

  &:focus {
    outline: none;
  }

  &::-webkit-scrollbar {
    width: 0.6rem;
    height: 10rem;
  }

  &::-webkit-scrollbar-track {
    border-radius: ${({ theme }) => theme.radius.md};
    margin-top: ${({ theme }) => theme.spacing[3]};
    margin-bottom: ${({ theme }) => theme.spacing[3]};
    background-color: ${({ theme }) => theme.colors.white};
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.blue.translucent};
    border-radius: ${({ theme }) => theme.radius.md};
  }
`;

const CounterContainer = styled.div`
  position: absolute;
  right: 2rem;
  bottom: 3.5rem;
`;
