import styled from 'styled-components';
import { MIButton } from 'src/components/common/MIButton';
import { BUTTON_VARIANT } from 'src/utils/consts';
import { WizardOrLine } from 'src/components/layout/WizardElements';
import Arrow from 'src/images/general/black-arrow-icon.svg';

type Props = {
  importToGoogle: () => void;
  onPrev: () => void;
  upgradePlan: () => void;
  loading: boolean;
};

export const ImportToGoogle = ({
  importToGoogle,
  onPrev,
  upgradePlan,
  loading,
}: Props) => {
  return (
    <Container>
      <Header>
        <ArrowIcon src={Arrow} onClick={onPrev} />
        <Title>
          Import to <Bold>Google Ads</Bold>
        </Title>
      </Header>
      <MIButton
        label='Upgrade Plan'
        onClick={upgradePlan}
        variant={BUTTON_VARIANT.PRIMARY}
        fullWidth
      />
      <WizardOrLine />
      <MIButton
        label='First 5 Free Ads'
        isProcessing={loading}
        onClick={importToGoogle}
        variant={BUTTON_VARIANT.PRIMARY}
        fullWidth
      />
      <Description>Import entire campaign with subscription plan</Description>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const Header = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  width: -webkit-fill-available;
  margin-bottom: ${(props) => props.theme.spacing[10]};
`;

const ArrowIcon = styled.img`
  width: 2.4rem;
  height: 2.4rem;
  cursor: pointer;
`;

const Title = styled.span`
  margin-left: ${(props) => props.theme.spacing[16]};
  ${(props) => props.theme.text.fontType.h5};
`;

const Bold = styled.span`
  font-weight: ${(props) => props.theme.text.weight.bold};
`;

const Description = styled.div`
  margin-top: ${(props) => props.theme.spacing[4]};
  margin-bottom: ${(props) => props.theme.spacing[4]};
  color: ${(props) => props.theme.colors.grey[200]};
  ${(props) => props.theme.text.fontType.body3};
`;
