import { useSelector } from 'react-redux';
import { getIsLoggedIn } from 'src/redux/user/selectors';
import { EnterCode } from './components/EnterCode';
import { EnterToSystem } from './components/EnterToSystem';

export const RedemptionPage = () => {
  const isLoggedIn = useSelector(getIsLoggedIn);

  return isLoggedIn ? <EnterCode /> : <EnterToSystem />
};
