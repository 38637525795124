import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import SkagIcon from 'src/images/general/skag-icon.png';
import StagIcon from 'src/images/general/stag-icon.svg';
import AdIcon from 'src/images/general/ad-icon.svg';
import { REGEX, USER_PLAN } from 'src/utils/consts';
import {
  AdBuilderType,
  AdType,
  AdGroupType,
  OptionType,
  TemplatesType,
  CampaignType,
  CampaignReviewTableType,
  UserDetailsType,
  AdsTypes,
} from './types';
import { CampaignDetailsType } from 'src/redux/campaigns/types';

export const extensions = [AdType.CALLOUT, AdType.SNIPPET, AdType.SITELINK];

const buttonTitle = (user: UserDetailsType | null) => {
  return !user ? 'BUILD' : 'NEW CAMPAIGN';
};

const checkAbilityToCreation = (
  user: UserDetailsType | null,
  campaigns: CampaignDetailsType[]
) => {
  if (user?.plan === USER_PLAN.FREE && campaigns.length) {
    return false;
  }
  return true;
};

export const calculateBuilderItemDescription = (
  type: AdBuilderType,
  user: UserDetailsType | null,
  campaigns: CampaignDetailsType[]
) => {
  let description;
  if (type === AdBuilderType.SKAG) {
    return (description = {
      title: 'SKAG',
      subtitle:
        'Single Keyword Ad Group - ad groups that contain just one keyword in them.',
      cta: buttonTitle(user),
      canCreateNewCampaign: checkAbilityToCreation(user, campaigns),
      icon: SkagIcon,
    });
  }

  if (type === AdBuilderType.STAG) {
    return (description = {
      title: 'STAG',
      subtitle:
        'Single Theme Ad Group — ad groups that contain multiple keywords in them with the same theme.',
      cta: buttonTitle(user),
      canCreateNewCampaign: checkAbilityToCreation(user, campaigns),
      icon: StagIcon,
    });
  }

  if (type === AdBuilderType.AD) {
    return (description = {
      title: 'Ad & extension',
      subtitle: 'Create ads and extensions for existing campaigns.',
      cta: 'BUILD',
      icon: AdIcon,
    });
  }

  return description;
};

export const calculateBuilderItemBackground = (type: AdBuilderType) => {
  let background;
  if (type === AdBuilderType.SKAG) {
    return (background = '#EDFCFE');
  }

  if (type === AdBuilderType.STAG) {
    return (background = '#F7F5FF');
  }

  if (type === AdBuilderType.AD) {
    return (background = '#FEF2EF');
  }

  return background;
};

export const calculateAdGroupNamesByType = (
  type: AdType,
  adGroupList: AdGroupType[]
) => {
  let names: string[] = [];

  adGroupList.forEach(
    (adGroup) => !isEmpty(adGroup[type]) && names.push(adGroup.adGroup)
  );

  return names;
};

const groupNameInCamelCase = (groupName: string[]) => {
  return groupName.reduce(
    (currentValue: string, item: string, index: number) =>
      (currentValue += `${
        item.charAt(0).toUpperCase() + item.slice(1).toLowerCase()
      }${index + 1 < groupName.length ? ' ' : ''}`),
    ''
  );
};

const capitalizeFirstLetter = (groupName: string[]) => {
  const firstLetter = groupName[0].charAt(0);
  groupName[0] = firstLetter.toUpperCase() + groupName[0].slice(1);
  return groupName.join(' ');
};

export const replaceTemplatesDependOnTemplateType = (
  forms: string,
  groupName: string
) => {
  const cutedGroupName = groupName.split(' ');
  forms = forms.replaceAll('[keyword]', groupName.toLowerCase());

  forms = forms.replaceAll('[KEYWORD]', groupName.toUpperCase());

  forms = forms.replaceAll('[Keyword]', capitalizeFirstLetter(cutedGroupName));

  forms = forms.replaceAll('[KeyWord]', groupNameInCamelCase(cutedGroupName));

  return forms;
};

export const generateSkagKeywords = (
  group: AdGroupType,
  exact: boolean,
  broad: boolean,
  phrase: boolean
) => {
  let keywordsArray: OptionType[] = [];
  keywordsArray = [
    exact
      ? {
          label: `[${group.adGroup}]`,
          value: `[${group.adGroup}]`,
        }
      : [],
    broad ? { label: `${group.adGroup}`, value: `${group.adGroup}` } : [],
    phrase
      ? {
          label: `"${group.adGroup}"`,
          value: `"${group.adGroup}"`,
        }
      : [],
  ].flat();
  return keywordsArray;
};

export const generateStagKeywords = (
  group: AdGroupType,
  exact: boolean,
  broad: boolean,
  phrase: boolean
) => {
  let keywordsArray: OptionType[] = [];
  keywordsArray = group.keywords.reduce(
    (currentValue: OptionType[], keyword: string) => {
      const data = [
        exact ? { label: `[${keyword}]`, value: `[${keyword}]` } : [],
        broad ? { label: `${keyword}`, value: `${keyword}` } : [],
        phrase ? { label: `"${keyword}"`, value: `"${keyword}"` } : [],
      ].flat();

      currentValue = [...currentValue, ...data];
      return currentValue;
    },
    []
  );
  return keywordsArray;
};

const withFilters = (
  filterByKeywords: string[],
  filterByNegatives: string[]
) => {
  return filterByKeywords.length || filterByNegatives.length;
};

const filteredByKeywords = (
  keywords: OptionType[],
  filterByKeywords: string[]
) => {
  return !keywords.filter((keyword) =>
    filterByKeywords.some((value) => keyword.value === value)
  ).length;
};

const filteredByNegatives = (
  negatives: OptionType[],
  filterByNegatives: string[]
) => {
  return (
    negatives.length &&
    !negatives.filter((negative) =>
      filterByNegatives.some((value) => negative.value === value)
    ).length
  );
};

export const shouldShowAd = (
  keywords: OptionType[],
  negatives: OptionType[],
  filterByKeywords: string[],
  filterByNegatives: string[],
  filterByAdType: string,
  groupType: string
) => {
  if (
    withFilters(filterByKeywords, filterByNegatives) &&
    (filteredByKeywords(keywords, filterByKeywords) ||
      filteredByNegatives(negatives, filterByNegatives))
  ) {
    return false;
  }

  return filterByAdType === AdType.ALL || filterByAdType === groupType;
};

export const generateNegatives = (adGroupList: AdGroupType[]) => {
  return adGroupList.reduce((currentValue: OptionType[], item: AdGroupType) => {
    return [...currentValue, { label: item.adGroup, value: item.adGroup }];
  }, []);
};

export const removeExtensions = (types: AdType[]) => {
  return types.filter((type) => !extensions.includes(type));
};

export const fillGroupWithData = (
  templates: TemplatesType,
  adGroupId: string
) => {
  const adsTypes = Object.values(AdType).slice(1);
  const typesWithoutExtenisons = removeExtensions(adsTypes);

  return typesWithoutExtenisons.reduce((currentValue, item) => {
    return {
      ...currentValue,
      [item]: checkIsOnlyTemplate(item)
        ? []
        : [
            ...templates[item].map((data) => {
              return { ...data, adGroupId };
            }),
          ],
    };
  }, {} as AdGroupType);
};

export const formCampaignReviewDataTable = (
  campaign: CampaignType
): CampaignReviewTableType[] => {
  return [
    {
      entity: 'Campaigns',
      added: 1,
    },
    {
      entity: 'Ad Groups',
      added: campaign.adGroupList.length,
    },
    {
      entity: 'Keywords',
      added: campaign.keywordsList.length,
    },
    {
      entity: 'Responsive Search Ads',
      added: campaign.adGroupList.filter((item) => item.searchExt.length)
        .length,
    },
    {
      entity: 'Expanded Text Ads',
      added: campaign.adGroupList.filter((item) => item.expTextAdExt.length)
        .length,
    },
    {
      entity: 'Call Only Ads',
      added: campaign.adGroupList.filter((item) => item.callOnlyExt.length)
        .length,
    },
    {
      entity: 'Snippet Extensions',
      added: campaign.templates.snippetExt.length,
    },
    {
      entity: 'Callout Extensions',
      added: campaign.templates.callOutExt.length,
    },
    {
      entity: 'Sitelink Extensions',
      added: campaign.templates.sitelinkExt.length,
    },
  ];
};

export const conversionDataTable = [
  {
    entity: 'Conversion',
    added: 1,
  },
];

export const conversionTableResults = () => {
  return [
    {
      Header: 'Type',
      accessor: 'type',
    },
    {
      Header: 'Page format',
      accessor: 'page_format',
    },
    {
      Header: 'Global site tag',
      accessor: 'global_site_tag',
    },
    {
      Header: 'Event snippet',
      accessor: 'event_snippet',
    },
  ];
};

export const errorsTableColumns = () => {
  return [
    {
      Header: 'Source',
      accessor: 'source',
    },
    {
      Header: 'Message',
      accessor: 'message',
    },
    {
      Header: 'Field name',
      accessor: 'fieldName',
    },
  ];
};

export const campaignsTableColumns = [
  {
    Header: 'Name',
    accessor: 'name',
  },
  {
    Header: 'Type',
    accessor: 'type',
  },
  {
    Header: 'Keywords',
    accessor: 'keywordsCnt',
  },
  {
    Header: 'Ads and ext.',
    accessor: 'adsAndExtCnt',
  },
  {
    Header: 'Last update',
    accessor: 'lastUpdate',
  },
];

export const campaignsTableData = (campaigns: CampaignDetailsType[]) => {
  return campaigns.map((campaign) => {
    return {
      ...campaign,
      type: campaign.stag ? 'Stag' : 'Skag',
      lastUpdate: moment(campaign.updatedAt).fromNow(),
    };
  });
};

export const checkEmptyParentheses = (value: string) => {
  const regExp = /\(\s*\)/g;
  return regExp.test(value); // check empty parentheses
};

const checkBalancedParentheses = (str: string) => {
  let len;
  const reduceRegExp = /(\[\])|(\(\))|(<>)/g;

  // eslint-disable-next-line no-useless-escape
  str = str.replace(/[^\[\]\(\)<>]+/g, '');
  if (str.length === 0) {
    return 1;
  }
  if (str.length % 2) {
    return 0;
  }
  do {
    len = str.length;
    str = str.replace(reduceRegExp, '');
  } while (len !== str.length);
  if (str.length === 0) {
    return 1;
  }
  return 0;
};

export const validateParentheses = (value: string = '') => {
  if (!checkEmptyParentheses(value)) {
    return !!checkBalancedParentheses(value);
  }
  return false;
};

export const getDropDownLabelByValue = (options: OptionType[], val: string) =>
  options.find((opt) => opt.value === val)?.label;

export const getStagAdGroupsIdsWithProhibitedSymbolsInKeywords = (
  adGroupList: AdGroupType[]
) => {
  const res: number[] = [];

  adGroupList.forEach((adGroup) => {
    if (!isEmpty(adGroup.keywords.filter((el) => el.match(REGEX.KEYWORDS)))) {
      res.push(adGroup.id);
    }
  });

  return res;
};

export const checkIsOnlyTemplate = (type: AdType) => {
  return extensions.includes(type);
};

export const getTemplatesToDisplayInReview = (templates: TemplatesType) => {
  const templatesToDisplay = [AdType.CALLOUT, AdType.SNIPPET, AdType.SITELINK];
  const result: AdsTypes[] = [];
  for (let eachTemplate of templatesToDisplay) {
    result.push(...templates[eachTemplate]);
  }
  return result;
};
