import styled from 'styled-components';
import { INPUT_TYPE, MITextInput } from 'src/components/common/MITextInput';
import { MIInputLabel } from 'src/components/common/MIInputLabel';
import { TEXT_INPUT_SIZE } from 'src/utils/consts';
import { REGEX } from 'src/utils/consts';

type Props = {
  label: string;
  min: string;
  max: string;
  filterValue: any;
  setFilter: any;
};

export const MINumberRange = ({
  label,
  min,
  max,
  filterValue,
  setFilter,
}: Props) => {
  return (
    <>
      <MIInputLabel label={label} required={true} />
      <InputsContainer>
        <MITextInput
          type={INPUT_TYPE.NUMBER}
          value={typeof filterValue[0] === 'number' ? filterValue[0] : ''}
          size={TEXT_INPUT_SIZE.SMALL}
          onChange={(e) => {
            const val = e.value;
            if (val === '' || REGEX.DECIMAL_NUMBER.test(val)) {
              setFilter((old = []) => [val ? +val : undefined, old[1]]);
            }
          }}
          placeholder={`Min (${min})`}
          required
        />
        <Separator>to</Separator>
        <MITextInput
          type={INPUT_TYPE.NUMBER}
          value={typeof filterValue[1] === 'number' ? filterValue[1] : ''}
          size={TEXT_INPUT_SIZE.SMALL}
          onChange={(e) => {
            const val = e.value;
            if (val === '' || REGEX.DECIMAL_NUMBER.test(val)) {
              setFilter((old = []) => [old[0], val ? +val : undefined]);
            }
          }}
          placeholder={`Max (${max})`}
          required
        />
      </InputsContainer>
    </>
  );
};

const Separator = styled.div`
  margin: 1rem;
`;

const InputsContainer = styled.div`
  display: flex;
`;
