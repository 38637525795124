export const MonthlyFree = [
  'Create 1 campaign',
  'Create 5 ad groups',
  'Create unlimited keywords',
  'Download 20 Ads',
  'CSV download',
  'Dashboard access to saved campaigns',
  'Direct import to Google Ads',
];

export const MonthlyBuilder = [
  'Create 5 campaigns',
  'Create unlimited ad groups',
  'Create unlimited keywords',
  'Download unlimited ads',
  '5 CSV downloads',
  'Dashboard access to saved campaigns',
  '5 imports to Google Ads',
];

export const MonthlyProBuilder = [
  'Create unlimited campaigns',
  'Create unlimited ad groups',
  'Create unlimited keywords',
  'Download unlimited ads',
  'Unlimited CSV downloads',
  'Dashboard access to saved campaigns',
  'Unlimited imports to Google Ads',
];
