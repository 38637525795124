import { ConversionFormErrors } from 'src/utils/types';

export const validate = (values) => {
  const errors: ConversionFormErrors = {};
  if (!values.category) {
    errors.category = 'This field is required';
  } else if (values.name.length < 1) {
    errors.name = 'This field is required';
  } else if (typeof values.useDefaultValue !== 'number') {
    errors.useDefaultValue = 'Choose one of presented values';
  } else if (values.useDefaultValue === 0 && values.value.length < 1) {
    errors.value = 'This field is requeired';
  } else if (values.useDefaultValue === 1 && values.value.length < 1) {
    errors.value = 'This field is requeired';
  } else if (!values.countingType) {
    errors.countingType = 'Choose one of presented values';
  } else if (!values.clickThroughConversionWindowDays) {
    errors.clickThroughConversionWindowDays = 'This field is required';
  } else if (!values.viewThroughConversionWindowDays) {
    errors.viewThroughConversionWindowDays = 'This field is required';
  } else if (!values.attributionModel) {
    errors.attributionModel = 'This field is required';
  }
  return errors;
};
