import styled from 'styled-components';

type Props = {
  icon: React.ReactNode;
  count: string | number;
  label: string;
};

export const MIFormCounter = ({ icon, count, label }: Props) => {
  return (
    <Counter>
      <CounterIcon src={icon} />
      {`${count} ${label}`}
    </Counter>
  );
};

const Counter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.4rem 1rem;
  border: 0.1rem solid ${(props) => props.theme.colors.blue[300]};
  box-sizing: border-box;
  border-radius: ${(props) => props.theme.radius.lg};
  background-color: ${(props) => props.theme.colors.grey[500]};
  ${(props) => props.theme.text.fontType.body4};
`;

const CounterIcon = styled.img`
  width: 1rem;
  margin: 0 ${(props) => props.theme.spacing[2]} 0
    ${(props) => props.theme.spacing[0.5]};
`;
