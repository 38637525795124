import styled from 'styled-components';
import { notifyServerError } from 'src/services/notifications/notificationService';
import GoogleAdsLogo from 'src/images/general/google-ads-logo.png';
import { GoogleLoginButton } from 'src/components/common/GoogleLoginButton';
import { useCallback, useState } from 'react';
import GoogleService from 'src/services/google/googleService';
import { GOOGLE_ADS_SCOPE } from 'src/utils/consts';
import { GoogleLoginResponse } from 'src/utils/types';

type ConnectGoogleAdsProps = {
  handleConnect: (code: string) => Promise<void> | void;
};

const { REACT_APP_GOOGLE_ADS_CLIENT_ID } = process.env;

export const ConnectGoogleAds = ({ handleConnect }: ConnectGoogleAdsProps) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleGoogleLogin = useCallback(
    async ({ code }: GoogleLoginResponse) => {
      try {
        setIsLoading(true);
        await GoogleService.connectGoogleAdsAccount(code);
        await handleConnect(code);
        setIsLoading(false);
      } catch (error: any) {
        const { message } = error;
        const { error_code } = message;
        setIsLoading(false);
        if (error_code === 'ACCESS_TOKEN_SCOPE_INSUFFICIENT') {
          notifyServerError({ msg: message?.message });
        }
      }
    },
    [handleConnect]
  );

  return (
    <Wrapper>
      <Logo src={GoogleAdsLogo} alt='Google Ads' />
      <Description>
        Connect your ad accounts to import campaigns directly to Google Ads
      </Description>
      <ButtonWrapper>
        <GoogleLoginButton
          scope={GOOGLE_ADS_SCOPE}
          onClick={handleGoogleLogin}
          clientId={REACT_APP_GOOGLE_ADS_CLIENT_ID}
          isLoading={isLoading}
        />
      </ButtonWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const Logo = styled.img`
  max-height: 15rem;
  object-fit: contain;
`;

const Description = styled.p`
  max-width: 80%;
  margin: ${({ theme }) => theme.spacing[8]};
  text-align: center;
  ${({ theme }) => theme.text.fontType.h7};
  font-weight: ${({ theme }) => theme.text.weight.light}; ;
`;

const ButtonWrapper = styled.div`
  max-width: 30rem;
`;
