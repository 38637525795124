import { LOCATIONS } from 'src/pages/locations';
import RestClient from '../../infrastructure/restClient/RestClient';
import { PricingPlansResponse } from './models/pricingPlansModel';
import { SelectPrcingPlanResponse } from './models/selectPricingPlanModel';

export default class PaymentsService {
  public static getPricingPlans = () => {
    return RestClient.get<PricingPlansResponse>('api/subscriptions/config')
      .then((response) => {
        if (!response.is_error && response.content) {
          return response.content;
        }
      })
      .catch((e) => e);
  };

  public static selectPricingPlan = (plan: string) => {
    return RestClient.post<SelectPrcingPlanResponse>(
      'api/subscriptions/create-checkout-session',
      {
        priceId: plan,
        successUrl: LOCATIONS.purchaseComplete, 
        cancelUrl: LOCATIONS.pricing,
      }
    )
      .then((response) => {
        if (!response.is_error && response.content) {
          return response.content;
        }
      })
      .catch((e) => e);
  };

  public static managePricingPlans = () => {
    return RestClient.post<SelectPrcingPlanResponse>(
      'api/subscriptions/customer-portal',
      {}
    )
      .then((response) => {
        if (!response.is_error && response.content) {
          return response.content;
        }
      })
      .catch((e) => e);
  };
}
