import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { MIButton } from 'src/components/common/MIButton';
import { MITextInput } from 'src/components/common/MITextInput';
import RedemptionService from 'src/services/redemption/RedemptionService';
import { Congratulation } from './Congratulation';
import { devices } from 'src/theme/appDevices';
import UserService from 'src/services/users/userService';
import { useDispatch } from 'react-redux';
import { saveUserDetails } from 'src/redux/user/actions';

export const EnterCode = () => {
  const [code, setCode] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isPreocessing, setIsProcessing] = useState(false);
  const [isCodePassed, setIsCodePassed] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (errorMessage) {
      setErrorMessage('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code]);

  const handleSendCode = async () => {
    setIsProcessing(true);
    const result = await RedemptionService.sendCode(code.toUpperCase());

    if (result?.content?.msg === 'Success') {
      const userDetails = await UserService.getUserDetails();
      dispatch(saveUserDetails(userDetails));
      setIsCodePassed(true);
    } else {
      setErrorMessage(result?.content?.message || 'error');
    }
    setIsProcessing(false);
  };

  return !isCodePassed ? (
    <Container>
      <Title>
        Enter <Bold>Redemption Code</Bold>
      </Title>
      <ContentContainer>
        <MITextInput
          value={code}
          errorMessage={errorMessage.length ? errorMessage : null}
          onChange={({ value }) => setCode(value)}
          label='Redemption code'
          placeholder='Type here...'
          required
        />
        <MIButton
          disabled={!code.length}
          label='Submit'
          type='submit'
          onClick={handleSendCode}
          isProcessing={isPreocessing}
          fullWidth
        />
      </ContentContainer>
    </Container>
  ) : (
    <Congratulation />
  );
};

const Container = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 5%;
`;

const Title = styled.span`
  font-size: 2.4rem;
  margin-bottom: 2rem;
  @media ${devices.mobile} {
    font-size: 2rem;
  }
`;

const Bold = styled.span`
  font-weight: ${(props) => props.theme.text.weight.bold};
`;

const ContentContainer = styled.div`
  width: 60rem;
  > div:nth-child(1) {
    margin-bottom: 4rem;
  }
  @media ${devices.mobile} {
    width: 90%;
  }
`;
