import { useEffect, useCallback } from 'react';
import {
  useTable,
  useSortBy,
  useFilters,
  usePagination,
  useFlexLayout,
  useRowSelect,
} from 'react-table';
import styled from 'styled-components';
import { MILoader } from 'src/components/common/MILoader';
import { MIPagination } from 'src/components/common/MIPagination';
import UpArrow from 'src/images/general/up-arrow.svg';
import DownArrow from 'src/images/general/down-arrow.svg';

type TableProps<DataItem extends object> = {
  columns: any[];
  data: DataItem[];
  fetchData?: (data: { pageIndex: number; pageSize: number }) => void;
  isLoading?: boolean;
  className?: string;
  showPagination?: boolean;
  onRowClick?: (id: string) => void;
  onRowSelect?: (rowData: any) => void;
  initialPageSize?: number;
  perPageValues?: number[];
};

const TableLoader = () => {
  return (
    <TableRow>
      <TableItem>
        <Loader context='page' color='primary' />
      </TableItem>
    </TableRow>
  );
};

const TableEmptyState = () => {
  return (
    <TableRow>
      <TableItem>
        <EmptyStateLabel>Oops! No results found</EmptyStateLabel>
      </TableItem>
    </TableRow>
  );
};

export const MITable = <DataItem extends object>({
  className,
  columns,
  data,
  fetchData,
  isLoading,
  showPagination,
  onRowClick,
  onRowSelect,
  initialPageSize = 10,
  perPageValues,
}: TableProps<DataItem>) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    footerGroups,
    pageCount,
    gotoPage,
    setPageSize,
    selectedFlatRows,
    state: { pageIndex, pageSize },
  } = useTable<DataItem>(
    {
      columns,
      data,
      initialState: { pageSize: initialPageSize },
    },
    useFilters,
    useSortBy,
    usePagination,
    useFlexLayout,
    useRowSelect
  );

  useEffect(() => {
    if (fetchData) {
      fetchData({ pageIndex, pageSize });
    }
  }, [fetchData, pageIndex, pageSize]);

  useEffect(() => {
    const data = selectedFlatRows.map((d) => d.original);
    onRowSelect && onRowSelect(data);
  }, [onRowSelect, selectedFlatRows]);

  useEffect(() => {
    gotoPage(0);
  }, [pageSize, gotoPage]);

  const TableDataComponent = onRowClick ? TableDataRow : TableRow;

  const rowClickHandler = useCallback(
    (id) => {
      onRowClick && onRowClick(id);
    },
    [onRowClick]
  );

  const renderTableBody = () => {
    if (isLoading) {
      return <TableLoader />;
    } else if (page.length > 0) {
      return page.map((row) => {
        prepareRow(row);
        return (
          <TableDataComponent
            {...row.getRowProps()}
            onClick={() => rowClickHandler(row.values._id)}
          >
            {row.cells.map((cell) => {
              return (
                <TableItem {...cell.getCellProps()}>
                  {cell.render('Cell')}
                </TableItem>
              );
            })}
          </TableDataComponent>
        );
      });
    } else {
      return <TableEmptyState />;
    }
  };

  return (
    <>
      {headerGroups.map((headerGroup) => (
        <Filters {...headerGroup.getHeaderGroupProps()}>
          {headerGroup.headers.map((column) => (
            <>
              {column.Filter ? (
                <Filter>{column.render('Filter')}</Filter>
              ) : null}
            </>
          ))}
        </Filters>
      ))}
      <TableWrapper {...getTableProps()} className={className}>
        <Header>
          {headerGroups.map((headerGroup) => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <HeaderItem
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                >
                  {column.render('Header')}
                  <ArrowsContainer>
                    {column.isSorted ? (
                      <Arrow src={column.isSortedDesc ? DownArrow : UpArrow} />
                    ) : (
                      <HiddenArrow />
                    )}
                  </ArrowsContainer>
                </HeaderItem>
              ))}
            </TableRow>
          ))}
        </Header>
        <TableBody {...getTableBodyProps()}>{renderTableBody()}</TableBody>
        <TableFooter>
          {footerGroups.map((group) => (
            <FooterRow {...group.getFooterGroupProps()}>
              <FooterItem>
                {showPagination && (
                  <MIPagination
                    pageCount={pageCount}
                    pageSize={pageSize}
                    onPageSizeChange={setPageSize}
                    perPageValues={perPageValues}
                    onPageChange={({ selected }) => gotoPage(selected)}
                    currentPage={pageIndex}
                  />
                )}
              </FooterItem>
            </FooterRow>
          ))}
        </TableFooter>
      </TableWrapper>
    </>
  );
};

MITable.defaultProps = {
  className: '',
  isLoading: false,
  showPagination: true,
};

const TableWrapper = styled.table`
  width: 100%;
  max-height: 70vh;
  overflow: hidden;
  border-spacing: 0;
  border: 0.1rem solid ${({ theme }) => theme.colors.grey[300]};
  border-radius: ${({ theme }) => theme.radius.xl};
  overflow: hidden;
`;

const Header = styled.thead`
  text-align: left;
  background-color: ${({ theme }) => theme.colors.blue[100]};
  color: ${({ theme }) => theme.colors.white};
`;

const TableBody = styled.tbody`
  background-color: ${({ theme }) => theme.colors.white};
`;

const TableFooter = styled.tfoot`
  background-color: ${({ theme }) => theme.colors.blue[100]};
`;

const TableRow = styled.tr`
  word-wrap: break-word;
`;

const TableDataRow = styled.tr`
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.colors.grey[900]};
  }
`;

const FooterRow = styled.tr`
  padding: ${({ theme }) => theme.spacing[4]};
  color: ${({ theme }) => theme.colors.white};
  ${({ theme }) => theme.text.fontType.body3}
`;

const FooterItem = styled.td`
  flex: 1;
`;

const HeaderItem = styled.th`
  display: flex;
  justify-content: flex-start !important;
  align-items: center;
  padding: 1.4rem 3.2rem 1rem;
  ${({ theme }) => theme.text.fontType.body3};
`;

const TableItem = styled.td`
  padding: 1.4rem 3rem 1rem;
  border-bottom: 0.1rem solid ${({ theme }) => theme.colors.blue[300]};
  ${({ theme }) => theme.text.fontType.body2};
`;

const Loader = styled(MILoader)`
  height: 20rem;
`;

const EmptyStateLabel = styled.div`
  height: 20rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.theme.colors.blue[100]};
  ${(props) => props.theme.text.fontType.h5};
`;

const Arrow = styled.img`
  width: 0.9rem;
`;

const HiddenArrow = styled.div`
  height: 1.8rem;
  width: 1.8rem;
`;

const ArrowsContainer = styled.div`
  height: 2rem;
  display: inline-flex;
  padding-top: ${({ theme }) => theme.spacing[0.5]};
  padding-left: ${({ theme }) => theme.spacing[3]};
`;

const Filters = styled.div`
  width: fit-content;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  color: ${(props) => props.theme.colors.grey[200]};
  ${(props) => props.theme.text.fontType.body3};
`;

const Filter = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: ${(props) => props.theme.spacing[10]};
`;
