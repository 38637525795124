import styled from 'styled-components';
import { TABLE_AD_TYPES } from 'src/utils/consts';
import { ExpTextAdExtType } from 'src/utils/types';
import { CardContextMenu } from './CardContextMenu';
import { MIEditedAdLabel } from 'src/components/common/MIEditedAdLabel';
import { checkIsOnlyTemplate } from 'src/utils/builder';

type Props = {
  isSkag: boolean;
  item: ExpTextAdExtType;
  template?: ExpTextAdExtType;
  adGroupNames: string[];
  renderAdGroupNames: (adGroupNames: string[]) => void;
  showEditFormModal: (item: ExpTextAdExtType) => void;
  onlyForm?: boolean;
  paused?: boolean;
};

export const ExpTextCard = ({
  isSkag,
  item,
  template,
  adGroupNames,
  renderAdGroupNames,
  showEditFormModal,
  onlyForm = false,
  paused = false,
}: Props) => {
  return (
    <>
      <AdPreviewContainer paused={paused}>
        <Content>
          <Title>
            {`${item.headlineOne} | ${item.headlineTwo} | ${item.headlineThree}`}
          </Title>
          <Link>{item.finalUrl}</Link>
          <Description>{item.descriptionOne}</Description>
          <Description>{item.descriptionTwo}</Description>
        </Content>
        <CardContextMenu
          isSkag={isSkag}
          onlyForm={onlyForm}
          item={template || item}
          showEditFormModal={showEditFormModal}
        />
        {item.edited && <MIEditedAdLabel />}
      </AdPreviewContainer>
      {!onlyForm && (
        <>
          <TypeContainer>{TABLE_AD_TYPES[item.type]}</TypeContainer>
          <AdGroupsContainer>
            {!checkIsOnlyTemplate(item.type)
              ? renderAdGroupNames(adGroupNames)
              : '-'}
          </AdGroupsContainer>
        </>
      )}
    </>
  );
};

const AdPreviewContainer = styled.div`
  position: relative;
  display: flex;
  height: fit-content;
  align-items: center;
  justify-content: space-between;
  padding: 1.7rem;
  box-sizing: border-box;
  border-radius: ${(props) => props.theme.radius.lg};
  border: 0.1rem solid ${(props) => props.theme.colors.grey[300]};
  width: 56rem;
  opacity: ${({ paused }) => (paused ? 0.6 : 1)};
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 50rem;
`;

const Title = styled.span`
  color: ${(props) => props.theme.colors.blue[200]};
  ${(props) => props.theme.text.fontType.body3};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const Link = styled.span`
  padding: 0.2rem 0;
  color: ${(props) => props.theme.colors.green[200]};
  ${(props) => props.theme.text.fontType.link};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const Description = styled.span`
  color: ${(props) => props.theme.colors.black[100]};
  ${(props) => props.theme.text.fontType.link};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const TypeContainer = styled.span`
  display: flex;
  align-items: flex-start;
  font-size: 1.5rem;
  font-weight: ${(props) => props.theme.text.weight.semi};
  color: ${(props) => props.theme.colors.black[100]};
  max-width: 30rem;
`;

const AdGroupsContainer = styled.span`
  width: 10%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  font-weight: ${(props) => props.theme.text.weight.normal};
`;
