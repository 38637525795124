import styled from 'styled-components';

type Props = {
  value: boolean;
  onClick: () => void;
  transparent?: boolean
};

export const MIRadioButton = ({ value, onClick, transparent = true }: Props) => (
  <Container transparent={transparent} onClick={onClick}>{value && <SelectedIcon />}</Container>
);

const Container = styled.div`
  width: 2rem;
  height: 2rem;
  border-radius: 1rem;
  border: 0.1rem solid ${(props) => props.theme.colors.blue[300]};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: ${({ transparent, theme }) => transparent ? 'transparent' : theme.colors.white};
`;

const SelectedIcon = styled.div`
  width: 1rem;
  height: 1rem;
  border-radius: 1rem;
  background: ${(props) => props.theme.colors.blue[100]};
`;
