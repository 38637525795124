import styled from 'styled-components';
import LogoImage from 'src/images/general/logo.svg';
import { MIModalMessage } from 'src/components/common/MIModalMessage';

export const RegisterSuccessMobileModal = () => {
  return (
    <MIModalMessage
      titleComponent={
        <Container>
          <Logo src={LogoImage} />
          <ModalTitle>Congratulations! You have created an account.</ModalTitle>
          <Subtitle>
            You should have received an email from us. Open the email link using
            a desktop browser to start using Builda.
          </Subtitle>
        </Container>
      }
    />
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const Logo = styled.img`
  width: 12.6rem;
`;

const ModalTitle = styled.span`
  margin: ${({ theme }) => theme.spacing[8]} 0
    ${({ theme }) => theme.spacing[2]} 0;
  ${({ theme }) => theme.text.fontType.h5};
  font-weight: ${({ theme }) => theme.text.weight.semi};
`;

const Subtitle = styled.div`
  display: flex;
  flex-direction: row;
  color: #606473;
  font-size: 2rem;
  line-height: 2.4rem;
  font-weight: ${({ theme }) => theme.text.weight.semi};
`;
