import { useEffect, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { persistor } from 'src/redux/store';
import styled from 'styled-components';
import { useModal } from 'src/helpers/react/useModal';
import { SignInModal } from 'src/components/header/Modal/SignInModal';
import { SignUpModal } from 'src/components/header/Modal/SignUpModal';
import { MIButton } from 'src/components/common/MIButton';
import AuthenticationService from 'src/services/authenticationService';
import LogoImage from 'src/images/general/logo.svg';
import { BUTTON_VARIANT, LINKS, SIGN_UP_FORM_KEYS } from 'src/utils/consts';
import { removeUser, saveUser, saveUserDetails } from 'src/redux/user/actions';
import { getIsLoggedIn, getUserDetails } from 'src/redux/user/selectors';
import { SendResetPasswordLinkModal } from 'src/components/header/Modal/SendResetPasswordLinkModal';
import { USER_PLAN } from 'src/utils/consts';
import { MILink } from 'src/components/common/MILink';
import UserService from 'src/services/users/userService';
import UserMenu from 'src/images/general/user-menu.svg';
import { LOCATIONS } from 'src/pages/locations';
import PaymentsService from 'src/services/payment/paymentsService';
import { notifyServerError } from 'src/services/notifications/notificationService';
import { MIContextMenu } from 'src/components/common/MIContextMenu';
import { deleteCampaigns } from 'src/redux/campaigns/actions';

export const AppHeader = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const isSignedIn = useSelector(getIsLoggedIn);
  const userDetails = useSelector(getUserDetails);
  let signUpForm: any;

  const ref = useRef();

  const goToBuilder = useCallback(
    () => history.push(LOCATIONS.dashboard),
    [history]
  );

  const setIsSignedIn = useCallback(async () => {
    dispatch(saveUser());
    const userDetails = await UserService.getUserDetails();
    dispatch(saveUserDetails(userDetails));
  }, [dispatch]);

  const setIsLoggedOut = useCallback(() => {
    dispatch(removeUser());
    dispatch(deleteCampaigns());
  }, [dispatch]);

  const checkIfUserSignedIn = async () => {
    const result = await AuthenticationService.isSignedIn();
    if (result) {
      setIsSignedIn();
    } else {
      setIsLoggedOut();
    }
  };

  useEffect(() => {
    checkIfUserSignedIn();
  }, [isSignedIn]); // eslint-disable-line react-hooks/exhaustive-deps

  const logOut = async () => {
    await AuthenticationService.signOut();
    setIsLoggedOut();
    persistor.purge();
    if (location.pathname !== LOCATIONS.pricing) {
      history.replace(LOCATIONS.dashboard);
    }
  };

  useEffect(() => {
    if (signUpForm.length) {
      ref.current = signUpForm[SIGN_UP_FORM_KEYS.handler];
    }
  }, [signUpForm]);

  const [SendResetPasswordModal, showSendResetPasswordLinkModal] = useModal(
    SendResetPasswordLinkModal,
    {
      id: 'SendResetPasswordLinkModal',
    }
  );

  const [SignIn, showSignIn] = useModal(SignInModal, {
    id: 'signInModal',
    setIsSignedIn: setIsSignedIn,
    showSendResetPasswordLinkModal: showSendResetPasswordLinkModal,
    onSignUpClick: ref.current,
    onSuccess: goToBuilder,
  });

  signUpForm = useModal(SignUpModal, {
    id: 'signUpModal',
    setIsSignedIn: setIsSignedIn,
    onSignInClick: showSignIn,
    onSuccess: goToBuilder,
  });

  const billingHandler = async () => {
    if (userDetails?.plan === USER_PLAN.FREE) {
      history.push(LOCATIONS.pricing);
      return;
    }
    try {
      const response = await PaymentsService.managePricingPlans();
      window.location = response.redirect;
    } catch (e) {
      notifyServerError({ msg: 'Server error' });
    } finally {
    }
  };

  const contextMenuOptions = [
    { label: 'BILLING', onClick: billingHandler },
    { label: 'SIGN OUT', onClick: logOut },
  ];

  return (
    <>
      {SignIn}
      {signUpForm[SIGN_UP_FORM_KEYS.component]}
      {SendResetPasswordModal}
      <AppHeaderContainer>
        <MILink to={LOCATIONS.dashboard}>
          <Logo src={LogoImage} />
        </MILink>
        <Menu>
          <MenuItem>
            <MILink to={LOCATIONS.dashboard}>Dashboard</MILink>
          </MenuItem>
          <MenuItem>
            <MILink to={LINKS.MAIN}>Product tour</MILink>
          </MenuItem>
          <MenuItem>
            <MILink to={LINKS.MAIN}>Help center</MILink>
          </MenuItem>
          <MenuItem>
            <MILink to={LOCATIONS.pricing}>Pricing</MILink>
          </MenuItem>
        </Menu>
        <Auth>
          {isSignedIn ? (
            <UserMenuContainer>
              <UserCircle src={UserMenu} alt='user-menu' />
              <ShortUserName>
                {userDetails?.fullName
                  ? (userDetails?.fullName[0] || '').toUpperCase()
                  : ''}
              </ShortUserName>
              <MIContextMenu
                id='profile_menu'
                options={
                  userDetails?.plan === USER_PLAN.APPSUMO
                    ? contextMenuOptions.slice(1)
                    : contextMenuOptions
                }
              />
            </UserMenuContainer>
          ) : (
            <>
              <MIButton
                label='SIGN IN'
                variant={BUTTON_VARIANT.SECONDARY}
                onClick={showSignIn}
              />
              <MIButton
                label='SIGN UP'
                onClick={signUpForm[SIGN_UP_FORM_KEYS.handler]}
              />
            </>
          )}
        </Auth>
      </AppHeaderContainer>
    </>
  );
};

const AppHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 10;
  padding: ${(props) => props.theme.spacing[4]}
    ${(props) => props.theme.spacing[28]};
  background-color: ${(props) => props.theme.colors.white};
  box-shadow: ${(props) => props.theme.shadows.card};
`;

const Logo = styled.img`
  width: 12.6rem;
`;

const Menu = styled.div`
  display: flex;
  flex-direction: row;
  color: ${(props) => props.theme.colors.black[100]};
  ${(props) => props.theme.text.fontType.body2};
`;

const MenuItem = styled.div`
  cursor: pointer;
  margin: ${(props) => props.theme.spacing[2]};
`;

const Auth = styled.div`
  display: flex;
  flex-direction: row;

  > button:nth-child(1) {
    margin: 0 ${(props) => props.theme.spacing[1]} 0 0;
  }
`;

const UserMenuContainer = styled.div`
  position: relative;
  cursor: pointer;

  &:hover {
    #profile_menu {
      display: block;
    }
  }
`;

const ShortUserName = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-weight: bold;
  color: ${({ theme }) => theme.colors.white};
  font-size: 3rem;
`;

const UserCircle = styled.img``;
