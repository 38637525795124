import styled from 'styled-components';
import LogoImage from 'src/images/general/logo.svg';

export const UnsupportedResolutionPage = () => (
  <Container>
    <Logo src={LogoImage} />
    <Title>Please use the desktop browser or resize the window</Title>
    <Subtitle>This screen resolution is not supported.</Subtitle>
  </Container>
);

const Container = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: #dae2f9;
  padding: 0 2rem;
`;

const Logo = styled.img`
  width: 14.6rem;
`;

const Title = styled.div`
  display: flex;
  flex-direction: row;
  color: #202226;
  font-size: 4.2rem;
  line-height: 4.8rem;
  font-weight: normal;
  padding: 2rem 0 2rem 0;
`;

const Subtitle = styled.div`
  display: flex;
  flex-direction: row;
  color: #606473;
  font-size: 2rem;
  line-height: 2.4rem;
  font-weight: bold;
`;
