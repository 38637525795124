import styled from 'styled-components';

type Props = {
  children: React.ReactNode;
  bigTopArea: boolean;
};

export const BuilderWrapper = ({ children, bigTopArea }: Props) => {
  return (
    <>
      <BuilderContainer bigTopArea={bigTopArea} />
      <Children bigTopArea={bigTopArea}>{children}</Children>
    </>
  );
};

const Children = styled.div``;

const BuilderContainer = styled.div`
  display: flex;
  height: 4rem;
  padding: ${(props) => props.theme.spacing[props.bigTopArea ? 36 : 12]}
    ${(props) => props.theme.spacing[28]} 20rem
    ${(props) => props.theme.spacing[28]};
  background-color: ${(props) => props.theme.colors.white};
`;
