import {
  SET_STAG_STEP,
  REPLACE_STAG_STEP,
  SET_STAG_CURRENT_FORM_DATA,
} from './actionTypes';

export const setStagStep = (data: number) => {
  return {
    type: SET_STAG_STEP,
    data,
  };
};

export const replaceStagStep = (index: number, newStep: string) => {
  return {
    type: REPLACE_STAG_STEP,
    payload: {
      index,
      newStep,
    },
  };
};

export const setStagCurrentFormData = (currentItem, currentAdTypeDetails) => {
  return {
    type: SET_STAG_CURRENT_FORM_DATA,
    payload: {
      currentItem,
      currentAdTypeDetails,
    },
  };
};
