import { useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import styled from 'styled-components';
import { FieldType } from 'src/utils/types';
import { MIModalMessage } from 'src/components/common/MIModalMessage';
import { MITextInput } from 'src/components/common/MITextInput';
import MIPasswordInput from 'src/components/common/MIPasswordInput';
import { REGEX } from 'src/utils/consts';
import { MIButton } from 'src/components/common/MIButton';
import UserService from 'src/services/users/userService';
import { UserRegistration } from 'src/services/users/models/userRegistration';
import { notifyServerError } from 'src/services/notifications/notificationService';

type Props = {
  dismiss?: (event: React.MouseEvent | null) => void;
  onSignInClick: () => void;
  setIsSignedIn: (value: boolean) => void;
  onSuccess: () => void;
};

export const SignUpModal = ({
  setIsSignedIn,
  onSignInClick,
  dismiss,
  onSuccess,
}: Props) => {
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [fullName, setFullName] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [validationErrors, setValidationErrors] = useState<Record<string, any>>(
    {}
  );

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setValidationErrors({});

    if (!REGEX.EMAIL.test(email)) {
      setValidationErrors({
        email: 'Please enter your email, make sure it’s valid',
      });
      setLoading(false);
      return;
    } else if (isEmpty(fullName)) {
      setValidationErrors({
        fullName: 'Please enter your full name',
      })
      setLoading(false);
      return;
    } else if (isEmpty(password)) {
      setValidationErrors({
        password: 'Please enter your password',
      })
      setLoading(false);
      return;
    };
    const request = { email, fullName, password } as unknown as UserRegistration;

    const response = await UserService.registerUser(request);

    if (response.is_error) {
      setLoading(false);
      notifyServerError({ msg: 'Server error' });
      return;
    }
    setIsSignedIn(true);
    setLoading(false);
    dismiss && dismiss(event);
    onSuccess && onSuccess();
  };

  const onFieldChanged = ({ id, value }: FieldType) => {
    if (id === 'email') {
      setEmail(value);
      setValidationErrors(prevErrors => {
        delete prevErrors['email'];
        return prevErrors;
      });
    }

    if (id === 'password') {
      setPassword(value);
      setValidationErrors(prevErrors => {
        delete prevErrors['password'];
        return prevErrors;
      });
    }

    if (id === 'fullName') {
      setFullName(value);
      setValidationErrors(prevErrors => {
        delete prevErrors['fullName'];
        return prevErrors;
      });
    }
  };

  const signInClickHandler = () => {
    dismiss && dismiss(null);
    onSignInClick();
  };

  return (
    <MIModalMessage
      dismiss={dismiss}
      titleComponent={
        <ModalTitleContainer>
          <ModalTitle>
            Sign <Bold>Up</Bold>
          </ModalTitle>
          <InputsContainer>
            <MITextInput
              id='fullName'
              value={fullName}
              label='Full name'
              autoFocus
              onChange={onFieldChanged}
              autocomplete='name'
              type='name'
              required
              errorMessage={validationErrors.fullName}
              placeholder='Type here...'
              transparent={false}
            />
            <MITextInput
              id='email'
              value={email}
              label='Email'
              onChange={onFieldChanged}
              autocomplete='email'
              type='email'
              required
              errorMessage={validationErrors.email}
              placeholder='Type here...'
              transparent={false}
            />
            <MIPasswordInput
              id='password'
              value={password}
              label='Password'
              required
              errorMessage={validationErrors.password}
              onChange={onFieldChanged}
              placeholder='Type here...'
              transparent={false}
            />
          </InputsContainer>
          <MIButton
            label='Sign Up'
            type='submit'
            onClick={handleSubmit}
            isProcessing={loading}
            fullWidth
          />
          <Separator />
          <Footer>
            Already have an account?{' '}
            <LoginButton onClick={signInClickHandler}>
              {' '}
              Sign in here
            </LoginButton>
          </Footer>
        </ModalTitleContainer>
      }
    />
  );
};

const ModalTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const ModalTitle = styled.span`
  ${(props) => props.theme.text.fontType.h5};
  margin-bottom: ${(props) => props.theme.spacing[2]};
`;

const Bold = styled.span`
  font-weight: ${(props) => props.theme.text.weight.bold};
`;

const InputsContainer = styled.div`
  margin-top: ${(props) => props.theme.spacing[2]};
  margin-bottom: ${(props) => props.theme.spacing[5]};
  width: 100%;
`;

const Separator = styled.div`
  position: absolute;
  width: 50rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey[300]};
  bottom: 9rem;
`;

const Footer = styled.div`
  width: 100%;
  text-align: center;
  margin-top: 5rem;
  font-size: 1.6rem;
`;

const LoginButton = styled.span`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.purple};
`;
