import { AdType, RespSearchAdType } from 'src/utils/types';

export const fullRow = [
  {
    label: 'Description one',
    key: 'descriptionOne',
    maxlength: 90,
  },
  {
    label: 'Description two',
    key: 'descriptionTwo',
    maxlength: 90,
  },
];

export const secondRow = [
  { label: 'Final URL', key: 'finalUrl', required: true },
  { label: 'Path one', key: 'pathOne', maxlength: 15 },
  { label: 'Path two', key: 'pathTwo', maxlength: 15 },
];

export const headlinesOptions = [
  { label: 'Unpinned', value: 'none' },
  { label: 'Position 1', value: 'positionOne' },
  { label: 'Position 2', value: 'positionTwo' },
  { label: 'Position 3', value: 'positionThree' },
];

export const descriptionsOptions = [
  { label: 'Unpinned', value: 'none' },
  { label: 'Position 1', value: 'positionOne' },
  { label: 'Position 2', value: 'positionTwo' },
];

export const defaultDataRespResearch: RespSearchAdType = {
  id: undefined,
  headlines: [
    { id: 1, value: '[Keyword]' },
    { id: 2, value: 'Online Store' },
    { id: 3, value: 'Free Delivery' },
  ],
  pinnedHeadlines: {
    positionOne: [],
    positionTwo: [],
    positionThree: [],
  },
  descriptions: [
    { id: 1, value: 'Buy online keyword' },
    { id: 2, value: `Vast collection of keyword` },
  ],
  pinnedDescriptions: {
    positionOne: [],
    positionTwo: [],
  },
  finalUrl: `https://google.com`,
  pathOne: 'shop',
  pathTwo: 'now',
  adGroupName: 'adGroupName',
  type: AdType.RESPONSIVE,
};

export const descriptions = 'descriptions';
export const headlines = 'headlines';

export const fieldsToCheckBlockedSymbols = [
  'headlines',
  'finalUrl',
  'pathOne',
  'pathTwo',
  'adGroupName',
];
