import styled from 'styled-components';
import { SitelinkFormType } from 'src/utils/types';

type Props = {
  item: SitelinkFormType;
};

export const SitelinkCardPreview = ({ item }: Props) => {
  const { data } = item;
  return (
    <Container>
      {data[0]?.text ? (
        <Title>{`${data[0].text}`}</Title>
      ) : (
        <Title>Sitelink 1</Title>
      )}
      {data[0]?.descriptionOne ? (
        <Description>{`${data[0].descriptionOne} & ${data[0].descriptionTwo}`}</Description>
      ) : (
        <Description>Description 1 & Description 2</Description>
      )}
      {data[1]?.text ? (
        <Title>{`${data[1].text}`}</Title>
      ) : (
        <Title>Sitelink 2</Title>
      )}
      {data[1]?.descriptionOne ? (
        <Description>{`${data[1].descriptionOne} & ${data[1].descriptionTwo}`}</Description>
      ) : (
        <Description>Description 1 & Description 2</Description>
      )}
      {data[2]?.text ? (
        <Title>{`${data[2].text}`}</Title>
      ) : (
        <Title>Sitelink 3</Title>
      )}
      {data[2]?.descriptionOne ? (
        <Description>{`${data[2].descriptionOne} & ${data[2].descriptionTwo}`}</Description>
      ) : (
        <Description>Description 1 & Description 2</Description>
      )}
      {data[3]?.text ? (
        <Title>{`${data[3].text}`}</Title>
      ) : (
        <Title>Sitelink 4</Title>
      )}
      {data[3]?.descriptionOne ? (
        <Description>{`${data[3].descriptionOne} & ${data[3].descriptionTwo}`}</Description>
      ) : (
        <Description>Description 1 & Description 2</Description>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  width: 60rem;
  height: fit-content;
  flex-direction: column;
  padding: 1.7rem;
  box-sizing: border-box;
  border-radius: 0 0 1rem 1rem;
  border: 0.1rem solid ${(props) => props.theme.colors.grey[300]};
  border-top: 0.1rem dashed ${(props) => props.theme.colors.grey[300]};
  background-color: ${(props) => props.theme.colors.grey[500]};
`;

const Title = styled.span`
  color: ${(props) => props.theme.colors.blue[200]};
  ${(props) => props.theme.text.fontType.body3};
  font-weight: ${({ theme }) => theme.text.weight.bold};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const Description = styled.span`
  color: ${(props) => props.theme.colors.black[100]};
  ${(props) => props.theme.text.fontType.link};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: 2rem;
`;
