import * as React from 'react';
import { devices } from 'src/theme/appDevices';
import styled from 'styled-components';

import { TextInputSize } from 'src/utils/types';
import { TEXT_INPUT_SIZE } from 'src/utils/consts';

type Props = {
  size?: TextInputSize;
  errorMessage?: string | null;
  errorMessageIcon?: React.ReactNode;
  notices?: Array<any>;
  align?: string;
  invalid?: boolean;
};

export const MINotices = ({
  size = TEXT_INPUT_SIZE.WIZARD,
  errorMessage,
  notices = [],
  align = 'left',
  errorMessageIcon = null,
  invalid = false,
}: Props) => (
  <Notices>
    {errorMessage && (
      <ErrorNotice size={size}>
        {errorMessageIcon}
        {errorMessage}
      </ErrorNotice>
    )}
    {!errorMessage &&
      notices &&
      notices.map((notice, index) => (
        <Notice key={index} size={size} align={align} invalid={invalid}>
          {typeof notice === 'string' && notice}
          {typeof notice !== 'string' && { notice }}
        </Notice>
      ))}
  </Notices>
);

const Notices = styled.div`
  margin-top: ${({ theme }) => theme.spacing[2]};
`;

const Notice = styled.label<{
  size?: TextInputSize;
  align?: string;
  invalid: boolean;
}>`
  display: block;
  color: ${({ theme, invalid }) => (invalid ? 'red' : theme.colors.grey[600])};
  ${({ theme }) => theme.text.fontType.caption};
  text-align: ${({ align }) => align};

  @media ${devices.mobile}, ${devices.phablet} {
    line-height: 1.4rem;
  }
`;

const ErrorNotice = styled(Notice)`
  color: ${({ theme }) => theme.colors.red[300]};
  display: flex;
  flex-direction: row;
  align-items: center;
`;
