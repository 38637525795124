import { devices } from 'src/theme/appDevices';
import styled from 'styled-components';

export const WizardOrLine = () => (
  <WizardOptionContainer>
    <WizardOptionLine />
    <WizardOptionText>or</WizardOptionText>
    <WizardOptionLine />
  </WizardOptionContainer>
);

export const WizardContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100%;
  box-sizing: border-box;
  flex-direction: column;
  margin: ${({ toTopPosition }) => (toTopPosition ? -34 : -14)}rem
    ${({ theme }) => theme.spacing[32]} ${({ theme }) => theme.spacing[8]}
    ${({ theme }) => theme.spacing[32]};

  @media ${devices.nonDesktop} {
    margin: 0;
  }
`;

const WizardOptionContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  margin: ${({ theme }) => theme.spacing[5]} 0;
  max-width: 45rem;
  align-self: center;
`;

const WizardOptionLine = styled.div`
  height: 0.1rem;
  border-bottom: 0.1rem solid ${({ theme }) => theme.colors.grey[300]};
  flex-grow: 1;
`;

const WizardOptionText = styled.div`
  flex-grow: none;
  margin: 0 ${({ theme }) => theme.spacing[4]} 0
    ${({ theme }) => theme.spacing[4]};
  color: ${({ theme }) => theme.colors.grey[100]};
  ${({ theme }) => theme.text.fontType.body3};
`;

export const WizardInner = styled.div<{ isCenterVert?: boolean }>`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;

  @media ${devices.desktop}, ${devices.tablet} {
    justify-content: ${({ isCenterVert }) =>
      isCenterVert ? 'center' : 'start'};
    width: 90rem;
  }

  @media ${devices.mobile}, ${devices.phablet} {
    justify-content: ${({ isCenterVert }) =>
      isCenterVert ? 'center' : 'start'};
    width: 100%;
  }
`;

export const WizardStepActionsContainer = styled.div`
  align-self: center;
  flex-direction: column;
  display: flex;
  text-align: center;
  margin-top: 3rem;

  @media ${devices.desktop}, ${devices.tablet} {
    min-width: 25%;
  }

  @media ${devices.mobile}, ${devices.phablet} {
    width: 100%;
    position: sticky;
    position: -webkit-sticky;
    bottom: 0;
    margin-bottom: -2rem;
    background-color: ${({ theme }) => theme.colors.white.veryLightGrey};
  }
`;
