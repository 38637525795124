import styled from 'styled-components';
import { MIButton } from 'src/components/common/MIButton';
import { UserDetailsType } from 'src/utils/types';
import { USER_PLAN } from 'src/utils/consts';

type Props = {
  type: string;
  userDetails: UserDetailsType | null;
  title: string;
  subtitle: string;
  amount: string;
  interval: string;
  descriptionItems: string[];
  onClick: () => void;
};

export const PaymentPlan = ({
  type,
  userDetails,
  title,
  subtitle,
  amount,
  interval,
  descriptionItems,
  onClick,
}: Props) => {
  const userPlan = (userDetails?.plan || '').toLowerCase();
  const userPlanInterval = (userDetails?.planInterval || '').toLowerCase();
  const isAppsumo =
    userPlan === USER_PLAN.APPSUMO.toLowerCase() &&
    type === USER_PLAN.BASIC.toLowerCase();

  const isCurrentPlan =
    (userPlan === type && userPlanInterval === interval) ||
    (userPlan === USER_PLAN.FREE.toLowerCase() && userPlan === type) ||
    isAppsumo;

  return (
    <Container>
      <Header>
        <Title>{title}</Title>
        <Subtitle>{subtitle}</Subtitle>
      </Header>
      <Separator />
      <ContentContainer>
        <Content>
          <Amount>{amount}</Amount>
          <Timing>per {interval}</Timing>
          <DescriptionContanter>
            {descriptionItems.map((description, index) => (
              <Description key={index}>
                <Dot>&#8226;</Dot>
                {description}
              </Description>
            ))}
          </DescriptionContanter>
        </Content>
        <MIButton
          disabled={(type === 'free' && userPlan === type) || isAppsumo}
          label={isCurrentPlan ? 'Manage' : 'Get started'}
          type='submit'
          onClick={onClick}
          fullWidth
        />
      </ContentContainer>
      <CurrentPlan>{isCurrentPlan ? '(Current Plan)' : ''}</CurrentPlan>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 0.1rem solid ${(props) => props.theme.colors.blue[300]};
  border-radius: ${(props) => props.theme.radius.xl};
  background-color: ${(props) => props.theme.colors.white};
  width: 26rem;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${(props) => props.theme.spacing[5]}
    ${(props) => props.theme.spacing[6]};
`;

const Title = styled.div`
  ${(props) => props.theme.text.fontType.h6};
`;

const Subtitle = styled.div`
  text-align: center;
  max-width: 19rem;
  height: 4rem;
  margin-top: ${(props) => props.theme.spacing[3]};
  ${(props) => props.theme.text.fontType.body4};
  font-weight: 300;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 21rem;
  height: 100%;
  padding: ${(props) => props.theme.spacing[5]}
    ${(props) => props.theme.spacing[6]};
  ${(props) => props.theme.text.fontType.body3};
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Amount = styled.div`
  ${(props) => props.theme.text.fontType.h5};
  color: ${(props) => props.theme.colors.blue[100]};
  font-weight: ${(props) => props.theme.text.weight.bold};
  font-size: 2.2rem;
`;

const Timing = styled.div`
  ${(props) => props.theme.text.fontType.body2};
  color: ${(props) => props.theme.colors.grey[200]};
  margin-bottom: ${(props) => props.theme.spacing[3]};
`;

const DescriptionContanter = styled.div`
  margin-bottom: ${(props) => props.theme.spacing[5]};
`;

const Description = styled.div`
  width: 100%;
  display: inline-flex;
  ${(props) => props.theme.text.fontType.body3};
  align-items: center;
  margin-bottom: ${(props) => props.theme.spacing[3]};
  font-weight: 300;
`;

const Dot = styled.span`
  ${(props) => props.theme.text.fontType.body2};
  color: ${(props) => props.theme.colors.blue[100]};
  font-weight: ${(props) => props.theme.text.weight.bold};
  margin-right: ${(props) => props.theme.spacing[3]};
  align-self: flex-start;
  font-size: 1.2rem;
`;

const Separator = styled.div`
  width: 100%;
  height: 0.1rem;
  background-color: ${(props) => props.theme.colors.blue[400]};
`;

const CurrentPlan = styled.div`
  height: 3rem;
  margin-bottom: 1rem;
  color: ${(props) => props.theme.colors.grey[200]};
  font-size: 1.4rem;
`;
