import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { AdType, Expandable, AdGroupType, OptionType } from 'src/utils/types';
import { MIDropDown } from 'src/components/common/MIDropDown';
import { useModal } from 'src/helpers/react/useModal';
import { MIModalMessage } from 'src/components/common/MIModalMessage';
import { AdCreatorModalFooter } from '../Modal/AdCreatorModalFooter';
import PlusIcon from 'src/images/general/plus-icon.svg';
import { createSkagAds } from 'src/redux/skagCompaign/actions';
import { createStagAds } from 'src/redux/stagCampaign/actions';
import {
  replaceSkagStep,
  setSkagCurrentFormData,
  setSkagStep,
} from 'src/redux/skagCreationFlow/actions';
import {
  replaceStagStep,
  setStagCurrentFormData,
  setStagStep,
} from 'src/redux/stagCreationFlow/actions';
import { getSkagFlowStep } from 'src/redux/skagCreationFlow/selectors';
import { getStagFlowStep } from 'src/redux/stagCreationFlow/selectors';
import { NONE_VALUE } from 'src/utils/consts';
import { getDataForForm } from '../form/data';

type Props = {
  isSkag: boolean;
  adsCount: number;
  adGroupList: AdGroupType[];
  selectedAdType: AdType;
  selectedAdGroup: string;
  onSelectAdType: (change: Expandable<{ value: string }>) => void;
  onSelectAdGroup: (change: Expandable<{ value: string }>) => void;
  adTypeFilterOptions: OptionType[];
};

export const AdCreatorTableControllers = ({
  isSkag,
  adsCount,
  adGroupList,
  selectedAdType,
  selectedAdGroup,
  onSelectAdType,
  onSelectAdGroup,
  adTypeFilterOptions,
}: Props) => {
  const dispatch = useDispatch();
  const currentStep = useSelector(isSkag ? getSkagFlowStep : getStagFlowStep);
  const adGroupOptions = adGroupList
    .map((el) => el.adGroup)
    .map((el) => ({ label: el, value: el }));
  const extendedAdGroupOptions = [
    { label: NONE_VALUE, value: NONE_VALUE },
  ].concat(adGroupOptions);

  const creationTypes = [
    AdType.EXPANDED,
    AdType.CALL,
    AdType.SNIPPET,
    AdType.RESPONSIVE,
    AdType.CALLOUT,
    AdType.SITELINK,
  ];

  const creationHandler = (type: AdType, data: any, label: string) => {
    if (creationTypes.includes(type)) {
      if (isSkag) {
        dispatch(setSkagCurrentFormData(null, getDataForForm(type)));
        dispatch(replaceSkagStep(3, label));
        dispatch(setSkagStep(currentStep + 1));
      } else {
        dispatch(setStagCurrentFormData(null, getDataForForm(type)));
        dispatch(replaceStagStep(4, label));
        dispatch(setStagStep(currentStep + 1));
      }
    } else {
      dispatch(isSkag ? createSkagAds(type, data) : createStagAds(type, data));
    }
  };

  const [SelectAdGroupModal, showSelectAdGroupModal, , dismiss] = useModal(
    MIModalMessage,
    {
      id: 'selectAdGroup',
      titleComponent: (
        <ModalTitleContainer>
          <ModalTitle>
            Add <Bold>Ad</Bold> Or <Bold>Extension</Bold>
          </ModalTitle>
          <ModalSubtitle>
            Creating ads here will change all ad groups. However in the next
            section you can edit ads individually for each ad group.
          </ModalSubtitle>
        </ModalTitleContainer>
      ),
      footerComponent: <AdCreatorModalFooter createAds={creationHandler} />,
    }
  );

  useEffect(() => {
    if (adsCount === 0) {
      showSelectAdGroupModal();
    } else {
      dismiss();
    }
  }, [adsCount, showSelectAdGroupModal, dismiss]);

  return (
    <Container>
      {SelectAdGroupModal}
      <LeftBlock>
        <ItemContainer>
          <ItemHeader>
            <Text>Ad group preview</Text>
            <Text>
              Total <Bold>{extendedAdGroupOptions.length}</Bold>
            </Text>
          </ItemHeader>
          <MIDropDown
            label={
              selectedAdGroup === NONE_VALUE
                ? 'Choose an ad group'
                : selectedAdGroup
            }
            value={selectedAdGroup}
            options={extendedAdGroupOptions}
            onChange={onSelectAdGroup}
            transparent={false}
          />
        </ItemContainer>
        <ItemContainer>
          <ItemHeader>
            <Text>Ad types</Text>
            <Text>
              Total <Bold>{adTypeFilterOptions.length - 1}</Bold>
            </Text>
          </ItemHeader>
          <MIDropDown
            label='Choose the type'
            value={selectedAdType}
            options={adTypeFilterOptions}
            onChange={onSelectAdType}
            transparent={false}
          />
        </ItemContainer>
      </LeftBlock>
      <RightBlock>
        <CreateAdButton onClick={showSelectAdGroupModal}>
          Add ads or extensions <CreateAdIcon src={PlusIcon} />
        </CreateAdButton>
      </RightBlock>
    </Container>
  );
};

const Container = styled.div`
  width: inherit;
  margin-bottom: 3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${(props) => props.theme.colors.grey[200]};
  ${(props) => props.theme.text.fontType.body3};
`;

const LeftBlock = styled.div`
  display: flex;
`;

const RightBlock = styled.div``;

const CreateAdButton = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: ${(props) => props.theme.spacing[3]}
    ${(props) => props.theme.spacing[4]};
  border-radius: ${(props) => props.theme.radius.lg};
  color: ${(props) => props.theme.colors.blue[200]};
  background-color: ${(props) => props.theme.colors.blue[700]};
  ${(props) => props.theme.text.fontType.body3};

  &:hover {
    box-shadow: ${(props) => props.theme.shadows.button};
  }
`;

const CreateAdIcon = styled.img`
  width: 1.5rem;
  height: 1.5rem;
  margin-left: ${(props) => props.theme.spacing[3]};
`;

const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: ${(props) => props.theme.spacing[10]};
`;

const ItemHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${(props) => props.theme.spacing[2]};
`;

const Text = styled.div``;

const ModalTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: ${(props) => props.theme.spacing[6]};
`;

const ModalTitle = styled.span`
  ${(props) => props.theme.text.fontType.h5};
  font-weight: ${(props) => props.theme.text.weight.normal};
`;

const ModalSubtitle = styled.span`
  margin-top: ${(props) => props.theme.spacing[2]};
  max-width: 50rem;
  text-align: center;
  ${(props) => props.theme.text.fontType.body2};
`;

const Bold = styled.span`
  font-weight: ${(props) => props.theme.text.weight.semi};
`;
