import { useState, useEffect } from 'react';
import { AdBuilderType } from 'src/utils/types';
import { PROGRESS_BAR_STEPS } from 'src/utils/consts';
import { useDispatch } from 'react-redux';

type BuilderSelection = {
  initialStep?: number;
  builderType?: AdBuilderType;
};

export const useBuilderSelection = ({
  initialStep = 0,
  builderType = AdBuilderType.EMPTY,
}: BuilderSelection = {}): {
  currentStep: number;
  progressBarSteps: string[];
  selectedBuilderType: AdBuilderType;
  setCurrentStep: (step: number) => void;
  setSelectedBuilderType: (type: AdBuilderType) => void;
} => {
  const dispatch = useDispatch();
  const [selectedBuilderType, setSelectedBuilderType] =
    useState<AdBuilderType>(builderType);

  const [progressBarSteps, setProgressBarSteps] = useState<string[]>([]);

  const [currentStep, setCurrentStep] = useState<number>(initialStep);

  useEffect(() => {
    if (selectedBuilderType !== AdBuilderType.EMPTY) {
      setProgressBarSteps(PROGRESS_BAR_STEPS[selectedBuilderType]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, selectedBuilderType]);

  return {
    currentStep,
    progressBarSteps,
    selectedBuilderType,
    setCurrentStep,
    setSelectedBuilderType,
  };
};
