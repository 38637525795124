import { replaceTemplatesDependOnTemplateType } from 'src/utils/builder';
import { v4 as uuid } from 'uuid';
import { escapeRegExp } from '../utils/regex';

export const replaceTextReducer = (state, action) => {
  return {
    ...state,
    adGroupList: state.adGroupList.map((group) => {
      if (group.id === action.payload.groupId) {
        let neededAdIndex = group[action.payload.adType].findIndex(
          (item) => item.id === action.payload.adId
        );
        if (neededAdIndex !== -1) {
          let neededAd = group[action.payload.adType][neededAdIndex];

          const searchEscaped = escapeRegExp(action.payload.stringForReplace);
          const regExpression = action.payload.matchWholeWords
            ? `\\b${searchEscaped}\\b`
            : searchEscaped;
          const searchRegExp = new RegExp(
            regExpression,
            `g${action.payload.matchCase ? '' : 'i'}`
          );

          let matchFields: Array<string> = [];
          action.payload.fields.forEach((fieldName) => {
            let dataToParse = JSON.stringify(neededAd[fieldName]); // replace template with group name
            neededAd[fieldName] = JSON.parse(
              replaceTemplatesDependOnTemplateType(dataToParse, group.adGroup)
            );
            if (neededAd[fieldName] && [...neededAd[fieldName].matchAll(searchRegExp)].length > 0) {
              matchFields.push(fieldName);
            }
          });

          if (matchFields.length > 0 && action.payload.keepOriginalAds) {
            neededAd = { ...neededAd };
            neededAd.id = uuid();
            group[action.payload.adType].push(neededAd);
            neededAdIndex = neededAd.id - 1;
          }

          matchFields.forEach((fieldName) => {
            neededAd.edited = true;
            neededAd[fieldName] = neededAd[fieldName].replace(
              searchRegExp,
              action.payload.newString
            );
          });
        }
      }
      return group;
    }),
  };
};

export const setTextReducer = (state, action) => {
  return {
    ...state,
    adGroupList: state.adGroupList.map((group) => {
      if (group.id === action.payload.groupId) {
        const neededAdIndex = group[action.payload.adType].findIndex(
          (item) => item.id === action.payload.adId
        );
        if (neededAdIndex !== -1) {
          let neededAd = group[action.payload.adType][neededAdIndex];
          if (action.payload.field in neededAd) {
            if (action.payload.keepOriginalAds) {
              neededAd = { ...neededAd };
              neededAd.id = uuid();
              group[action.payload.adType].push(neededAd);
            }
            neededAd.edited = true;
            neededAd[action.payload.field] = `${action.payload.value}`;
          }
        }
      }
      return group;
    }),
  };
};

export const appendTextReducer = (state, action) => {
  return {
    ...state,
    adGroupList: state.adGroupList.map((group) => {
      if (group.id === action.payload.groupId) {
        const neededAdIndex = group[action.payload.adType].findIndex(
          (item) => item.id === action.payload.adId
        );
        if (neededAdIndex !== -1) {
          let neededAd = group[action.payload.adType][neededAdIndex];
          if (action.payload.field in neededAd) {
            if (action.payload.keepOriginalAds) {
              neededAd = { ...neededAd };
              neededAd.id = uuid();
              group[action.payload.adType].push(neededAd);
            }
            neededAd.edited = true;
            neededAd[action.payload.field] = action.payload.before
              ? `${action.payload.value}${
                  group[action.payload.adType][neededAdIndex][
                    action.payload.field
                  ]
                }`
              : `${
                  group[action.payload.adType][neededAdIndex][
                    action.payload.field
                  ]
                }${action.payload.value}`;
          }
        }
      }
      return group;
    }),
  };
};
