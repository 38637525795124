import React, { useMemo } from 'react';
import styled, { css } from 'styled-components';
import isNil from 'lodash/isNil';
import PrevIcon from 'src/images/general/prev-icon.svg';
import NextIcon from 'src/images/general/next-icon.svg';
import { CommonStepLayoutItem } from './CommonStepLayoutItem';

type Props = {
  children: React.ReactNode[];
  currentStep: number;
  showStepFooter?: boolean;
  isNextDisabled?: boolean;
  progressBarSteps: string[];
  onNext?: () => void;
  onCancel: () => void;
  onFinish?: () => void;
  onChangePage: (page: number) => void;
};

export const CommonStepLayout = ({
  children,
  currentStep,
  showStepFooter = true,
  isNextDisabled,
  progressBarSteps,
  onNext,
  onCancel,
  onFinish,
  onChangePage,
}: Props) => {
  const isFirstStep = currentStep === 0 && !isNil(currentStep);

  const headerItems = useMemo(
    () =>
      progressBarSteps.map((label, index) => (
        <CommonStepLayoutItem
          key={index}
          label={label}
          currentStep={currentStep}
          step={index}
          changeStep={onChangePage}
        />
      )),
    [currentStep, progressBarSteps, onChangePage]
  );

  const activePage = React.Children.toArray(children)[currentStep];

  const handleNext = () => {
    if (onNext) {
      onNext();
    } else {
      const isLastPage = currentStep === React.Children.count(children) - 1;

      if (isLastPage && onFinish) {
        return onFinish();
      }

      onChangePage(currentStep + 1);
    }
  };

  const handlePrev = () => {
    if (isFirstStep) {
      return onCancel();
    }

    onChangePage(currentStep - 1);
  };

  return (
    <>
      <StepHeader>
        <CommonStepLayoutItem label='Start' isZeroStep />
        {headerItems}
        <CommonStepLayoutItem label='Finish' isLastStep />
      </StepHeader>
      <Children>{activePage}</Children>
      <StepFooter showStepFooter={showStepFooter}>
        <PrevStepButton onClick={handlePrev}>
          BACK
          <PrevStepIconContainer>
            <PrevStepIcon src={PrevIcon} />
          </PrevStepIconContainer>
        </PrevStepButton>
        <NextStepButton onClick={handleNext} disabled={isNextDisabled}>
          NEXT
          <NextStepIconContainer>
            <NextStepIcon src={NextIcon} />
          </NextStepIconContainer>
        </NextStepButton>
      </StepFooter>
    </>
  );
};

CommonStepLayout.Child = ({ children }) => {
  return <>{children}</>;
};

const Children = styled.div`
  max-width: 80%;
  margin: 0 auto;
`;

const StepHeader = styled.div`
  position: relative;
  display: flex;
  height: 4rem;
  padding: ${(props) => props.theme.spacing[12]}
    ${(props) => props.theme.spacing[28]};
  background-color: ${(props) => props.theme.colors.white};

  &:after {
    content: '';
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 17rem;
    background-color: ${(props) => props.theme.colors.white};
    z-index: -1;
  }
`;

const StepFooter = styled.div<{ showStepFooter: boolean }>`
  display: ${(props) => (props.showStepFooter ? 'flex' : 'none')};
  justify-content: center;
  flex-direction: space-between;
  width: 100%;
  margin: ${(props) => props.theme.spacing[5]} 0;
`;

const StepButtonStyles = css`
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: ${(props) => props.theme.radius.xl};
  padding: ${(props) => props.theme.spacing[2]}
    ${(props) => props.theme.spacing[2]} ${(props) => props.theme.spacing[2]}
    ${(props) => props.theme.spacing[10]};
  ${(props) => props.theme.text.fontType.h6};

  &:hover {
    box-shadow: ${(props) => props.theme.shadows.card};
  }
`;

const PrevStepButton = styled.button`
  margin-right: 22rem;
  color: ${(props) => props.theme.colors.blue[100]};
  background-color: ${(props) => props.theme.colors.white};
  border: 0.1rem solid ${(props) => props.theme.colors.blue[400]};
  ${StepButtonStyles}
`;

const PrevStepIconContainer = styled.div`
  display: flex;
  padding: ${(props) => props.theme.spacing[3]}
    ${(props) => props.theme.spacing[8]};
  margin-left: ${(props) => props.theme.spacing[6]};
  box-sizing: border-box;
  border-radius: ${(props) => props.theme.radius.xl};
  border: 0.2rem solid ${(props) => props.theme.colors.blue[400]};
`;

const PrevStepIcon = styled.img`
  width: 1rem;
  height: 2rem;
`;

const NextStepButton = styled.button<{ disabled: boolean }>`
  color: ${(props) => props.theme.colors.white};
  background-color: ${(props) =>
    props.disabled
      ? props.theme.colors.blue[400]
      : props.theme.colors.blue[100]};
  border: none;
  ${StepButtonStyles}
  cursor: ${(props) => props.disabled && 'not-allowed'};

  &:hover {
    box-shadow: ${(props) => props.disabled && 'none'};
  }
`;

const NextStepIconContainer = styled.div`
  display: flex;
  padding: ${(props) => props.theme.spacing[3]}
    ${(props) => props.theme.spacing[6]};
  margin-left: ${(props) => props.theme.spacing[6]};
  box-sizing: border-box;
  border-radius: ${(props) => props.theme.radius.xl};
  border: 0.2rem solid ${(props) => props.theme.colors.white};
`;

const NextStepIcon = styled.img`
  width: 2.2rem;
  height: 2rem;
`;
