import { useState } from 'react';
import styled from 'styled-components';
import { FieldType } from 'src/utils/types';
import { MIModalMessage } from 'src/components/common/MIModalMessage';
import { notifySuccess } from 'src/services/notifications/notificationService';
import { REGEX } from 'src/utils/consts';
import { MIButton } from 'src/components/common/MIButton';
import { MITextInput } from 'src/components/common/MITextInput';
import UserService from 'src/services/users/userService';
import { ResetPasswordModel } from 'src/services/users/models/resetPasswordModel';
import { ResetPasswordResult } from 'src/services/users/models/resetPasswordResult';

type Props = {
  dismiss?: (event: React.MouseEvent) => void;
};

export const SendResetPasswordLinkModal = ({ dismiss }: Props) => {
  const [email, setEmail] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [validationErrors, setValidationErrors] = useState<Record<string, any>>(
    {}
  );

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setValidationErrors({});

    if (!REGEX.EMAIL.test(email)) {
      setValidationErrors({
        email: 'Please enter your email, make sure it’s valid',
      });
      setLoading(false);
      return;
    }
    var request = { email } as ResetPasswordModel;

    UserService.resetPassword(request).then((response) => {
      if (!response.is_error) {
        if (response.content?.result === ResetPasswordResult.Done) {
          notifySuccess({
            msg: 'Link was successfully sent',
          });
          setLoading(false);
          dismiss && dismiss(event);
        }
        if (response.content?.result === ResetPasswordResult.UserNotFound) {
          setValidationErrors({
            email: 'User not found',
          });
          setLoading(false);
          return;
        }
        if (response.content?.result === ResetPasswordResult.ServerError) {
          setValidationErrors({
            email: 'Server error',
          });
          setLoading(false);
          return;
        }
      } else {
        setValidationErrors({
          email: 'Server error',
        });
        setLoading(false);
        return;
      }
    });
  };

  const onFieldChanged = ({ id, value }: FieldType) => {
    if (id === 'email') {
      setEmail(value);
    }
  };

  return (
    <MIModalMessage
      dismiss={dismiss}
      titleComponent={
        <ModalTitleContainer>
          <ModalTitle>Reset password</ModalTitle>
          <InputsContainer>
            <MITextInput
              id='email'
              value={email}
              label='Email'
              transparent={false}
              onChange={onFieldChanged}
              autoFocus
              autocomplete='username email'
              type='email'
              required
              errorMessage={validationErrors.email}
            />
          </InputsContainer>
          <MIButton
            label='Send reset link'
            type='submit'
            onClick={handleSubmit}
            isProcessing={loading}
            fullWidth
          />
        </ModalTitleContainer>
      }
    />
  );
};

const ModalTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const ModalTitle = styled.span`
  margin-bottom: ${(props) => props.theme.spacing[2]};
  ${(props) => props.theme.text.fontType.h5};
  font-weight: ${(props) => props.theme.text.weight.semi};
`;

const InputsContainer = styled.div`
  width: 100%;
`;
