import {
  SET_SKAG_STEP,
  REPLACE_SKAG_STEP,
  SET_SKAG_CURRENT_FORM_DATA,
} from './actionTypes';

export const setSkagStep = (data: number) => {
  return {
    type: SET_SKAG_STEP,
    data,
  };
};

export const replaceSkagStep = (index: number, newStep: string) => {
  return {
    type: REPLACE_SKAG_STEP,
    payload: {
      index,
      newStep,
    },
  };
};

export const setSkagCurrentFormData = (currentItem, currentAdTypeDetails) => {
  return {
    type: SET_SKAG_CURRENT_FORM_DATA,
    payload: {
      currentItem,
      currentAdTypeDetails,
    },
  };
};
