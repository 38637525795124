import { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import Arrow from 'src/images/general/black-arrow-icon.svg';
import { MIButton } from 'src/components/common/MIButton';
import { MITable } from 'src/components/common/table/MITable';
import { BUTTON_VARIANT } from 'src/utils/consts';
import { CampaignType } from 'src/utils/types';
import {
  conversionDataTable,
  formCampaignReviewDataTable,
} from 'src/utils/builder';
import { useViewport } from 'src/hooks/useViewport';

type Props = {
  isConversion?: boolean;
  campaign?: CampaignType;
  onPrev: () => void;
  onSubmit: () => void;
};

export const ImportToGoogleAds = ({
  campaign,
  isConversion,
  onPrev,
  onSubmit,
}: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const { height } = useViewport();

  const tableData = useMemo(() => {
    if (!isConversion && campaign) {
      return formCampaignReviewDataTable(campaign);
    } else {
      return conversionDataTable;
    }
  }, [campaign, isConversion]);

  const columns = useMemo(
    () => [
      {
        Header: 'Campaigns Created',
        accessor: 'entity',
        width: 300,
        Cell: ({ value }) => value.toLocaleString('en-US'),
      },
      {
        Header: 'Added',
        accessor: 'added',
        Cell: ({ value }) => (value || 0).toLocaleString('en-US'),
      },
      {
        Header: 'Errors',
        accessor: 'skipped',
        Cell: ({ value }) => (value || '-').toLocaleString('en-US') || '-',
      },
    ],
    []
  );

  const handleSubmit = useCallback(async () => {
    try {
      setIsLoading(true);
      await onSubmit();
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      throw error;
    }
  }, [onSubmit]);

  return (
    <Container height={height || 0}>
      <Header>
        <ArrowIcon src={Arrow} onClick={onPrev} />
        <Title>
          Import To <Bold>Google Ads</Bold>
        </Title>
      </Header>
      <Text>Changes Made:</Text>
      <TableContainer>
        <Table columns={columns} data={tableData} showPagination={false} />
      </TableContainer>
      <ButtonContainer>
        <MIButton
          label='Cancel'
          onClick={onPrev}
          variant={BUTTON_VARIANT.SECONDARY}
        />
        <MIButton
          label='Approve & Upload'
          onClick={handleSubmit}
          variant={BUTTON_VARIANT.PRIMARY}
          isProcessing={isLoading}
        />
      </ButtonContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  min-width: 1152px;
  max-height: ${({ height }) => height / 1.4}px;
`;

const Header = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  width: -webkit-fill-available;
  margin-bottom: ${(props) => props.theme.spacing[10]};
`;

const ArrowIcon = styled.img`
  width: 2.4rem;
  height: 2.4rem;
  cursor: pointer;
`;

const Title = styled.span`
  margin: 0 auto;
  ${(props) => props.theme.text.fontType.h2};
`;

const Bold = styled.span`
  font-weight: ${(props) => props.theme.text.weight.bold};
`;

const Text = styled.span`
  ${(props) => props.theme.text.fontType.body2};
  margin-bottom: ${(props) => props.theme.spacing[4]};
  margin-left: ${(props) => props.theme.spacing[2]};
  color: ${(props) => props.theme.colors.black[200]};
  font-weight: 300;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: ${(props) => props.theme.spacing[5]};
  width: 100%;
  margin-top: ${(props) => props.theme.spacing[10]};

  & > button {
    width: 200px;
  }
`;

const Table = styled(MITable)`
  & td {
    font-weight: 300;
  }
`;

const TableContainer = styled.div`
  width: 100%;
  overflow-y: auto;
`;
