import styled from 'styled-components';
import { MITextInput } from 'src/components/common/MITextInput';
import {
  AdType,
  CampaignType,
  SitelinkFormErrors,
  SitelinkFormType,
  SitelinkDataType,
} from 'src/utils/types';
import CloseIcon from 'src/images/general/close-icon.svg';
import { FormPreview } from '../FormPreview';
import { firstRow, secondRow, thirdRow } from './data';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import {
  createSkagAds,
  updateSkagAds,
  updateOneSkagAd,
} from 'src/redux/skagCompaign/actions';
import {
  createStagAds,
  updateStagAds,
  updateOneStagAd,
} from 'src/redux/stagCampaign/actions';
import MIFormButtons from 'src/components/common/MIFormButtons';
import { checkIsOnlyTemplate, validateParentheses } from 'src/utils/builder';
import { REGEX, TEXT_INPUT_SIZE } from 'src/utils/consts';
import { SitelinkCardPreview } from './SitelinkCardPreview';
import { MIFormAddButton } from 'src/components/common/MIFormAddButton';
import { isEmpty } from 'lodash';
import { notifyError } from 'src/services/notifications/notificationService';
import {
  invalidParenthesesMessage,
  invalidSpecialCharactersMessage,
} from '../data';

type Props = {
  isSkag: boolean;
  initialValues: SitelinkFormType;
  closeModal: (toNextStep?: boolean) => void;
  updateCampaignOnServer: (data?: CampaignType) => void;
};

export const SitelinkForm = ({
  isSkag,
  initialValues,
  closeModal,
  updateCampaignOnServer,
}: Props) => {
  const isNewData = initialValues?.id === undefined;
  const dispatch = useDispatch();
  const validate = (values) => {
    let errors: SitelinkFormErrors = {};
    delete values.keywords; // do not validate keywords and negatives (in case: edit ad from review table)
    delete values.negatives;
    let showParenthesesError = false;
    let showCharactersError = false;

    values.data.forEach((item: SitelinkDataType, index) => {
      if (isEmpty(item?.text)) {
        errors = { id: item.id, text: 'This field is required' };
      } else if (isEmpty(item?.descriptionOne)) {
        errors = { id: item.id, descriptionOne: 'This field is required' };
      } else if (isEmpty(item?.descriptionTwo)) {
        errors = { id: item.id, descriptionTwo: 'This field is required' };
      } else if (isEmpty(item?.finalUrl)) {
        errors = { id: item.id, finalUrl: 'This field is required' };
      } else if (!REGEX.URL.test(item?.finalUrl)) {
        errors = { id: item.id, finalUrl: 'Wrong format (should start with https://)' };
      }

      Object.keys(item).forEach((el) => {
        if (REGEX.EXCLAMATION_SYMBOL.test(values.data[index][el])) {
          errors = { id: item.id, [el]: 'There is blocked symbol' };
          showCharactersError = true;
        }

        if (
          typeof values.data[index][el] === 'string' &&
          !validateParentheses(values.data[index][el])
        ) {
          errors = { id: item.id, [el]: 'Invalid parentheses' };
          showParenthesesError = true;
        }
      });
    });

    if (showParenthesesError) {
      notifyError({ msg: invalidParenthesesMessage });
    } else if (showCharactersError) {
      notifyError({ msg: invalidSpecialCharactersMessage });
    }

    return errors;
  };

  const convertFormData = (values: SitelinkFormType) => {
    const { data } = values;
    let convertedData = data.map((sitelink) => {
      return {
        text: sitelink.text,
        descriptionOne: sitelink.descriptionOne,
        descriptionTwo: sitelink.descriptionTwo,
        finalUrl: sitelink.finalUrl,
        adGroupName: values.adGroupName,
        type: values.type,
      };
    });

    return convertedData;
  };

  const formik = useFormik({
    validateOnBlur: false,
    validateOnChange: false,
    initialValues,
    validate,
    onSubmit: (values) => {
      const convertedData = convertFormData(values);
      if (!Object.keys(formik.errors).length) {
        closeModal(!!values.edited);

        const onlyTemplate = checkIsOnlyTemplate(AdType.SITELINK);
        if (isNewData) {
          isSkag
            ? convertedData.forEach((el) =>
                dispatch(createSkagAds(AdType.SITELINK, el, onlyTemplate))
              )
            : convertedData.forEach((el) =>
                dispatch(createStagAds(AdType.SITELINK, el, onlyTemplate))
              );
        } else {
          if (isSkag) {
            !values.edited || onlyTemplate
              ? convertedData.forEach((el) =>
                  dispatch(updateSkagAds(initialValues.id, el, onlyTemplate))
                )
              : convertedData.forEach((el) =>
                  dispatch(updateOneSkagAd(initialValues.id, el))
                );
          } else {
            !values.edited || onlyTemplate
              ? convertedData.forEach((el) =>
                  dispatch(updateStagAds(initialValues.id, el, onlyTemplate))
                )
              : convertedData.forEach((el) =>
                  dispatch(updateOneStagAd(initialValues.id, el))
                );
          }
        }
        updateCampaignOnServer();
      }
    },
  });

  const inputHandler = (key: string, value: string, id: number) => {
    formik.setFieldValue(`data[${id}][${key}]`, value);
    if (formik.errors) {
      formik.setErrors({});
    }
  };

  const getInputError = (id: number, key: string) => {
    if (!isEmpty(formik.errors)) {
      if (formik.errors.id && Number(formik.errors.id) === id) {
        return formik.errors[key];
      }
    } else {
      return null;
    }
  };

  const renderInputs = (values, id) => {
    return values.map((item) => {
      return (
        <MITextInput
          size={TEXT_INPUT_SIZE.INLINE}
          key={item.key + id}
          errorMessage={getInputError(id + 1, item.key)}
          value={formik.values.data[id][item.key]}
          onChange={({ value }) => inputHandler(item.key, value, id)}
          onBlur={({ target }) =>
            inputHandler(item.key, target.value?.trim(), id)
          }
          {...item}
        />
      );
    });
  };

  const onAddNewSection = (key) => {
    if (formik.values[key].length < 15) {
      const updatedData = [
        ...formik.values.data,
        {
          id: formik.values.data.length + 1,
          text: '',
          descriptionOne: '',
          descriptionTwo: '',
          finalUrl: '',
        },
      ];
      formik.setFieldValue(key, updatedData);
    }
  };

  const onRemoveSection = (id: number) => {
    const updatedData = formik.values.data.filter(
      (sitelink) => sitelink.id !== id
    );
    formik.setFieldValue('data', updatedData);
    if (formik.errors) {
      formik.setErrors({});
    }
  };

  return (
    <Wrapper>
      <Container>
        <ItemsContainer>
          <ButtonWrapper>
            <MIFormAddButton
              title='add new sitelink'
              onClick={() => onAddNewSection('data')}
            />
          </ButtonWrapper>
          {formik.values.data.map((sitelink, id) => {
            return (
              <>
                <Title>
                  {`Sitelink ${id + 1}`}
                  {id !== 0 && (
                    <RemoveIcon
                      src={CloseIcon}
                      onClick={() => onRemoveSection(sitelink.id || 0)}
                    />
                  )}
                </Title>
                <Item>{renderInputs(firstRow, id)}</Item>
                <Item>{renderInputs(secondRow, id)}</Item>
                <Item>{renderInputs(thirdRow, id)}</Item>
              </>
            );
          })}
        </ItemsContainer>
        <FormPreview
          type={AdType.SITELINK}
          preview={<SitelinkCardPreview item={formik.values} />}
          withoutFooter={true}
        />
      </Container>
      <MIFormButtons closeModal={closeModal} saveHandler={formik.submitForm} />
    </Wrapper>
  );
};

const Wrapper = styled.form`
  display: flex;
  flex-direction: column;
  max-width: 120rem;
`;

const ButtonWrapper = styled.form`
  margin: ${({ theme }) => theme.spacing[3]} 0;
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
`;

const Title = styled.div`
  margin: ${({ theme }) => theme.spacing[5]} 0
    ${({ theme }) => theme.spacing[2]} 0;
  color: ${({ theme }) => theme.colors.black};
  ${({ theme }) => theme.text.fontType.body2};
  font-weight: ${({ theme }) => theme.text.weight.semi};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const RemoveIcon = styled.img`
  width: 1rem;
  height: 1rem;
  cursor: pointer;
  margin-left: ${({ theme }) => theme.spacing[2]};
`;

const ItemsContainer = styled.div`
  border-radius: ${({ theme }) => theme.radius.xl};
  padding: 4rem;
  background-color: ${({ theme }) => theme.colors.white};
`;

const Item = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-width: 100rem;
  margin-bottom: ${({ theme }) => theme.spacing[5]};
  > div:nth-child(2) {
    margin: 0 0 0 ${({ theme }) => theme.spacing[10]};
  }
`;

const Container = styled.div`
  border: 0.1rem solid ${({ theme }) => theme.colors.blue[300]};
  border-radius: ${({ theme }) => theme.radius.xl};
  background-color: ${({ theme }) => theme.colors.blue[700]};
`;
