import styled from 'styled-components';
import { SitelinkAdType } from 'src/utils/types';

type Props = {
  item: SitelinkAdType;
};

export const SitelinkEditCardPreview = ({ item }: Props) => {
  return (
    <Container>
      <Title>{`${item.text}`}</Title>
      <Description>
        {`${item.descriptionOne} & ${item.descriptionTwo}`}
      </Description>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  width: 60rem;
  height: fit-content;
  flex-direction: column;
  padding: 1.7rem;
  box-sizing: border-box;
  border-radius: 0 0 1rem 1rem;
  border: 0.1rem solid ${(props) => props.theme.colors.grey[300]};
  border-top: 0.1rem dashed ${(props) => props.theme.colors.grey[300]};
  background-color: ${(props) => props.theme.colors.grey[500]};
`;

const Title = styled.span`
  color: ${(props) => props.theme.colors.blue[200]};
  ${(props) => props.theme.text.fontType.body3};
  font-weight: ${({ theme }) => theme.text.weight.bold};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const Description = styled.span`
  color: ${(props) => props.theme.colors.black[100]};
  ${(props) => props.theme.text.fontType.link};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: 2rem;
`;