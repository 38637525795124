import { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import CurrentIcon from 'src/images/general/current-step-icon.svg';

type Props = {
  label: string;
  isCurrent: boolean;
  isZeroStep: boolean;
  isLastStep: boolean;
  isPrevStep: boolean;
  onClick: () => void;
};

export const BuilderStepLayoutItem = ({
  label,
  isCurrent,
  isZeroStep,
  isLastStep,
  isPrevStep,
  onClick,
}: Props) => {
  const [isExistStep, setIsExistStep] = useState<boolean>(false);

  useEffect(() => {
    isCurrent && setIsExistStep(true);
  }, [isCurrent]);

  if (isZeroStep) {
    return <Step onClick={onClick}>{label}</Step>;
  }

  if (isCurrent && !isLastStep) {
    return (
      <CurrentStep>
        <CurrentStepIcon src={CurrentIcon} />
        {label}
      </CurrentStep>
    );
  }

  if (isLastStep) {
    return (
      <LastStepContainer>
        <LastStepSeparator />
        <LastStep>{label}</LastStep>
      </LastStepContainer>
    );
  }

  if ((isPrevStep || isExistStep) && !isCurrent) {
    return (
      <Step onClick={onClick}>
        <CurrentStepIcon src={CurrentIcon} />
        {label}
      </Step>
    );
  }

  return (
    <EmptyStep>
      <CurrentStepIcon src={CurrentIcon} isHidden />
      {label}
    </EmptyStep>
  );
};

const StepStyles = css`
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  border-radius: ${(props) => props.theme.radius.lg};
  height: 4.2rem;
  padding: 0 ${(props) => props.theme.spacing[4]};
  margin-right: ${(props) => props.theme.spacing[1]};
  ${(props) => props.theme.text.fontType.body3};
`;

const Step = styled.div`
  cursor: pointer;
  color: ${(props) => props.theme.colors.grey[100]};
  background-color: ${(props) => props.theme.colors.blue[400]};
  ${StepStyles};
`;

const CurrentStep = styled.div`
  color: ${(props) => props.theme.colors.blue[100]};
  background-color: ${(props) => props.theme.colors.blue[600]};
  ${StepStyles};
`;

const CurrentStepIcon = styled.img<{ isHidden?: boolean }>`
  margin-right: ${(props) => props.theme.spacing[2]};
  visibility: ${(props) => (props.isHidden ? 'hidden' : 'visible')};
`;

const LastStep = styled.div`
  height: 100%;
  color: ${(props) => props.theme.colors.blue[400]};
  border: 0.2rem dashed ${(props) => props.theme.colors.blue[400]};
  ${StepStyles};
  margin-right: 0;
  height: 3.8rem;
`;

const LastStepContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

const LastStepSeparator = styled.div`
  width: 100%;
  height: 0.1rem;
  margin: 0 ${(props) => props.theme.spacing[5]};
  background-color: ${(props) => props.theme.colors.blue[400]};
`;

const EmptyStep = styled.div`
  color: ${(props) => props.theme.colors.white};
  border: 0.2rem dashed ${(props) => props.theme.colors.blue[400]};
  ${StepStyles};
  height: 3.8rem;
`;
