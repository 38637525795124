import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ErrorBoundary } from 'src/pages/error/ErrorBoundary';
import { AppLayout } from 'src/components/layout/AppLayout';
import { store, persistor } from 'src/redux/store';
import { ViewportProvider } from 'src/hooks/useViewport';
import { Routes } from 'src/components/routes/Routes';

export const App = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ErrorBoundary>
          <Router>
            <ViewportProvider>
              <AppLayout>
                <Routes />
              </AppLayout>
            </ViewportProvider>
          </Router>
        </ErrorBoundary>
      </PersistGate>
    </Provider>
  );
};
