import { useEffect, useCallback, useState, useMemo } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import KeyWordPlannerService from 'src/services/keywordPlanner/keyWordPlannerService';
import { getGoogleKeywords } from 'src/redux/planner/selectors';
import { MICheckbox } from 'src/components/common/MICheckbox';
import { ReactComponent as SettingsIcon } from 'src/images/general/settings.svg';
import { MIContextMenu } from 'src/components/common/MIContextMenu';
import { MITable } from 'src/components/common/table/MITable';
import { notifyServerError } from 'src/services/notifications/notificationService';
import {
  setGoogleKeywords,
  deleteGoogleKeyword,
  setSelectedKeywords,
} from 'src/redux/planner/actions';
import {
  convertNumberToCurrency,
  compareNumericString,
} from 'src/utils/currency';
import {
  KeywordColumnFilter,
  VolumeRangeColumnFilter,
  CompetitionColumnFilter,
  LowBidRangeColumnFilter,
  HighBidRangeColumnFilter,
} from 'src/components/common/table/KeywordPlannerFilters';
import DeleteIcon from 'src/images/general/delete-icon.svg';

type KeywordPlannerPageProps = {
  keywords: string[];
};

const perPageValues = [10, 30, 50, 100, 200, 500];

export const KeywordPlannerPage = ({ keywords }: KeywordPlannerPageProps) => {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState<boolean>(false);
  const googleKeywords = useSelector(getGoogleKeywords);

  const deleteHandler = (keyword: string) => {
    dispatch(deleteGoogleKeyword(keyword));
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Keyword (by relevance)',
        accessor: 'keyword',
        Filter: KeywordColumnFilter,
        Cell: ({ value, row }) => {
          const { checked, onChange } = row.getToggleRowSelectedProps();
          return (
            <KeywordColumn>
              <MICheckbox
                value={checked}
                onChange={(value) => onChange({ target: { checked: value } })}
              />
              <KeywordValue>{value}</KeywordValue>
            </KeywordColumn>
          );
        },
      },
      {
        Header: 'Avg. Monthly Searches',
        accessor: 'avgMonthSearch',
        Filter: VolumeRangeColumnFilter,
        filter: 'between',
        Cell: ({ value }) => Number(value).toLocaleString('en-US'),
      },
      {
        Header: 'Competition',
        accessor: 'competition',
        Filter: CompetitionColumnFilter,
        Cell: ({ value }) => value.toLowerCase(),
      },
      {
        Header: 'Low Bid Range',
        accessor: 'lowBidRange',
        Filter: LowBidRangeColumnFilter,
        filter: 'between',
        sortType: compareNumericString,
        Cell: ({ value }) => (value ? convertNumberToCurrency(value) : '-'),
      },
      {
        Header: 'High Bid Range',
        accessor: 'highBidRange',
        Filter: HighBidRangeColumnFilter,
        filter: 'between',
        sortType: compareNumericString,
        Cell: ({ value }) => (value ? convertNumberToCurrency(value) : '-'),
      },
      {
        id: 'settings',
        accessor: 'keyword',
        minWidth: 15,
        width: 15,
        maxWidth: 15,
        Cell: ({ value }) => {
          const contextMenuOptions = [
            {
              label: 'Delete',
              icon: DeleteIcon,
              onClick: () => deleteHandler(value),
            },
          ];

          return (
            <SettingsContainer>
              <Settings id='settingsIcon' />
              <MIContextMenu
                id='menu'
                options={contextMenuOptions}
                position={{ right: 15, top: -11 }}
              />
            </SettingsContainer>
          );
        },
      },
    ],
    []
  );

  const handleRowSelect = useCallback(
    (keywordsData: any) => {
      dispatch(setSelectedKeywords(keywordsData));
    },
    [dispatch]
  );

  const fetchGoogleKeywords = useCallback(async () => {
    try {
      setLoading(true);
      const {
        content,
        is_error: isError,
        error_content: errorContent,
      } = await KeyWordPlannerService.getIdeasList({
        keywords,
      });

      if (isError || !content) {
        notifyServerError({ msg: errorContent?.error || 'Server error' });
        return;
      }

      const { ideas } = content;
      dispatch(setGoogleKeywords(ideas));
    } catch (error) {
      notifyServerError({ msg: 'Server error' });
    } finally {
      setLoading(false);
    }
  }, [dispatch, keywords]);

  const fetchData = useCallback(
    ({ pageIndex, pageSize }) => {
      const offset = pageSize * pageIndex;
      return googleKeywords.slice(offset, offset + pageSize + 1);
    },
    [googleKeywords]
  );

  useEffect(() => {
    fetchGoogleKeywords();
  }, [fetchGoogleKeywords, keywords]);

  return (
    <Container>
      <Header>
        <Title>
          Keyword <Bold>Planner</Bold>
        </Title>
      </Header>
      <MITable
        columns={columns}
        data={googleKeywords}
        fetchData={fetchData}
        isLoading={isLoading}
        perPageValues={perPageValues}
        initialPageSize={100}
        onRowSelect={handleRowSelect}
      />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 5rem;
  padding: 0;

  &:after {
    content: '';
    position: absolute;
    top: 30rem;
    left: 0;
    width: 100%;
    height: 24rem;
    background-color: ${(props) => props.theme.colors.white};
    z-index: -1;
  }
`;

const Header = styled.h1`
  margin: 0 0 0.6rem;
  text-align: center;
  font-weight: 300;
`;

const Title = styled.p`
  ${({ theme }) => theme.text.fontType.h1}
  margin: 0;
  text-align: center;
  font-size: 4.8rem;
  line-height: 5.9rem;
`;

const Bold = styled.b``;

const KeywordColumn = styled.div`
  display: flex;
  align-items: center;
  column-gap: ${({ theme }) => theme.spacing[2]};
`;

const KeywordValue = styled.span``;

const SettingsContainer = styled.div`
  cursor: pointer;
  position: absolute;

  &:hover {
    #menu {
      display: block;
    }
    #settingsIcon {
      color: ${({ theme }) => theme.colors.black[100]};
    }
  }
`;

const Settings = styled(SettingsIcon)`
  color: ${({ theme }) => theme.colors.grey[300]};
`;
