import styled from 'styled-components';
import { MITextInput } from 'src/components/common/MITextInput';
import {
  AdType,
  CampaignType,
  RespSearchAdType,
  RespSearchFormErrors,
} from 'src/utils/types';
import { FormPreview } from '../FormPreview';
import {
  descriptions,
  headlines,
  secondRow,
  headlinesOptions,
  descriptionsOptions,
  fieldsToCheckBlockedSymbols,
} from './data';
import { REGEX } from 'src/utils/consts';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import {
  createSkagAds,
  updateSkagAds,
  updateOneSkagAd,
} from 'src/redux/skagCompaign/actions';
import {
  createStagAds,
  updateStagAds,
  updateOneStagAd,
} from 'src/redux/stagCampaign/actions';
import { RespResearchPreview } from './RespResearchPreview';
import { MIFormAddButton } from 'src/components/common/MIFormAddButton';
import MIFormButtons from 'src/components/common/MIFormButtons';
import { ReactComponent as Pin } from 'src/images/general/pin.svg';
import {
  invalidParenthesesMessage,
  invalidSpecialCharactersMessage,
} from '../data';
import { checkIsOnlyTemplate, validateParentheses } from 'src/utils/builder';
import { notifyError } from 'src/services/notifications/notificationService';

type Props = {
  isSkag: boolean;
  initialValues: RespSearchAdType;
  closeModal: (toNextStep?: boolean) => void;
  updateCampaignOnServer: (data?: CampaignType) => void;
};

export const RespResearchForm = ({
  isSkag,
  initialValues,
  closeModal,
  updateCampaignOnServer,
}: Props) => {
  const isNewData = initialValues?.id === undefined;
  const dispatch = useDispatch();
  const validate = (values) => {
    const errors: RespSearchFormErrors = {};
    delete values.keywords; // do not validate keywords and negatives (in case: edit ad from review table)
    delete values.negatives;

    const fields = Object.keys(values);
    let showParenthesesError = false;
    let showCharactersError = false;

    fields.forEach((item) => {
      if (typeof values[item] === 'object') {
        if (Array.isArray(values[item])) {
          values[item].forEach((key, index) => {
            if (!validateParentheses(key.value)) {
              errors[`${item}[${index}]`] = 'Invalid parentheses';
              showParenthesesError = true;
            }
            if (!fieldsToCheckBlockedSymbols.includes(item)) {
              return;
            }
            if (REGEX.EXCLAMATION_SYMBOL.test(key.value)) {
              errors[`${item}[${index}]`] = 'There is blocked symbol';
              showCharactersError = true;
            }
          });
        }
        return;
      }
      if (typeof values[item] !== 'boolean') {
        if (!validateParentheses(values[item])) {
          errors[item] = 'Invalid parentheses';
          showParenthesesError = true;
        }
        if (!fieldsToCheckBlockedSymbols.includes(item)) {
          return;
        }
        if (REGEX.EXCLAMATION_SYMBOL.test(values[item])) {
          errors[item] = 'There is blocked symbol';
          showCharactersError = true;
        }
      }
    });

    if (showParenthesesError) {
      notifyError({ msg: invalidParenthesesMessage });
    } else if (showCharactersError) {
      notifyError({ msg: invalidSpecialCharactersMessage });
    }

    if (!values.finalUrl) {
      errors.finalUrl = 'This field is required';
    } else if (!REGEX.URL.test(values.finalUrl)) {
      errors.finalUrl = 'Wrong format (should start with https://)';
    }
    return errors;
  };

  const formik = useFormik({
    validateOnBlur: false,
    validateOnChange: false,
    initialValues,
    validate,
    onSubmit: (values) => {
      if (!Object.keys(formik.errors).length) {
        closeModal(!!values.edited);

        const onlyTemplate = checkIsOnlyTemplate(AdType.RESPONSIVE);
        if (isNewData) {
          dispatch(
            isSkag
              ? createSkagAds(AdType.RESPONSIVE, values, onlyTemplate)
              : createStagAds(AdType.RESPONSIVE, values, onlyTemplate)
          );
        } else {
          if (isSkag) {
            dispatch(
              !values.edited || onlyTemplate
                ? updateSkagAds(initialValues.id, values, onlyTemplate)
                : updateOneSkagAd(initialValues.id, values)
            );
          } else {
            dispatch(
              !values.edited || onlyTemplate
                ? updateStagAds(initialValues.id, values, onlyTemplate)
                : updateOneStagAd(initialValues.id, values)
            );
          }
        }
        updateCampaignOnServer();
      }
    },
  });

  const inputHandler = (key: string, value: string | string[]) => {
    formik.setFieldValue(key, value);
    if (formik.errors) {
      formik.setErrors({});
    }
  };

  const pinHandler = (
    selectedKey: string,
    pinnedData: string,
    fieldId: number
  ) => {
    for (const [key] of Object.entries(formik.values[pinnedData])) {
      const currentValue = formik.values[pinnedData][key];
      if (selectedKey === 'none') {
        if (currentValue.find((position) => position === fieldId)) {
          const updatedData = currentValue.filter((data) => data !== fieldId);
          formik.setFieldValue(`${pinnedData}.${key}`, updatedData);
        }
        continue;
      }
      if (
        key === selectedKey &&
        !currentValue.find((position) => position === fieldId)
      ) {
        const updatedData = [...currentValue, fieldId];
        formik.setFieldValue(`${pinnedData}.${selectedKey}`, updatedData);
      } else if (currentValue.find((position) => position === fieldId)) {
        const updatedData = currentValue.filter((data) => data !== fieldId);
        formik.setFieldValue(`${pinnedData}.${key}`, updatedData);
      }
    }
  };

  const checkIsPinned = (id: number, pinnedData: any) => {
    let isPinned = false;
    let position = 'none';
    for (const [key] of Object.entries(formik.values[pinnedData])) {
      if (formik.values[pinnedData][key].find((position) => position === id)) {
        isPinned = true;
        position = key;
      }
    }
    return { isPinned, position };
  };

  const getLabel = (isPinned: boolean, position: string, key: string) => {
    const field = key === headlines ? 'Headline' : 'Description';
    const data = key === headlines ? headlinesOptions : descriptionsOptions;
    if (!isPinned) {
      return `${field} not pinned`;
    } else {
      return `${field} ${data
        .find((option) => option.value === position)
        ?.label?.toLocaleLowerCase()}`;
    }
  };

  const renderInputs = (values, key?) => {
    const onChangeHandler = (value: string, item, index = null) => {
      inputHandler(index !== null ? item : item.key, value);
    };

    if (key) {
      return values.map((item, index) => {
        const isHeadlines = key === headlines;
        const pinnedData = isHeadlines
          ? 'pinnedHeadlines'
          : 'pinnedDescriptions';
        const { isPinned, position } = checkIsPinned(item.id, pinnedData);
        return (
          <MITextInput
            withPin
            required
            suffix={<PinIcon isPinned={isPinned} />}
            label={getLabel(isPinned, position, key)}
            selectedPinOption={position}
            pinnedOptions={isHeadlines ? headlinesOptions : descriptionsOptions}
            key={key + item.id}
            value={formik.values[key][index].value}
            onPin={(value) => pinHandler(value, pinnedData, item.id)}
            errorMessage={
              `${key}[${index}]` in formik.errors
                ? formik.errors[`${key}[${index}]`]
                : null
            }
            onChange={({ value }) =>
              onChangeHandler(value, `${[key]}.${index}.value`, index)
            }
            onBlur={({ target }) =>
              onChangeHandler(
                target.value?.trim(),
                `${[key]}.${index}.value`,
                index
              )
            }
            {...{ maxlength: isHeadlines ? 30 : 90 }}
          />
        );
      });
    }
    return values.map((item) => (
      <MITextInput
        key={item.key}
        id={item.key}
        errorMessage={
          item.key in formik.errors ? formik.errors[item.key] : null
        }
        value={formik.values[item.key]}
        onChange={({ value }) => onChangeHandler(value, item)}
        onBlur={({ target }) => onChangeHandler(target.value?.trim(), item)}
        {...item}
      />
    ));
  };

  const addNewItems = (key, maxCount) => {
    if (formik.values[key].length < maxCount) {
      formik.setFieldValue(
        key,
        formik.values[key].concat({
          id: formik.values[key].length + 1,
          value: '',
        })
      );
    }
  };

  return (
    <Wrapper>
      <Container>
        <ItemsContainer>
          <AddButtonContainer>
            <Title>Headlines</Title>
            <MIFormAddButton
              title='add new headline'
              onClick={() => addNewItems(headlines, 15)}
            />
          </AddButtonContainer>
          {formik.values.headlines.length ? (
            <ShortItemsCustom>
              {renderInputs(formik.values.headlines, headlines)}
            </ShortItemsCustom>
          ) : null}
          <AddButtonContainer>
            <Title>Descriptions</Title>
            <MIFormAddButton
              title='add new description'
              onClick={() => addNewItems(descriptions, 4)}
            />
          </AddButtonContainer>
          {formik.values.descriptions?.length
            ? renderInputs(formik.values.descriptions, descriptions)
            : null}
          <ShortItems>{renderInputs(secondRow)}</ShortItems>
        </ItemsContainer>
        <FormPreview
          type={AdType.RESPONSIVE}
          preview={<RespResearchPreview item={formik.values} />}
        />
      </Container>
      <MIFormButtons closeModal={closeModal} saveHandler={formik.submitForm} />
    </Wrapper>
  );
};

const AddButtonContainer = styled.div`
  margin: ${(props) => props.theme.spacing[3]} 0;
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
`;

const Wrapper = styled.form`
  display: flex;
  flex-direction: column;
  max-width: 120rem;
`;

const Title = styled.h2`
  margin-right: auto;
  font-weight: 400;
`;

const PinIcon = styled(Pin)<{ isPinned: boolean }>`
  display: inline-block;
  width: 1.5rem;
  height: 1.5rem;
  position: relative;
  display: flex;
  top: 0.3rem;
  padding: 0.5rem;
  margin: -0.1rem;
  fill: ${(props) =>
    props.isPinned
      ? props.theme.colors.blue[100]
      : props.theme.colors.grey[300]};
`;

const ShortItems = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-width: 100rem;
  > div:nth-child(2) {
    margin: 0 ${(props) => props.theme.spacing[10]} 0
      ${(props) => props.theme.spacing[10]};
  }
`;

const ItemsContainer = styled.div`
  border-radius: ${(props) => props.theme.radius.xl};
  padding: 4rem;
  background-color: ${(props) => props.theme.colors.white};
`;

const Container = styled.div`
  border: 0.1rem solid ${(props) => props.theme.colors.blue[300]};
  border-radius: ${(props) => props.theme.radius.xl};
  background-color: ${(props) => props.theme.colors.blue[700]};
`;

const ShortItemsCustom = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  max-width: 100rem;
  > div:nth-child(3n - 1) {
    margin: 0 ${(props) => props.theme.spacing[10]} 0
      ${(props) => props.theme.spacing[10]};
  }
  > div:nth-child(n) {
    min-width: calc(100% - 70rem);
    max-width: 30.7rem;
  }
`;
