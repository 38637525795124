import { useCallback, useEffect, useState, useMemo, useRef } from 'react';
import styled, { css } from 'styled-components';
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';
import PrevIcon from 'src/images/general/prev-icon.svg';
import NextIcon from 'src/images/general/next-icon.svg';
import { BuilderStepLayoutItem } from 'src/components/layout/BuilderStepLayout/BuilderStepLayoutItem';
import { CampaignType } from 'src/utils/types';
import { useDispatch, useSelector } from 'react-redux';
import { USER_PLAN, REGEX, BID_STRATEGY } from 'src/utils/consts';
import {
  replaceSkagStep,
  setSkagStep,
} from 'src/redux/skagCreationFlow/actions';
import { SIGN_UP_FORM_KEYS } from 'src/utils/consts';
import { useModal } from 'src/helpers/react/useModal';
import { SaveCampaignModal } from 'src/components/common/AdCreator/Modal/SaveCampaignModal/SaveCampaignModal';
import {
  replaceStagStep,
  setStagStep,
} from 'src/redux/stagCreationFlow/actions';
import { getIsLoggedIn, getUserDetails } from 'src/redux/user/selectors';
import { SendResetPasswordLinkModal } from 'src/components/header/Modal/SendResetPasswordLinkModal';
import { SignInModal } from 'src/components/header/Modal/SignInModal';
import { SignUpModal } from 'src/components/header/Modal/SignUpModal';
import { saveUser } from 'src/redux/user/actions';
import { ChoosePaymentPlanModal } from 'src/components/common/Modals/ChoosePaymentPlanModal/ChoosePaymentPlanModal';
import { getCampaigns } from 'src/redux/campaigns/selectors';
import { CannotCreateCampaignModal } from 'src/pages/main/components/CannotCreateCampaignModal';
import CampaignService from 'src/services/campaign/campaignService';
import {
  notifyError,
  notifySuccess,
  notifyServerError,
} from 'src/services/notifications/notificationService';
import { loadSkagCampaign } from 'src/redux/skagCompaign/actions';
import {
  loadStagCampaign,
  setKeywordsValidationError,
} from 'src/redux/stagCampaign/actions';
import { getSkagCampaign } from 'src/redux/skagCompaign/selectors';
import { getStagCampaign } from 'src/redux/stagCampaign/selectors';
import { useHistory } from 'react-router-dom';
import { LOCATIONS } from 'src/pages/locations';
import { getStagAdGroupsIdsWithProhibitedSymbolsInKeywords } from 'src/utils/builder';

type Props = {
  isSkag: boolean;
  children: React.ReactNode;
  currentStep: number;
  progressBarSteps: string[];
  updateCampaign: (data: CampaignType) => void;
};

export const BuilderStepLayout = ({
  isSkag,
  children,
  currentStep,
  progressBarSteps,
  updateCampaign,
}: Props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const campaign = useSelector(isSkag ? getSkagCampaign : getStagCampaign);
  const isSTAG = campaign?.stag;
  const ref = useRef();
  let signUpForm: any;
  const [campaignCopy, setCopy] = useState<string>(JSON.stringify(campaign));

  const isUserLoggedIn = useSelector(getIsLoggedIn);
  const isKeywordsEmpty = campaign && isEmpty(campaign?.keywordsList);
  const isAdGroupListEmpty = campaign && isEmpty(campaign?.adGroupList);

  const user = useSelector(getUserDetails);
  const { campaigns } = useSelector(getCampaigns);

  const isSettingsStep = isSkag
    ? currentStep === 3 && progressBarSteps[3] === 'Settings'
    : currentStep === 4 && progressBarSteps[4] === 'Settings';
  const isStagKeywordsStep = isSTAG && currentStep === 2;
  const isSkagKeywordsStep = isSkag && currentStep === 1;
  const isSkagKeywordsIncludeProhibitedSymbols =
    campaign &&
    !isEmpty(campaign.keywordsList.filter((el) => el.match(REGEX.KEYWORDS)));
  const prohibitedSymbolsInKeywordsErrorMessage =
    'Looks like you are using special characters that are not recognized by Google. Please remove or replace ^()=?,;!@%*" from your keywords list';
  const isWrongCpaTarget =
    campaign.bidTargetGoal &&
    campaign.bidStrategyType === BID_STRATEGY.TARGET_CPA &&
    !REGEX.CPA_GOAL.test(String(campaign?.bidTargetGoal));
  const isWrongRoasTarget =
    campaign.bidTargetGoal &&
    campaign.bidStrategyType === BID_STRATEGY.TARGET_ROAS &&
    (campaign.bidTargetGoal < 0.1 || campaign?.bidTargetGoal > 1000);

  const isBudgetEmpty =
    typeof campaign?.budget === 'number' ? false : isEmpty(campaign?.budget);

  const isSettingsEmpty =
    campaign && (isBudgetEmpty || isEmpty(campaign?.name));

  const isFirstStep = currentStep === 1 && !isNil(currentStep);

  const checkCurrentStep = useCallback(() => {
    if (isSkag && currentStep === 3) {
      dispatch(replaceSkagStep(3, 'Settings'));
      return;
    }
    if (isSTAG && currentStep === 4) {
      dispatch(replaceStagStep(4, 'Settings'));
      return;
    }
  }, [currentStep, dispatch, isSTAG, isSkag]);

  useEffect(() => {
    if (signUpForm.length) {
      ref.current = signUpForm[SIGN_UP_FORM_KEYS.handler];
    }
    return () => checkCurrentStep();
  }, [signUpForm, checkCurrentStep]);

  const setIsSignedIn = useCallback(() => {
    dispatch(saveUser());
  }, [dispatch]);

  const showStepFooter =
    !isNil(currentStep) &&
    !isEmpty(progressBarSteps) &&
    progressBarSteps[isSkag ? 3 : 4] === 'Settings';

  const canUserBuildACompany = useMemo(() => {
    if (user?.plan === USER_PLAN.FREE && campaigns.length) {
      return false;
    }
    return true;
  }, [campaigns, user]);

  useEffect(() => {
    if (!canUserBuildACompany && !isEmpty(progressBarSteps) && !campaign?._id) {
      showCannotCreateCampaign();
      history.push(LOCATIONS.dashboard);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canUserBuildACompany, progressBarSteps, campaign]);

  const calculateIsNextDisabled = useCallback(() => {
    if (currentStep === progressBarSteps.length - 1) {
      return true;
    }

    if (isSkag) {
      if (isKeywordsEmpty || (isSettingsEmpty && isSettingsStep)) {
        return true;
      }
    }

    if (isSTAG) {
      if (isAdGroupListEmpty || (isSettingsEmpty && isSettingsStep)) {
        return true;
      }
    }

    return false;
  }, [
    currentStep,
    progressBarSteps.length,
    isSkag,
    isSTAG,
    isSettingsStep,
    isKeywordsEmpty,
    isSettingsEmpty,
    isAdGroupListEmpty,
  ]);

  const isNextDisabled = calculateIsNextDisabled();

  const onPrev = () => {
    updateCampaignOnServer();
    if (isFirstStep) {
      history.push(LOCATIONS.dashboard);
    }
    dispatch(
      isSkag ? setSkagStep(currentStep - 1) : setStagStep(currentStep - 1)
    );
  };

  const createCampaign = () => {
    const campaignName = `Draft ${Date.now()}`;
    const campaignData = campaign;

    if (campaignData) {
      delete campaignData._id;
      CampaignService.saveCampaign({ ...campaignData, name: campaignName })
        .then((response) => {
          if (!response.is_error && response?._id) {
            const updatedData = {
              ...campaignData,
              _id: response._id,
              name: response.name,
            };
            dispatch(
              isSkag
                ? loadSkagCampaign(updatedData)
                : loadStagCampaign(updatedData)
            );
          }
          if (response.is_error) {
            throw new Error('Server error');
          }
          notifySuccess({
            msg: `Campaign successfully created`,
          });
        })
        .catch(() => {
          notifyServerError({ msg: 'Server error' });
        });
    }
  };

  const updateCampaignOnServer = () => {
    const isCampaignChanged = JSON.stringify(campaign) !== campaignCopy; // is campaigns data changed on each step or not
    const data: CampaignType | undefined = campaign;

    if (isUserLoggedIn && isCampaignChanged && data) {
      if (data._id) {
        updateCampaign(data);
      } else {
        const isSkagFirstStepFilled = isSkag && data.keywordsList.length;
        const isStagFirstStepFilled = isSTAG && data.adGroupList.length;

        if (isSkagFirstStepFilled || isStagFirstStepFilled) {
          createCampaign();
        }
      }
      setCopy(JSON.stringify(campaign));
    }
  };

  const updateAndContinue = () => {
    updateCampaignOnServer();
    dispatch(
      isSkag ? setSkagStep(currentStep + 1) : setStagStep(currentStep + 1)
    );
  };

  const onNext = () => {
    if (!isNextDisabled) {
      if (
        (isSkag && currentStep + 1 === 5) ||
        (isSTAG && currentStep + 1 === 6)
      ) {
        if (isUserLoggedIn) {
          showSaveCampaign();
        } else {
          showSignIn();
        }
      } else if (isSkagKeywordsStep && isSkagKeywordsIncludeProhibitedSymbols) {
        notifyError({
          msg: prohibitedSymbolsInKeywordsErrorMessage,
        });
      } else if (isStagKeywordsStep) {
        const ids = getStagAdGroupsIdsWithProhibitedSymbolsInKeywords(
          campaign.adGroupList
        );

        campaign.adGroupList.forEach((adGroup) => {
          const isError = ids.includes(adGroup.id);

          dispatch(setKeywordsValidationError(adGroup.id, isError));
        });

        if (!isEmpty(ids)) {
          notifyError({
            msg: prohibitedSymbolsInKeywordsErrorMessage,
          });
        } else {
          updateAndContinue();
        }
      } else if (isSettingsStep && isWrongCpaTarget) {
        notifyError({
          msg: 'Please use positive decimal numbers in "Goal value" input',
        });
      } else if (isSettingsStep && isWrongRoasTarget) {
        notifyError({
          msg: 'Please use positive numbers from 0.1 to 1000 inclusive in "Goal value" input',
        });
      } else {
        updateAndContinue();
      }
    }
  };

  const onJump = (step: number) => {
    if (!isNextDisabled) {
      updateCampaignOnServer();
      if (step > 0) {
        checkCurrentStep(); // if user was in the ad editing (from review table), we should update step structure
        dispatch(isSkag ? setSkagStep(step) : setStagStep(step));
        return;
      }
      history.push(LOCATIONS.dashboard);
      return;
    }
    if (step < currentStep && step !== 0) {
      updateCampaignOnServer();
      dispatch(isSkag ? setSkagStep(step) : setStagStep(step));
      return;
    }
    if (step === 0) {
      updateCampaignOnServer();
      history.push(LOCATIONS.dashboard);
    }
  };

  const [SendResetPasswordModal, showSendResetPasswordLinkModal] = useModal(
    SendResetPasswordLinkModal,
    {
      id: 'SendResetPasswordLinkModal',
    }
  );

  const [SignIn, showSignIn] = useModal(SignInModal, {
    id: 'stepsSignInModal',
    setIsSignedIn: setIsSignedIn,
    showSendResetPasswordLinkModal: showSendResetPasswordLinkModal,
    onSignUpClick: ref.current,
  });

  signUpForm = useModal(SignUpModal, {
    id: 'signUpModal',
    setIsSignedIn: setIsSignedIn,
    onSignInClick: showSignIn,
  });

  const [PricingPlan, showPricingPlans] = useModal(ChoosePaymentPlanModal, {
    id: 'pricingPlansModal',
  });

  const [SaveCampaign, showSaveCampaign] = useModal(SaveCampaignModal, {
    isSkag: isSkag,
    id: 'campaignModal',
    upgradePlan: showPricingPlans,
  });

  const [CannotCreateCampaign, showCannotCreateCampaign] = useModal(
    CannotCreateCampaignModal,
    {
      id: 'cannotCreateCampaignModal',
    }
  );

  return (
    <>
      {SignIn}
      {signUpForm[SIGN_UP_FORM_KEYS.component]}
      {SendResetPasswordModal}
      {SaveCampaign}
      {PricingPlan}
      {CannotCreateCampaign}
      <StepHeader>
        {progressBarSteps.map((label, index) => {
          const isCurrent = currentStep === index;
          const isZeroStep = index === 0;
          const isLastStep = index === progressBarSteps.length - 1;
          const isPrevStep = index <= currentStep;
          return (
            <BuilderStepLayoutItem
              onClick={() => onJump(index)}
              isPrevStep={isPrevStep}
              key={index}
              label={label}
              isCurrent={isCurrent}
              isZeroStep={isZeroStep}
              isLastStep={isLastStep}
            />
          );
        })}
      </StepHeader>
      <Children>{children}</Children>
      <StepFooter showStepFooter={showStepFooter}>
        <PrevStepButton onClick={onPrev}>
          BACK
          <PrevStepIconContainer>
            <PrevStepIcon src={PrevIcon} />
          </PrevStepIconContainer>
        </PrevStepButton>
        <NextStepButton onClick={onNext} isDisabled={isNextDisabled}>
          NEXT
          <NextStepIconContainer>
            <NextStepIcon src={NextIcon} />
          </NextStepIconContainer>
        </NextStepButton>
      </StepFooter>
    </>
  );
};

const Children = styled.div`
  margin-top: -14rem;
  padding: 0 12.8rem;
  display: flex;
  justify-content: center;
`;

const StepHeader = styled.div`
  display: flex;
  height: 4rem;
  padding: ${(props) => props.theme.spacing[12]}
    ${(props) => props.theme.spacing[28]} 20rem
    ${(props) => props.theme.spacing[28]};
  background-color: ${(props) => props.theme.colors.white};
`;

const StepFooter = styled.div<{ showStepFooter: boolean }>`
  display: ${(props) => (props.showStepFooter ? 'flex' : 'none')};
  justify-content: center;
  flex-direction: space-between;
  width: 100%;
  margin: ${(props) => props.theme.spacing[5]} 0;
`;

const StepButtonStyles = css`
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: ${(props) => props.theme.radius.xl};
  padding: ${(props) => props.theme.spacing[2]}
    ${(props) => props.theme.spacing[2]} ${(props) => props.theme.spacing[2]}
    ${(props) => props.theme.spacing[10]};
  ${(props) => props.theme.text.fontType.h6};

  font-weight: 700;
  &:hover {
    box-shadow: ${(props) => props.theme.shadows.card};
  }
`;

const PrevStepButton = styled.button`
  margin-right: 22rem;
  color: ${(props) => props.theme.colors.blue[100]};
  background-color: ${(props) => props.theme.colors.white};
  border: 0.1rem solid ${(props) => props.theme.colors.blue[400]};
  ${StepButtonStyles}
`;

const PrevStepIconContainer = styled.div`
  display: flex;
  padding: ${(props) => props.theme.spacing[3]}
    ${(props) => props.theme.spacing[8]};
  margin-left: ${(props) => props.theme.spacing[6]};
  box-sizing: border-box;
  border-radius: ${(props) => props.theme.radius.xl};
  border: 0.2rem solid ${(props) => props.theme.colors.blue[400]};
`;

const PrevStepIcon = styled.img`
  width: 1rem;
  height: 2rem;
`;

const NextStepButton = styled.button<{ isDisabled: boolean }>`
  color: ${(props) => props.theme.colors.white};
  background-color: ${(props) =>
    props.isDisabled
      ? props.theme.colors.blue[400]
      : props.theme.colors.blue[100]};
  border: none;
  ${StepButtonStyles}
  cursor: ${(props) => props.isDisabled && 'default'};

  &:hover {
    box-shadow: ${(props) => props.isDisabled && 'none'};
  }
`;

const NextStepIconContainer = styled.div`
  display: flex;
  padding: ${(props) => props.theme.spacing[3]}
    ${(props) => props.theme.spacing[6]};
  margin-left: ${(props) => props.theme.spacing[6]};
  box-sizing: border-box;
  border-radius: ${(props) => props.theme.radius.xl};
  border: 0.2rem solid ${(props) => props.theme.colors.white};
`;

const NextStepIcon = styled.img`
  width: 2.2rem;
  height: 2rem;
`;
