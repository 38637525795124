import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import ReplaceIcon from 'src/images/general/replace-icon.svg';
import SetIcon from 'src/images/general/set-icon.svg';
import AppendIcon from 'src/images/general/append-icon.svg';
import DeleteIcon from 'src/images/general/delete-icon.svg';
import PauseIcon from 'src/images/general/pause-icon.svg';
import PlayIcon from 'src/images/general/play-icon.svg';
import CloseIcon from 'src/images/general/close-icon.svg';
import { ReplaceTextForm } from './Forms/ReplaceTextForm';
import { AppendTextForm } from './Forms/AppendTextForm';
import { SetTextForm } from './Forms/SetTextForm';
import {
  appendSkagText,
  setSkagText,
  replaceSkagText,
} from 'src/redux/skagCompaign/actions';
import { getFieldsByActionOption } from '../data';
import {
  appendStagText,
  replaceStagText,
  setStagText,
} from 'src/redux/stagCampaign/actions';

type Props = {
  isSkag: boolean;
  onClose: () => void;
  totalAdsCount: number;
  selectAllAds: () => void;
  setPausedItems: () => void;
  removePausedItems: () => void;
  deleteItems: () => void;
  selectedAds: { id: number; adGroupId: number; type: string }[];
};

export const TooltipContent = ({
  isSkag,
  onClose,
  selectedAds,
  totalAdsCount,
  selectAllAds,
  setPausedItems,
  removePausedItems,
  deleteItems,
}: Props) => {
  const [replaceTextModal, showReplaceTextModal] = useState(false);
  const [appendTextModal, showAppendTextModal] = useState(false);
  const [setTextModal, showSetTextModal] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (replaceTextModal) {
      showSetTextModal(false);
      showAppendTextModal(false);
    }
  }, [replaceTextModal]);

  useEffect(() => {
    if (appendTextModal) {
      showReplaceTextModal(false);
      showSetTextModal(false);
    }
  }, [appendTextModal]);

  useEffect(() => {
    if (setTextModal) {
      showAppendTextModal(false);
      showReplaceTextModal(false);
    }
  }, [setTextModal]);

  const onTextActionSubmit = (data: any, type: string) => {
    selectedAds.forEach((ad) => {
      switch (type) {
        case 'append':
          showAppendTextModal(false);
          if (isSkag) {
            dispatch(
              appendSkagText({
                ...data,
                groupId: ad.adGroupId,
                adType: ad.type,
                adId: ad.id,
                value: data.stringToAdd,
                before: data.before,
                field: data.field,
              })
            );
          }
          if (!isSkag) {
            dispatch(
              appendStagText({
                ...data,
                groupId: ad.adGroupId,
                adType: ad.type,
                adId: ad.id,
                value: data.stringToAdd,
                before: data.before,
                field: data.field,
              })
            );
          }
          break;
        case 'set':
          showSetTextModal(false);
          if (isSkag) {
            dispatch(
              setSkagText({
                ...data,
                groupId: ad.adGroupId,
                adType: ad.type,
                adId: ad.id,
                value: data.stringToAdd,
                field: data.field,
              })
            );
          }
          if (!isSkag) {
            dispatch(
              setStagText({
                ...data,
                groupId: ad.adGroupId,
                adType: ad.type,
                adId: ad.id,
                value: data.stringToAdd,
                field: data.field,
              })
            );
          }
          break;
        case 'replace':
          showReplaceTextModal(false);
          if (isSkag) {
            dispatch(
              replaceSkagText({
                ...data,
                groupId: ad.adGroupId,
                adType: ad.type,
                adId: ad.id,
                fields: getFieldsByActionOption(data.selectedField),
              })
            );
          }
          if (!isSkag) {
            dispatch(
              replaceStagText({
                ...data,
                groupId: ad.adGroupId,
                adType: ad.type,
                adId: ad.id,
                fields: getFieldsByActionOption(data.selectedField),
              })
            );
          }
          break;
        default:
          return;
      }
    });
  };

  return (
    <Container>
      <SelectContainer>
        <Bold>{selectedAds.length}</Bold> from <Bold>{totalAdsCount}</Bold>{' '}
        selected
        <SelectAll onClick={selectAllAds}>Select all</SelectAll>
      </SelectContainer>
      <TextActionsContainer>
        <ActionBlock onClick={() => showReplaceTextModal(true)}>
          <Image src={ReplaceIcon} alt='replace' />
          <ActionLabel openedModal={replaceTextModal}>Replace text</ActionLabel>
        </ActionBlock>
        {replaceTextModal && (
          <ReplaceTextForm
            onClose={() => showReplaceTextModal(false)}
            itemNumber={1}
            onSubmit={(data) => onTextActionSubmit(data, 'replace')}
          />
        )}
        <ActionBlock onClick={() => showAppendTextModal(true)}>
          <Image src={AppendIcon} alt='append' />
          <ActionLabel openedModal={appendTextModal}>Append text</ActionLabel>
        </ActionBlock>
        {appendTextModal && (
          <AppendTextForm
            itemNumber={2}
            onClose={() => showAppendTextModal(false)}
            onSubmit={(data) => onTextActionSubmit(data, 'append')}
          />
        )}
        <ActionBlock onClick={() => showSetTextModal(true)}>
          <Image src={SetIcon} alt='set' />
          <ActionLabel openedModal={setTextModal}>Set text</ActionLabel>
        </ActionBlock>
        {setTextModal && (
          <SetTextForm
            onClose={() => showSetTextModal(false)}
            itemNumber={3}
            onSubmit={(data) => onTextActionSubmit(data, 'set')}
          />
        )}
      </TextActionsContainer>
      <PauseDeleteContainer>
        <PauseDeleteBlock>
          <ActionBlock onClick={setPausedItems}>
            <Image src={PauseIcon} alt='pause' />
            <Pause>Pause</Pause>
          </ActionBlock>
          <ActionBlock onClick={removePausedItems}>
            <Image src={PlayIcon} alt='Resume' />
            <Pause>Resume</Pause>
          </ActionBlock>
          <ActionBlock onClick={deleteItems}>
            <Image src={DeleteIcon} alt='delete' />
            <Delete>Delete</Delete>
          </ActionBlock>
        </PauseDeleteBlock>
        <Image
          src={CloseIcon}
          alt='close'
          width='12rem'
          height='12rem'
          style={{ cursor: 'pointer' }}
          onClick={onClose}
        />
      </PauseDeleteContainer>
    </Container>
  );
};

const Container = styled.div`
  height: 7.8rem;
  display: flex;
  flex: 1;
  flex-direction: row;
  font-size: 1.4rem;
  color: black;
`;

const SelectContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-evenly;
  box-shadow: 0.05rem 0rem 0rem ${({ theme }) => theme.colors.grey[200]};
`;

const SelectAll = styled.div`
  text-decoration: underline;
  color: ${({ theme }) => theme.colors.blue[100]};
  cursor: pointer;
`;

const TextActionsContainer = styled.div`
  display: flex;
  flex: 2;
  align-items: center;
  justify-content: space-evenly;
  box-shadow: 0.05rem 0rem 0rem ${({ theme }) => theme.colors.grey[200]};
`;

const ActionBlock = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const ActionLabel = styled.div`
  margin-left: 1rem;
  position: relative;
  color: ${({ openedModal, theme }) =>
    openedModal ? theme.colors.blue[100] : theme.colors.black[300]};
`;

const Image = styled.img``;

const PauseDeleteContainer = styled.div`
  display: flex;
  flex: 2;
  align-items: center;
  justify-content: space-between;
  padding: 0 3.4rem;
`;

const PauseDeleteBlock = styled.div`
  display: flex;
  flex-direction: row;
`;

const Pause = styled.div`
  margin-left: 1rem;
  margin-right: 3rem;
`;

const Delete = styled.div`
  margin-left: 1rem;
`;

const Bold = styled.span`
  font-weight: bold;
`;
