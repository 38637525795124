import { StagStepsState } from './types';
import {
  SET_STAG_STEP,
  REPLACE_STAG_STEP,
  SET_STAG_CURRENT_FORM_DATA,
} from './actionTypes';
import { PROGRESS_BAR_STEPS } from 'src/utils/consts';

const initialState: StagStepsState = {
  stagSteps: PROGRESS_BAR_STEPS.stag,
  currentStep: 0,
  currentItem: null,
  currentAdTypeDetails: {},
};

const stagCreationFlow = (state = initialState, action) => {
  switch (action.type) {
    case SET_STAG_STEP: {
      return {
        ...state,
        currentStep: action.data,
      };
    }
    case REPLACE_STAG_STEP: {
      return {
        ...state,
        stagSteps: state.stagSteps.map((step, index) => {
          if (index === action.payload.index) {
            return action.payload.newStep;
          }
          return step;
        }),
      };
    }
    case SET_STAG_CURRENT_FORM_DATA: {
      return {
        ...state,
        currentItem: action.payload.currentItem,
        currentAdTypeDetails: action.payload.currentAdTypeDetails,
      };
    }
    default:
      return state;
  }
};

export default stagCreationFlow;
