import { useState } from 'react';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import styled from 'styled-components';
import { FieldType } from 'src/utils/types';
import { MIModalMessage } from 'src/components/common/MIModalMessage';
import {
  notifySuccess,
  notifyServerError,
} from 'src/services/notifications/notificationService';
import { MIButton } from 'src/components/common/MIButton';
import MIPasswordInput from 'src/components/common/MIPasswordInput';
import UserService from 'src/services/users/userService';
import { SetNewuserPasswordRequest } from 'src/services/users/models/setNewUserPasswordRequest';

type Props = {
  dismiss?: (event: React.MouseEvent) => void;
};

export const ResetPasswordModal = ({ dismiss }: Props) => {
  const [password, setPassword] = useState<string>('');
  const [confirmedPassword, setConfirmedPassword] = useState<string>('');
  const [validationErrors, setValidationErrors] = useState<Record<string, any>>(
    {}
  );
  const [loading, setLoading] = useState<boolean>(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setValidationErrors({});

    if (!isEqual(password, confirmedPassword)) {
      setValidationErrors({ confirmedPassword: 'Passwords did not match' });
      setLoading(false);
      return;
    } else if (isEmpty(password)) {
      setValidationErrors({
        password: 'This field is required',
      });
      setLoading(false);
      return;
    } else if (isEmpty(confirmedPassword)) {
      setValidationErrors({
        confirmedPassword: 'This field is required',
      });
      setLoading(false);
      return;
    }

    var url = window.location.href;
    var guid = url.split('/reset-password/')[1].toString();

    if (!isGuid(guid)) {
      notifyServerError({
        msg: 'Not valid code',
      });
      setLoading(false);
      return;
    }

    var request = { password, guid } as SetNewuserPasswordRequest;

    UserService.setNewUserPassword(request).then((response) => {
      if (response.is_error) {
        notifyServerError({
          msg: 'Server error',
        });
        setLoading(false);
        return;
      } else {
        notifySuccess({
          msg: 'Password was successfully changed',
        });
        setLoading(false);
        dismiss && dismiss(event);
      }
    });
  };
  const isGuid = (string: string) => {
    if (string[0] === '{') {
      string = string.substring(1, string.length - 1);
    }
    var regexGuid =
      /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
    return regexGuid.test(string);
  };
  const onFieldChanged = ({ id, value }: FieldType) => {
    if (id === 'password') {
      setPassword(value);
    }

    if (id === 'confirmedPassword') {
      setConfirmedPassword(value);
    }
  };

  return (
    <MIModalMessage
      dismiss={dismiss}
      titleComponent={
        <ModalTitleContainer>
          <ModalTitle>
            <Bold>Reset password</Bold>
          </ModalTitle>
          <InputsContainer>
            <MIPasswordInput
              id='password'
              value={password}
              label='New password'
              required
              errorMessage={validationErrors.password}
              onChange={onFieldChanged}
            />

            <MIPasswordInput
              id='confirmedPassword'
              value={confirmedPassword}
              label='Confirm password'
              required
              errorMessage={validationErrors.confirmedPassword}
              onChange={onFieldChanged}
            />
          </InputsContainer>
          <MIButton
            label='Reset password'
            type='submit'
            onClick={handleSubmit}
            isProcessing={loading}
            fullWidth
          />
        </ModalTitleContainer>
      }
    />
  );
};

const ModalTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const ModalTitle = styled.span`
  margin-bottom: 1rem;
  ${(props) => props.theme.text.fontType.h5};
  font-weight: ${(props) => props.theme.text.weight.semi};
`;

const Bold = styled.span`
  font-weight: ${(props) => props.theme.text.weight.bold};
`;

const InputsContainer = styled.div`
  margin-top: 1rem;
  width: 100%;
`;
