import { ConversionType } from 'src/utils/types';

export const defaultData: ConversionType = {
  category: null,
  name: '',
  useDefaultValue: null,
  value: '',
  defaultValue: '',
  countingType: null,
  clickThroughConversionWindowDays: '30',
  viewThroughConversionWindowDays: '1',
  attributionModel: 'last',
};

export const countings = {
  ONE_PER_CLICK: 'onePerClick',
  MANY_PER_CLICK: 'manyPerClick',
};

export const categories: { label: string; value: string }[] = [
  { label: 'Purchase', value: 'purchase' },
  { label: 'Add to cart', value: 'addToCart' },
  { label: 'Begin checkout', value: 'beginCheckout' },
  { label: 'Subscribe', value: 'subscribe' },
  { label: 'Contact', value: 'contact' },
  { label: 'Submit lead form', value: 'submitLeadForm' },
  { label: 'Book appointment', value: 'bookAppointment' },
  { label: 'Sign-up', value: 'signUp' },
  { label: 'Request quote', value: 'requestQuote' },
  { label: 'Get directions', value: 'getDirections' },
  { label: 'Outbound click', value: 'outboundClick' },
  { label: 'Page view', value: 'pageView' },
  { label: 'Other', value: 'other' },
];

export const viewPeriods: { label: string; value: string }[] = [
  { label: '30 days', value: '30' },
  { label: '4 weeks', value: '28' },
  { label: '3 weeks', value: '21' },
  { label: '2 weeks', value: '14' },
  { label: '1 week', value: '7' },
  { label: '3 days', value: '3' },
  { label: '1 day', value: '1' },
];

export const clickPeriods: { label: string; value: string }[] = [
  { label: '90 days', value: '90' },
  { label: '60 days', value: '60' },
  { label: '45 days', value: '45' },
  { label: '30 days', value: '30' },
  { label: '4 weeks', value: '28' },
  { label: '3 weeks', value: '21' },
  { label: '2 weeks', value: '14' },
  { label: '1 week', value: '7' },
];

export const attributionModels: { label: string; value: string }[] = [
  { label: 'Last click', value: 'last' },
  { label: 'First click', value: 'first' },
  { label: 'Linear', value: 'linear' },
  { label: 'Time decay', value: 'time' },
  { label: 'Possition-based', value: 'possition' },
];
