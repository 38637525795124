import { useMemo } from 'react';
import uniqBy from 'lodash/uniqBy';
import styled from 'styled-components';
import { MIDropDown } from 'src/components/common/MIDropDown';
import { MITextInput } from 'src/components/common/MITextInput';
import { MINumberRange } from 'src/components/common/MINumberRange';
import { TEXT_INPUT_SIZE } from 'src/utils/consts';

export const KeywordColumnFilter = ({ column: { filterValue, setFilter } }) => {
  return (
    <KeywordColumnFilterWrapper>
      <MITextInput
        id='searchKeyword'
        value={filterValue || ''}
        size={TEXT_INPUT_SIZE.WIZARD}
        label='Filter by keyword'
        onChange={(e) => {
          setFilter(e.value || undefined);
        }}
        placeholder='Search keyword'
        required
      />
    </KeywordColumnFilterWrapper>
  );
};

export const VolumeRangeColumnFilter = ({
  column: { filterValue = [], preFilteredRows, setFilter, id },
}) => {
  const [min, max] = useMemo(() => {
    let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    preFilteredRows.forEach((row) => {
      min = Math.min(row.values[id], min);
      max = Math.max(row.values[id], max);
    });
    return [min, max];
  }, [id, preFilteredRows]);

  return (
    <MINumberRange
      label='Filter by search volume'
      min={min}
      max={max}
      filterValue={filterValue}
      setFilter={setFilter}
    />
  );
};

export const CompetitionColumnFilter = ({
  column: { filterValue, setFilter, preFilteredRows, id },
}) => {
  const uniqueOptions = uniqBy(preFilteredRows, 'values.competition');
  const count = uniqueOptions.length;
  const options: any[] = useMemo(() => {
    const options = new Set();
    options.add({
      label: 'ALL',
      value: '',
    });
    uniqueOptions.forEach((row: any) => {
      options.add({
        label: row.values[id],
        value: row.values[id],
      });
    });
    return [...options.values()];
  }, [id, uniqueOptions]);

  return (
    <>
      <ItemHeader>
        <Text>Filter by competition</Text>
        <Text>
          Total <Bold>{count}</Bold>
        </Text>
      </ItemHeader>
      <MIDropDown
        label={filterValue || 'Choose competition'}
        value={filterValue}
        options={options}
        onChange={(e) => {
          setFilter(e.value || undefined);
        }}
        transparent={false}
      />
    </>
  );
};

const ItemHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${(props) => props.theme.spacing[2]};
`;

const Text = styled.div`
  font-size: 1.2rem;
  font-weight: ${(props) => props.theme.text.weight.semi};
  letter-spacing: 0.02rem;
  line-height: 1.8rem;
  color: ${(props) => props.theme.colors.grey[600]};
`;

const KeywordColumnFilterWrapper = styled.div`
  margin-bottom: ${(props) => props.theme.spacing[2]};
`;

const Bold = styled.span`
  font-weight: ${(props) => props.theme.text.weight.semi};
`;

export const LowBidRangeColumnFilter = ({
  column: { filterValue = [], preFilteredRows, setFilter, id },
}) => {
  const [min, max] = useMemo(() => {
    let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    preFilteredRows.forEach((row) => {
      min = Math.min(row.values[id], min);
      max = Math.max(row.values[id], max);
    });
    return [min, max];
  }, [id, preFilteredRows]);

  return (
    <MINumberRange
      label='Filter by low bid range'
      min={min}
      max={max}
      filterValue={filterValue}
      setFilter={setFilter}
    />
  );
};

export const HighBidRangeColumnFilter = ({
  column: { filterValue = [], preFilteredRows, setFilter, id },
}) => {
  const [min, max] = useMemo(() => {
    let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    preFilteredRows.forEach((row) => {
      min = Math.min(row.values[id], min);
      max = Math.max(row.values[id], max);
    });
    return [min, max];
  }, [id, preFilteredRows]);

  return (
    <MINumberRange
      label='Filter by high bid range'
      min={min}
      max={max}
      filterValue={filterValue}
      setFilter={setFilter}
    />
  );
};
