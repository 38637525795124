import { useEffect, useState, useCallback } from 'react';
import styled from 'styled-components';
import { AdType, TemplatesType } from 'src/utils/types';
import { AdCreatorTableItem } from './AdCreatorTableItem';
import { TableFooter } from './TableFooter';
import { usePagination } from 'src/hooks/usePagination';
import {
  countSelectedAdAds,
  getGroupedItems,
  splitItemsByPages,
} from './utils';
import { MITableEmptyState } from 'src/components/common/MITableEmtyState';
import { NONE_VALUE } from 'src/utils/consts';
import { replaceTemplatesDependOnTemplateType } from 'src/utils/builder';
import UpArrow from 'src/images/general/up-arrow.svg';
import DownArrow from 'src/images/general/down-arrow.svg';

type Props = {
  isSkag: boolean;
  adsCount: number;
  selectedAdType: AdType;
  selectedAdGroup: string;
  templates: TemplatesType;
};

export const AdCreatorTable = ({
  isSkag,
  adsCount,
  selectedAdType,
  selectedAdGroup,
  templates,
}: Props) => {
  const [isSortActive, setSortActive] = useState<boolean>(false);
  const [sortByAsc, setSortType] = useState<boolean>(true);

  const [pageSize, setPageSize] = useState<number>(10);
  const showEmptyState = adsCount === 0;

  const selectedAdsCount =
    selectedAdGroup !== NONE_VALUE
      ? countSelectedAdAds([templates], selectedAdType)
      : 0;
  const { goToPage, pageCount, pageIndex, setPaginationState } = usePagination({
    totalItems: selectedAdsCount,
    limit: pageSize,
  });

  const sortHandler = useCallback(
    (value) => {
      setSortActive(true);
      if (value !== sortByAsc) {
        setSortType(value);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isSortActive, sortByAsc]
  );

  useEffect(() => {
    setPaginationState(pageIndex);
  }, [pageIndex, setPaginationState]);

  const renderItems = () => {
    const adType = selectedAdGroup !== NONE_VALUE ? selectedAdType : AdType.ALL;
    const items = getGroupedItems(templates, adType);

    const sortedItems = isSortActive
      ? items.sort((firstItem, secondItem) => {
          if (sortByAsc) {
            return firstItem.type > secondItem.type ? 1 : -1;
          } else {
            return firstItem.type < secondItem.type ? 1 : -1;
          }
        })
      : items;

    let groupedItems = splitItemsByPages(
      sortedItems,
      adsCount,
      pageIndex,
      pageSize
    );
    let parsedItems = groupedItems.slice();

    if (selectedAdGroup !== NONE_VALUE) {
      const formInJson = JSON.stringify(parsedItems);
      const formWithReplacedKeywords = replaceTemplatesDependOnTemplateType(
        formInJson,
        selectedAdGroup
      );
      parsedItems = JSON.parse(formWithReplacedKeywords);
    }

    return groupedItems.map((item, index) => (
      <AdCreatorTableItem
        isSkag={isSkag}
        key={index}
        template={item}
        item={parsedItems[index]}
      />
    ));
  };

  return (
    <Container>
      <BlockContainer>
        <Header>
          <HeaderItem>Ad Templates</HeaderItem>
          <HeaderItem>
            Type
            <ArrowsContainer>
              <Arrow
                src={UpArrow}
                onClick={() => sortHandler(true)}
                selected={isSortActive && sortByAsc}
              />
              <Arrow
                src={DownArrow}
                onClick={() => sortHandler(false)}
                selected={isSortActive && !sortByAsc}
              />
            </ArrowsContainer>
          </HeaderItem>
          <HeaderItem>Ad groups</HeaderItem>
        </Header>
        {showEmptyState ? (
          <MITableEmptyState canCreateAd />
        ) : (
          <ItemsContainer>{renderItems()}</ItemsContainer>
        )}
        <Footer
          onPageChange={({ selected }) => goToPage(selected + 1)}
          currentPage={pageIndex - 1}
          pageCount={pageCount}
          onPageSizeChange={setPageSize}
          pageSize={pageSize}
        />
      </BlockContainer>
    </Container>
  );
};

const Container = styled.div`
  width: inherit;
  margin-bottom: ${(props) => props.theme.spacing[5]};
  display: flex;
  flex-direction: column;
`;

const BlockContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: ${(props) => props.theme.radius.xl};
  overflow: hidden;
  border: 0.1rem solid ${(props) => props.theme.colors.blue[400]};
`;

const Header = styled.div`
  height: 5.5rem;
  display: flex;
  justify-content: space-between;
  padding: 0 ${(props) => props.theme.spacing[6]};
  color: ${(props) => props.theme.colors.white};
  background-color: ${(props) => props.theme.colors.blue[100]};
  ${(props) => props.theme.text.fontType.body3};

  > div:nth-child(1) {
    width: 60rem;
  }
`;

const HeaderItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 5rem;
`;

const ItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 3rem;
  ${(props) => props.theme.text.fontType.body3};
`;

const ArrowsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
`;

const Arrow = styled.img`
  padding: 0.4rem;
  cursor: pointer;
  background-color: ${({ theme, selected }) =>
    selected ? theme.colors.blue[200] : 'none'};
`;

const Footer = styled(TableFooter)`
  border-top: 0.1rem solid ${(props) => props.theme.colors.blue[300]};
`;
