import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import { KeywordIdeaType } from 'src/utils/types';
import { MIModalMessage } from 'src/components/common/MIModalMessage';
import DownloadImage from 'src/images/general/download-image.svg';
import GoogleAdwardsLogo from 'src/images/general/google-adwards-logo.svg';
import CloudDownloadImage from 'src/images/general/cloud-download.svg';
import KeyWordPlannerService from 'src/services/keywordPlanner/keyWordPlannerService';
import {
  notifyServerError,
  notifySuccess,
} from 'src/services/notifications/notificationService';
import { ModalActionItem } from '../SaveCampaignModal/components/ModalActionItem';

type Props = {
  selectedKeywords: KeywordIdeaType[];
  dismiss?: (event: React.MouseEvent) => void;
};

type ModalScreens = 'DEFAULT';

const isGoogleAdsImportEnabled =
  process.env.REACT_APP_GOOGLE_ADS_IMPORT === 'true';

export const OrganizeKeywordsModal = ({ selectedKeywords, dismiss }: Props) => {
  const [isDownloadProcessing, setIsDownloadProcessing] =
    useState<boolean>(false);
  const [currentScreen] = useState<ModalScreens>('DEFAULT');

  const onSaveKeywords = () => console.log('onSaveKeywords');

  const onSort = () => console.log('onSort');

  const csvDownload = useCallback(async () => {
    setIsDownloadProcessing(true);
    KeyWordPlannerService.csvDownload(selectedKeywords)
      .then((response) => {
        if (response.is_error) {
          setIsDownloadProcessing(false);
          notifyServerError({ msg: 'Server error' });
          return;
        }
        setIsDownloadProcessing(false);
        notifySuccess({ msg: 'Keywords download started' });
        return;
      })
      .catch(() => {
        setIsDownloadProcessing(false);
        notifyServerError({ msg: 'Server error' });
        return;
      });
  }, [selectedKeywords]);

  const onCsvDownload = useCallback(() => {
    csvDownload();
  }, [csvDownload]);

  const modalContent = useMemo(() => {
    switch (currentScreen) {
      case 'DEFAULT':
      default:
        return (
          <ModalTitleContainer>
            <ModalTitle>
              Organize <Bold>Keywords</Bold>
            </ModalTitle>
            <ItemsContainer>
              <ModalActionItem
                onClick={onCsvDownload}
                icon={DownloadImage}
                title='Download'
                subtitle='CSV'
                loading={isDownloadProcessing}
              />
              {isGoogleAdsImportEnabled && (
                <ModalActionItem
                  onClick={onSort}
                  icon={GoogleAdwardsLogo}
                  title='Sort & Group'
                  subtitle='Keywords'
                />
              )}
              <ModalActionItem
                onClick={onSaveKeywords}
                icon={CloudDownloadImage}
                title='Save'
                subtitle='Keyword List'
              />
            </ItemsContainer>
          </ModalTitleContainer>
        );
    }
  }, [
    isDownloadProcessing,
    currentScreen,
    onCsvDownload,
    onSort,
    onSaveKeywords,
  ]);

  return <MIModalMessage dismiss={dismiss} contentComponent={modalContent} />;
};

const ModalTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const ModalTitle = styled.span`
  ${(props) => props.theme.text.fontType.h5};
  font-weight: ${(props) => props.theme.text.weight.normal};
`;

const Bold = styled.span`
  font-weight: ${(props) => props.theme.text.weight.bold};
`;

const ItemsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  > div:nth-child(2) {
    margin: 5rem 2rem;
  }
`;
