import { SyntheticEvent } from 'react';
import styled from 'styled-components';

type Types = {
  id: string;
  options: { label: string; onClick: () => void; icon?: string }[];
  position?: object;
};

export const MIContextMenu = ({ id, options, position = {} }: Types) => {
  const clickHandler = (
    e: SyntheticEvent<HTMLElement>,
    handler: () => void
  ) => {
    e.stopPropagation();
    handler();
  };

  return (
    <MenuContainer id={id} style={position}>
      {options.map((option) => (
        <Option
          key={option.label}
          onClick={(e) => clickHandler(e, option.onClick)}
          centered={!option.icon}
        >
          {option.icon && <Icon src={option.icon} />}
          <Label>{option.label}</Label>
        </Option>
      ))}
    </MenuContainer>
  );
};

const MenuContainer = styled.div`
  position: absolute;
  right: 0;
  display: none;
  border-radius: 1rem;
  overflow: hidden;
  border: 0.1rem solid ${({ theme }) => theme.colors.grey[300]};
  z-index: 10;
`;

const Option = styled.div`
  display: flex;
  justify-content: ${({ centered }) => (centered ? 'center' : 'flex-start')};
  align-items: center;
  width: 15rem;
  height: 4rem;
  background-color: ${({ theme }) => theme.colors.white};
  border-bottom: 0.1rem solid ${({ theme }) => theme.colors.grey[300]};

  &:hover {
    background-color: ${({ theme }) => theme.colors.grey[800]};
  }

  :nth-last-child(1) {
    border: none;
  }
`;

const Label = styled.span`
  font-size: 1.6rem;
`;

const Icon = styled.img`
  margin: 0 1rem;
`;
