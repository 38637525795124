import { CampaignType } from 'src/utils/types';
import RestClient from '../../infrastructure/restClient/RestClient';
import { CampaignDetailsResponse } from './models/campaignModel';

export default class CampaignService {
  public static saveCampaign(request: CampaignType) {
    return RestClient.post<CampaignDetailsResponse>('api/campaigns/', request)
      .then((response) => {
        if (!response.is_error && response.content) {
          return response.content;
        }
      })
      .catch((e) => e);
  }
  public static updateCampaign(request: CampaignType) {
    return RestClient.put<CampaignDetailsResponse>(
      `api/campaigns/${request._id}`,
      request
    )
      .then((response) => {
        if (!response.is_error && response.content) {
          return response.content;
        }
      })
      .catch((e) => e);
  }
  public static updateCampaignName(id: string, name: string) {
    return RestClient.put<CampaignDetailsResponse>(`api/campaigns/${id}`, {
      name,
    })
      .then((response) => {
        if (!response.is_error && response.content) {
          return response.content;
        }
      })
      .catch((e) => e);
  }
  public static deleteCampaign(id: string) {
    return RestClient.delete(`api/campaigns/${id}`)
      .then((response) => {
        if (!response.is_error && response.content) {
          return response.content;
        }
      })
      .catch((e) => e);
  }
  public static csvDownload(request: CampaignType) {
    return RestClient.post('api/campaigns/csv', request);
  }
  public static saveForGoogleApi(request: CampaignType) {
    request.forGoogleAds = true;
    return RestClient.post('api/campaigns/save-campaign', request);
  }
  public static getLink() {
    return RestClient.get('api/campaigns/auth-link');
  }
  public static getCampaigns() {
    return RestClient.get('api/campaigns/');
  }
  public static getCampaignDetails(id: string) {
    return RestClient.get<CampaignDetailsResponse>(`api/campaigns/${id}`)
      .then((response) => {
        if (!response.is_error && response.content) {
          return response.content;
        }
      })
      .catch((e) => e);
  }
}
