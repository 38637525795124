import * as React from 'react';
import styled from 'styled-components';
import { SLIDER_SIZE } from 'src/utils/consts';
import { SliderSizeType } from 'src/utils/types';

type Props = {
  id?: string;
  size?: SliderSizeType;
  positiveLabel?: string | null;
  negativeLabel?: string | null;
  disabled?: boolean;
  value: boolean;
  onChange: (value: boolean, id?: string) => any;
};

export const MISlider = ({
  id,
  size = SLIDER_SIZE.NORMAL,
  positiveLabel,
  negativeLabel,
  value,
  onChange,
  disabled,
}: Props) => {
  const isPositiveSelected = value === false;

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.checked, id);
  };

  return (
    <Container>
      {positiveLabel && (
        <PositiveLabel isPositiveSelected={isPositiveSelected} htmlFor={id}>
          {positiveLabel}
        </PositiveLabel>
      )}
      <BooleanSliderContainer size={size}>
        <Checkbox
          size={size}
          id={id}
          disabled={disabled}
          checked={value}
          onChange={handleOnChange}
        />
        <RoundSlider size={size} />
      </BooleanSliderContainer>
      {negativeLabel && (
        <NegativeLabel isPositiveSelected={isPositiveSelected} htmlFor={id}>
          {negativeLabel}
        </NegativeLabel>
      )}
    </Container>
  );
};

const TINY_ROUND_SIZE = 'calc(1.6rem - 0.4rem)';
const NORMAL_ROUND_SIZE = 'calc(2rem - 0.4rem)';
const ANIMATION_SPEED = '0.2s';

const Container = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const PositiveLabel = styled.label<{ isPositiveSelected: boolean }>`
  color: ${({ theme, isPositiveSelected }) =>
    isPositiveSelected ? theme.colors.black[100] : theme.colors.grey[200]};
  margin-right: ${({ theme }) => theme.spacing[5]};
  cursor: pointer;
  min-width: 2.4rem;
  ${({ theme }) => theme.text.fontType.body2};
`;

export const NegativeLabel = styled.label`
  color: ${({ theme, isPositiveSelected }) =>
    isPositiveSelected ? theme.colors.grey[200] : theme.colors.black[100]};
  margin-left: ${({ theme }) => theme.spacing[5]};
  cursor: pointer;
  min-width: 2.4rem;
  ${({ theme }) => theme.text.fontType.body2};
`;

export const RoundSlider = styled.span<{ size?: boolean }>`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${({ theme }) => theme.colors.grey[500]};
  -webkit-transition: ${ANIMATION_SPEED};
  transition: ${ANIMATION_SPEED};
  border-radius: ${({ size }) =>
    size === SLIDER_SIZE.NORMAL ? NORMAL_ROUND_SIZE : TINY_ROUND_SIZE};

  :before {
    position: absolute;
    content: '';
    height: ${({ size }) =>
      size === SLIDER_SIZE.NORMAL ? NORMAL_ROUND_SIZE : TINY_ROUND_SIZE};
    width: ${({ size }) =>
      size === SLIDER_SIZE.NORMAL ? NORMAL_ROUND_SIZE : TINY_ROUND_SIZE};
    left: 0.2rem;
    bottom: 0.2rem;
    background-color: ${({ theme }) => theme.colors.grey[200]};
    -webkit-transition: ${ANIMATION_SPEED};
    transition: ${ANIMATION_SPEED};
    border-radius: 50%;
  }
`;

export const Checkbox = styled.input.attrs({ type: 'checkbox' })<{
  size?: boolean;
}>`
  :enabled:checked + ${RoundSlider} {
    background-color: ${({ theme }) => theme.colors.grey[500]};
  }

  :enabled:focus + ${RoundSlider} {
    box-shadow: 0 0 0.1rem ${({ theme }) => theme.colors.blue[500]};
  }

  :checked + ${RoundSlider}:before {
    background-color: ${({ theme }) => theme.colors.blue[100]};
    transform: ${({ size }) =>
      size === SLIDER_SIZE.NORMAL
        ? 'translateX(calc(2rem - 0.2rem))'
        : 'translateX(calc(1.4rem - 0.2rem))'};
  }
`;

const BooleanSliderContainer = styled.label<{ size?: boolean }>`
  position: relative;
  display: inline-block;

  width: ${({ size }) => (size === SLIDER_SIZE.NORMAL ? '3.8rem' : '2.8rem')};
  height: ${({ size }) => (size === SLIDER_SIZE.NORMAL ? '2rem' : '1.5rem')};
  border-radius: ${({ size }) =>
    size === SLIDER_SIZE.NORMAL ? NORMAL_ROUND_SIZE : TINY_ROUND_SIZE};
  border: 0.1rem solid ${({ theme }) => theme.colors.blue[300]};

  ${Checkbox} {
    display: none;
  }
`;
