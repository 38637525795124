import * as React from 'react';
import styled from 'styled-components';
import { TextInputSize } from 'src/utils/types';
import Eye from 'src/images/general/eye.svg';
import ClosedEye from 'src/images/general/closed-eye.svg';
import {
  MITextInput,
  MITextInputBaseProps,
} from 'src/components/common/MITextInput';

type Props = MITextInputBaseProps & {
  shouldShowValue?: boolean;
  additionalTitle?: () => void;
};

type State = {
  shouldShowValue: boolean;
};

class MIPasswordInput extends React.PureComponent<Props, State> {
  static defaultProps = {
    ...MITextInput.defaultProps,
    type: 'password',
    shouldShowValue: false,
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      shouldShowValue: props.shouldShowValue || props.type !== 'password',
    };
  }

  togglePassword = () => {
    this.setState(({ shouldShowValue }) => ({
      shouldShowValue: !shouldShowValue,
    }));
  };

  inputTypeHandler = () => {
    const { type, viewOnly, inputMode } = this.props;
    const shouldShowValue = this.state.shouldShowValue && !viewOnly;

    if (shouldShowValue && type === 'password' && inputMode === 'numeric') {
      return 'number';
    } else if (shouldShowValue && type === 'password') {
      return 'text';
    }

    return type;
  };

  render() {
    const { type, size, viewOnly, additionalTitle } = this.props;
    const shouldShowValue = this.state.shouldShowValue && !viewOnly;
    const inputType = this.inputTypeHandler();
    const passwordIcon = shouldShowValue ? Eye : ClosedEye;
    return (
      <MITextInput
        {...this.props}
        transparent={false}
        type={inputType}
        suffix={
          <EyeContainer onClick={this.togglePassword}>
            <EyeIcon
              src={passwordIcon}
              password={type === 'password'}
              size={size as TextInputSize}
              viewOnly={viewOnly}
            />
          </EyeContainer>
        }
        additionalTitle={additionalTitle}
      />
    );
  }
}

export default MIPasswordInput;

const EyeContainer = styled.div`
  display: inline-block;
  position: relative;
  display: flex;
  top: 0.3rem;
  padding: 0.5rem;
  margin: -0.1rem;
`;

const EyeIcon = styled.img<{
  password?: boolean;
  size: TextInputSize;
  viewOnly?: boolean;
}>`
  height: 1.5rem;
  width: 1.5rem;
  display: ${(props) => (!props.viewOnly ? 'block' : 'none')};
  border: none;
  cursor: pointer;
`;
