import { useRef, useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { MISlider } from 'src/components/common/MISlider';
import { PaymentPlan } from 'src/pages/pricing/components/PaymentPlan';
import { MonthlyFree, MonthlyBuilder, MonthlyProBuilder } from './data';
import { useDispatch, useSelector } from 'react-redux';
import { getIsLoggedIn, getUserDetails } from 'src/redux/user/selectors';
import { useModal } from 'src/helpers/react/useModal';
import { SignInModal } from 'src/components/header/Modal/SignInModal';
import { SignUpModal } from 'src/components/header/Modal/SignUpModal';
import { SIGN_UP_FORM_KEYS } from 'src/utils/consts';
import { saveUser } from 'src/redux/user/actions';
import { SendResetPasswordLinkModal } from 'src/components/header/Modal/SendResetPasswordLinkModal';
import PaymentsService from 'src/services/payment/paymentsService';
import { notifyServerError } from 'src/services/notifications/notificationService';
import { MILoader } from 'src/components/common/MILoader';
import { LOCATIONS } from 'src/pages/locations';

export const PricingPlansPage = () => {
  const [isYearly, setisYearly] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const isUserLoggedIn = useSelector(getIsLoggedIn);
  const userDetails = useSelector(getUserDetails);
  let signUpForm: any;
  const ref = useRef();
  const dispatch = useDispatch();
  const setIsSignedIn = useCallback(() => {
    dispatch(saveUser());
  }, [dispatch]);

  useEffect(() => {
    if (signUpForm.length) {
      ref.current = signUpForm[SIGN_UP_FORM_KEYS.handler];
    }
  }, [signUpForm]);

  const onChangePlanTimeFrame = () => {
    setisYearly(!isYearly);
  };

  const history = useHistory();
  const goToBuilder = useCallback(() => history.push(LOCATIONS.dashboard), [history]);

  const handleGetStarted = async (plan: string) => {
    if (!isUserLoggedIn) {
      showSignIn();
    } else {
      try {
        setLoading(true);
        let response;
        if (plan === 'free') {
          response = await PaymentsService.managePricingPlans();
        } else {
          const pricingPlans = await PaymentsService.getPricingPlans();
          response = await PaymentsService.selectPricingPlan(
            pricingPlans[plan]
          );
        }
        window.location = response.redirect;
      } catch (e) {
        notifyServerError({ msg: 'Server error' });
      } finally {
        setLoading(false);
      }
    }
  };

  const [SendResetPasswordModal, showSendResetPasswordLinkModal] = useModal(
    SendResetPasswordLinkModal,
    {
      id: 'SendResetPasswordLinkModal',
    }
  );

  const [SignIn, showSignIn] = useModal(SignInModal, {
    id: 'signInModal',
    setIsSignedIn: setIsSignedIn,
    showSendResetPasswordLinkModal: showSendResetPasswordLinkModal,
    onSignUpClick: ref.current,
    onSuccess: goToBuilder,
  });

  signUpForm = useModal(SignUpModal, {
    id: 'signUpModal',
    setIsSignedIn: setIsSignedIn,
    onSignInClick: showSignIn,
    onSuccess: goToBuilder,
  });

  return (
    <>
      {SignIn}
      {signUpForm[SIGN_UP_FORM_KEYS.component]}
      {SendResetPasswordModal}
      <Container>
        <Title>
          Choose a <Bold>Plan</Bold>
        </Title>
        <SliderContainer>
          <MISlider
            positiveLabel='Monthly'
            negativeLabel='Yearly'
            value={isYearly}
            onChange={onChangePlanTimeFrame}
          />
        </SliderContainer>
        <ItemsContainer>
          {isYearly ? (
            <>
              <PaymentPlan
                type='free'
                interval='year'
                userDetails={userDetails}
                title='Free'
                subtitle='Everything you need to build small campaigns'
                amount='$0'
                descriptionItems={MonthlyFree}
                onClick={() => handleGetStarted('free')}
              />
              <PaymentPlan
                type='basic'
                interval='year'
                userDetails={userDetails}
                title='Builder'
                subtitle='Unlimited ads, keywords, ad groups and 5 campaigns'
                amount='$399'
                descriptionItems={MonthlyBuilder}
                onClick={() => handleGetStarted('yearlyBasicPrice')}
              />
              <PaymentPlan
                type='builder pro'
                interval='year'
                userDetails={userDetails}
                title='Pro Builder'
                subtitle='Unlimited Access To All Features'
                amount='$699'
                descriptionItems={MonthlyProBuilder}
                onClick={() => handleGetStarted('yearlyProPrice')}
              />
            </>
          ) : (
            <>
              <PaymentPlan
                type='free'
                interval='month'
                userDetails={userDetails}
                title='Free'
                subtitle='Everything you need to build small campaigns'
                amount='$0'
                descriptionItems={MonthlyFree}
                onClick={() => handleGetStarted('free')}
              />
              <PaymentPlan
                type='basic'
                interval='month'
                userDetails={userDetails}
                title='Builda'
                subtitle='Unlimited ads, keywords, ad groups and 5 campaigns'
                amount='$49'
                descriptionItems={MonthlyBuilder}
                onClick={() => handleGetStarted('monthlyBasicPrice')}
              />
              <PaymentPlan
                type='builder pro'
                interval='month'
                userDetails={userDetails}
                title='Builda Pro '
                subtitle='Unlimited Access To All Features'
                amount='$99'
                descriptionItems={MonthlyProBuilder}
                onClick={() => handleGetStarted('monthlyProPrice')}
              /> 
            </>
          )}
        </ItemsContainer>
      </Container>
      <LoaderContainer>
        {loading && <MILoader context='button' color='primary' />}
      </LoaderContainer>
    </>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const Title = styled.span`
  ${(props) => props.theme.text.fontType.h5};
  margin-top: ${(props) => props.theme.spacing[12]};
  margin-bottom: ${(props) => props.theme.spacing[2]};
  font-weight: 300;
`;

const Bold = styled.span`
  font-weight: ${(props) => props.theme.text.weight.bold};
`;

const SliderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: ${(props) => props.theme.spacing[5]} 0;
  width: 100%;
`;

const ItemsContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${(props) => props.theme.spacing[2]};
  margin-bottom: ${(props) => props.theme.spacing[5]};
  width: 100%;

  > div:nth-child(1) {
    margin-right: ${(props) => props.theme.spacing[5]};
  }
  // > div:nth-child(2) {
  //   margin-left: ${(props) => props.theme.spacing[5]};
  // }
  > div:nth-child(3) {
    margin-left: ${(props) => props.theme.spacing[5]};
  }`;

const LoaderContainer = styled.div`
  position: relative;
`;
