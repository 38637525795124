export const LOAD_STAG_CAMPAIGN: 'LOAD_STAG_CAMPAIGN' = 'LOAD_STAG_CAMPAIGN';
export const SET_STAG_AD_GROUPS: 'SET_STAG_AD_GROUPS' = 'SET_STAG_AD_GROUPS';
export const SET_KEYWORDS_FOR_GROUP: 'SET_KEYWORDS_FOR_GROUP' =
  'SET_KEYWORDS_FOR_GROUP';
export const CREATE_STAG_ADS: 'CREATE_STAG_ADS' = 'CREATE_STAG_ADS';
export const UPDATE_STAG_ADS: 'UPDATE_STAG_ADS' = 'UPDATE_STAG_ADS';
export const UPDATE_ONE_STAG_AD: 'UPDATE_ONE_STAG_AD' = 'UPDATE_ONE_STAG_AD';
export const DELETE_STAG_AD: 'DELETE_STAG_AD' = 'DELETE_STAG_AD';
export const DELETE_ALL_STAG_ADS_BY_TYPE: 'DELETE_ALL_STAG_ADS_BY_TYPE' =
  'DELETE_ALL_STAG_ADS_BY_TYPE';
export const SET_STAG_SETTINGS: 'SET_STAG_SETTINGS' = 'SET_STAG_SETTINGS';
export const REPLACE_STAG_TEXT: 'REPLACE_STAG_TEXT' = 'REPLACE_STAG_TEXT';
export const APPEND_STAG_TEXT: 'APPEND_STAG_TEXT' = 'APPEND_STAG_TEXT';
export const SET_STAG_TEXT: 'SET_STAG_TEXT' = 'SET_STAG_TEXT';
export const UPDATE_NEGATIVES_IN_STAG_GROUP: 'UPDATE_NEGATIVES_IN_STAG_GROUP' =
  'UPDATE_NEGATIVES_IN_STAG_GROUP';
export const DUPLICATE_STAG_AD_TEMPLATE: 'DUPLICATE_STAG_AD_TEMPLATE' =
  'DUPLICATE_STAG_AD_TEMPLATE';
export const DUPLICATE_STAG_AD_GROUP: 'DUPLICATE_STAG_AD_GROUP' =
  'DUPLICATE_STAG_AD_GROUP';
export const CLEAN_STAG_CAMPAIGN: 'CLEAN_STAG_CAMPAIGN' = 'CLEAN_STAG_CAMPAIGN';
export const SET_KEYWORDS_VALIDATION_ERROR: 'SET_KEYWORDS_VALIDATION_ERROR' =
  'SET_KEYWORDS_VALIDATION_ERROR';
